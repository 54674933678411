import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import Button from "components/common/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import logo from "../../assets/img/logo.png";

import {
  Typography,
  FormHelperText,
  FormControl,
  Grid,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
} from "@material-ui/core";

import { connect } from "react-redux";
import "./Register.scss";
import MailIcon from "@material-ui/icons/Mail";
import Visibility from "@material-ui/icons/Visibility";
import LockIcon from "@material-ui/icons/Lock";
import { unmaskPhone, getBrowserLanguage } from "../../utils/Utils";
import { register } from "../../actions/auth";
import { Redirect } from "react-router-dom";
import Loader from "../../utils/Loader";
import { parsePhoneNumber, checkPasswordStrength } from "../../utils/UserUtils";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import { ArrowBack } from "@material-ui/icons";
import RegisterIconTextField from "./RegisterIconTextField";

const useStyles = createStyles(styles);

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tyc: false,
      tycAdm: false,
      tycAdmError: false,
      password: "",
      confirmPassword: "",
      email: "",
      confirmEmail: "",
      phone: "",
      updating: false,
      old: "",
      passwordsDontMatch: false,
      passwordValidationLength: false,
      passwordValidationNumbers: false,
      passwordValidationSymbols: false,
      passwordValidationLower: false,
      passwordValidationUpper: false,
      passwordError: true,
      emailDontMatch: false,
      tycError: false,
      psw_type: "password",
      psw_type_confirm: "password",
      confirmDialog: false,
    };

    this.handleTycChange = this.handleTycChange.bind(this);
    this.handleTycAdmChange = this.handleTycAdmChange.bind(this);
    this.register = this.register.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.showHidePsw = this.showHidePsw.bind(this);
    this.dialogClose = this.dialogClose.bind(this);
    this.dialogOpen = this.dialogOpen.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkPasswordStrength = checkPasswordStrength.bind(this);
  }

  handleEmailChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      emailDontMatch: false,
    });
  }

  handlePasswordChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      passwordsDontMatch: false,
    });

    if(ev.target.name == 'password'){
      this.checkPasswordStrength(ev.target.value);
    }    
  }

  handleTycChange() {
    this.setState({
      tyc: !this.state.tyc,
      tycError: false,
    });
  }

  handleTycAdmChange() {
    this.setState({
      tycAdm: !this.state.tycAdm,
      tycAdmError: false,
    });
  }

  handleNumberChange(ev) {
    const { phone } = this.state;
    let s = ev.target.value;

    this.setState({
      phone: parsePhoneNumber(phone, s),
    });
  }

  showHidePsw(field = null) {
    if (field) {
      let type = this.state[field];
      if (type === "text") type = "password";
      else type = "text";
      this.setState({
        [field]: type,
      });
    }
  }

  onSubmit(ev) {
    ev.preventDefault();
    const { t, modeAdm } = this.props;
    const { password, email, phone } = this.state;

    if (this.isValidData()) {
      if (modeAdm) {
        this.dialogOpen();
      } else {
        this.props.register(
          { password, email, phone: unmaskPhone(phone) },
          t,
          modeAdm
        );
      }
    }
  }

  register(ev) {
    ev.preventDefault();

    const { t, modeAdm } = this.props;
    const { password, email, phone } = this.state;

    this.props.register(
      { password, email, phone: unmaskPhone(phone) },
      t,
      modeAdm
    );
  }

  dialogClose() {
    this.setState({
      confirmDialog: false,
    });
  }

  dialogOpen() {
    this.setState({
      confirmDialog: true,
    });
  }

  isValidData() {
    const {
      password,
      confirmPassword,
      tyc,
      email,
      confirmEmail,
      tycAdm,
    } = this.state;
    const { modeAdm } = this.props;
    let errors = {};

    if (email !== confirmEmail) {
      errors = {
        emailDontMatch: true,
      };
    }
    if (password !== confirmPassword) {
      errors = {
        ...errors,
        passwordsDontMatch: true,
      };
    }

    if (!tyc) {
      errors = {
        ...errors,
        tycError: true,
      };
    }

    if (modeAdm && !tycAdm) {
      errors = {
        ...errors,
        tycAdmError: true,
      };
    }

    if (Object.entries(errors).length === 0) {
      return true;
    }

    this.setState(errors);

    return false;
  }

  render() {
    const { t, classes, loading, registered, modeAdm } = this.props;
    const {
      tyc,
      email,
      tycError,
      tycAdm,
      tycAdmError,
      confirmDialog,
      passwordValidationLength,
      passwordValidationNumbers,
      passwordValidationSymbols,
      passwordValidationLower,
      passwordValidationUpper,
      passwordError,
    } = this.state;

    if (registered) {
      return <Redirect from="/register" to="/register/verify" />;
    }

    return (
      <>
        <Link href="/login" variant="body2">
          <IconButton
            aria-label="back"
            style={{ marginRight: "2em", marginLeft: "2em", marginTop: "1em" }}
          >
            <ArrowBack color="black" />
          </IconButton>
        </Link>
        <div className="register">
          <img alt="Web Logo" className="logo" src={logo} />
          <Typography className="info">
            {modeAdm ? t("adm_register") : t("create_user_info")}
          </Typography>

          <Grid container>
            <Grid item xs></Grid>
            <Grid item lg={4} sm={6} xs={11}>
              <form onSubmit={this.onSubmit}>
                <RegisterIconTextField
                  inputProps={{
                    type: "email",
                    required: true,
                  }}
                  label={this.props.t("mail")}
                  name="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  icon={<MailIcon />}
                />
                <RegisterIconTextField
                  inputProps={{
                    type: "email",
                    required: true,
                  }}
                  label={this.props.t("repeat_mail")}
                  name="confirmEmail"
                  value={this.state.confirmEmail}
                  onChange={this.handleEmailChange}
                  icon={<MailIcon />}
                  error={
                    this.state.emailDontMatch
                      ? this.props.t("email_dont_match")
                      : null
                  }
                />
                <div className="box-psw">
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                      {" "}
                      {/* Adjust the value for xs as needed */}
                      <RegisterIconTextField
                        inputProps={{
                          autoComplete: "new-password",
                          required: true,
                          type: this.state.psw_type,
                        }}
                        onChange={this.handlePasswordChange}
                        error={
                          this.state.passwordsDontMatch
                            ? this.props.t("passwords_dont_match")
                            : null
                        }
                        value={this.state.password}
                        name="password"
                        label={this.props.t("password")}
                        icon={<LockIcon />}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {this.state.password && (
                        <Visibility
                          className="show-psw"
                          onMouseDown={() => this.showHidePsw("psw_type")}
                          onMouseUp={() => this.showHidePsw("psw_type")}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>

                <Typography className="info passwordValidation">
                  <p className={passwordValidationLength ? 'success' : ''}>{this.props.t("password_validation_length")}</p>
                  <p className={passwordValidationNumbers ? 'success' : ''}>{this.props.t("password_validation_numbers")}</p>
                  <p className={passwordValidationSymbols ? 'success' : ''}>{this.props.t("password_validation_symbols")}</p>
                  <p className={passwordValidationLower ? 'success' : ''}>{this.props.t("password_validation_lower")}</p>
                  <p className={passwordValidationUpper ? 'success' : ''}>{this.props.t("password_validation_upper")}</p>
                </Typography>

                <div className="box-psw">
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                      <RegisterIconTextField
                        inputProps={{
                          autoComplete: "new-password",
                          required: true,
                          type: this.state.psw_type_confirm,
                        }}
                        name="confirmPassword"
                        error={
                          this.state.passwordsDontMatch
                            ? this.props.t("passwords_dont_match")
                            : null
                        }
                        value={this.state.confirmPassword}
                        onChange={this.handlePasswordChange}
                        label={this.props.t("repeat_password")}
                        icon={<LockIcon />}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {this.state.confirmPassword && (
                        <Visibility
                          className="show-psw"
                          onMouseDown={() =>
                            this.showHidePsw("psw_type_confirm")
                          }
                          onMouseUp={() => this.showHidePsw("psw_type_confirm")}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
                <FormControl
                  error={tycError}
                  component="fieldset"
                  fullWidth
                  className={classes.formControl + " terms left"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={tyc}
                        onChange={this.handleTycChange}
                      />
                    }
                    label={
                      <span>
                        {t("accept_terms")}{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            process.env.REACT_APP_TERMS_AND_CONDITIONS_URL +
                            "?lang=" +
                            getBrowserLanguage()
                          }
                        >
                          {t("terms")}
                        </a>
                        {modeAdm && " " + t("adm_register_tyc")}
                      </span>
                    }
                  />

                  {tycError && (
                    <FormHelperText className="terms-error">
                      {t("must_accept_terms")}
                    </FormHelperText>
                  )}
                </FormControl>
                {modeAdm ? (
                  <FormControl
                    error={tycAdmError}
                    component="fieldset"
                    fullWidth
                    className={classes.formControl + " left"}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={tycAdm}
                          onChange={this.handleTycAdmChange}
                        />
                      }
                      label={
                        <span>
                          {t("text_TC_1") + " "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              process.env.REACT_APP_TERMS_AND_CONDITIONS_ADM +
                              "?lang=" +
                              getBrowserLanguage()
                            }
                          >
                            {t("terms")}
                          </a>
                          {" " + t("adm_tyc")}
                        </span>
                      }
                    />

                    {tycAdmError && (
                      <FormHelperText className="terms-error">
                        {t("must_accept_terms")}
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : null}
                <FormControl
                  component="fieldset"
                  fullWidth
                  className={classes.formControl}
                >
                  <Button
                    color="primary"
                    type="submit"
                    className="add"
                    disabled={loading || passwordError}
                    round
                  >
                    {t("create_user")}
                  </Button>
                  <div>
                    <Loader className="submit" visible={loading} />
                  </div>
                </FormControl>
              </form>
            </Grid>
            <Grid item xs></Grid>
          </Grid>

          <ConfirmationDialog
            open={confirmDialog}
            onConfirm={this.register}
            onClose={this.dialogClose}
            title={t("adm_confirm")}
            labelNo={t("cancel")}
            labelYes={t("confirm")}
          >
            {email}
          </ConfirmationDialog>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.authReducer.loading,
  user: state.authReducer.user,
  registered: state.authReducer.registered,
  modeAdm: state.authReducer.modeAdm,
});

const actionCreators = {
  register: register,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(Register)));
