import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import {
  TextField,
  FormControl,
  Button,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ParkingsTable from "./ParkingsTables";
import { columns } from "./columns/parkings";

import {
  getGarageList,
  filterList,
  addParkingLots,
  setParkingLotParkingGroup,
  setParkingLotUser,
  unsetParkingLotUser,
  deleteParkingLot,
  editParkingLot,
  checkDeleteParkingLot,
} from "../../actions/parking";

const ParkingsList = (props) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState("");
  const [item, setItem] = useState("");
  const [parkingNames, setParkingNames] = useState([]);
  const [globalPageIndex, setGlobalPageIndex] = useState(0);
  const [canEditInput, setCanEditInput] = useState(true);
  const {
    t,
    getList,
    filterList,
    listAll,
    list,
    parkingGroups,
    listEmployees,
    parkingReservationMode,
    countParkings,
    loading,
    checkDeleteParkingLot,
    showMessaje,
    addParkingLots,
    editParkingLot,
    setParkingLotParkingGroup,
    setParkingLotUser,
    deleteParkingLot,
    unsetParkingLotUser,
  } = props;

  useEffect(() => {
    getList(t);
  }, []);

  const setCurrentPage = (page) => {
    setGlobalPageIndex(page);
  };

  const openNewParking = () => {
    setOpen(true);
    setParkingNames(["", "", "", ""]);
    setAction("add");
  };
  const onClose = () => {
    setOpen(false);
    setParkingNames([]);
  };

  const handleChange = (event, index) => {
    if (canEditInput) {
      let parkingNamesAux = parkingNames.map((name) => name);
      parkingNamesAux[index] = event.target.value;
      setParkingNames(parkingNamesAux);
    } else {
      setCanEditInput(true);
    }
  };

  const addNewName = () => {
    let parkingNamesAux = parkingNames.map((name) => name);
    parkingNamesAux.push("");
    setParkingNames(parkingNamesAux);
  };

  const handlePaste = (event) => {
    setCanEditInput(false);
    const pasteDatas = event.clipboardData;
    const text =
      typeof pasteDatas !== "undefined" ? pasteDatas.getData("text") : "";
    let parkingNamesAux = parkingNames.map((name) => name);
    /** remove the current element */
    let ID_item = event.target.id.replace("parkingName", "");
    ID_item = parseInt(ID_item);
    if (
      typeof parkingNamesAux[ID_item] != "undefined" &&
      parkingNamesAux[ID_item] === ""
    )
      parkingNamesAux.splice(ID_item, 1);

    let newList = parkingNamesAux.filter((name) => {
      if (typeof name !== "undefined" && name !== "") return true;
      return false;
    });

    const lines = text.split("\n");
    if (lines.length > 0) {
      for (let i in lines) {
        if (!lines[i]) {
          break;
        }
        newList.push(lines[i]);
      }
    }
    setParkingNames(newList);
  };

  const handleSearch = (event) => {
    const text = event.target.value;
    setSearchText(text);
    setGlobalPageIndex(0);
    filterList(
      listAll.filter((v) => {
        if (v.name && v.name.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  };

  const handleCancel = (data) => {
    checkDeleteParkingLot(t, data.id);
    setShowConfirm(true);
    setAction("remove");
    setItem(data.id);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const sendItem = () => {
    switch (action) {
      case "add": {
        const parkingNamesAux = parkingNames.filter((v) => {
          if (typeof v !== "undefined" && v.trim() !== "") return true;
          return false;
        });
        let parkingNamesObj = parkingNamesAux.map((v) => {
          return { name: v };
        });

        if (
          typeof parkingNamesObj !== "undefined" &&
          parkingNamesObj.length > 0
        ) {
          addParkingLots(t, parkingNamesObj);
        } else {
          showMessaje(t("error_add_empty_parkingNames"));
          return;
        }
        break;
      }
      case "edit": {
        let name = parkingNames[0];
        if (typeof name !== "undefined" && name.trim() !== "") {
          editParkingLot(t, item, parkingNames[0]);
        } else {
          showMessaje(t("error_add_empty_parkingNames"));
          return;
        }
        break;
      }
      default:
    }
    setOpen(false);
    setParkingNames([]);
    setAction(null);
    setItem(null);
  };

  const handleUpdate = (data, type, id) => {
    switch (type) {
      case "ParkingGroup":
        setParkingLotParkingGroup(t, data, id);
        break;
      case "Employees":
        setParkingLotUser(t, data, id);
        break;
      case "unsetParkingLotUser":
        setShowConfirm(true);
        setAction("unsetParkingLotUser");
        setItem(data.id);
        break;
      default:
        setOpen(true);
        setAction("edit");
        setParkingNames([data.name]);
        setItem(data.id);
        break;
    }
  };

  const handleConfirm = () => {
    switch (action) {
      case "remove":
        deleteParkingLot(t, item);
        break;
      case "unsetParkingLotUser":
        unsetParkingLotUser(t, item);
        break;
      default:
    }
    setShowConfirm(false);
    setAction("");
    setItem(null);
  };

  const formModalParkings = () => {
    if (action === "add") {
      return (
        <div className="flex-right">
          {parkingNames.map((parkingName, i) => (
            <FormControl className="full-input" key={i}>
              <TextField
                type="text"
                label={t("description")}
                fullWidth={true}
                value={parkingName}
                id={"parkingName" + i}
                onChange={(e) => handleChange(e, i)}
                onPaste={(e) => handlePaste(e)}
                key={i}
              ></TextField>
            </FormControl>
          ))}
          <Fab color="primary" onClick={addNewName}>
            <AddIcon />
          </Fab>
        </div>
      );
    }

    if (action === "edit") {
      return (
        <FormControl className="full-input">
          {parkingNames.map((parkingName, i) => (
            <TextField
              type="text"
              label={t("name")}
              fullWidth={true}
              value={parkingName}
              id={"parkingName" + i}
              onChange={(e) => handleChange(e, i)}
              key={i}
            ></TextField>
          ))}
        </FormControl>
      );
    }
  };

  let availableEmployees = [];
  for (let i in listEmployees) {
    let available = true;
    for (let j in listAll) {
      if (listAll[j]["user"] === listEmployees[i]["id"]) {
        available = false;
        break;
      }
    }
    listEmployees[i].available = available;
    availableEmployees.push(listEmployees[i]);
  }

  return (
    <div className="body-box">
      <div className="search-box-2">
        <FormControl className="box-input">
          <TextField
            type="text"
            placeholder={t("search")}
            name="search"
            value={searchText}
            onChange={handleSearch}
          ></TextField>
        </FormControl>
      </div>
      <ParkingsTable
        data={list}
        columns={columns}
        handleUpdate={(data, type, id) => handleUpdate(data, type, id)}
        handleCancel={(data) => handleCancel(data)}
        t={t}
        globalPageIndex={globalPageIndex}
        parkingGroups={parkingGroups}
        listEmployees={availableEmployees}
        parkingReservationMode={parkingReservationMode}
        setCurrentPage={setCurrentPage}
        classTable="table-with-field"
        loading={loading}
      ></ParkingsTable>
      {parkingReservationMode === "byCompany" && (
        <Fab color="primary" onClick={openNewParking}>
          <AddIcon />
        </Fab>
      )}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle onClose={onClose}>
          {action === "add" ? t("add_parking_lots") : t("edit_parking_lot")}
        </DialogTitle>
        <DialogContent className="dialog" dividers>
          {formModalParkings()}
        </DialogContent>
        <DialogActions className="button-group">
          <Button className="buttom-reservation" onClick={onClose}>
            {" "}
            {t("cancel")}
          </Button>
          <Button
            color="primary"
            onClick={sendItem}
            className="buttom-reservation"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirm && countParkings !== false}
        onConfirm={handleConfirm}
        onClose={closeConfirm}
        title={
          action
            ? t("confirm_parkingLot_" + action, {
                countParkings: countParkings,
              })
            : ""
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.parkings.currentList,
  listAll: state.parkings.list,
  countParkings: state.parkings.countParkings,
  parkingGroups: state.parkings.listGroups,
  listEmployees: state.parkings.listEmployees,
  loading: state.parkings.loading,
  parkingReservationMode:
    state.authReducer.user.parkingManager.parkingReservationMode,
});
const mapActionsToProps = {
  getList: getGarageList,
  filterList,
  addParkingLots,
  setParkingLotParkingGroup,
  setParkingLotUser,
  unsetParkingLotUser,
  deleteParkingLot,
  editParkingLot,
  checkDeleteParkingLot,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(ParkingsList));
