export const columns = [
  {
    Header: "description",
    accessor: "name",
  },
  {
    Header: "group",
    accessor: "deskGroup",
    action: "selectDeskGroup",
  },
  {
    Header: "assigned_user",
    accessor: "",
    action: "selectEmployees",
  },
  {
    Header: "desk_qr_print_all",
    action: "printQr",
    headerAction: "printAllQr",
    short: 0,
  },
  {
    Header: "actions",
    action: "actionsDesks",
    short: 0,
  },
];
