export const columns = [
  {
    Header: "photo",
    accessor: "facePhoto",
    action: "avatar",
    short: 0,
  },
  {
    Header: "name",
    accessor: "name",
    action: "link",
  },
  {
    Header: "dni",
    accessor: "dni",
  },
  {
    Header: "phone",
    accessor: "phone",
  },
  {
    Header: "email",
    accessor: "email",
  },
  {
    Header: "place",
    accessor: "place",
  },
  {
    Header: "actions",
    action: "actions",
  },
];
