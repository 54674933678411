import { Grid } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import CharterReserveMain from "views/CharterReserveMain/CharterReserveMain";
import Login from "views/Login/Login";
import {
  getUser,
  loginWithToken,
  logout,
  setCustomizationExternalUser,
  setCustomizationExternalUserEmailValidation,
  setExternalUserToken,
} from "./actions/auth";
import { getGlobalOptions } from "./actions/user";
import Backdrop from "./components/Backdrop/Backdrop";
import DialogHolder from "./components/DialogHolder/DialogHolder";
import JoinEstablishment from "./components/JoinEstablishment/JoinEstablishment";
import Profile from "./components/Profile/Profile";
import { STATE_LOGGED_IN } from "./Constant";
import history from "./history";
import PassAppApi from "./services/PassAppApi";
import Home from "./views/Home/Home";
import PhoneVerification from "./views/PhoneVerification/PhoneVerification";
import PhoneVerificationSMS from "./views/PhoneVerification/PhoneVerificationSMS";
import Register from "./views/Register/Register";
import UploadUserPhoto from "./views/UploadUserPhoto/UploadUserPhoto";
const qs = require("query-string");
class App extends React.Component {
  constructor(props) {
    super(props);
    this.getUserData();
    this.state = {
      isExt: false,
    };
  }
  componentDidUpdate(prevProps) {
    const { state } = this.props;
    if (prevProps.state !== state) {
      this.getUserData();
    }
  }
  getUserData() {
    const { t, token } = this.props;
    const externalUserToken = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).ex;
    if (externalUserToken && externalUserToken !== "") {
      if (token) {
        this.props.logout(t);
        window.localStorage.clear();
      }
      this.setState({ isExt: true });
      PassAppApi.checkExternalUserToken(externalUserToken)
        .then((data) => {
          this.props.setCustomizationExternalUser(data.color);
          this.props.setCustomizationExternalUserEmailValidation(
            data.requiresEmailValidation
          );
          this.props.setExternalUserToken(data.token);
          this.props.loginWithToken(data.token, t, true);
          this.props.setExternalUserToken(data.token);
          this.props.setCustomizationExternalUserEmailValidation(
            data.requiresEmailValidation
          );
        })
        .finally(() => this.setState({ isExt: false }));
    } else {
      if (token) {
        // Ready check
        this.props.getUser(t);
        this.props.getGlobalOptions(t);
      } else {
        const magicToken = qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).token;
        if (magicToken && magicToken !== "") {
          this.props.loginWithToken(magicToken, t);
          return;
        }
      }
    }
  }
  render() {
    const { state, otherCompany } = this.props;
    const { isExt } = this.state;
    return (
      <>
        {isExt ? (
          <Backdrop open={isExt} />
        ) : (
          <Router history={history}>
            <DialogHolder />
            <Backdrop open={otherCompany?.loading} />
            {state === STATE_LOGGED_IN ? (
              <Switch>
                <Route path="/home/" component={Home} />
                <Route path="/login" component={Login} />
                <Redirect from="/logout" to="/" />
                <Redirect to="/home/passes" />
                <Route path="/charterReserve" component={CharterReserveMain} />
              </Switch>
            ) : (
              <Switch>
                <Route path="/charterReserve" component={CharterReserveMain} />
                <Route exact path="/" component={Login} />
                <Route path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route
                  exact
                  path="/register/verify"
                  component={PhoneVerification}
                />
                <Route
                  exact
                  path="/register/verify-SMS"
                  component={PhoneVerificationSMS}
                />
                <Route
                  exact
                  path="/register/uploadUserPhotos"
                  component={UploadUserPhoto}
                />
                <React.Fragment>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item lg={4}>
                      <Switch>
                        <Route
                          exact
                          path="/register/profile"
                          component={Profile}
                        />
                      </Switch>
                    </Grid>
                    <Grid item xs></Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item xs={6}>
                      <Switch>
                        <Route
                          exact
                          path="/register/joinEstablishment"
                          component={JoinEstablishment}
                        />
                      </Switch>
                    </Grid>
                    <Grid item xs></Grid>
                  </Grid>
                </React.Fragment>
              </Switch>
            )}
          </Router>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  state: state.authReducer.state,
  otherCompany: state.authReducer.otherCompany,
  token: state.authReducer.token,
});
const actionCreators = {
  loginWithToken: loginWithToken,
  getUser: getUser,
  logout: logout,
  getGlobalOptions: getGlobalOptions,
  setCustomizationExternalUser: setCustomizationExternalUser,
  setCustomizationExternalUserEmailValidation: setCustomizationExternalUserEmailValidation,
  setExternalUserToken: setExternalUserToken,
};
export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(App));
