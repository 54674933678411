import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GUEST_TYPE_PROVIDER, PASS_TYPE_AUTHORIZATION } from "../../Constant";
import {
  findEstablishment,
  getPassUser,
  getTimezoneLocal,
  getUserFullname,
  isPendingUser,
} from "../../utils/Utils";
import AuthorizationIcon from "../../assets/img/AuthorizationIcon";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import PassStatus from "./PassStatus";
import PassDetail from "../PassDetail/PassDetail";
import { useDispatch } from "react-redux";
import { onDialogOpen } from "../../actions/dialog";
import NotificationAnimationWatchMe from "../NotificationsApp/NotificationAnimationWatchMe";
import { passWasUsed } from "../../utils/Status";
import NotifySecurity from "../NotifySecurity/NotifySecurity";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "5px",
    background: "#FAFAFA",
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
    "& .MuiCardContent-root": {
      padding: "10px",
      paddingBottom: "10px",
    },
  },
  divider: {
    flexGrow: 1,
    paddingInline: "5px",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  line: {
    height: "2px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  tracker: {
    position: "absolute",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  containerShedule: {
    display: "flex",
  },
  labelSchedule: {
    letterSpacing: "-0.03em !important",
  },
  labelIcon: {
    display: "flex",
    flexDirection: "row",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: "center",
  },
  infoContainer: {
    display: "flex",
  },
  infoSubContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

const SchedulePass = ({ startDay, endDay }) => {
  const classes = useStyles();
  return (
    <div className={classes.containerShedule}>
      <Typography variant="caption" className={classes.labelSchedule}>
        {startDay}
      </Typography>
      <div className={classes.divider}>
        <div className={classes.line}></div>
        <div className={classes.tracker} style={{ left: 5 }}></div>
        <div className={classes.tracker} style={{ right: 5 }}></div>
      </div>
      <Typography variant="caption" className={classes.labelSchedule}>
        {endDay}
      </Typography>
    </div>
  );
};

const LabelIconPass = ({ invite, authorization, status, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.labelIcon}>
      {invite && <PersonAddRoundedIcon color="primary" />}
      {authorization && <AuthorizationIcon color="primary" />}
      <div className={classes.spaceBetween}>
        <Typography>{children}</Typography>
        <PassStatus status={status} />
      </div>
    </div>
  );
};

const PassInfo = ({ profile, establishment, startDay, endDay }) => {
  const classes = useStyles();
  const { token_file: tokenFile } = useSelector((state) => state.authReducer);
  const getUrl = (id, token) =>
    token && id
      ? `${process.env.REACT_APP_IMAGES_URL}${id}?token=${token}`
      : null;

  return (
    <div className={classes.infoContainer}>
      {profile && (
        <Avatar
          src={getUrl(profile, tokenFile?.t)}
          alt="profile pass"
          style={{ marginRight: 5 }}
        />
      )}
      <div className={classes.infoSubContainer}>
        <Typography variant="caption">{establishment}</Typography>
        <SchedulePass startDay={startDay} endDay={endDay} />
      </div>
    </div>
  );
};

const Pass = ({ pass, authorization, invite }) => {
  const classes = useStyles();
  const { companies, countries, friends, professionTypes } = useSelector(
    (state) => state.userReducer
  );
  const { token } = useSelector((state) => state.authReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getNameEstablishment = () => {
    let establishment = findEstablishment(pass, companies, countries);
    return establishment?.name || "";
  };

  const getSectorName = (id) => {
    let sectorSelected = professionTypes?.find((sector) => sector.id === id);
    return sectorSelected?.name || "";
  };

  const getLabelUsers = () => {
    let user = getPassUser(pass, friends, token);
    let name = t("pass_label_no_invited");
    if (user != null) {
      if (!isPendingUser(user)) {
        name = getUserFullname(user);

        if (pass.guests.length > 1) {
          name += " (+" + (pass.guests.length - 1) + ")";
        }
      } else {
        name = "";
        if (pass.guests.length >= 1) {
          name = `${pass.guests.length} ${t("guests")}${
            pass.guests.length === 1 ? "" : "s"
          }`;
        }
      }
    } else {
      if (pass.type === PASS_TYPE_AUTHORIZATION) {
        if (pass.guestType === GUEST_TYPE_PROVIDER)
          name = (
            <>
              {pass.providerName}{" "}
              <b>{`(${getSectorName(pass?.providerType)})`} </b>
            </>
          );
        else name = getUserFullname(pass);
      }
    }
    return name;
  };

  const getTimezone = () => {
    const establishment = findEstablishment(pass, companies, countries);
    return establishment && establishment.timezone != null
      ? establishment.timezone
      : getTimezoneLocal();
  };

  const getStartDate = () => {
    const startDateTz = moment.tz(pass.beginTimestamp * 1000, getTimezone());
    return startDateTz.format("DD/MM/YYYY HH:mm[hs]");
  };

  const getEndDate = () => {
    let format = "DD/MM/YYYY HH:mm[hs]";
    const startDateTz = moment.tz(pass.beginTimestamp * 1000, getTimezone());
    const endDateTz = moment.tz(pass.endTimestamp * 1000, getTimezone());
    if (startDateTz.isSame(endDateTz, "day")) format = "HH:mm[hs]";
    return endDateTz.format(format);
  };

  const getProfileUser = () => {
    const user = getPassUser(pass, friends, token);
    return user?.facePhoto;
  };

  const openDetail = () => {
    if (authorization)
      dispatch(
        onDialogOpen(NotifySecurity, {
          closeWarning: true,
          authorization: pass,
        })
      );
    else
      dispatch(
        onDialogOpen(PassDetail, {
          closeWarning: true,
          pass: pass,
        })
      );
  };

  return (
    <NotificationAnimationWatchMe valueToReact={pass.id}>
      <Card className={classes.root}>
        <CardActionArea onClick={openDetail}>
          <CardContent>
            <PassInfo
              profile={
                invite &&
                !(pass?.maxGuest || pass?.maxGuests) &&
                getProfileUser()
              }
              establishment={getNameEstablishment()}
              startDay={getStartDate()}
              endDay={getEndDate()}
            />
            <LabelIconPass
              invite={(pass?.maxGuest || pass?.maxGuests) && invite}
              authorization={authorization}
              status={passWasUsed(pass, token) ? "used" : pass.status}
            >
              {getLabelUsers()}
            </LabelIconPass>
          </CardContent>
        </CardActionArea>
      </Card>
    </NotificationAnimationWatchMe>
  );
};

export default Pass;
