import { SvgIcon } from "@material-ui/core";
import React from "react";

const DesktopMacIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 1440 1370"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M980 1335 c0 -52 52 -95 115 -95 l36 0 -3 -97 -3 -98 -72 -3 -73 -3 0 -44 0 -43 119 -4 120 -3 35 -34 c40 -39 52 -81 61 -216 3 -49 8 -116 11 -147 l6 -58 45 0 45 0 -6 87 c-20 264 -34 334 -77 394 -23 33 -67 59 -99 59 -19 0 -20 6 -20 105 l0 105 41 0 c33 0 47 6 75 34 44 44 46 76 4 76 -19 0 -33 -7 -40 -20 -10 -18 -21 -20 -130 -20 -110 0 -119 1 -125 20 -8 24 -65 29 -65 5z" />
      <path d="M70 990 l0 -350 -30 0 c-28 0 -30 -3 -30 -35 l0 -35 355 0 355 0 0 35 0 35 -75 0 -75 0 0 350 0 350 -250 0 -250 0 0 -350z" />
      <path d="M620 1320 c-11 -11 -20 -30 -20 -42 0 -29 73 -282 106 -370 36 -96 71 -118 211 -136 l58 -7 13 -109 c7 -60 11 -110 10 -112 -2 -1 -32 1 -68 6 -71 8 -230 -4 -260 -20 -47 -25 -50 -87 -6 -115 20 -13 34 -14 87 -5 130 21 217 8 313 -46 40 -22 56 -26 103 -22 49 4 61 10 90 41 40 44 41 54 13 291 -19 171 -30 205 -70 226 -12 6 -78 13 -148 14 -70 2 -140 7 -156 11 -36 10 -61 64 -116 252 -23 82 -47 143 -57 150 -26 20 -80 16 -103 -7z" />
      <path d="M235 363 c-26 -98 -48 -182 -49 -188 0 -5 11 -11 25 -13 25 -3 27 0 70 160 l44 163 133 3 132 3 0 24 0 25 -153 0 -154 0 -48 -177z" />
      <path d="M1062 300 c-46 -28 -76 -98 -67 -156 18 -125 181 -173 264 -79 36 41 45 72 38 125 -14 106 -143 166 -235 110z" />
    </SvgIcon>
  );
};

export default DesktopMacIcon;
