import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import QRCode from "qrcode.react";
import React from "react";
import LogoTagLinePT from "../../../assets/img/passapp_lockuptagline_pt.png";
import LogoTagLineES from "../../../assets/img/passapp_lockuptagline_es.png";
import md5 from "md5";
import { MD5_SALT } from "Constant";
import { getBrowserLanguage } from "utils/Utils";
import Scissor from "assets/img/scissor.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    height: "100vh",
  },
  fullwidth: {
    width: "100%",
    textAlign: "center",
  },
  section: {
    paddingTop: 14,
  },
  sectionSlim: {
    paddingTop: 5,
  },
  content: {
    width: 381,
    height: 571,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    position: "relative",
  },
  title: {
    color: "#15983a",
    fontSize: "2.5rem",
    lineHeight: 1,
  },
  imageFooter: {
    width: "55%",
  },
  desc: {
    fontSize: "1.2rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  descBold: {
    color: "#4FAE50",
    fontSize: "1.2rem",
    fontFamily: "Roboto",
    fontWeight: "500",
  },
  qr: {
    height: "auto",
    width: "63%",
    paddingBlock: 10,
  },
  divider: {
    backgroundColor: "#227B41",
  },
  sectionSlimLeft: {
    paddingTop: 5,
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  sectionSlimRight: {
    paddingTop: 5,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  dividerShort: {
    width: "80%",
    border: "1px solid #979797",
  },
  cropV: {
    position: "absolute",
    width: "70px",
    left: "-9%",
    bottom: "-18%",
  },
  cropH: {
    position: "absolute",
    width: "70px",
    top: "-7%",
    right: "-23%",
    transform: "rotate(270deg)",
  },
});

class FlyerPDF extends React.PureComponent {
  getSize(length) {
    return length >= 40
      ? "1rem"
      : length >= 30
      ? "1.25rem"
      : length >= 20
      ? "1.5rem"
      : "2.5rem";
  }
  getDeskNameStyle(length) {
    return {
      color: "#498044",
      fontSize: this.getSize(length),
    };
  }
  render() {
    const { t, desks } = this.props;
    const lang = getBrowserLanguage();
    return (
      <Document>
        {desks.map((desk, key) => {
          const code = `DESK-${desk.id}@@`;
          return (
            <Page key={key} size="A4" style={styles.page}>
              <div style={styles.content}>
                <View style={{ ...styles.fullwidth, ...styles.sectionSlim }}>
                  <Text style={styles.title}>{t("desk_flyer_title")}</Text>
                </View>
                <View style={{ ...styles.fullwidth, ...styles.section }}>
                  <Text style={this.getDeskNameStyle(desk?.name?.length)}>
                    {desk.name}
                  </Text>
                </View>
                <View
                  style={{ ...styles.fullwidth, ...styles.sectionSlimLeft }}
                >
                  <div style={styles.dividerShort} />
                </View>
                <View
                  style={{
                    ...styles.fullwidth,
                    ...styles.sectionSlim,
                  }}
                >
                  <Text style={styles.desc}>
                    {t("desk_flyer_description1")}
                    <b style={styles.descBold}>
                      {t("desk_flyer_description2")}
                    </b>
                    <br />
                  </Text>
                  <Text style={styles.desc}>
                    {t("desk_flyer_description3")}
                    <b style={styles.descBold}>
                      {t("desk_flyer_description4")}
                    </b>
                  </Text>
                </View>
                <View
                  style={{ ...styles.fullwidth, ...styles.sectionSlimRight }}
                >
                  <div style={styles.dividerShort} />
                </View>
                <View style={{ ...styles.fullwidth }}>
                  <QRCode
                    style={styles.qr}
                    value={`${code}${md5(`${code}${MD5_SALT}`)}`}
                  />
                </View>
                <View style={{ ...styles.fullwidth }}>
                  <hr style={styles.divider} />
                </View>
                <View style={{ ...styles.fullwidth, ...styles.sectionSlim }}>
                  <img
                    style={styles.imageFooter}
                    src={
                      lang === "es"
                        ? LogoTagLineES
                        : lang === "pt"
                        ? LogoTagLinePT
                        : LogoTagLineES
                    }
                    alt={"Logo Tag Line"}
                  />
                </View>
                <img src={Scissor} alt="crop" style={styles.cropH} />
                <img src={Scissor} alt="crop" style={styles.cropV} />
              </div>
            </Page>
          );
        })}
      </Document>
    );
  }
}

export default FlyerPDF;
