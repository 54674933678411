import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import {
  TextField,
  FormControl,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DesksTable from "../DesksTables";
import { columns } from "../columns/reservationsByDetails";
import { getUserEstablishments, getTimezoneLocal } from "../../../utils/Utils";
import {
  getDeskReservationsByDetail,
  filterList,
  rejectDeskReservation,
} from "../../../actions/desk";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ByDetails extends React.Component {
  constructor(props) {
    super(props);
    const { t, user, companies, company } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmReject = this.handleConfirmReject.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

    let userEstablishments = getUserEstablishments(user, companies, []);
    let selectedEstablishment = userEstablishments.find(
      (e) => e.id === company
    );
    let timezone =
      selectedEstablishment && selectedEstablishment.timezone
        ? selectedEstablishment.timezone
        : getTimezoneLocal();

    let date = moment().tz(timezone);

    this.state = {
      searchText: "",
      from: date,
      to: date,
      status: "",
      itemID: null,
      showConfirm: false,
      statusLst: [
        "reserved",
        "canceled",
        "rejected",
        "used",
        "unused",
        "using",
      ],
      globalPageIndex: 0,
    };
    this.props.getList(
      t,
      date.format("YYYY-MM-DD"),
      date.format("YYYY-MM-DD"),
      this.state.status
    );
  }

  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }

  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        if (v.DeskLot && v.DeskLot.search(new RegExp(text, "i")) > -1)
          return true;
        if (v.user && v.user.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }

  handleDateChange(date, field) {
    if (date && date.isValid()) {
      if (field === "from") {
        this.setState({ from: date });
      }

      if (field === "to") {
        this.setState({ to: date });
      }
    }
  }

  handleStatusFilter(event) {
    this.setState({ status: event.target.value });
  }

  handleFilter() {
    const { from, to, status } = this.state;
    this.props.getList(
      this.props.t,
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      status
    );
  }

  handleCancel(itemID) {
    this.setState({ showConfirm: true, itemID: itemID });
  }

  handleConfirmReject() {
    const { itemID, from, to, status } = this.state;
    this.props.rejectDeskReservation(
      this.props.t,
      itemID,
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      status
    );
    this.closeConfirm();
  }

  closeConfirm() {
    this.setState({ showConfirm: false, itemID: null });
  }

  render() {
    const { t, list, handleList, loading } = this.props;
    const {
      searchText,
      showConfirm,
      from,
      to,
      status,
      statusLst,
      globalPageIndex,
    } = this.state;

    const dataExport = list.map((item) => {
      return {
        creationDate: item.creationDate,
        date: item.date,
        user: item.user,
        adminComment: item.adminComment,
        DeskLot: item.desk,
        status: t("desk_" + item.status),
        modificationDate: item.modificationDate,
      };
    });

    return (
      <div className="body-box">
        <div className="search-box-2">
          <div className="column-left cols-2">
            <div className="filter_field">
              <label>{t("status")}</label>
              <FormControl className="input_status">
                <Select
                  onChange={this.handleStatusFilter}
                  fullWidth={true}
                  value={status}
                  displayEmpty
                  defaultValue=""
                >
                  <MenuItem value={""}>Todas</MenuItem>
                  {statusLst.map((data, j) => (
                    <MenuItem value={data} key={j}>
                      {t("status_" + data)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
              <div className="filter_field">
                <label>{t("dete_from")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={from}
                    onChange={(date) => this.handleDateChange(date, "from")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
              <div className="filter_field">
                <label>{t("date_to")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={to}
                    onChange={(date) => this.handleDateChange(date, "to")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
            </MuiPickersUtilsProvider>
            <div className="filter_field">
              <Button onClick={this.handleFilter} className="filterButton">
                {t("filter")}
              </Button>
            </div>
          </div>
          <div className="column-rigth">
            <Button onClick={handleList}>{t("change_list_to_desks")}</Button>
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name="Usuarios">
                <ExcelColumn
                  label={t("reserv_create_date_excel")}
                  value="creationDate"
                />
                <ExcelColumn label={t("reserv_date_excel")} value="date" />
                <ExcelColumn
                  label={t("reserv_user_employee_excel")}
                  value="user"
                />
                <ExcelColumn
                  label={t("admin_comment")}
                  value="adminComment"
                />
                <ExcelColumn label={t("reserv_desk_excel")} value="DeskLot" />
                <ExcelColumn label={t("status")} value="status" />
                <ExcelColumn
                  label={t("reserv_modification_date_excel")}
                  value="modificationDate"
                />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
        </div>
        <DesksTable
          data={list}
          columns={columns}
          handleCancel={(data) => this.handleCancel(data)}
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></DesksTable>
        <ConfirmationDialog
          open={showConfirm}
          onConfirm={this.handleConfirmReject}
          onClose={this.closeConfirm}
          title={t("confirm_reject_DeskReserv")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  company: state.authReducer.user.parkingManager.company,
  companies: state.userReducer.companies,
  list: state.desks.currentList,
  listAll: state.desks.reservationsByDetails,
  loading: state.desks.loading,
});

const mapActionsToProps = {
  getList: getDeskReservationsByDetail,
  filterList,
  rejectDeskReservation,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(ByDetails));
