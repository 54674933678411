import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  REGISTER_REQUEST,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  PHONE_VERIFY_REQUEST,
  PHONE_VERIFY_REQUESTED,
  PHONE_VERIFY_ERROR,
  PHONE_VERIFY_CANCEL,
  PHONE_VERIFYING,
  PHONE_VERIFIED,
  USER_UPDATING,
  USER_UPDATED,
  USER_UPDATE_ERROR,
  PHONE_VERIFIED_ERROR,
  USER_WHATSAPP_CODE,
  USER_TOKEN_FILE,
  USER_ASSOCIATION,
  CLEAR_ASSOCIATION,
  ENABLE_ASSOCIATION,
  USER_REQUEST_TOKEN_FILE,
  SET_EXTERNAL_USER,
  CLOSE_LOADING_EXTERNAL_USER,
  SET_CUSTOM_EXTERNAL_USER,
  SET_ALLOW_FACEID_EXTERNAL_USER,
  HANDLE_MENU_MOBILE,
  SET_EXTERNAL_USER_FACE,
  SET_EXTERNAL_USER_FACE_EMAIL_VALIDATION,
  SET_EXTERNAL_USER_TOKEN,
} from "../actions/types";
import { clearStorage } from "../utils/Store";
import { getUserState } from "../utils/UserUtils";
const initialState = {
  token: "",
  user: {},
  loading: false,
  state: "",
  token_file: {
    t: "",
    exp: null,
  },
  modeAdm: false,
  enableAssociation: false,
  activationToken: "",
  association: {
    id: "",
    name: "",
    email: "",
    company: "",
    associationToken: "",
  },
  otherCompany: {
    customization: {
      primaryColor: "",
    },
    isAllowFaceId: false,
    isExternalUser: false,
    requiresEmailValidation: false,
    loading: false,
  },
  externalToken: "",
  mobileOpen: false,
};
const parsePalette = (palette) => {
  Object.keys(palette).forEach((key) => {
    if (!palette[key].includes("#")) {
      palette[key] = `#${palette[key]}`;
    }
  });
};
export function authReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_MENU_MOBILE:
      return {
        ...state,
        mobileOpen: action.payload,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS: {
      const userState = getUserState(action.payload.user);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        state: userState,
        loading: false,
      };
    }
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGIN_SUCCESS: {
      const userState = getUserState(action.payload.user);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        state: userState,
        loggingIn: false,
      };
    }
    case LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
      };
    case LOGOUT:
      return initialState;
    case GET_USER_REQUEST:
      return {
        ...state,
        ready: false,
      };
    case GET_USER_SUCCESS: {
      const userState = getUserState(action.payload);
      return {
        ...state,
        user: action.payload,
        ready: true,
        state: userState,
      };
    }
    case GET_USER_ERROR:
      return {
        ...state,
        ready: false,
      };
    case PHONE_VERIFYING:
    case PHONE_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PHONE_VERIFY_ERROR:
    case PHONE_VERIFIED_ERROR:
    case PHONE_VERIFIED:
    case PHONE_VERIFY_REQUESTED:
      return {
        ...state,
        loading: false,
      };
    case PHONE_VERIFY_CANCEL: {
      clearStorage();
      return {
        ...state,
        loading: false,
        token: "",
        user: {},
        state: "",
      };
    }
    case USER_UPDATING:
      return {
        ...state,
        loading: true,
      };
    case USER_UPDATED: {
      const userState = getUserState(action.payload.user);
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        state: userState,
      };
    }
    case USER_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_WHATSAPP_CODE:
      return {
        ...state,
        whatsappCode: action.payload,
      };
    case USER_REQUEST_TOKEN_FILE:
      return {
        ...state,
        token_file: {
          ...state.token_file,
          wait: true,
        },
      };
    case USER_TOKEN_FILE:
      return {
        ...state,
        token_file: {
          t: action.payload.token,
          exp: action.payload.exp,
          wait: action.payload.wait,
        },
      };
    case USER_ASSOCIATION:
      return {
        ...state,
        activationToken: action.payload.activationToken,
        association: action.payload.association,
        modeAdm: true,
      };
    case CLEAR_ASSOCIATION:
      return {
        ...state,
        activationToken: "",
        enableAssociation: false,
        association: {
          id: "",
          name: "",
          email: "",
          company: "",
          associationToken: "",
        },
        modeAdm: false,
      };
    case ENABLE_ASSOCIATION:
      return {
        ...state,
        enableAssociation: action.payload,
      };
    case SET_EXTERNAL_USER:
      return {
        ...state,
        otherCompany: {
          ...state.otherCompany,
          isExternalUser: action.payload,
          loading: true,
        },
      };
    case SET_EXTERNAL_USER_FACE:
      return {
        ...state,
        otherCompany: {
          ...state.otherCompany,
          isAllowFaceId: action.payload,
        },
      };
    case CLOSE_LOADING_EXTERNAL_USER:
      return {
        ...state,
        otherCompany: { ...state.otherCompany, loading: false },
      };
    case SET_CUSTOM_EXTERNAL_USER: {
      action.payload && parsePalette(action.payload);
      return {
        ...state,
        otherCompany: { ...state.otherCompany, customization: action.payload },
      };
    }
    case SET_ALLOW_FACEID_EXTERNAL_USER: {
      return {
        ...state,
        otherCompany: { ...state.otherCompany, isAllowFaceId: action.payload },
      };
    }
    case SET_EXTERNAL_USER_FACE_EMAIL_VALIDATION: {
      return {
        ...state,
        otherCompany: {
          ...state.otherCompany,
          requiresEmailValidation: action.payload,
        },
      };
    }
    case SET_EXTERNAL_USER_TOKEN: {
      return {
        ...state,
        externalToken: action.payload,
      };
    }
    default:
      return state;
  }
}
