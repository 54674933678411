import {
  DESKS_FILTERED_LOAD,
  DESKS_LOADING,
  DESKS_LOADING_END,
  DESKS_LOAD,
  DESKS_GROUP_LOAD,
  DESKS_EMPLOYEES_LOAD,
  DESKS_CHECK_DESKS_LOT,
  DESKS_CHECK_DESKS_GROUP,
  DESKS_LOAD_RESERV_BY_DESK,
  DESKS_LOAD_RESERV_BY_DETAIL,
  DESKS_LOAD_REPORT_BY_DESK,
  DESKS_LOAD_REPORT_BY_USERS,
  DESKS_ACTION,
  DESKS_LAYOUT,
} from "../actions/types";

const initialState = {
  loading: false,
  list: [],
  listEmployees: [],
  listGroups: [],
  type: null,
  currentList: [],
  item: null,
  countDesks: null,
  reservationsByParkingLots: [],
  reservationsByDetails: [],
  reportByParkingLots: [],
  reportByUsers: [],
  action: null,
  layout: null,
};

export function desks(state = initialState, action) {
  switch (action.type) {
    case DESKS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DESKS_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case DESKS_FILTERED_LOAD:
      return {
        ...state,
        currentList: action.payload.data,
        loading: false,
      };
    case DESKS_LOAD:
      return {
        ...state,
        list: action.payload.data,
      };
    case DESKS_EMPLOYEES_LOAD:
      return {
        ...state,
        listEmployees: action.payload.data,
      };
    case DESKS_GROUP_LOAD:
      return {
        ...state,
        listGroups: action.payload.data,
      };
    case DESKS_CHECK_DESKS_LOT:
    case DESKS_CHECK_DESKS_GROUP:
      return {
        ...state,
        countDesks: action.payload,
      };
    case DESKS_LOAD_RESERV_BY_DESK:
      return {
        ...state,
        reservationsByParkingLots: action.payload.data,
      };
    case DESKS_LOAD_RESERV_BY_DETAIL:
      return {
        ...state,
        reservationsByDetails: action.payload.data,
      };
    case DESKS_LOAD_REPORT_BY_DESK:
      return {
        ...state,
        reportByParkingLots: action.payload.data,
      };
    case DESKS_LOAD_REPORT_BY_USERS:
      return {
        ...state,
        reportByUsers: action.payload.data,
      };
    case DESKS_ACTION:
      if (state.action === action.payload) return state;

      return {
        ...state,
        action: action.payload,
        currentList: [],
      };
    case DESKS_LAYOUT:
      return { ...state, layout: action.payload };
    default:
      return state;
  }
}
