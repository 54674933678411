import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import { TextField, FormControl, Button } from "@material-ui/core";
import DesksTable from "./DesksTables";

import {
  getEmployeesDeskGroups,
  filterList,
  removeEmployeeDeskGroup,
  addEmployeeDeskGroup,
} from "../../actions/desk";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class DesksUsers extends React.Component {
  constructor(props) {
    super(props);
    const { t, DeskGroups } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleConfirmUncheck = this.handleConfirmUncheck.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    let columns = [
      {
        Header: "name",
        accessor: "name",
      },
    ];

    const count = DeskGroups.length;
    for (let i = 0; i < count; i++) {
      const groupItem = DeskGroups[i];
      columns.push({
        Header: groupItem.name,
        action: "checDeskGroup",
        id: groupItem.id,
        short: 0,
      });
    }

    this.state = {
      searchText: "",
      columns: columns,
      globalPageIndex: 0,
      showConfirm: false,
      DeskGroup: null,
      userID: null,
    };
    this.props.getList(t);
  }

  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }

  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        if (v.name && v.name.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }

  handleUpdate(userID, group, checked) {
    if (checked) {
      //uncheck
      this.setState({ showConfirm: true, DeskGroup: group, userID: userID });
    } else {
      //if add
      this.props.addEmployeeDeskGroup(this.props.t, userID, group);
    }
  }
  handleConfirmUncheck() {
    const { userID, DeskGroup } = this.state;
    this.props.removeEmployeeDeskGroup(this.props.t, userID, DeskGroup);
    this.closeConfirm();
  }
  closeConfirm() {
    this.setState({ showConfirm: false, DeskGroup: null, userID: null });
  }

  render() {
    const { t, list, DeskGroups, loading } = this.props;
    const { searchText, showConfirm, columns, globalPageIndex } = this.state;

    const countGroups = DeskGroups.length;
    const dataExport = list.map((user) => {
      let item = { name: user.name, adminComment: user.adminComment };
      if (Array.isArray(user.deskGroups)) {
        for (let i = 0; i < countGroups; i++) {
          const groupItem = DeskGroups[i];
          item = {
            ...item,
            [groupItem.id]: user.deskGroups.includes(groupItem.id)
              ? t("yes")
              : t("no"),
          };
        }
      } else {
        for (let i = 0; i < countGroups; i++) {
          const groupItem = DeskGroups[i];
          item = { ...item, [groupItem.id]: "1" };
        }
      }
      return item;
    });

    return (
      <div className="body-box">
        <div className="search-box-2">
          <ExcelFile
            element={
              <Button onClick={this.exportList}>{t("export_to_excel")}</Button>
            }
          >
            <ExcelSheet data={dataExport} name={t("users")}>
              <ExcelColumn label={t("name")} value="name" />
              <ExcelColumn label={t("admin_comment")} value="adminComment" />
              {DeskGroups.map((group, i) => {
                return (
                  <ExcelColumn label={group.name} value={group.id} key={i} />
                );
              })}
            </ExcelSheet>
          </ExcelFile>
          <FormControl className="box-input">
            <TextField
              type="text"
              name="search"
              placeholder={t("search")}
              value={searchText}
              onChange={this.handleSearch}
            ></TextField>
          </FormControl>
        </div>
        <DesksTable
          data={list}
          columns={columns}
          handleUpdate={(userID, group, checked) =>
            this.handleUpdate(userID, group, checked)
          }
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></DesksTable>
        <ConfirmationDialog
          open={showConfirm}
          onConfirm={this.handleConfirmUncheck}
          onClose={this.closeConfirm}
          title={t("confirm_uncheck_deskGroup")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  list: state.desks.currentList,
  listAll: state.desks.listEmployees,
  DeskGroups: state.desks.listGroups,
  loading: state.desks.loading,
});
const mapActionsToProps = {
  getList: getEmployeesDeskGroups,
  filterList,
  removeEmployeeDeskGroup,
  addEmployeeDeskGroup,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(DesksUsers));
