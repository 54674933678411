import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import Button from "components/common/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import logo from "../../assets/img/logo.png";

import {
  Typography,
  TextField,
  FormControl,
  Grid,
  Link,
} from "@material-ui/core";

import { connect } from "react-redux";
import { onFieldChange, unmaskPhone } from "../../utils/Utils";
import { TWOFA_TYPE_SMS, TWOFA_TYPE_CALL } from "../../Constant";
import {
  requestVerificationCode,
  cancelVerification,
  verifyVerificationCode,
} from "../../actions/auth";
import "./PhoneVerification.scss";
import Loader from "../../utils/Loader";
import ChangePhoneNumber from "../../components/ChangePhoneNumber/ChangePhoneNumber";
import ConfirmationDialog from "../../components/common/ConfirmationDialog/ConfirmationDialog";
import history from "../../history";
const useStyles = createStyles(styles);

const CODE_DIGITS = 6;

class PhoneVerificationSMS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tyc: false,
      password: "",
      confirmPassword: "",
      email: "",
      phone: "",
      updating: false,
      old: "",
      focusInput: 0,
      verificationCode: [],
      changingNumber: false,
      showCancelWarning: false,
    };

    this.onFieldChange = onFieldChange.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.changeNumber = this.changeNumber.bind(this);
    this.codeInputs = [];
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.resend = this.resend.bind(this);
    this.call = this.call.bind(this);
    this.cancel = this.cancel.bind(this);
    this.closeCancelWarning = this.closeCancelWarning.bind(this);
    this.onCancelVerification = this.onCancelVerification.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  changeNumber(ev) {
    if (ev) ev.preventDefault();
    this.setState({
      changingNumber: !this.state.changingNumber,
    });
  }

  onChangeNumber(phone) {
    this.setState(
      {
        phone: unmaskPhone(phone),
      },
      () => {
        this.sendSms();
      }
    );
  }

  onCodeChange(ev) {
    const { verificationCode, focusInput } = this.state;
    let newFocus = focusInput + 1 >= CODE_DIGITS ? focusInput : focusInput + 1;

    let value = ev.target.value.replace(verificationCode[focusInput], "");
    verificationCode[focusInput] = value;

    this.setState(
      {
        focusInput: newFocus,
        verificationCode,
      },
      () => {
        this.codeInputs[newFocus].querySelector("input").focus();
        if (this.state.verificationCode.join("").length === CODE_DIGITS) {
          this.codeInputs[newFocus].querySelector("input").blur();
        }
      }
    );
  }

  resend(ev) {
    ev.preventDefault();
    this.sendSms();
  }

  confirm() {
    const { verificationCode, phone } = this.state;
    const { t } = this.props;

    this.props.verifyVerificationCode(verificationCode.join(""), phone, t);
  }

  sendSms() {
    const { requestVerificationCode, t } = this.props;
    const { phone } = this.state;
    requestVerificationCode(phone, TWOFA_TYPE_SMS, t);
  }

  call(ev) {
    ev.preventDefault();
    const { requestVerificationCode, t } = this.props;
    const { phone } = this.state;
    requestVerificationCode(phone, TWOFA_TYPE_CALL, t);
  }

  cancel(ev) {
    ev.preventDefault();
    this.setState({
      showCancelWarning: true,
    });
  }

  closeCancelWarning() {
    this.setState({
      showCancelWarning: false,
    });
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState(
      {
        phone: user.phone,
      },
      () => {
        this.sendSms();
      }
    );
  }

  onCancelVerification() {
    this.props.cancelVerification();

    this.setState({
      showCancelWarning: false,
    });
    history.push("/");
  }

  render() {
    const { t, classes, loading, user, modeAdm } = this.props;
    const {
      verificationCode,
      changingNumber,
      phone,
      showCancelWarning,
    } = this.state;

    const inputs = [];

    if (!loading && !user.requires2FA) {
      if (modeAdm) history.push("/register/profile");
      else history.push("/register/joinEstablishment");
      return null;
    }

    for (let i = 0; i < CODE_DIGITS; i++) {
      inputs.push(
        <TextField
          ref={(field) => (this.codeInputs[i] = field)}
          className={classes.textField}
          margin="normal"
          onChange={this.onCodeChange}
          value={verificationCode[i] || ""}
          onClick={() => {
            this.setState({
              focusInput: i,
            });
          }}
          key={i}
          variant="outlined"
        />
      );
    }

    return (
      <div className="verification">
        <img alt="Web Logo" className="logo" src={logo} />
        <ConfirmationDialog
          open={showCancelWarning}
          onConfirm={this.onCancelVerification}
          onClose={this.closeCancelWarning}
          title={t("confirm_verification_cancel")}
        />
        <ChangePhoneNumber
          open={changingNumber}
          onClose={this.changeNumber}
          onSave={this.onChangeNumber}
        ></ChangePhoneNumber>
        <Typography className="info">
          {t("two_factor_info")} <span className="bold">{phone}</span>
        </Typography>

        <Link className="bold option" href="" onClick={this.changeNumber}>
          {t("wish_to_change")}
        </Link>

        <Grid container>
          <Grid item xs></Grid>
          <Grid item lg={4} sm={6} xs={12}>
            {inputs}

            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl + " send "}
            >
              <Button
                color="primary"
                type="submit"
                disabled={loading || verificationCode.length !== CODE_DIGITS}
                round
                onClick={this.confirm}
              >
                {t("send")}
              </Button>
              <div>
                <Loader className="submit" visible={loading} />
              </div>
            </FormControl>

            <Link className="bold option" href="" onClick={this.resend}>
              {t("resend_sms_code")}
            </Link>
            <Link className="bold option" href="" onClick={this.call}>
              {t("receive_code_by_call")}
            </Link>

            <Link className="bold option cancel" href="" onClick={this.cancel}>
              {t("cancel_verification")}
            </Link>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.userReducer.loading || state.authReducer.loading,
  user: state.authReducer.user,
  modeAdm: state.authReducer.modeAdm,
});

const actionCreators = {
  requestVerificationCode: requestVerificationCode,
  cancelVerification: cancelVerification,
  verifyVerificationCode: verifyVerificationCode,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(PhoneVerificationSMS)));
