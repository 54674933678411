import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import CustomPagination from "../CustomPaginationGrid/CustomPaginationGrid";

const CustomDataGrid = (props) => {
  const { rows, columns, loading, localeText } = props;
  return (
    <DataGrid
      disableColumnMenu
      rowHeight={80}
      rows={rows}
      columns={columns}
      loading={loading}
      localeText={localeText}
      components={{
        Pagination: CustomPagination,
      }}
      disableSelectionOnClick
    />
  );
};

export default CustomDataGrid;
