export const columns = [
  {
    Header: "report_user_employee",
    accessor: "user",
    class: "cell-text-br",
  },
  {
    Header: "report_desk",
    accessor: "desk",
    class: "cell-text-br",
  },
  {
    Header: "report_total",
    accessor: "total",
    class: "cell-text-br",
  },
  {
    Header: "report_used",
    accessor: "used",
    class: "cell-text-br",
  },
  {
    Header: "report_percent",
    accessor: "percentage",
    class: "cell-text-br",
  },
];
