import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/common/Grid/GridItem.js";
import GridContainer from "components/common/Grid/GridContainer.js";
import Button from "components/common/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import "./Passes.scss";
import PassDetail from "components/PassDetail/PassDetail";
import PassesBlock from "components/PassesBlock/PassesBlock";
import CustomTabs from "components/common/CustomTabs/CustomTabs";
import Notifications from "views/Notifications/Notifications";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import NotifySecurity from "components/NotifySecurity/NotifySecurity";
import { connect } from "react-redux";
import { onDialogOpen } from "../../actions/dialog";
import { getAuthorizations, getInvitesAsHost } from "../../actions/user";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = createStyles(styles);

const Passes = (props) => {
  const {
    t,
    passes,
    authorizations,
    user,
    classes,
    getAuthorizations,
    getInvitesAsHost,
  } = props;
  const [newInviteAnchor, setNewInviteAnchor] = useState(null);

  useEffect(() => {
    getInvitesAsHost(t);
    getAuthorizations(t);
  }, []);

  const openSecurityDetail = () => {
    props.onDialogOpen(NotifySecurity, {});
    setNewInviteAnchor(null);
  };

  const onPassUpdate = () => {
    getInvitesAsHost(t);
    getAuthorizations(t);
  };

  const closeMenu = () => {
    setNewInviteAnchor(null);
  };

  const openMenu = (ev) => {
    setNewInviteAnchor(ev.currentTarget);
  };

  const newInvite = () => {
    props.onDialogOpen(PassDetail, {});

    setNewInviteAnchor(null);
  };

  return (
    <>
      {user &&
      (!user.workplaces || user.workplaces.length === 0) &&
      (!user.residences || user.residences.length === 0) ? (
        <div>
          <Typography className={classes.under_construction}>
            {t("must_join_establishment")}
          </Typography>
        </div>
      ) : (
        <div className="passes">
          <GridContainer>
            <GridItem xs={12} lg={8} className="tabs-container">
              <Button color="primary" className="add" round onClick={openMenu}>
                {t("new_invitation")}
              </Button>
              <Menu
                anchorEl={newInviteAnchor}
                keepMounted
                open={Boolean(newInviteAnchor)}
                onClose={closeMenu}
              >
                <MenuItem onClick={newInvite}>{t("send_pass")}</MenuItem>
                <MenuItem onClick={openSecurityDetail}>
                  {t("notify_security")}
                </MenuItem>
              </Menu>

              <CustomTabs
                headerColor="primary"
                tabs={[
                  {
                    hidden: false,
                    tabName: t("my_guests"),
                    tabContent: (
                      <GridContainer>
                        <PassesBlock
                          onClose={onPassUpdate}
                          passes={
                            passes ? passes.filter((p) => !p.calendar) : []
                          }
                          authorizations={authorizations || []}
                          loading={passes === null || authorizations === null}
                          title={t("occasional_passes")}
                        />
                        <PassesBlock
                          onClose={onPassUpdate}
                          passes={
                            passes ? passes.filter((p) => p.calendar) : []
                          }
                          loading={passes === null}
                          title={t("permanent_passes")}
                        />
                      </GridContainer>
                    ),
                  },
                ]}
              />
            </GridItem>

            <GridItem xs={12} lg={4}>
              <Notifications />
            </GridItem>
          </GridContainer>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.userReducer.loading,
  passes: state.userReducer.hostInvites,
  authorizations: state.userReducer.authorizations,
});

const actionCreators = {
  getAuthorizations: getAuthorizations,
  getInvitesAsHost: getInvitesAsHost,
  onDialogOpen: onDialogOpen,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(Passes)));
