import React from "react";
import { SvgIcon } from "@material-ui/core";

const AuthorizationIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="88"
      height="100"
      viewBox="0 0 88 100"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M37.3001 2.39995C35.0001 3.69995 31.2001 6.09995 28.8001 7.79995C22.4001 12.2 12.9001 16.8 7.40006 18.1C-1.99994 20.4 -2.29994 23 6.00006 31.2C9.30006 34.5 12.0001 37.7 12.0001 38.2C12.0001 38.7 13.2001 38.2 14.7001 37.1C20.0001 32.9 30.4001 30.5 44.0001 30.5C57.7001 30.5 65.5001 32.2 72.3001 36.6C74.4001 38 76.0001 38.5 76.0001 37.9C76.0001 37.2 78.7001 34.1 82.0001 30.9C87.9001 25.3 89.3001 22.2 86.8001 20.3C86.1001 19.8 82.6001 18.5 79.0001 17.4C74.8001 16.2 68.9001 13.1 62.3001 8.79995C47.5001 -1.10005 44.7001 -1.80005 37.3001 2.39995ZM47.5001 13.2C48.9001 13.8 50.0001 15 50.0001 15.9C50.0001 18.8 46.1001 24 44.0001 24C41.9001 24 38.0001 18.8 38.0001 15.9C38.0001 14.4 41.2001 12.2 43.7001 12.1C44.4001 12 46.1001 12.5 47.5001 13.2Z"
        />
        <path
          id="Vector_2"
          d="M33.5 34.6C28.6 35.4 20.8 38.1 17.3 40.2C14.4 41.9 14 42.7 14 46.2C14 49.4 13.4 50.8 11 53C8.8 55.1 8 56.7 8 59.3C8 63.1 10.9 67.5 14 68.5C15.1 68.9 16 69.6 16 70.1C16 72.7 24 86.9 28 91.3C38.2 102.7 49.8 102.7 60 91.3C64 86.9 72 72.7 72 70.1C72 69.6 72.9 68.9 74 68.5C77.1 67.5 80 63.1 80 59.3C80 56.8 79.2 55 77 52.8C74.7 50.6 74 48.9 74 46C74 41.6 72.1 40 63.2 36.9C57.5 34.9 40.2 33.6 33.5 34.6ZM26.5 51.3C30.6 53 33.6 53.4 44 53.4C56.7 53.4 56.6 53.4 68.3 48.5C69.7 47.9 70 48.3 70 51.4C70 54.6 70.3 55 72.5 55C74.3 55 75.1 55.7 75.6 57.7C76.4 60.9 75.2 63.1 71.7 64.8C70.2 65.5 69 66.6 69 67.2C69 69 61.3 83.7 58.4 87.5C54.9 92.1 48.3 96 44 96C39.8 96 33.1 92.1 29.8 87.8C26.8 83.9 22.1 75.2 19.7 69.2C18.8 66.9 17.5 65 16.8 65C16.2 65 14.8 64.3 13.8 63.3C10.5 60.4 12.1 55 16.2 55C17.6 55 18 54.2 18 51.4C18 48.3 18.3 47.9 19.8 48.5C20.7 48.9 23.8 50.2 26.5 51.3Z"
        />
        <path
          id="Vector_3"
          d="M23.1001 57.3C19.4001 61 24.9001 70 31.0001 70C34.7001 70 40.0001 65.3 40.0001 62.1C40.0001 60.4 40.6001 60 43.5001 60C46.5001 60 47.2001 60.5 48.4001 63.4C51.3001 70.4 58.1001 72.1 63.1001 67.1C66.2001 64 67.0001 59.1 64.7001 57.2C62.9001 55.7 24.6001 55.8 23.1001 57.3Z"
        />
      </g>
    </SvgIcon>
  );
};

export default AuthorizationIcon;
