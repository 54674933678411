import moment from "moment";
import {
  EVENT_CHANGE_FILTER,
  EVENT_CHANGE_FILTER_QUERY,
  EVENT_CHANGE_FROM,
  EVENT_CHANGE_TO,
  EVENT_INIT,
  EVENT_REQUEST,
} from "../actions/types";

const initialState = {
  events: [],
  eventsFilter: [],
  loading: false,
  from: moment().subtract(1, "month"),
  to: moment(),
  filterQuery: "",
};

export const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EVENT_INIT:
      return {
        ...state,
        events: action.payload,
        eventsFilter: action.payload,
        loading: false,
      };
    case EVENT_CHANGE_FROM:
      return {
        ...state,
        from: action.payload,
      };
    case EVENT_CHANGE_TO:
      return {
        ...state,
        to: action.payload,
      };
    case EVENT_CHANGE_FILTER_QUERY:
      return {
        ...state,
        filterQuery: action.payload,
      };
    case EVENT_CHANGE_FILTER:
      return {
        ...state,
        eventsFilter: action.payload,
      };
    default:
      return state;
  }
};
