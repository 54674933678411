export const columns = [
  {
    Header: "report_group",
    accessor: "name",
  },
  {
    Header: "report_total",
    accessor: "total",
    class: "cell-text-br",
  },
  {
    Header: "report_used",
    accessor: "used",
    class: "cell-text-br",
  },
  {
    Header: "report_percent",
    accessor: "percentage",
    class: "cell-text-br",
  },
];
