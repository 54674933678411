import { Icon, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HANDLE_MENU_MOBILE } from "actions/types";
import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import sidebarStyle from "../../../../assets/jss/material-dashboard-react/components/sidebarStyle";
const useStyles = makeStyles((theme) => sidebarStyle(theme));
const MenuItem = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { otherCompany } = useSelector((state) => state.authReducer);
  let listItemClasses = classNames({
    [" " + classes.green]: location.pathname === `${props.layout}${props.path}`,
    [classes.itemLinkApp]: otherCompany?.isExternalUser,
  });
  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]:
      location.pathname === `${props.layout}${props.path}`,
  });
  const itemIcon = otherCompany?.isExternalUser
    ? classes.itemIconApp
    : classes.itemIcon;
  const itemText = otherCompany?.isExternalUser
    ? classes.itemTextApp
    : classes.itemText;
  const redirect = () => {
    history.push(props.layout + props.path);
    dispatch({
      type: HANDLE_MENU_MOBILE,
      payload: false,
    });
  };
  return (
    <ListItem
      button
      className={classNames(classes.itemLink, listItemClasses)}
      onClick={() => redirect()}
    >
      {typeof props.icon === "string" ? (
        <Icon className={classNames(itemIcon, whiteFontClasses, {})}>
          {props.icon}
        </Icon>
      ) : (
        <props.icon className={classNames(itemIcon, whiteFontClasses, {})} />
      )}
      <ListItemText
        primary={props.name}
        className={classNames(itemText, whiteFontClasses, {})}
        disableTypography={true}
      />
    </ListItem>
  );
};
export default MenuItem;
