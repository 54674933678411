import { CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterList, getListEvents } from "../../../actions/events";
import { useStyles } from "./AccessEventTableStyle";
import { escapeRegExp } from "lodash";
import moment from "moment";
import { extractContentHtml } from "../../../utils/Utils";
import CustomDataGrid from "../../common/CustomDataGrid/CustomDataGrid";

const AccessEventTable = ({ t }) => {
  const stateEvents = useSelector((state) => state.events);
  const classes = useStyles();
  const dispatch = useDispatch();

  const render = (params) => (
    <div
      style={{ lineHeight: 1.5 }}
      dangerouslySetInnerHTML={{ __html: params.value }}
    ></div>
  );
  const column = [
    {
      id: "who",
      field: "who",
      headerName: t("access_event_table_incoming"),
      width: 150,
      renderCell: render,
    },
    {
      id: "type",
      field: "type",
      headerName: t("access_event_table_type"),
      width: 100,
      renderCell: render,
    },
    {
      id: "timestamp",
      field: "timestamp",
      headerName: t("access_event_table_date"),
      width: 150,
      renderCell: (value) =>
        moment.unix(value.value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      id: "companies",
      field: "companies",
      headerName: t("access_event_table_company"),
      width: 150,
      renderCell: render,
    },
    {
      id: "hosts",
      field: "hosts",
      headerName: t("access_event_table_host"),
      width: 130,
      renderCell: render,
    },
    {
      id: "managers",
      field: "managers",
      headerName: t("access_event_table_manager"),
      width: 120,
      renderCell: render,
    },
    {
      id: "authorizationsType",
      field: "authorizationsType",
      headerName: t("access_event_table_authorizationType"),
      width: 150,
      renderCell: render,
    },
    {
      id: "securityGuard",
      field: "securityGuard",
      headerName: t("access_event_table_receptionist"),
      minWidth: 150,
      renderCell: render,
      flex: 1,
    },
  ];

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = stateEvents.events.filter((row) => {
      return Object.keys(row).some((field) => {
        const extractString = extractContentHtml(row[field] ? row[field].toString() : '');
        return searchRegex.test(extractString);
      });
    });
    dispatch(changeFilterList(filteredRows));
  };

  useEffect(() => {
    if (stateEvents.events?.length === 0 && !stateEvents.loading){
      stateEvents.from.set({hour:0, minute:0, second:0, millisecond:0});
      dispatch(getListEvents(stateEvents.from.unix(), stateEvents.to.unix()));
    }
  }, []);

  useEffect(() => {
    requestSearch(stateEvents.filterQuery);
  }, [stateEvents.filterQuery]);

  return (
    <>
      {stateEvents.events ? (
        <CustomDataGrid
          rows={stateEvents.eventsFilter}
          columns={column}
          loading={stateEvents.loading}
          localeText={{
            noRowsLabel: t("access_event_table_norows"),
          }}
        />
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={3}
          className={classes.loadingContainer}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withTranslation()(AccessEventTable);
