//RoomReservation
import PassAppApi from "../services/PassAppApi";
import SnackbarMessage from "../utils/SnackbarMessage";
import {
  ROOM_RESERV_INITIAL,
  ROOM_RESERV_LOAD,
  ROOM_RESERV_BASEURL,
  ROOM_RESERV_RELOAD,
  ROOM_RESERV_UPD,
  ROOM_RESERV_REMOVE,
  ROOM_RESERV_GET,
  ROOM_RESERV_NEW,
  ROOM_RESERV_SETTINGS,
  ROOM_RESERV_PURCHASE_ORDER,
  ROOM_RESERV_FILES,
} from "./types";

export const getRoomReservationList = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  dispatch({
    type: ROOM_RESERV_BASEURL,
    payload: { data: PassAppApi.getUrlBase() },
  });

  PassAppApi.getSharedFacilitiesReservations(token)
    .then((response) => {
      const items = response.data.map((item) => {
        return { ...item, time: item.date + " " + item.beginTime };
      });
      dispatch({
        type: ROOM_RESERV_INITIAL,
        payload: { data: items },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_RoomReservation")
      );
    });
};

export const filterRoomReservation = (listItems, searchText, searchstatus) => {
  return listItems.filter((v) => {
    if (searchstatus && searchstatus !== "all" && searchstatus.length > 0) {
      if (!v.status || v.status !== searchstatus) return false;
    }

    if (v.datetime && v.datetime.search(new RegExp(searchText, "i")) > -1)
      return true;
    if (
      v.sharedFacilitiesText &&
      v.sharedFacilitiesText.search(new RegExp(searchText, "i")) > -1
    )
      return true;
    if (v.adminNotes && v.adminNotes.search(new RegExp(searchText, "i")) > -1)
      return true;

    const listsearchText = v.otherFilesUrl.filter((v) => {
      if (v.name && v.name.search(new RegExp(searchText, "i")) > -1)
        return true;
      return false;
    });
    if (listsearchText.length > 0) return true;

    return false;
  });
};

export const filterRoomReservationList = (list, text, status) => (dispatch) => {
  const result = filterRoomReservation(list, text, status);
  dispatch({
    type: ROOM_RESERV_LOAD,
    payload: { data: result },
  });
};

export const cancelRoomReservation = (t, item) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  PassAppApi.setSharedFacilityReservation(token, {
    id: item.id,
    action: "cancel",
  })
    .then(() => {
      PassAppApi.getSharedFacilitiesReservations(token, { id: item.id })
        .then((response) => {
          if (typeof response?.id != "undefined") {
            response = {
              ...response,
              time: response.date + " " + response.beginTime,
            };
            dispatch({
              type: ROOM_RESERV_RELOAD,
              payload: { data: response },
            });
          } else {
            dispatch({
              type: ROOM_RESERV_REMOVE,
              payload: { id: item.id },
            });
          }
        })
        .catch((err) => {
          SnackbarMessage.show(
            "error",
            err ? err.toString() : t("error_get_simple_RoomReservation")
          );
        });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_cancel_RoomReservation") + ": " + (err ? err.toString() : "")
      );
    });
};

export const getRoomReservation = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  dispatch({
    type: ROOM_RESERV_BASEURL,
    payload: { data: PassAppApi.getUrlBase() },
  });

  dispatch({
    type: ROOM_RESERV_GET,
    payload: { data: [] },
  });

  if (id) {
    PassAppApi.getSharedFacilitiesReservations(token, { id: id })
      .then((response) => {
        if (typeof response?.id != "undefined") {
          response = {
            ...response,
            time: response.date + " " + response.beginTime,
            beginTime: response.date + " " + response.beginTime,
            endTime: response.date + " " + response.endTime,
          };
          dispatch({
            type: ROOM_RESERV_GET,
            payload: { data: response },
          });
          return response;
        } else {
          dispatch({
            type: ROOM_RESERV_NEW,
          });
        }
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          err ? err.toString() : t("error_get_simple_RoomReservation")
        );
      });
  } else {
    PassAppApi.emptySharedFacilityReservation(token)
      .then((response) => {
        // const utc = new Date().toJSON().slice(0, 10);
        response = {
          ...response,
          time: response.date + " " + response.beginTime,
          //beginTime: utc+' 08:00',
          //endTime: utc+' 08:30'
        };
        dispatch({
          type: ROOM_RESERV_NEW,
          payload: { data: response },
        });
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          err ? err.toString() : t("error_get_simple_RoomReservation")
        );
      });
  }
};
export const getRoomReservationSettings = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  PassAppApi.getRoomReservationSettings(token, { id: id })
    .then((response) => {
      dispatch({
        type: ROOM_RESERV_SETTINGS,
        payload: { data: response.data },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_get_simple_RoomReservation")
      );
    });
};

export const updReservation = (reservation) => (dispatch) => {
  dispatch({
    type: ROOM_RESERV_UPD,
    payload: { data: reservation },
  });
};

export const sendRoomReservation = (
  t,
  reservation,
  openPdf,
  termsAndConditionsUrl
) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  let type = "add";
  if (reservation.hasOwnProperty("id") && typeof reservation.id != "undefined")
    type = "upd";

  PassAppApi.sendRoomReservation(token, reservation, type)
    .then(() => {
      if (type === "add") {
        SnackbarMessage.show(
          "success",
          t("added_successfully_RoomReservation"),
          10000
        );
        openPdf(t("text_TC_2"), termsAndConditionsUrl);
      } else {
        SnackbarMessage.show(
          "success",
          t("updated_successfully_RoomReservation")
        );
      }
      dispatch(getRoomReservationList(t));
      dispatch(getRoomReservationSettings(t));

      dispatch({
        type: ROOM_RESERV_GET,
        payload: { data: null },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_save_RoomReservation") + ": " + (err ? err.toString() : "")
      );
    });
};

export const sendPostFiles = (t, id, files, type) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  PassAppApi.sendPostFiles(token, id, files)
    .then((response) => {
      if (type === "purchase_order")
        dispatch({
          type: ROOM_RESERV_PURCHASE_ORDER,
          payload: { id: response.id, name: files.name },
        });
      else {
        dispatch({
          type: ROOM_RESERV_FILES,
          payload: {
            otherFiles: response.id,
            otherFilesUrl: { name: files.name, url: null },
          },
        });
      }
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_upload_file_RoomReservation") +
          ": " +
          (err ? err.toString() : "")
      );
    });
};
