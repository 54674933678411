import PassAppApi from "../services/PassAppApi";
import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  GET_USER_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  PHONE_VERIFY_REQUESTED,
  PHONE_VERIFY_REQUEST,
  PHONE_VERIFYING,
  PHONE_VERIFIED,
  PHONE_VERIFIED_ERROR,
  PHONE_VERIFY_ERROR,
  PHONE_VERIFY_CANCEL,
  USER_UPDATING,
  USER_UPDATED,
  USER_UPDATE_ERROR,
  LOGOUT,
  USER_WHATSAPP_CODE,
  USER_TOKEN_FILE,
  USER_ASSOCIATION,
  CLEAR_ASSOCIATION,
  USER_CLEAR,
  ENABLE_ASSOCIATION,
  USER_REQUEST_TOKEN_FILE,
  SET_EXTERNAL_USER,
  CLOSE_LOADING_EXTERNAL_USER,
  SET_ALLOW_FACEID_EXTERNAL_USER,
  SET_CUSTOM_EXTERNAL_USER,
  SET_EXTERNAL_USER_FACE,
  SET_EXTERNAL_USER_FACE_EMAIL_VALIDATION,
  SET_EXTERNAL_USER_TOKEN,
} from "./types";
import SnackbarMessage from "utils/SnackbarMessage";
import history from "../history";
import {
  STATE_VERIFICATION,
  STATE_PHOTOS,
  STATE_NEEDS_TO_JOIN,
  STATE_LOGGED_IN,
} from "../Constant";

import { getUserState } from "../utils/UserUtils";
import { getGlobalOptions } from "./user";
import ConfirmationDialogStatic from "components/common/ConfirmationDialog/ConfirmationDialogStatic";

export const login = (credentials, t, modeAdm) => (dispatch, getState) => {
  let association = getState().authReducer.association;
  dispatch({ type: LOGIN_REQUEST });
  PassAppApi.login(credentials.email, credentials.password)
    .then((res) => {
      if (modeAdm) {
        PassAppApi.associate(
          res.token,
          association.associationToken,
          association.id
        ).then(() => {
          dispatch(clearAssociation());
          getUserLogin(res.token, t, dispatch);
          ConfirmationDialogStatic.show({
            title: t("adm_success"),
            labelYes: t("confirm"),
          });
        });
      } else {
        getUserLogin(res.token, t, dispatch);
      }
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAILURE });
      if (modeAdm) {
        ConfirmationDialogStatic.show({
          title: t("adm_no_user_exist") + " " + t("adm_register_new_user"),
          labelNo: t("adm_login_repeat"),
          labelYes: t("adm_login_register"),
          onAccept: () => history.push("/register"),
        });
      } else {
        SnackbarMessage.show(
          "error",
          t("invalid_login") + ": " + (err ? err.toString() : "")
        );
      }
    });
};

const getUserLogin = (token, t, dispatch) => {
  PassAppApi.getUser(token)
    .then((user) => {
      const userState = getUserState(user);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: token,
          user: user,
        },
      });

      switch (userState) {
        case STATE_LOGGED_IN:
          history.push("/home/passes");
          break;
        case STATE_VERIFICATION:
          history.push("/register/verify");
          break;
        case STATE_NEEDS_TO_JOIN:
          history.push("/register/joinEstablishment");
          break;
        case STATE_PHOTOS:
          history.push("/register/uploadUserPhotos");
          break;
        default:
          break;
      }
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAILURE });
      SnackbarMessage.show(
        "error",
        t("error_getting_user_info") + ": " + (err ? err.toString() : "")
      );
    });
};

const getUserRegister = (token, t, dispatch) => {
  PassAppApi.getUser(token)
    .then((opts) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { token: token, user: opts },
      });
      history.push("/register/uploadUserPhotos");
    })
    .catch((err) => {
      dispatch({ type: GET_USER_ERROR });
      SnackbarMessage.show(
        "error",
        t("error_getting_user_info") + ": " + (err ? err.toString() : "")
      );
    });
};

export const register = (credentials, t) => (dispatch) => {
  dispatch({ type: REGISTER_REQUEST });
  PassAppApi.register(credentials)
    .then((res) => {
      getUserRegister(res.token, t, dispatch);
    })
    .catch((err) => {
      dispatch({ type: REGISTER_FAILURE, payload: err });
      if (err) {
        SnackbarMessage.show("error", err.toString());
      } else {
        SnackbarMessage.show("error", t("register_error"));
      }
    });
};

export const getUser = (t) => (dispatch, getState) => {
  let token = getState().authReducer.token;
  dispatch({ type: GET_USER_REQUEST });
  refreshToken(dispatch, getState);
  PassAppApi.getUser(token)
    .then((opts) => {
      dispatch({ type: GET_USER_SUCCESS, payload: opts });
    })
    .catch((err) => {
      dispatch({ type: GET_USER_ERROR, payload: err });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_user_info")
      );
    });
};

export const requestVerificationCode = (phone, type, t) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  dispatch({ type: PHONE_VERIFY_REQUEST });

  PassAppApi.requestVerificationCode(token, phone, type)
    .then(() => {
      SnackbarMessage.show("success", t("code_sent_successfully"));
      dispatch({ type: PHONE_VERIFY_REQUESTED });
    })
    .catch(() => {
      SnackbarMessage.show("error", t("error_requesting_verification"));
      dispatch({ type: PHONE_VERIFY_ERROR });
    });
};

export const verifyVerificationCode = (code, phone, t) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;

  dispatch({ type: PHONE_VERIFYING });

  PassAppApi.verifyVerificationCode(token, phone, code)
    .then((opts) => {
      if (opts === true || opts.data) {
        dispatch(getGlobalOptions(t));
        dispatch(getUser(t));
        dispatch({ type: PHONE_VERIFIED });
      } else {
        SnackbarMessage.show("error", t("invalid_verification_code"));
        dispatch({ type: PHONE_VERIFIED_ERROR });
      }
    })
    .catch(() => {
      dispatch({ type: PHONE_VERIFIED_ERROR });
      SnackbarMessage.show("error", t("error_requesting_verification"));
    });
};

export const cancelVerification = () => (dispatch) => {
  dispatch({ type: PHONE_VERIFY_CANCEL });
};

export const updateUser = (data, t) => (dispatch, getState) => {
  dispatch({ type: USER_UPDATING });
  const token = getState().authReducer.token;
  const association = getState().authReducer.association;
  const modeAdm = getState().authReducer.modeAdm;
  const enableAssociation = getState().authReducer.enableAssociation;
  return PassAppApi.updateUser(token, data)
    .then((user) => {
      dispatch({ type: USER_UPDATED, payload: { user } });
      SnackbarMessage.show("success", t("user_updated"));
      if (modeAdm && enableAssociation) {
        PassAppApi.associate(
          token,
          association.associationToken,
          association.id
        ).then(() => {
          dispatch(getUser(t));
          dispatch(clearAssociation());
          ConfirmationDialogStatic.show({
            title: t("adm_success"),
            labelYes: t("confirm"),
          });
        });
      }
    })
    .catch((err) => {
      let message = err[0] ? t(err[0]) : t("error_updating_user");
      SnackbarMessage.show("error", message);
      dispatch({ type: USER_UPDATE_ERROR });
      return Promise.reject();
    });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: USER_CLEAR });
};

export const updateDeviceToken = (t, token) => (dispatch) => {
  dispatch({ type: USER_UPDATING });
  if ("Android" in window) {
    let deviceToken = window.Android.getTokenNotification();
    PassAppApi.updateUser(token, {
      deviceToken: deviceToken,
      deviceType: "web",
    })
      .then((user) => {
        dispatch({ type: USER_UPDATED, payload: { user } });
      })
      .catch(() => {
        SnackbarMessage.show("error", t("error_updating_user"));
        dispatch({ type: USER_UPDATED });
      });
  }
};

export const loginWithToken = (token, t, isExternalUser) => (dispatch) => {
  dispatch({
    type: SET_EXTERNAL_USER,
    payload: isExternalUser || false,
  });
  PassAppApi.getUser(token)
    .then((user) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: token,
          user: user,
        },
      });
      dispatch(updateDeviceToken(t, token));
    })
    .catch(() => {
      dispatch({ type: LOGIN_FAILURE });
      //    SnackbarMessage.show("error", t("error_getting_user_info") + ": " + (err ? err.toString() : ""));
    })
    .finally(() => dispatch(closeLoadingOtherCompany()));
};

export const closeLoadingOtherCompany = () => (dispatch, getState) => {
  const otherCompany = getState().authReducer?.otherCompany;
  if (otherCompany?.loading)
    dispatch({
      type: CLOSE_LOADING_EXTERNAL_USER,
    });
};

export const whatsappActivation = () => (dispatch, getState) => {
  const token = getState().authReducer.token;
  PassAppApi.getActivationCode(token).then((resp) => {
    dispatch({ type: USER_WHATSAPP_CODE, payload: resp });
    if (resp && typeof resp != "undefined") {
      dispatch({ type: PHONE_VERIFYING });
      const url =
        "https://wa.me/" + resp.phone + "?text=" + encodeURI(resp.text);
      const newWindow = window.open(url, "_self", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  });
};

export function refreshToken(dispatch, getState) {
  const token = getState().authReducer.token;
  dispatch({ type: USER_REQUEST_TOKEN_FILE });
  /*** lOAD JWT FILE *****/
  PassAppApi.getTokenFile(token)
    .then((tokenFile) => {
      let tdata = { token: tokenFile.token, exp: null, wait: false };
      let tokenPart = tokenFile.token.split(".");
      if (tokenPart[1]) {
        const aux = JSON.parse(atob(tokenPart[1]));
        if (aux.exp) tdata.exp = aux.exp;
      }
      dispatch({ type: USER_TOKEN_FILE, payload: tdata });
    })
    .catch(() => {
      let tdata = { token: "", exp: null, wait: true };
      dispatch({ type: USER_TOKEN_FILE, payload: tdata });
    });
}

export const refreshActionToken = () => (dispatch, getState) => {
  refreshToken(dispatch, getState);
};

export const setAssociation = (association, activationToken) => (dispatch) => {
  dispatch({
    type: USER_ASSOCIATION,
    payload: {
      association,
      activationToken,
    },
  });
};

export const clearAssociation = () => (dispatch) => {
  dispatch({
    type: CLEAR_ASSOCIATION,
  });
};

export const setEnableAssociation = (value) => (dispatch) => {
  dispatch({
    type: ENABLE_ASSOCIATION,
    payload: value,
  });
};

export const associate = () => () => {};

export const setAllowFaceIdExternalUser = (value) => {
  return {
    type: SET_ALLOW_FACEID_EXTERNAL_USER,
    payload: value,
  };
};

export const setCustomizationExternalUser = (value) => (dispatch) => {
  dispatch({
    type: SET_CUSTOM_EXTERNAL_USER,
    payload: { primaryColor: value },
  });
};

export const setCustomizationExternalUserEmailValidation = (value) => (
  dispatch
) => {
  dispatch({
    type: SET_EXTERNAL_USER_FACE_EMAIL_VALIDATION,
    payload: value,
  });
};

export const setExternalUserToken = (value) => (dispatch) => {
  dispatch({
    type: SET_EXTERNAL_USER_TOKEN,
    payload: value,
  });
};