import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  onDialogOpen,
  onDialogClose,
  onDialogClear,
  onDialogExited,
  onDialogHideConfirmation,
} from "../../actions/dialog";
import ConfirmationDialog from "../common/ConfirmationDialog/ConfirmationDialog";

class DialogHolder extends React.Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onDialogClear();
    this.props.onDialogClose();
  }

  onConfirm() {
    this.props.onDialogClear();
    this.props.onDialogClose();
  }

  render() {
    const {
      detailOpen,
      dialogParams,
      visible,
      modified,
      showConfirmation,
      t,
      onDialogHideConfirmation,
      Dialog,
    } = this.props;

    return (
      <div>
        {modified && (
          <ConfirmationDialog
            open={showConfirmation}
            onConfirm={this.onClose}
            onClose={onDialogHideConfirmation}
            title={t("confirm_cancel")}
          />
        )}
        {visible && <Dialog open={detailOpen} {...dialogParams} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  detailOpen: state.dialogReducer.detailOpen,
  visible: state.dialogReducer.visible,
  dialogParams: state.dialogReducer.params,
  Dialog: state.dialogReducer.dialog,
  modified: state.dialogReducer.modified,
  showConfirmation: state.dialogReducer.showConfirmation,
});

const actionCreators = {
  onDialogOpen: onDialogOpen,
  onDialogClose: onDialogClose,
  onDialogClear: onDialogClear,
  onDialogExited: onDialogExited,
  onDialogHideConfirmation: onDialogHideConfirmation,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(DialogHolder));
