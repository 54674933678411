import React from "react";
import { withTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import marker from "../../../assets/img/marker.png";

class GoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: {
        lat: parseFloat(this.props.establishment.lat),
        lng: parseFloat(this.props.establishment.long),
      },
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(ev) {
    const { locked } = this.props;

    if (!locked) {
      this.setState({
        position: {
          lat: ev.lat,
          lng: ev.lng,
        },
      });
      this.props.onSelect(this.state.position);
    }
  }

  handleApiLoaded(map, maps) {}

  render() {
    const { establishment } = this.props;
    const { position } = this.state;

    return (
      <GoogleMapReact
        onClick={this.handleClick}
        bootstrapURLKeys={{ key: "AIzaSyBKlVZO7MpCJywKSbj3rQxQnUqbKOfUDwg" }}
        defaultCenter={{
          lat: parseFloat(establishment.lat),
          lng: parseFloat(establishment.long),
        }}
        defaultZoom={16}
        options={{
          mapTypeId: "hybrid",
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
      >
        {
          <img
            className="map-marker"
            src={marker}
            width={26}
            height={57}
            lat={position.lat}
            lng={position.lng}
            alt="map"
          ></img>
        }
      </GoogleMapReact>
    );
  }
}

export default withTranslation()(GoogleMap);
