import { useTranslation } from "react-i18next";

const useCalendar = () => {
  const { t } = useTranslation();

  function getMonthName(num) {
    let result;
    switch (Number(num)) {
      case 0:
        result = t("charter_reserve_january");
        break;
      case 1:
        result = t("charter_reserve_february");
        break;
      case 2:
        result = t("charter_reserve_march");
        break;
      case 3:
        result = t("charter_reserve_april");
        break;
      case 4:
        result = t("charter_reserve_may");
        break;
      case 5:
        result = t("charter_reserve_june");
        break;
      case 6:
        result = t("charter_reserve_july");
        break;
      case 7:
        result = t("charter_reserve_august");
        break;
      case 8:
        result = t("charter_reserve_september");
        break;
      case 9:
        result = t("charter_reserve_october");
        break;
      case 10:
        result = t("charter_reserve_november");
        break;
      case 11:
        result = t("charter_reserve_december");
        break;
      default:
        break;
    }
    return result;
  }

  function getWeekDayName(num) {
    let result;
    switch (Number(num)) {
      case 0:
        result = t("monday");
        break;
      case 1:
        result = t("tuesday");
        break;
      case 2:
        result = t("wednesday");
        break;
      case 3:
        result = t("thursday");
        break;
      case 4:
        result = t("friday");
        break;
      case 5:
        result = t("saturday");
        break;
      case 6:
        result = t("sunday");
        break;
      default:
        break;
    }
    return result;
  }

  return { getMonthName, getWeekDayName };
};

export default useCalendar;
