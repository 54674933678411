import React, { createContext, useState } from "react";

const initialState = {
  query: "",
  onSearch: () => {},
};

export const PassContext = createContext(initialState);

export const PassContextProvider = ({ children }) => {
  const [query, setQuery] = useState("");
  const onSearch = (value) => setQuery(value);

  return (
    <PassContext.Provider value={{ query, onSearch }}>
      {children}
    </PassContext.Provider>
  );
};
