import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import MailIcon from "@material-ui/icons/Mail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { HANDLE_MENU_MOBILE } from "actions/types";
import { useDispatch } from "react-redux";
const useStyles = makeStyles(styles);
export default function Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  const propsEmail =
    "Android" in window
      ? { onClick: () => sendEmail() }
      : {
          target: "_blank",
          rel: "noopener noreferrer",
          href: "mailto:soporte@passapp.net?subject=Soporte PassApp",
        };
  const propsWhatsapp =
    "Android" in window
      ? { onClick: () => sendWhatsapp() }
      : {
          target: "_blank",
          rel: "noopener noreferrer",
          href: "https://wa.me/5491167524037",
        };
  const sendWhatsapp = () => {
    if ("Android" in window) {
      window.Android.sendWhatsapp(
        "whatsapp://send/?phone=5491167524037&text&app_absent=0"
      );
    }
  };
  const sendEmail = () => {
    if ("Android" in window) {
      window.Android.sendEmail(
        "mailto:soporte@passapp.net?subject=Soporte PassApp"
      );
    }
  };
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <div
            disabled
            color="transparent"
            href="#"
            className={classes.title + " " + classes.infoItems}
          >
            <div className={classes.moreInfo + " info"}>
              <div className={classes.infoItem}>
                <MailIcon className="navbar-icon" />{" "}
                <a className={classes.infoItem} {...propsEmail}>
                  soporte@passapp.net
                </a>
              </div>
              <div className={classes.infoItem}>
                <WhatsAppIcon className="navbar-icon" />{" "}
                <a className={classes.infoItem} {...propsWhatsapp}>
                  +54 911 67524037
                </a>
              </div>
            </div>
          </div>
        </div>
        <Hidden smDown implementation="css">
          {/* Right side contents */}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              dispatch({
                type: HANDLE_MENU_MOBILE,
                payload: true,
              });
            }}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  routes: PropTypes.arrayOf(PropTypes.object),
};
