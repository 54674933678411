import {
  PASS_UPDATING,
  PASS_UPDATED,
  DYNAMIC_LINK_CREATED,
  DYNAMIC_LINK_ERROR,
  DYNAMIC_LINK_CLEAR,
  PASS_ERROR,
  PASS_CREATING,
  PASS_CREATED,
  PASS_GETTING,
  PASS_ACQUIRED,
  PASS_MANAGER_LOAD,
  PASS_MANAGER_UPD,
  PASS_MANAGER_REMOVE,
} from "../actions/types";

const initialState = {
  loading: false,
  dynamicLink: null,
  passManagerList: [],
};

export function passesReducer(state = initialState, action) {
  switch (action.type) {
    case PASS_CREATING:
    case PASS_UPDATING:
    case PASS_GETTING:
      return {
        ...state,
        loading: true,
      };
    case PASS_CREATED:
    case PASS_UPDATED:
    case PASS_ACQUIRED:
      return {
        ...state,
        loading: false,
      };
    case PASS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DYNAMIC_LINK_CREATED:
      return {
        ...state,
        dynamicLink: action.payload.link,
      };
    case DYNAMIC_LINK_ERROR:
    case DYNAMIC_LINK_CLEAR:
      return {
        ...state,
        dynamicLink: null,
      };
    case PASS_MANAGER_LOAD:
      return {
        ...state,
        passManagerList: action.payload.data,
      };
    case PASS_MANAGER_UPD: {
      const passObj = action.payload.obj;
      return {
        ...state,
        passManagerList: state.passManagerList.map((v) => {
          if (passObj.id === v.id) {
            return passObj;
          }
          return v;
        }),
      };
    }
    case PASS_MANAGER_REMOVE: {
      const passID = action.payload.id;
      return {
        ...state,
        passManagerList: state.passManagerList.filter((v) => {
          return passID !== v.id;
        }),
      };
    }
    default:
      return state;
  }
}
