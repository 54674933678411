import React, { Component } from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";
import Button from "components/common/CustomButtons/Button.js";
import { withTranslation } from "react-i18next";
import { FormControl, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { connect } from "react-redux";
import { onFieldChange } from "../../utils/Utils";
import "./AddGuestsDialog.scss";
class AddGuestsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxGuests: props.maxGuests,
    };
    this.onGuestsChanged = this.onGuestsChanged.bind(this);
    this.handleFieldChange = onFieldChange.bind(this);
  }

  onGuestsChanged(ev) {
    ev.preventDefault();
    const { maxGuests } = this.state;
    this.props.onMaxGuestsChanged(maxGuests);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        maxGuests: prevProps.maxGuests,
      });
    }
  }

  render() {
    const { t, open, onClose, classes } = this.props;
    const { maxGuests } = this.state;

    return (
      <Dialog
        className="add-guests-dialog"
        onClose={onClose}
        maxWidth={"xs"}
        open={open}
      >
        <form onSubmit={this.onGuestsChanged}>
          <DialogContent>
            <Typography>{t("dialog_max_guests_allowed")}</Typography>

            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                label={t("max_guests_allowed")}
                name="maxGuests"
                fullWidth
                type="number"
                inputProps={{ autoComplete: "new-password", min: "1" }}
                onChange={this.handleFieldChange}
                value={maxGuests}
                required
              />
            </FormControl>
          </DialogContent>

          <DialogActions className="dialog-buttons">
            <Button round type="submit" color="primary">
              {t("accept")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  token: state.authReducer.token,
  appStrings: state.userReducer.appStrings,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  dialogParams: state.dialogReducer,
  isLoading: state.passesReducer.loading,
  dynamicLink: state.passesReducer.dynamicLink,
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(AddGuestsDialog)));
