import MomentUtils from "@date-io/moment";

class CustomMomentUtils extends MomentUtils {
  getWeekdays() {
    let weekDays = ["LU", "MA", "MI", "JU", "VI", "SA", "DO"];
    let defaultLanguage = "es";
    let userLang =
      navigator.language || navigator.userLanguage || defaultLanguage;
    if (userLang.slice(0, 2) === "es") {
      weekDays = ["LU", "MA", "MI", "JU", "VI", "SA", "DO"];
    } else {
      if (userLang.slice(0, 2) === "pt") {
        weekDays = ["DO", "SE", "TE", "QU", "QU", "SE", "SA"];
      } else {
        weekDays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
      }
    }
    return weekDays;
  }
  getWeekArray(date) {
    let start = date
      .clone()
      .startOf("month")
      .startOf("week");
    let end = date
      .clone()
      .endOf("month")
      .endOf("week");
    let count = 0;
    let current = start.add(12, "hour");
    let nestedWeeks = [];
    while (current.isBefore(end)) {
      let weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);
      current = current.clone().add(1, "day");
      count += 1;
    }
    return nestedWeeks;
  }
}

export default CustomMomentUtils;
