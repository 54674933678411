import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { amber, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class SnackbarMessage extends React.Component {
  static snackbar;
  static show(variant, message, duration) {
    SnackbarMessage.snackbar.setState({
      open: true,
      variant: variant,
      message: message,
      duration: duration ? duration : 4000,
    });
  }

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    SnackbarMessage.snackbar = this;
    this.state = {
      open: false,
      variant: "info",
      message: "",
      vertical: "bottom",
      horizontal: "center",
    };
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    if (SnackbarMessage.snackbar !== this) return <div />;

    const {
      open,
      variant,
      message,
      horizontal,
      vertical,
      duration,
    } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: vertical,
          horizontal: horizontal,
        }}
        open={open}
        autoHideDuration={duration}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function MySnackbarContentWrapper(props) {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

export default SnackbarMessage;
