import React from "react";
import ReactDOM from "react-dom";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import { getBrowserLanguage } from "utils/Utils";
import "./lang/i18n";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/es";
//import 'moment/locale/en-gb';
import "moment/locale/pt-br";
import "./assets/css/App.scss";

import { Provider } from "react-redux";
import { store } from "./store";
import Theming from "./Theming";

moment.locale(getBrowserLanguage());

Moment.globalMoment = moment;
Moment.globalLocale = getBrowserLanguage();
Moment.globalLocal = true;

ReactDOM.render(
  <Provider store={store}>
    <Theming />
  </Provider>,
  document.getElementById("root")
);
