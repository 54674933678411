import MomentUtils from "@date-io/moment";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getSpaceReservationsFilter, filterList } from "actions/space";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import ParkingsTable from "components/Parkings/ParkingsTables";
import moment from "moment";
import React from "react";
import ReactExport from "react-data-export";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getTimezoneLocal, getUserEstablishments } from "utils/Utils";
const columns = [
  {
    Header: "space_reserve_date_generation",
    accessor: "creationDate",
    class: "cell-text-br",
  },
  {
    Header: "space_reserve_date_resverve",
    accessor: "beginDatetime",
    class: "cell-text-br",
  },
  {
    Header: "space_reserve_hour",
    accessor: "endDatetime",
    class: "cell-text-br",
  },
  {
    Header: "space_reserve_user_employee",
    accessor: "user",
    class: "cell-text-br",
  },
  {
    Header: "space_reserve",
    accessor: "space",
    class: "cell-text-br",
  },
  {
    Header: "space_reserve_status",
    accessor: "status",
    action: "translate",
  },
  {
    Header: "space_reserve_date_modification",
    accessor: "modificationDate",
    class: "cell-text-br",
  },
  {
    Header: "space_reserve_credits",
    accessor: "credit",
  },
  {
    Header: "space_reserve_money",
    accessor: "money",
  },
];
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ByReserve extends React.Component {
  constructor(props) {
    super(props);
    const { t, user, companies, company } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmReject = this.handleConfirmReject.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleNewList = this.handleNewList.bind(this);
    this.extractDateTime = this.extractDateTime.bind(this);
    let userEstablishments = getUserEstablishments(user, companies, []);
    let selectedEstablishment = userEstablishments.find(
      (e) => e.id === company
    );
    let timezone =
      selectedEstablishment && selectedEstablishment.timezone
        ? selectedEstablishment.timezone
        : getTimezoneLocal();
    let date = moment().tz(timezone);
    this.state = {
      searchText: "",
      from: date,
      to: date,
      status: "",
      itemID: null,
      showConfirm: false,
      statusLst: ["approved", "canceled", "pending", "rejected", "expired"],
      globalPageIndex: 0,
    };
    this.props.getList(t, date.format("YYYY-MM-DD"), date.format("YYYY-MM-DD"));
  }
  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }
  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        if (v.space && v.space.search(new RegExp(text, "i")) > -1) return true;
        if (v.user && v.user.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }
  handleDateChange(date, field) {
    if (date && date.isValid()) {
      if (field === "from") {
        this.setState({ from: date });
      }
      if (field === "to") {
        this.setState({ to: date });
      }
    }
  }
  handleStatusFilter(event) {
    this.setState({ status: event.target.value });
  }
  handleFilter() {
    const { from, to, status } = this.state;
    this.props.getList(
      this.props.t,
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      status
    );
  }
  handleCancel(itemID) {
    this.setState({ showConfirm: true, itemID: itemID });
  }
  handleConfirmReject() {
    const { itemID, from, to, status } = this.state;
    this.props.rejectParkingReservation(
      this.props.t,
      itemID,
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      status
    );
    this.closeConfirm();
  }
  closeConfirm() {
    this.setState({ showConfirm: false, itemID: null });
  }
  extractDateTime(dateTimeStr) {
    const dateTime = new Date(dateTimeStr);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    return { date, time };
  }
  handleNewList(list) {
    const { t } = this.props;
    return list.map((item) => ({
      ...item,
      money: `${item.symbol || ""} ${item.money || ""}`,
      moneySimple: item.money || "",
      symbol: item.symbol || "",
      creditSimple: item.credit ? `${item.credit}` : "",
      credit: item.credit ? `${item.credit} ${t("space_reserve_credits")}` : "",
      beginDatetime: `${this.extractDateTime(item.beginDatetime).date}`,
      endDatetime: `${this.extractDateTime(item.beginDatetime).time}-${
        this.extractDateTime(item.endDatetime).time
      }`,
    }));
  }
  render() {
    const { t, list, loading } = this.props;
    const {
      searchText,
      showConfirm,
      from,
      to,
      status,
      statusLst,
      globalPageIndex,
    } = this.state;
    const dataExport = this.handleNewList(list).map((item) => {
      return {
        creationDate: item.creationDate,
        beginDatetime: item.beginDatetime,
        endDatetime: item.endDatetime,
        user: item.user,
        adminComment: item.adminComment,
        space: item.space,
        status: t("parking_" + item.status),
        modificationDate: item.modificationDate,
        credit: item.credit,
        money: item.money,
        moneySimple: item.moneySimple,
        symbol: item.symbol,
        creditSimple: item.creditSimple,
      };
    });
    return (
      <div className="body-box">
        <div className="search-box-2">
          <div className="column-left cols-2">
            <div className="filter_field">
              <label>{t("status")}</label>
              <FormControl className="input_status">
                <Select
                  onChange={this.handleStatusFilter}
                  fullWidth={true}
                  value={status}
                  displayEmpty
                  defaultValue=""
                >
                  <MenuItem value={""}>Todas</MenuItem>
                  {statusLst.map((data, j) => (
                    <MenuItem value={data} key={j}>
                      {t("status_" + data)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="filter_field">
                <label>{t("dete_from")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={from}
                    onChange={(date) => this.handleDateChange(date, "from")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
              <div className="filter_field">
                <label>{t("date_to")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={to}
                    onChange={(date) => this.handleDateChange(date, "to")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
            </MuiPickersUtilsProvider>
            <div className="filter_field">
              <Button onClick={this.handleFilter} className="filterButton">
                {t("filter")}
              </Button>
            </div>
          </div>
          <div className="column-rigth">
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name="Spaces">
                <ExcelColumn
                  label={t("space_reserve_date_generation_excel")}
                  value="creationDate"
                />
                <ExcelColumn
                  label={t("space_reserve_date_resverve_excel")}
                  value="beginDatetime"
                />
                <ExcelColumn
                  label={t("space_reserve_hour_excel")}
                  value="endDatetime"
                />
                <ExcelColumn
                  label={t("space_reserve_user_employee_excel")}
                  value="user"
                />
                <ExcelColumn
                  label={t("admin_comment")}
                  value="adminComment"
                />
                <ExcelColumn label={t("space_reserve_excel")} value="space" />
                <ExcelColumn label={t("status")} value="status" />
                <ExcelColumn
                  label={t("space_reserve_date_modification_excel")}
                  value="modificationDate"
                />
                <ExcelColumn
                  label={t("space_reserve_credits_excel")}
                  value="creditSimple"
                />
                <ExcelColumn
                  label={t("space_reserve_money_excel")}
                  value="moneySimple"
                />
                <ExcelColumn
                  label={t("space_credit_money_excel")}
                  value="symbol"
                />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
        </div>
        <ParkingsTable
          data={this.handleNewList(list)}
          columns={columns}
          handleCancel={(data) => this.handleCancel(data)}
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></ParkingsTable>
        <ConfirmationDialog
          open={showConfirm}
          onConfirm={this.handleConfirmReject}
          onClose={this.closeConfirm}
          title={t("confirm_reject_parkingReserv")}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  company: state.authReducer.user.spaceManager.company,
  companies: state.userReducer.companies,
  list: state.spaceReducer.spaces_reservations,
  listAll: state.spaceReducer.spaces_reservations,
  loading: state.spaceReducer.loading,
});
const mapActionsToProps = {
  getList: getSpaceReservationsFilter,
  filterList,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(ByReserve));
