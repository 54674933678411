import { Button } from "@material-ui/core";
import React, { useRef } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import FlyerPDF from "./reports/FlyerPDF";

const HeaderActions = (props) => {
  const documentRef = useRef(null);
  const actions = (props) => {
    const { t, column, currentList } = props;
    switch (column.headerAction) {
      case "printAllQr": {
        return (
          <>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!currentList || currentList.length === 0}
                >
                  {t(`${column.render("Header")}`)}
                </Button>
              )}
              content={() => documentRef.current}
            />
            <div style={{ display: "none" }}>
              <FlyerPDF ref={documentRef} t={t} desks={currentList} />
            </div>
          </>
        );
      }
    }
  };
  return <div>{actions(props)}</div>;
};

const mapStateToProps = (state) => ({
  currentList: state.desks.currentList,
});

export default connect(
  mapStateToProps,
  null
)(HeaderActions);
