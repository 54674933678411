import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import {
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Button from "components/common/CustomButtons/Button.js";
import "./ConfirmationDialog.scss";
const useStyles = createStyles(styles);

class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
      url: "",
    };
  }

  render() {
    const {
      t,
      open,
      onClose,
      onConfirm,
      title,
      onExited,
      labelNo,
      labelYes,
      children,
    } = this.props;

    return (
      <Dialog
        onClose={onClose}
        className="confirmation-dialog"
        aria-labelledby="simple-dialog-title"
        open={open}
        onExited={onExited}
      >
        <DialogTitle>{title}</DialogTitle>
        {children ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {children}
            </DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions className="dialog-buttons">
          {labelNo !== "" ? (
            <Button round onClick={onClose} color="danger">
              {labelNo ? labelNo : t("no")}
            </Button>
          ) : null}
          <Button round onClick={onConfirm} color="primary">
            {labelYes ? labelYes : t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withTranslation()(withStyles(useStyles)(ConfirmationDialog));
