import { combineReducers } from "redux";

import { authReducer } from "./auth.reducer";
import { userReducer } from "./user.reducer";
import { dialogReducer } from "./dialog.reducer";
import { passesReducer } from "./passes.reducer";
import { companyStaff } from "./companyStaff.reducer";
import { establishmentReducer } from "./establishment.reducer";
import { areas } from "./areas.reducer";
import { roomReservation } from "./roomReservation.reducer";
import { parkings } from "./parkings.reducer";
import { desks } from "./desks.reducer";
import { eventsReducer } from "./events.reducer";
import { menuReducer } from "./menu.reducer";
import { charterReducer } from "./charter.reducer";
import { spaceReducer } from "./space.reducer";

const rootReducer = combineReducers({
  authReducer,
  userReducer,
  dialogReducer,
  passesReducer,
  establishmentReducer,
  companyStaff,
  roomReservation,
  parkings,
  desks,
  events: eventsReducer,
  charterReducer,
  menuReducer,
  areas,
  spaceReducer,
});
export default rootReducer;
