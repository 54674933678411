import React, { Component } from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";
import Button from "components/common/CustomButtons/Button.js";
import { withTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  TextField,
  FormHelperText,
  IconButton,
  Checkbox,
  Grid,
  Avatar,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import "./PassDetail.scss";
import {
  PASS_TYPE_INVITE,
  PASS_TYPE_OCCASIONAL,
  ESTABLISHMENT,
  PASS_TYPE_PERMANENT,
  GUEST_TYPE_PARTICULAR,
  GUEST_TYPE_PROVIDER,
  PASS_HOURS_LIMIT,
  PASS_DATE,
  PASS_STATUS_FINISHED,
  PASS_STATUS_CANCELED,
  PASS_STATUS_CANCELLED,
  PASS_STATUS_PENDING,
  PASS_STATUS_REJECTED,
  PASS_STATUS_ACCEPTED,
  COUNTRY,
  COMPANY,
} from "../../Constant";
import PermanentType from "./PermanentType/PermanentType";
import {
  getUserEstablishments,
  isCountry,
  onFieldChange,
  getTimezoneLocal,
  getUserPhotoUrl,
  getLocaleLanguage,
  getBrowserLanguage,
} from "../../utils/Utils";
import moment from "moment-timezone";
import CustomMomentUtils from "utils/CustomMomentUtils";
import SnackbarMessage from "utils/SnackbarMessage";
import Loader from "utils/Loader";
import PassRequest from "components/PassRequest/PassRequest";
import CopyComponent from "components/common/CopyComponent/CopyComponent";
import PassGuests from "components/PassGuests/PassGuests";
import { getUserWorkplace, getDatetimeShort } from "utils/Utils";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import {
  onDialogClose,
  onDialogExited,
  onDialogModified,
  onDialogClear,
  onDialogOpen,
} from "../../actions/dialog";
import {
  updateInvite,
  createInvite,
  createDynamicLink,
} from "../../actions/passes";
import AddGuestsDialog from "../AddGuestsDialog/AddGuestsDialog";
import PassSelectContacts from "./PassSelectContacts";
import withBreakpoint from "../../hooks/classHooks/withBreakpoint";
import "moment/locale/pt-br";
import "moment/locale/es";
import "moment/locale/en-ca";
import MomentUtils from "@date-io/moment";

moment.locale("es");

class PassDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getInitialState(),
      establishments: [],
    };

    this.showCancelWarning = this.showCancelWarning.bind(this);
    this.closeCancelWarning = this.closeCancelWarning.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEstablishmentSelect = this.handleEstablishmentSelect.bind(this);
    this.handleBeginDate = this.handleBeginDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handlePassTypeChange = this.handlePassTypeChange.bind(this);
    this.handleGuestTypeChange = this.handleGuestTypeChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.onCalendarUpdate = this.onCalendarUpdate.bind(this);
    this.onAllDayChange = this.onAllDayChange.bind(this);
    this.handleFreeParking = this.handleFreeParking.bind(this);
    this.onGuestRemoved = this.onGuestRemoved.bind(this);
    this.handleFieldChange = onFieldChange.bind(this);
    this.handleDynamicLinkCopy = this.handleDynamicLinkCopy.bind(this);
    this.closeDynamicLinkCopy = this.closeDynamicLinkCopy.bind(this);
    this.onMaxGuestsChanged = this.onMaxGuestsChanged.bind(this);
    this.createDynamicLink = this.createDynamicLink.bind(this);
    this.revertMaxGuests = this.revertMaxGuests.bind(this);
    this.goToDetailPass = this.goToDetailPass.bind(this);
    this.onChangeSelectionContact = this.onChangeSelectionContact.bind(this);
  }

  getInitialState() {
    let endDate = new Date();
    endDate.setHours(endDate.getHours() + 3);
    return {
      open: false,
      beginDate: new Date(),
      endDate: endDate,
      passType: PASS_TYPE_OCCASIONAL,
      guestType: GUEST_TYPE_PARTICULAR,
      selectedEstablishmentId: "",
      selectedEstablishment: "",
      comment: "",
      calendar: {},
      errors: {},
      permanentPassesEnabled: true,
      providersEnabled: true,
      freeParking: false,
      removeGuests: [],
      showCancelWarning: false,
      maxGuests: "",
      isCopying: false,
      copyEvent: null,
      onError: null,
      timezone: getTimezoneLocal(),
      stepSelectionContact: true,
      selectedContacts: [],
    };
  }

  closeCancelWarning() {
    this.setState({
      showCancelWarning: false,
    });
  }

  componentDidMount() {
    const { establishments } = this.state;
    const { pass, user, companies, countries, officeBuildings } = this.props;
    let newEstablishments;
    newEstablishments = getUserEstablishments(user, companies, countries, officeBuildings);

    if (establishments.length !== newEstablishments.length) {
      this.setState({
        establishments: newEstablishments,
      });
    }

    let initialState = this.getInitialState();

    if (pass) {
      let selectedEstablishmentId = pass.company ? pass.company : pass.country;
      let selectedEstablishment = newEstablishments.find(
        (e) => e.id === selectedEstablishmentId
      );
      let timezone =
        selectedEstablishment && selectedEstablishment.timezone
          ? selectedEstablishment.timezone
          : initialState.timezone;

      let beginDate = moment(new Date(pass.beginTimestamp * 1000));
      if (pass.beginDatetime) {
        beginDate = moment.tz(getDatetimeShort(pass.beginDatetime), timezone);
      }

      let endDate = moment(new Date(pass.endTimestamp * 1000));
      if (pass.endDatetime) {
        endDate = moment.tz(getDatetimeShort(pass.endDatetime), timezone);
      }

      this.setState(
        {
          ...initialState,
          beginDate: beginDate,
          endDate: endDate,
          passType: pass.calendar ? PASS_TYPE_PERMANENT : PASS_TYPE_OCCASIONAL,
          guestType: pass.guestsType || pass.guestType,
          selectedEstablishmentId: selectedEstablishmentId,
          selectedEstablishment: selectedEstablishment,
          calendar: pass.calendar,
          comment: pass.comments,
          freeParking: !!pass.freeParking,
          providersEnabled: false,
          permanentPassesEnabled: false,
          type: pass.company ? COMPANY : COUNTRY,
          maxGuests: pass.maxGuests
            ? pass.maxGuests
            : pass.guests
            ? pass.guests.length + 1
            : 1,
          timezone: timezone,
          stepSelectionContact: false,
        },
        this.createDynamicLink
      );
    } else {
      this.setState(initialState, this.createDynamicLink);
    }
  }

  onGuestRemoved(guest) {
    const { removeGuests } = this.state;
    removeGuests.push(guest);
    this.setState({ removeGuests });
    this.props.onDialogModified();
  }

  handleDynamicLinkCopy(onCopy) {
    onCopy(true);
    this.setState({
      isCopying: true,
      copyEvent: onCopy,
      onError: this.revertMaxGuests,
    });
  }

  revertMaxGuests() {
    const { prevMaxGuests } = this.state;

    if (prevMaxGuests) {
      this.setState({
        maxGuests: prevMaxGuests,
      });
    }
  }

  closeDynamicLinkCopy() {
    this.setState({
      isCopying: false,
    });
  }

  isValidPass() {
    const { t, passes, pass, user, unlimitedPasses } = this.props;
    const {
      maxGuests,
      beginDate,
      endDate,
      selectedEstablishmentId,
      selectedEstablishment,
      passType,
      calendar,
      updatedCalendar,
      selectedContacts,
    } = this.state;

    let errors = {};

    if (!maxGuests && selectedContacts.length === 0) {
      errors.maxGuests = true;
    }

    if (!selectedEstablishmentId) {
      errors[ESTABLISHMENT] = t("must_select_destination");
    }

    if (
      passType === PASS_TYPE_OCCASIONAL &&
      moment(endDate).diff(moment(beginDate), "hours") > PASS_HOURS_LIMIT
    ) {
      errors[PASS_DATE] = t("error_pass_length");
    }

    if (moment(endDate).diff(moment(beginDate), "seconds") < 0) {
      errors[PASS_DATE] = t("error_end_before_start");
    }

    if (passType === PASS_TYPE_PERMANENT) {
      let c = updatedCalendar || calendar;

      if (!c.days || c.days.length === 0) {
        SnackbarMessage.show("error", t("error_select_day"));
        return false;
      }
      if (c.toTime && c.fromTime && c.toTime <= c.fromTime) {
        SnackbarMessage.show("error", t("error_out_before_entry"));
        return false;
      }

      let currentCountryPasses = 0;
      let maxPermanentPasses = selectedEstablishment.maxPermanentPassesPerUser;

      !unlimitedPasses &&
        passes.forEach((p) => {
          let id = p.country ? p.country : p.company;
          if (
            p.status === PASS_STATUS_ACCEPTED &&
            id === selectedEstablishmentId &&
            p.calendar
          ) {
            currentCountryPasses++;
          }
        });

      if (user.maxPermanentPasses !== -1 && !pass) {
        if (
          user.maxPermanentPasses > 0 &&
          currentCountryPasses >= user.maxPermanentPasses
        ) {
          SnackbarMessage.show("error", t("error_max_permanent_passes_user"));
          return false;
        } else if (
          user.maxPermanentPasses > 0 &&
          currentCountryPasses >= maxPermanentPasses &&
          currentCountryPasses >= user.maxPermanentPasses
        ) {
          SnackbarMessage.show("error", t("error_max_permanent_passes"));
          return false;
        } else if (user.maxPermanentPasses === 0) {
          SnackbarMessage.show("error", t("error_max_permanent_passes_user"));
          return false;
        }
      }
    }

    this.setState({
      errors: errors,
    });
    return Object.entries(errors).length === 0;
  }

  handleSubmit(closeDialog) {
    if (this.isValidPass()) {
      const { user, pass, t, appStrings } = this.props;
      const {
        selectedEstablishment,
        beginDate,
        endDate,
        copyEvent,
        onError,
      } = this.state;

      if (pass) {
        this.props.updateInvite(
          this.buildUpdatePass(pass),
          t,
          t("pass_updated_successfully"),
          !!closeDialog,
          copyEvent,
          onError
        );
      } else {
        let data = {
          user,
          selectedEstablishment,
          beginDate,
          endDate,
          appStrings,
        };
        this.props.createInvite(this.buildPass(), t, data);
      }
    }
  }

  createDynamicLink() {
    const { t, appStrings, pass, user } = this.props;
    const { selectedEstablishment, beginDate, endDate } = this.state;

    if (pass) {
      this.props.createDynamicLink(pass, t, {
        user,
        selectedEstablishment,
        beginDate,
        endDate,
        appStrings,
      });
    }
  }

  showCancelWarning() {
    this.setState({
      showCancelWarning: true,
    });
  }

  handleCancel() {
    const { pass, t } = this.props;
    if (pass) {
      let cancelPass = {
        status: PASS_STATUS_CANCELLED,
        id: pass.id,
      };

      this.setState({
        showCancelWarning: false,
      });

      this.props.updateInvite(cancelPass, t);
    }
  }

  buildUpdatePass() {
    const {
      beginDate,
      endDate,
      comment,
      selectedEstablishment,
      guestType,
      calendar,
      updatedCalendar,
      freeParking,
      removeGuests,
      maxGuests,
    } = this.state;
    const { pass, user, token } = this.props;

    let establishment = selectedEstablishment;

    let country = isCountry(establishment) ? establishment : null;
    let company = !isCountry(establishment) ? establishment : null;

    let updatePass = {
      beginTimestamp: Math.floor(beginDate.toDate().getTime() / 1000),
      beginDatetime: moment(beginDate).format(),
      endTimestamp: Math.floor(endDate.toDate().getTime() / 1000),
      endDatetime: moment(endDate).format(),
      type: PASS_TYPE_INVITE,
      guestsType: guestType,
      id: pass.id,
      lat: establishment.lat,
      long: establishment.long,
      host: token.split(":")[0],
      country: country ? country.id : null,
      building: company ? company.building : null,
      manager: establishment.manager,
      location: establishment.location,
      company: company ? company.id : null,
      comments: comment,
      calendar: updatedCalendar || calendar,
      freeParking: freeParking,
      removeGuests: removeGuests.map((guest) => guest.id),
      maxGuests: parseInt(maxGuests),
    };

    updatePass.status = PASS_STATUS_ACCEPTED;

    if (updatePass.company) {
      let workplace = getUserWorkplace(user, updatePass.company);

      if (!workplace || !workplace.isAdmin) {
        updatePass.status = PASS_STATUS_PENDING;
      }
      updatePass.manager = workplace.manager;
    }

    return updatePass;
  }

  buildPass() {
    const {
      passType,
      beginDate,
      endDate,
      comment,
      selectedEstablishment,
      guestType,
      calendar,
      updatedCalendar,
      freeParking,
      maxGuests,
    } = this.state;
    const { token } = this.props;
    const { selectedContacts } = this.state;
    let establishment = selectedEstablishment;

    let country = isCountry(establishment) ? establishment : null;
    let company = !isCountry(establishment) ? establishment : null;

    let pass = {
      beginTimestamp: Math.floor(beginDate.toDate().getTime() / 1000),
      beginDatetime: moment(beginDate).format(),
      endTimestamp: Math.floor(endDate.toDate().getTime() / 1000),
      endDatetime: moment(endDate).format(),
      type: PASS_TYPE_INVITE,
      guests: selectedContacts.map((c) => c.id),
      guestsType: guestType,
      id: "",
      lat: establishment.lat,
      long: establishment.long,
      host: token.split(":")[0],
      country: country ? country.id : null,
      building: company ? company.building : null,
      manager: establishment.manager,
      location: establishment.location,
      company: company ? company.id : null,
      comments: comment,
      calendar:
        passType === PASS_TYPE_PERMANENT
          ? updatedCalendar || calendar
          : undefined,
      freeParking: freeParking,
    };

    if (selectedContacts.length === 0)
      pass = { ...pass, maxGuests: parseInt(maxGuests) };

    return pass;
  }

  handleBeginDate(date) {
    const { timezone } = this.state;

    let beginDate = moment.tz(date, timezone);
    let endDate = moment.tz(date, timezone).add("3", "hours");

    this.setState({
      beginDate,
      endDate,
    });
    this.props.onDialogModified();
  }

  handleEndDate(date) {
    const { timezone } = this.state;

    let endDate = moment.tz(date, timezone);

    this.setState({
      endDate: endDate,
    });
    this.props.onDialogModified();
  }

  handleEstablishmentSelect(ev) {
    const { errors, establishments, passType, guestType } = this.state;
    let permanentPassesEnabled = true;
    let providersEnabled = true;
    let type = COUNTRY;
    let establishment = establishments.find(
      (est) => est.id === ev.target.value
    );
    let timezone =
      establishment.timezone != null
        ? establishment.timezone
        : getTimezoneLocal();

    let beginDateAux = moment().tz(timezone);
    let endDateAux = moment()
      .tz(timezone)
      .add("3", "hours");

    if (passType === PASS_TYPE_PERMANENT) {
      endDateAux = moment()
        .tz(timezone)
        .endOf("year");
    }

    if (establishment.id === ev.target.value) {
      if (!isCountry(establishment)) {
        type = COMPANY;
        providersEnabled = false;
      } else {
        permanentPassesEnabled = establishment.permanentPassesEnabled;
        providersEnabled = establishment.providersEnabled;
      }
    }

    let newGuestType = guestType;
    if (!providersEnabled && guestType === GUEST_TYPE_PROVIDER) {
      newGuestType = GUEST_TYPE_PARTICULAR;
    }

    let newPassType = passType;
    if (!permanentPassesEnabled && passType === PASS_TYPE_PERMANENT) {
      newPassType = PASS_TYPE_OCCASIONAL;
      endDateAux = moment()
        .tz(timezone)
        .add("3", "hours");
    }

    errors[ESTABLISHMENT] = null;
    this.setState({
      timezone: timezone,
      beginDate: beginDateAux,
      endDate: endDateAux,
      selectedEstablishmentId: ev.target.value,
      permanentPassesEnabled,
      providersEnabled,
      type,
      selectedEstablishment: establishment,
      guestType: newGuestType,
      passType: newPassType,
    });
    this.props.onDialogModified();
  }

  handleCommentChange(ev) {
    this.setState({
      comment: ev.target.value,
    });
    this.props.onDialogModified();
  }

  handlePassTypeChange(type) {
    const { timezone } = this.state;

    let endDate = moment()
      .tz(timezone)
      .add("3", "hours");
    if (type.target.value === PASS_TYPE_PERMANENT) {
      endDate = moment()
        .tz(timezone)
        .endOf("year");
    }

    this.setState({
      passType: type.target.value,
      endDate: endDate,
    });
    this.props.onDialogModified();
  }

  handleGuestTypeChange(type) {
    this.setState({
      guestType: type.target.value,
    });
    this.props.onDialogModified();
  }

  onAllDayChange(newEndTime) {
    this.setState({
      endDate: newEndTime,
    });
    this.props.onDialogModified();
  }

  handleFreeParking() {
    const { freeParking } = this.state;
    this.setState({
      freeParking: !freeParking,
    });
    this.props.onDialogModified();
  }

  onCalendarUpdate(calendar) {
    let updatedCalendar = calendar;

    if (updatedCalendar) {
      this.props.onDialogModified();

      updatedCalendar = {
        ...calendar,
        fromTime: moment(calendar.fromTime).format("HH:mm"),
        toTime: moment(calendar.toTime).format("HH:mm"),
      };
    }

    this.setState({
      updatedCalendar: updatedCalendar,
    });
  }

  goToDetailPass() {
    this.setState({
      stepSelectionContact: false,
    });
  }

  renderSubmitButtons() {
    const { t, pass, isLoading } = this.props;
    const { stepSelectionContact, selectedContacts } = this.state;

    if (stepSelectionContact) {
      return (
        <Button
          onClick={this.goToDetailPass}
          round
          color="primary"
          className="submitButton"
        >
          {t(
            selectedContacts.length > 0
              ? "send_pass_contact_button"
              : "send_pass_contact_new_button"
          )}
        </Button>
      );
    }

    if (pass) {
      let disabled =
        pass.status === PASS_STATUS_FINISHED ||
        pass.status === PASS_STATUS_CANCELED ||
        pass.status === PASS_STATUS_CANCELLED ||
        pass.status === PASS_STATUS_REJECTED;

      return (
        <div>
          <Button
            onClick={this.showCancelWarning}
            round
            disabled={disabled || isLoading}
            color="danger"
          >
            {t("cancel_invite")}
          </Button>

          <Button
            onClick={this.handleSubmit}
            round
            disabled={disabled || isLoading}
            color="primary"
          >
            {t("update_invite")}
          </Button>
        </div>
      );
    }

    return (
      <Button
        onClick={this.handleSubmit}
        disabled={isLoading}
        round
        color="primary"
      >
        {t("send_invite")}
      </Button>
    );
  }

  onMaxGuestsChanged(newMaxGuests) {
    const { t, pass } = this.props;
    const { maxGuests } = this.state;

    if (newMaxGuests <= pass.guests.length) {
      SnackbarMessage.show("error", t("dialog_max_guests_error"));
      return false;
    }

    this.setState(
      {
        prevMaxGuests: maxGuests,
        maxGuests: newMaxGuests,
      },
      () => {
        this.handleSubmit(false);
        this.setState({
          isCopying: false,
          copyEvent: null,
          onError: null,
        });
      }
    );
  }

  onChangeSelectionContact(contacts) {
    this.setState({
      selectedContacts: contacts,
    });
  }

  render() {
    const {
      t,
      open,
      classes,
      pass,
      onDialogClose,
      onDialogExit,
      dynamicLink,
      isLoading,
      tokenFile,
      isMobile,
    } = this.props;
    const {
      errors,
      comment,
      beginDate,
      endDate,
      establishments,
      passType,
      guestType,
      selectedEstablishmentId,
      calendar,
      permanentPassesEnabled,
      providersEnabled,
      freeParking,
      type,
      removeGuests,
      showCancelWarning,
      maxGuests,
      isCopying,
      stepSelectionContact,
      selectedContacts,
    } = this.state;

    let establishmentItems = establishments.map((establishment) => {
      return (
        <MenuItem key={establishment.id} value={establishment.id}>
          {establishment.name}
        </MenuItem>
      );
    });

    if (pass && pass.status === PASS_STATUS_PENDING && !pass.manager) {
      return (
        <PassRequest
          pass={pass}
          establishmentItems={establishmentItems}
          open={open}
          onClose={onDialogClose}
          onExited={onDialogExit}
        />
      );
    }

    return (
      <div>
        <ConfirmationDialog
          open={showCancelWarning}
          onConfirm={this.handleCancel}
          onClose={this.closeCancelWarning}
          title={t("confirm_cancel_pass")}
        />
        <AddGuestsDialog
          open={isCopying}
          onMaxGuestsChanged={this.onMaxGuestsChanged}
          onClose={this.closeDynamicLinkCopy}
          maxGuests={maxGuests}
        />
        <Dialog
          className="pass-detail"
          fullWidth={true}
          fullScreen={isMobile}
          maxWidth="sm"
          onClose={onDialogClose}
          TransitionProps={{ onExited: onDialogExit }}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title">
            {pass ? t("invite_detail") : t("new_invitation")}
            <div className="dialog-title-button">
              {dynamicLink && (
                <CopyComponent
                  placement="left"
                  msg={t("share_link_copied")}
                  show={pass}
                  link={dynamicLink}
                  button={(onClick) => {
                    return (
                      <IconButton
                        onClick={() => this.handleDynamicLinkCopy(onClick)}
                        aria-label="delete"
                      >
                        <ShareIcon />
                      </IconButton>
                    );
                  }}
                />
              )}

              <IconButton onClick={onDialogClose} aria-label="delete">
                <CancelIcon />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent className="dialog" dividers>
            {stepSelectionContact ? (
              <PassSelectContacts onChange={this.onChangeSelectionContact} />
            ) : (
              <MuiPickersUtilsProvider
                utils={CustomMomentUtils}
                locale={getLocaleLanguage()}
              >
                <div>
                  <form className={classes.root}>
                    <FormControl
                      className={classes.formControl + " select-form"}
                      error={!!errors[ESTABLISHMENT]}
                    >
                      <InputLabel htmlFor="outlined-age-simple">
                        {t("choose_destination")}
                      </InputLabel>
                      <Select
                        disabled={!!pass}
                        value={selectedEstablishmentId}
                        onChange={this.handleEstablishmentSelect}
                      >
                        {establishmentItems}
                      </Select>
                      {<FormHelperText>{errors[ESTABLISHMENT]}</FormHelperText>}
                    </FormControl>

                    {!pass && selectedContacts.length === 0 && (
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                        error={errors.maxGuests}
                      >
                        <TextField
                          label={t("max_guests_allowed")}
                          name="maxGuests"
                          fullWidth
                          type="number"
                          inputProps={{
                            autoComplete: "new-password",
                            min: "1",
                          }}
                          onChange={this.handleFieldChange}
                          value={maxGuests}
                          required
                        />
                        {errors.maxGuests && (
                          <FormHelperText>
                            {t("please_select_max_guests")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}

                    <FormControl
                      component="fieldset"
                      className={classes.formControl + " radio"}
                    >
                      <FormLabel component="legend">{t("pass_type")}</FormLabel>
                      <RadioGroup
                        aria-label="occasional"
                        name="occasional"
                        value={passType}
                        onChange={this.handlePassTypeChange}
                      >
                        <FormControlLabel
                          value={PASS_TYPE_OCCASIONAL}
                          control={
                            <Radio
                              disabled={!permanentPassesEnabled}
                              color="primary"
                            />
                          }
                          label={t("occasional")}
                        />
                        <FormControlLabel
                          value={PASS_TYPE_PERMANENT}
                          control={
                            <Radio
                              disabled={!permanentPassesEnabled}
                              color="primary"
                            />
                          }
                          label={t("permanent")}
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>

                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      locale={getBrowserLanguage()}
                      utils={MomentUtils}
                    >
                      <FormControl className={classes.formControl}>
                        <DateTimePicker
                          format="LLLL"
                          lang={getBrowserLanguage()}
                          label={
                            <div>
                              {t("authorized_from")} ({t("establishment_hour")})
                            </div>
                          }
                          value={beginDate}
                          onChange={this.handleBeginDate}
                          error={!!errors[PASS_DATE]}
                          helperText={errors[PASS_DATE]}
                        />
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <DateTimePicker
                          format="LLLL"
                          label={
                            <div>
                              {t("authorized_until")} ({t("establishment_hour")}
                              )
                            </div>
                          }
                          value={endDate}
                          onChange={this.handleEndDate}
                          error={!!errors[PASS_DATE]}
                          helperText={errors[PASS_DATE]}
                        />
                      </FormControl>
                    </MuiPickersUtilsProvider>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl + " radio"}
                    >
                      {passType === PASS_TYPE_PERMANENT && (
                        <PermanentType
                          enabled={permanentPassesEnabled}
                          calendar={calendar}
                          onCalendarUpdate={this.onCalendarUpdate}
                        />
                      )}
                    </FormControl>

                    <FormControl
                      component="fieldset"
                      className={classes.formControl + " radio"}
                    >
                      <FormLabel component="legend">
                        {t("guest_type")}
                      </FormLabel>
                      <RadioGroup
                        aria-label="particular"
                        name="particular"
                        value={guestType}
                        onChange={this.handleGuestTypeChange}
                      >
                        <FormControlLabel
                          value={GUEST_TYPE_PARTICULAR}
                          control={
                            <Radio
                              disabled={!providersEnabled}
                              color="primary"
                            />
                          }
                          label={t("particular")}
                        />
                        <FormControlLabel
                          value={GUEST_TYPE_PROVIDER}
                          control={
                            <Radio
                              disabled={!providersEnabled}
                              color="primary"
                            />
                          }
                          label={t("provider")}
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>

                    {!pass && selectedContacts.length !== 0 && (
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend">
                          {t("send_pass_contact_amount_title", {
                            amountContact: selectedContacts.length,
                          })}
                        </FormLabel>
                        <Grid container spacing={2}>
                          {selectedContacts.map((contact) => (
                            <Grid item key={contact.id}>
                              <Avatar
                                alt="Remy Sharp"
                                src={
                                  tokenFile
                                    ? `${getUserPhotoUrl(contact)}?token=${
                                        tokenFile?.t
                                      }`
                                    : null
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </FormControl>
                    )}

                    {pass && (
                      <PassGuests
                        onGuestRemoved={this.onGuestRemoved}
                        pass={pass}
                        removeGuests={removeGuests}
                      />
                    )}

                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <TextField
                        variant="outlined"
                        label={t("comment")}
                        fullWidth
                        multiline
                        minRows={3}
                        onChange={this.handleCommentChange}
                        value={comment}
                      />
                    </FormControl>

                    {type === COMPANY && (
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={freeParking}
                              onChange={this.handleFreeParking}
                            />
                          }
                          label={t("assign_parking")}
                        />
                      </FormControl>
                    )}
                  </form>
                </div>
              </MuiPickersUtilsProvider>
            )}
          </DialogContent>

          <DialogActions className="dialog-buttons">
            <div className="loader">
              <Loader className="submit" visible={isLoading} />
            </div>
            {this.renderSubmitButtons()}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  token: state.authReducer.token,
  tokenFile: state.authReducer.token_file,
  appStrings: state.userReducer.appStrings,
  officeBuildings: state.userReducer.officeBuildings,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  dialogParams: state.dialogReducer,
  isLoading: state.passesReducer.loading,
  dynamicLink: state.passesReducer.dynamicLink,
  passes: state.userReducer.hostInvites,
});

const actionCreators = {
  onDialogClose: onDialogClose,
  onDialogExit: onDialogExited,
  onDialogModified: onDialogModified,
  onDialogClear: onDialogClear,
  onDialogOpen: onDialogOpen,
  updateInvite: updateInvite,
  createInvite: createInvite,
  createDynamicLink: createDynamicLink,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(withBreakpoint()(PassDetail))));
