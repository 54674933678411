export const columns = [
  {
    Header: "group",
    accessor: "name",
  },
  {
    Header: "assigned_desk",
    accessor: "desksCount",
  },
  {
    Header: "assigned_users",
    accessor: "usersCount",
  },
  {
    Header: "actions",
    action: "actions",
    short: 0,
  },
];
