import {
  Fade,
  IconButton,
  ListItemSecondaryAction,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import HeadsetMicRoundedIcon from "@material-ui/icons/HeadsetMicRounded";
import MailIcon from "@material-ui/icons/Mail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React from "react";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "0px 12px 12px 12px",
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  item: {
    minHeight: "35px",
  },
  itemSecondary: {
    top: "0px",
    transform: "none",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.dark,
  },
}));

const SupportMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openUrl = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const sendWhatsapp = () => {
    const url = "whatsapp://send/?phone=5491167524037&text&app_absent=0";
    if ("Android" in window) window.Android.sendWhatsapp(url);
    else openUrl(url);
    handleClose();
  };

  const sendEmail = () => {
    const url = "mailto:soporte@passapp.net?subject=Soporte PassApp";
    if ("Android" in window) window.Android.sendEmail(url);
    else openUrl(url);
    handleClose();
  };
  return (
    <div>
      <IconButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <HeadsetMicRoundedIcon />
      </IconButton>
      <StyledMenu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem className={classes.item} onClick={sendEmail}>
          <Typography>soporte@passapp.net</Typography>
          <ListItemSecondaryAction className={classes.itemSecondary}>
            <MailIcon fontSize="small" />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem className={classes.item} onClick={sendWhatsapp}>
          <Typography>Whatsapp</Typography>
          <ListItemSecondaryAction className={classes.itemSecondary}>
            <WhatsAppIcon fontSize="small" />
          </ListItemSecondaryAction>
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default SupportMenu;
