import {
  PASS_STATUS_ACCEPTED,
  PASS_STATUS_PENDING,
  PASS_STATUS_REJECTED,
  PASS_STATUS_CANCELED,
  PASS_STATUS_CANCELLED,
  PASS_STATUS_FINISHED,
  AUTHORIZATION_STATUS_PENDING,
  AUTHORIZATION_STATUS_USED,
  AUTHORIZATION_STATUS_EXPIRED,
  AUTHORIZATION_STATUS_ACCEPTED,
} from "Constant";
import Danger from "components/common/Typography/Danger";
import Muted from "components/common/Typography/Muted";
import Success from "components/common/Typography/Success";
import Info from "components/common/Typography/Info";

export function passWasUsed(pass, token) {
  if (pass.calendar !== null) return false;

  const userId = token.split(":")[0];

  if (pass.attendedGuests) {
    const size = pass.guests ? pass.guests.length : 0;
    return (
      (pass.attendedGuests.includes(userId) && size === 0) ||
      (pass.status === PASS_STATUS_ACCEPTED &&
        pass.attendedGuests.length !== 0 &&
        pass.attendedGuests.length >= size &&
        size !== 0)
    );
  }
  return false;
}

export function getPassStatus(pass, t, token) {
  if (passWasUsed(pass, token)) {
    return {
      short: t("pass_status_used"),
      long: t("pass_status_used_label"),
      typography: Muted,
    };
  } else {
    switch (pass.status) {
      case PASS_STATUS_ACCEPTED: {
        return {
          short: t("pass_status_accepted"),
          long: t("pass_status_accepted_label"),
          typography: Success,
        };
      }
      case PASS_STATUS_PENDING: {
        return {
          short: pass.company
            ? t("pass_status_pending_approval")
            : t("pass_status_pending"),
          long: t("pass_status_pending_label"),
          typography: Info,
        };
      }
      case PASS_STATUS_REJECTED: {
        return {
          short: t("pass_status_rejected"),
          long: t("pass_status_rejected_label"),
          typography: Danger,
        };
      }
      case PASS_STATUS_CANCELLED: {
        return {
          short: t("pass_status_cancelled"),
          long: t("pass_status_cancelled_label"),
          typography: Danger,
        };
      }
      case PASS_STATUS_CANCELED: {
        return {
          short: t("pass_status_cancelled"),
          long: t("pass_status_cancelled_label"),
          typography: Danger,
        };
      }
      case PASS_STATUS_FINISHED: {
        return {
          short: t("pass_status_finished"),
          long: t("pass_status_finished_label"),
          typography: Muted,
        };
      }

      default:
    }
  }

  return false;
}

export function getAuthorizationStatus(authorization, t) {
  switch (authorization.status) {
    case AUTHORIZATION_STATUS_USED: {
      return {
        short: t("pass_status_used"),
        typography: Muted,
      };
    }
    case AUTHORIZATION_STATUS_EXPIRED: {
      return {
        short: t("authorization_expired"),
        typography: Danger,
      };
    }
    case AUTHORIZATION_STATUS_PENDING: {
      return {
        short: t("auth_status_pending_approval"),
        typography: Info,
      };
    }
    case AUTHORIZATION_STATUS_ACCEPTED: {
      return {
        short: t("pass_status_accepted"),
        typography: Success,
      };
    }
    default:
      return {
        short: t("no_status"),
        typography: Muted,
      };
  }
}
