import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import "./Login.scss";
import Container from "@material-ui/core/Container";
import { withTranslation } from "react-i18next";
import logo from "../../assets/img/logo.png";
import Loader from "../../utils/Loader";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  Link,
  Typography,
} from "@material-ui/core";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import Button from "components/common/CustomButtons/Button.js";
import { version } from "../../../package.json";
import { connect } from "react-redux";
import { onFieldChange } from "../../utils/Utils";
import {
  clearAssociation,
  login,
  logout,
  setAssociation,
} from "../../actions/auth";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import PassAppApi from "services/PassAppApi";
import AlertShow from "components/common/Alert/AlertShow";
import history from "../../history";
import { STATE_LOGGED_IN } from "Constant";

class Login extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.openRecoverPassword = this.openRecoverPassword.bind(this);
    this.closeRecoverPassword = this.closeRecoverPassword.bind(this);
    this.onFieldChange = onFieldChange.bind(this);

    this.state = {
      email: "",
      password: "",
      recoverPasswordOpen: false,
      loading: false,
      tyc: false,
      tycError: false,
      alertError: false,
      alertMessage: "",
      confirmDialog: false,
    };
    this.handleTycChange = this.handleTycChange.bind(this);
    this.login = this.login.bind(this);
    this.dialogClose = this.dialogClose.bind(this);
    this.dialogOpen = this.dialogOpen.bind(this);
    this.alertCloseError = this.alertCloseError.bind(this);
  }
  componentDidMount() {
    const { state, t } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const rt = query.get("rt");
    if (state === STATE_LOGGED_IN) {
      if (rt) {
        this.props.logout(t);
        window.localStorage.clear();
        window.location.replace("/login?rt=" + rt);
      } else {
        history.push("/");
        return;
      }
    }
    if (rt) {
      this.checkActivationToken(rt);
    } else {
      this.props.clearAssociation();
    }
  }

  onSubmit(ev) {
    ev.preventDefault();
    const { t, modeAdm } = this.props;
    const { email, password } = this.state;
    if (this.isValid()) {
      if (modeAdm) {
        this.dialogOpen();
      } else this.props.login({ email, password }, t);
    }
  }

  login() {
    const { t } = this.props;
    const { email, password } = this.state;
    this.dialogClose();
    this.props.login({ email, password }, t, true);
  }

  isValid() {
    const { modeAdm } = this.props;
    const { tyc } = this.state;
    let flag = true;
    if (modeAdm) {
      if (!tyc) {
        flag = false;
        this.setState({
          tycError: true,
        });
      }
    }
    return flag;
  }

  openRecoverPassword(ev) {
    ev.preventDefault();
    this.setState({
      recoverPasswordOpen: true,
    });
  }

  closeRecoverPassword() {
    this.setState({
      recoverPasswordOpen: false,
    });
  }

  handleTycChange() {
    this.setState({
      tyc: !this.state.tyc,
      tycError: false,
    });
  }

  dialogClose() {
    this.setState({
      confirmDialog: false,
    });
  }
  dialogOpen() {
    this.setState({
      confirmDialog: true,
    });
  }

  alertCloseError() {
    this.setState({
      alertError: false,
    });
  }

  async checkActivationToken(token) {
    const { modeAdm, activationToken } = this.props;
    if (modeAdm && token === activationToken) return;
    this.props.clearAssociation();
    this.setState({
      loading: true,
    });
    PassAppApi.checkActivationToken(token)
      .then((associate) => {
        this.props.setAssociation(associate, token);
      })
      .catch((err) => {
        this.setState({
          alertError: true,
          alertMessage: err.toString(),
        });
        console.error(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { t, loggingIn, association, modeAdm, otherCompany } = this.props;
    const {
      recoverPasswordOpen,
      loading,
      tyc,
      tycError,
      alertError,
      alertMessage,
      confirmDialog,
      email,
    } = this.state;

    if (otherCompany?.isExternalUser)
      return (
        <Container className="login" component="main" maxWidth="xs">
          <div className="container">
            <Loader visible={otherCompany?.isExternalUser} />
          </div>
        </Container>
      );

    return (
      <Container className="login" component="main" maxWidth="xs">
        <div className="container">
          <img alt="Web Logo" className="logo" src={logo} />

          <ForgotPassword
            onClose={this.closeRecoverPassword}
            open={recoverPasswordOpen}
          />
          <Loader visible={loading} />
          <Grow in={!loading}>
            <form onSubmit={this.onSubmit}>
              {modeAdm ? (
                <>
                  <Typography variant="h6">{t("adm_title")}</Typography>
                  <Typography variant="body1">
                    {association?.name} {association?.email}
                  </Typography>
                </>
              ) : null}
              <TextField
                variant="outlined"
                margin="normal"
                onChange={this.onFieldChange}
                required
                fullWidth
                type="email"
                id="email"
                label={t("email")}
                name="email"
                autoComplete="email"
                autoFocus
              />
              {modeAdm ? (
                <Typography variant="body2" className="advice">
                  <b>{t("advice_1")}</b> {t("advice_2")}
                </Typography>
              ) : null}
              <TextField
                variant="outlined"
                margin="normal"
                required
                onChange={this.onFieldChange}
                fullWidth
                name="password"
                label={t("password")}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <AlertShow
                in={alertError}
                type="error"
                className="alert"
                onClose={this.alertCloseError}
              >
                {alertMessage}
              </AlertShow>
              {modeAdm ? (
                <FormControl error={tycError} className="terms">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={tyc}
                        onChange={this.handleTycChange}
                      />
                    }
                    label={
                      <span>
                        {t("text_TC_1") + " "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={process.env.REACT_APP_TERMS_AND_CONDITIONS_ADM}
                        >
                          {t("terms")}
                        </a>
                        {" " + t("adm_tyc")}
                      </span>
                    }
                  />

                  {tycError && (
                    <FormHelperText className="terms-error">
                      {t("must_accept_terms")}
                    </FormHelperText>
                  )}
                </FormControl>
              ) : null}
              <Button
                type="submit"
                fullWidth
                round
                color="primary"
                disabled={loggingIn}
                className="submit"
              >
                {t("login")}
              </Button>
              <Grid container className="forgot-password">
                <Grid item xs={6} className="left">
                  <Link
                    href=""
                    variant="body2"
                    onClick={this.openRecoverPassword}
                  >
                    {t("forgot_password")}
                  </Link>
                </Grid>
                <Grid item xs={6} className="right">
                  <Link href="/register" variant="body2">
                    {t("create_new_user")}
                  </Link>
                </Grid>
              </Grid>
              <Loader className="submit" visible={loggingIn} />
            </form>
          </Grow>

          <div className="version">{version}</div>
        </div>
        <ConfirmationDialog
          open={confirmDialog}
          onConfirm={this.login}
          onClose={this.dialogClose}
          title={t("adm_confirm")}
          labelNo={t("cancel")}
          labelYes={t("confirm")}
        >
          {email}
        </ConfirmationDialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.authReducer.state,
  loggingIn: state.authReducer.loggingIn,
  association: state.authReducer.association,
  activationToken: state.authReducer.activationToken,
  modeAdm: state.authReducer.modeAdm,
  otherCompany: state.authReducer.otherCompany,
});

const actionCreators = {
  login: login,
  logout: logout,
  setAssociation: setAssociation,
  clearAssociation: clearAssociation,
};
export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(Login));
