import React from "react";
import { CircularProgress } from "@material-ui/core";

class Loader extends React.Component {
  render() {
    const { visible, className } = this.props;

    if (!visible) return <div />;

    return <CircularProgress className={className} color="primary" />;
  }
}

export default Loader;
