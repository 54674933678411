import React, { useEffect, useState } from "react";
import { Backdrop as BackdropUI, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Backdrop = ({ open }) => {
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(open);

  const onClose = () => setOpenBackDrop(false);

  useEffect(() => setOpenBackDrop(open), [open]);

  return (
    <BackdropUI
      className={classes.backdrop}
      open={openBackDrop || false}
      onClick={onClose}
    >
      <CircularProgress color="inherit" />
    </BackdropUI>
  );
};

export default Backdrop;
