import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useRoutes from "./useRoutes";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import { useDispatch } from "react-redux";
import {
  myGuestsOpenMenu,
  myQrRefresh,
  updateFacePhotoTake,
  updateFacePhotoUpload,
} from "../actions/menu";
import { useSelector } from "react-redux";

const useActionButtonMenu = () => {
  const [onClick, setOnClick] = useState(null);
  const [icon, setIcon] = useState(<></>);
  const location = useLocation();
  const { routes, routePath } = useRoutes();
  const dispatch = useDispatch();
  const { isUpdateFacePhotoTaked } = useSelector((state) => state.menuReducer);

  const getActions = (route) => {
    switch (route.path) {
      case routePath.PASSES: {
        setOnClick(() => (event) => {
          dispatch(myGuestsOpenMenu(event.currentTarget));
        });
        setIcon(<AddIcon />);
        break;
      }
      case routePath.QR: {
        setOnClick(() => () => {
          dispatch(myQrRefresh());
        });
        setIcon(<RefreshIcon />);
        break;
      }
      case routePath.UPDATE_FACEPHOTO: {
        if (isUpdateFacePhotoTaked) {
          setOnClick(() => () => {
            dispatch(updateFacePhotoUpload());
          });
          setIcon(<PublishIcon />);
        } else {
          setOnClick(() => () => {
            dispatch(updateFacePhotoTake());
          });
          setIcon(<PhotoCameraOutlinedIcon />);
        }
        break;
      }
      default:
        setOnClick(null);
    }
  };

  useEffect(() => {
    if (location.pathname.includes(routePath.UPDATE_FACEPHOTO)) {
      if (isUpdateFacePhotoTaked) {
        setOnClick(() => () => {
          dispatch(updateFacePhotoUpload());
        });
        setIcon(<PublishIcon />);
      } else {
        setOnClick(() => () => {
          dispatch(updateFacePhotoTake());
        });
        setIcon(<PhotoCameraOutlinedIcon />);
      }
    }
  }, [isUpdateFacePhotoTaked]);

  useEffect(() => {
    const route = routes.find((item) => location.pathname.includes(item.path));
    if (route) getActions(route);
  }, [location.pathname]);

  return { action: onClick ? { onClick, icon } : null };
};

export default useActionButtonMenu;
