import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import {
  TextField,
  FormControl,
  Button,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DesksTable from "./DesksTables";
import { columns } from "./columns/desks";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DownloadIcon from "assets/img/download_icon.svg";
import {
  getDeskList,
  filterList,
  addDesks,
  setDeskDeskGroup,
  setDeskUser,
  unsetDeskUser,
  deleteDesk,
  editDesk,
  checkDeleteDesk,
  uploadDeskLayout,
  getDeskLayout,
  deleteDeskLayout,
} from "../../actions/desk";
import DropzoneFiles from "components/common/DropzoneFiles/DropzoneFiles";
import UploadIcon from "../../assets/img/upload_icon.svg";
import DeskShowLayout from "./DeskShowLayout";
import { useEffect } from "react";

const DesksList = (props) => {
  const {
    t,
    showMessaje,
    getList,
    filterList,
    addDesks,
    setDeskDeskGroup,
    setDeskUser,
    unsetDeskUser,
    deleteDesk,
    editDesk,
    checkDeleteDesk,
    uploadDeskLayout,
    getDeskLayout,
    deleteDeskLayout,
    list,
    listAll,
    countDesks,
    DeskGroups,
    listEmployees,
    loading,
    company,
    layoutFile,
    tokenFile,
  } = props;

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState("");
  const [item, setItem] = useState("");
  const [deskNames, setDeskNames] = useState([]);
  const [globalPageIndex, setGlobalPageIndex] = useState(0);
  const [canEditInput, setCanEditInput] = useState(true);
  const [layout, setLayout] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [availableEmployees, setAvailableEmployees] = useState([]);

  useEffect(() => {
    getList(t);
    getDeskLayout(t, company);
  }, []);

  const setCurrentPage = (page) => {
    setGlobalPageIndex(page);
  };

  const openNewDesk = () => {
    setOpen(true);
    setDeskNames(["", "", "", ""]);
    setAction("add");
  };
  const onClose = () => {
    setOpen(false);
    setDeskNames([]);
    setLayout(null);
  };

  const handleChange = (event, index) => {
    if (canEditInput) {
      let deskNamesAux = deskNames.map((names) => names);
      deskNamesAux[index] = event.target.value;
      setDeskNames(deskNamesAux);
    } else {
      setCanEditInput(true);
    }
  };

  const addNewName = () => {
    let deskNamesAux = deskNames.map((names) => names);
    deskNamesAux.push("");
    setDeskNames(deskNamesAux);
  };

  const handlePaste = (event) => {
    setCanEditInput(false);
    const pasteDatas = event.clipboardData;
    const text =
      typeof pasteDatas !== "undefined" ? pasteDatas.getData("text") : "";
    let deskNamesAux = deskNames.map((names) => names);
    /** remove the current element */
    let ID_item = event.target.id.replace("DeskName", "");
    ID_item = parseInt(ID_item);
    if (
      typeof deskNamesAux[ID_item] !== "undefined" &&
      deskNamesAux[ID_item] === ""
    )
      deskNamesAux.splice(ID_item, 1);

    let newList = deskNamesAux.filter((name) => {
      return typeof name !== "undefined" && name !== "";
    });

    const lines = text.split("\n");
    if (lines.length > 0) {
      for (let i in lines) {
        if (!lines[i]) {
          break;
        }
        newList.push(lines[i]);
      }
    }

    setDeskNames(newList);
  };

  const handleSearch = (event) => {
    const text = event.target.value;
    setSearchText(text);
    setGlobalPageIndex(0);
    filterList(
      listAll.filter((v) => {
        return v.name && v.name.search(new RegExp(text, "i")) > -1;
      })
    );
  };

  const handleCancel = (data) => {
    checkDeleteDesk(t, data.id);
    setShowConfirm(true);
    setAction("remove");
    setItem(data.id);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const deleteLayoutConfirmation = () => {
    setShowDeleteConfirm(true);
  };

  const cancelLayoutConfirmation = () => {
    setShowDeleteConfirm(false);
  };

  const handleDeleteDeskLayout = () => {
    deleteDeskLayout(t, company);
    cancelLayoutConfirmation();
    onClose();
  };

  const sendItem = () => {
    switch (action) {
      case "add": {
        const deskNamesAux = deskNames.filter((v) => {
          return typeof v !== "undefined" && v.trim() !== "";
        });
        const DeskNamesObj = deskNamesAux.map((v) => {
          return { name: v };
        });

        if (typeof DeskNamesObj !== "undefined" && DeskNamesObj.length > 0) {
          addDesks(t, DeskNamesObj);
        } else {
          showMessaje(t("error_add_empty_DeskNames"));
          return;
        }
        break;
      }
      case "edit": {
        const name = deskNames[0];
        if (typeof name !== "undefined" && name.trim() !== "") {
          editDesk(t, item, deskNames[0]);
        } else {
          showMessaje(t("error_add_empty_DeskNames"));
          return;
        }
        break;
      }
      case "loadLayout": {
        uploadDeskLayout(t, company, layout);
        break;
      }
      default:
    }
    setOpen(false);
    setDeskNames([]);
    setAction(null);
    setItem(null);
  };

  const handleUpdate = (data, type, id) => {
    switch (type) {
      case "DeskGroup":
        setDeskDeskGroup(t, data, id);
        break;
      case "Employees":
        setDeskUser(t, data, id);
        break;
      case "unsetDeskUser":
        setShowConfirm(true);
        setAction("unsetDeskUser");
        setItem(data.id);
        break;
      default:
        setOpen(true);
        setAction("edit");
        setDeskNames([data.name]);
        setItem(data.id);
        break;
    }
  };

  const handleConfirm = () => {
    switch (action) {
      case "remove":
        deleteDesk(t, item);
        break;
      case "unsetDeskUser":
        unsetDeskUser(t, item);
        break;
      default:
    }
    setShowConfirm(false);
    setAction("");
    setItem(null);
  };

  const formModalDesks = () => {
    if (action === "add") {
      return (
        <div className="flex-right">
          {deskNames.map((DeskName, i) => (
            <FormControl className="full-input" key={i}>
              <TextField
                type="text"
                label={t("description")}
                fullWidth={true}
                value={DeskName}
                id={"DeskName" + i}
                onChange={(e) => handleChange(e, i)}
                onPaste={(e) => handlePaste(e)}
                key={i}
              ></TextField>
            </FormControl>
          ))}
          <Fab color="primary" onClick={addNewName}>
            <AddIcon />
          </Fab>
        </div>
      );
    }

    if (action === "edit") {
      return (
        <FormControl className="full-input">
          {deskNames.map((DeskName, i) => (
            <TextField
              type="text"
              label={t("name")}
              fullWidth={true}
              value={DeskName}
              id={"DeskName" + i}
              onChange={(e) => handleChange(e, i)}
              key={i}
              autoFocus
            ></TextField>
          ))}
        </FormControl>
      );
    }
    if (action === "loadLayout") {
      return (
        <>
          <span>{layout ? layout.name : t("desk_layout_label_formats")}</span>
          <FormControl className="DesksLayout" fullWidth>
            <DropzoneFiles
              maxFiles="1"
              label={<img src={UploadIcon} alt="upload icon" />}
              textInfo1={t("desk_layout_label_dragdrop")}
              onload={uploadLayout}
              showMessaje={showMessaje}
              url={
                process.env.REACT_APP_BASE_URL +
                "/ws/desk-manager/uploadDeskPlan"
              }
              errorFileSize={t("desk_layout_label_maxsize_error")}
              rejectedFileType={t("desk_layout_label_formats_error")}
              accept="application/pdf, image/png, .jpg, .jpeg, .docx, .doc"
              maxSizeMb={8}
              t={t}
            />
          </FormControl>
        </>
      );
    }
    if (action === "showLayout") {
      return <DeskShowLayout />;
    }
  };

  const openLoadLayout = () => {
    setOpen(true);
    setAction("loadLayout");
  };
  const openShowLayout = () => {
    setOpen(true);
    setAction("showLayout");
  };
  const uploadLayout = (files) => {
    setLayout(files);
  };

  const onDownload = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}ws/files/${layoutFile?.id}/file?token=${tokenFile.t}`
    )
      .then((response) => response.blob())
      .then((response) => {
        let fileType = "";
        switch (response.type) {
          case "image/png":
            fileType = "png";
            break;
          case "image/jpeg":
            fileType = "jpeg";
            break;
          case "application/pdf":
            fileType = "pdf";
            break;
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            fileType = "docx";
            break;
          case "application/msword":
            fileType = "doc";
            break;
          default:
            fileType = "png";
        }
        const blobbedResponse = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = blobbedResponse;
        link.setAttribute(
          "download",
          `${t("desk_layout_download_filename")}.${fileType}`
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  const getTitle = () => {
    switch (action) {
      case "loadLayout":
        return t("desk_layout_title_load");
      case "showLayout":
        return t("desk_layout_title_show");
      case "add":
        return t("add_desks");
      default:
        return t("edit_desk");
    }
  };

  useEffect(() => {
    let availableEmployees = [];
    for (let i in listEmployees) {
      let available = true;
      for (let j in listAll) {
        if (listAll[j]["user"] === listEmployees[i]["id"]) {
          available = false;
          break;
        }
      }
      listEmployees[i].available = available;
      availableEmployees.push(listEmployees[i]);
    }
    setAvailableEmployees(availableEmployees);
  }, [listEmployees]);

  return (
    <div className="body-box">
      <div className="search-box-2">
        <Button
          size="small"
          onClick={openShowLayout}
          disabled={!layoutFile || !layoutFile?.id}
        >
          {t("desk_layout_button_show")}
        </Button>
        <Button size="small" onClick={openLoadLayout}>
          {t("desk_layout_button_load")}
        </Button>
        <FormControl className="box-input">
          <TextField
            type="text"
            placeholder={t("search")}
            name="search"
            value={searchText}
            onChange={handleSearch}
          ></TextField>
        </FormControl>
      </div>
      <DesksTable
        data={list}
        columns={columns}
        handleUpdate={(data, type, id) => handleUpdate(data, type, id)}
        handleCancel={(data) => handleCancel(data)}
        t={t}
        globalPageIndex={globalPageIndex}
        DeskGroups={DeskGroups}
        listEmployees={availableEmployees}
        setCurrentPage={setCurrentPage}
        classTable="table-with-field"
        loading={loading}
      ></DesksTable>
      <Fab color="primary" onClick={openNewDesk}>
        <AddIcon />
      </Fab>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle onClose={onClose}>
          {getTitle()}
          {action === "showLayout" && (
            <div className="iconButtonContainer">
              <IconButton onClick={onDownload}>
                <img
                  src={DownloadIcon}
                  alt="download icon"
                  className="iconButton"
                />
              </IconButton>
              <IconButton onClick={deleteLayoutConfirmation}>
                <DeleteOutlineIcon className="iconButton" />
              </IconButton>
            </div>
          )}
        </DialogTitle>
        <DialogContent className="dialog" dividers>
          {formModalDesks()}
        </DialogContent>
        <DialogActions className="button-group">
          {action === "showLayout" ? (
            <Button
              color="primary"
              onClick={onClose}
              className="buttom-reservation"
            >
              {t("desk_layout_button_close")}
            </Button>
          ) : (
            <>
              <Button className="buttom-reservation" onClick={onClose}>
                {" "}
                {t("cancel")}
              </Button>
              <Button
                color="primary"
                onClick={sendItem}
                className="buttom-reservation"
                disabled={action === "loadLayout" && !layout}
              >
                {t("save")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirm && countDesks !== false}
        onConfirm={handleConfirm}
        onClose={closeConfirm}
        title={
          action ? t("confirm_desk_" + action, { countDesks: countDesks }) : ""
        }
      />
      <ConfirmationDialog
        open={showDeleteConfirm}
        onConfirm={handleDeleteDeskLayout}
        onClose={cancelLayoutConfirmation}
        title={t("desk_layout_label_delete_confirm")}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.desks.currentList,
  listAll: state.desks.list,
  countDesks: state.desks.countDesks,
  DeskGroups: state.desks.listGroups,
  listEmployees: state.desks.listEmployees,
  loading: state.desks.loading,
  company: state.authReducer.user.deskManager.company,
  layoutFile: state.desks.layout,
  tokenFile: state.authReducer.token_file,
});
const mapActionsToProps = {
  getList: getDeskList,
  filterList,
  addDesks,
  setDeskDeskGroup,
  setDeskUser,
  unsetDeskUser,
  deleteDesk,
  editDesk,
  checkDeleteDesk,
  uploadDeskLayout,
  getDeskLayout,
  deleteDeskLayout,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(DesksList));
