import {
  Button,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SnackbarMessage from "utils/SnackbarMessage";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import SpaceTables from "./SpaceTables";
import {
  getSpaceCreditUsers,
  filterCreditUsersList,
  setSpaceCreditUsers,
} from "../../actions/space";
import "./../common/ConfirmationDialog/ConfirmationDialog.scss";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class SpaceCreditUsers extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleConfirmChanges = this.handleConfirmChanges.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    let columns = [
      {
        Header: "name",
        accessor: "name",
      },
      {
        Header: "email",
        accessor: "email",
      },
      {
        Header: "space_credit_column",
        accessor: "credit",
        action: "credit",
      },
    ];
    this.state = {
      searchText: "",
      columns: columns,
      globalPageIndex: 0,
      showConfirm: false,
      loadingConfirm: false,
      warning: "",
    };
    this.props.getList(t);
  }
  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }
  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterCreditUsersList(
      this.props.listAll.filter((v) => {
        if (v.name && v.name.search(new RegExp(text, "i")) > -1) return true;
        if (v.email && v.email.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }
  handleConfirmChanges(check) {
    const { t, listAll } = this.props;
    let users = [];
    for (let row of listAll) {
      if (row.modified) {
        users.push({ id: row.id, credit: row.credit });
      }
    }
    if (users.length) {
      this.setState({ loadingConfirm: true });
      this.props.setSpaceCreditUsers(t, users, check).then((res) => {
        this.setState({ loadingConfirm: false });
        if (typeof res != "undefined") {
          let userNames = [];
          if (res.length) {
            for (let id of res) {
              for (let row of listAll) {
                if (row.id === id) {
                  userNames.push(row.name);
                }
              }
            }
            let warning = (
              <>
                {t("space_credit_warning_text")}
                <br />
                <ul>
                  {userNames.map((user, i) => (
                    <li key={user + i}>
                      <b>{user}</b>
                    </li>
                  ))}
                </ul>
              </>
            );
            this.setState({ warning: warning });
            this.setState({ showConfirm: true });
          } else {
            SnackbarMessage.show("success", t("space_credit_success"));
            this.closeConfirm();
          }
        }
      });
    }
  }
  closeConfirm() {
    this.setState({ showConfirm: false });
  }
  render() {
    const { t, list, loading } = this.props;
    const {
      searchText,
      showConfirm,
      loadingConfirm,
      warning,
      columns,
      globalPageIndex,
    } = this.state;
    const dataExport = list.map((user) => {
      let item = { name: user.name, email: user.email, credit: user.credit };
      return item;
    });
    return (
      <>
        <div className="body-box">
          <div className="search-box-2">
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name={t("users")}>
                <ExcelColumn label={t("name")} value="name" />
                <ExcelColumn label={t("email")} value="email" />
                <ExcelColumn label={t("space_credit_column")} value="credit" />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
          <SpaceTables
            data={list}
            columns={columns}
            handleUpdate={(value) => this.handleUpdate(value)}
            t={t}
            globalPageIndex={globalPageIndex}
            setCurrentPage={this.setCurrentPage}
            loading={loading}
          ></SpaceTables>
        </div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          style={{ marginTop: 10 }}
        >
          <Grid item>
            <Button
              color={"primary"}
              variant="contained"
              onClick={() => this.handleConfirmChanges(true)}
              style={{ margin: 3, color: "white" }}
            >
              {loadingConfirm ? (
                <CircularProgress
                  style={{ height: 20, width: 20, margin: 3 }}
                />
              ) : (
                t("charter_button_save_changes")
              )}
            </Button>
          </Grid>
        </Grid>
        <ConfirmationDialog
          open={showConfirm}
          onConfirm={() => this.handleConfirmChanges(false)}
          onClose={this.closeConfirm}
          title={t("space_credit_warning_title")}
          children={warning}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  list: state.spaceReducer.spaces_credit_users_current_list,
  listAll: state.spaceReducer.spaces_credit_users_list,
  loading: state.parkings.loading,
});
const mapActionsToProps = {
  getList: getSpaceCreditUsers,
  filterCreditUsersList,
  setSpaceCreditUsers,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(SpaceCreditUsers));
