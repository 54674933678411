import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PassContext } from "../../context/PassContext";

const useStyles = makeStyles({
  root: {
    marginBottom: 5,
    "& input[type='search' i]::-webkit-search-cancel-button": {
      content: "''",
      color: "#000",
    },
  },
});

const PassesSearch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onSearch } = useContext(PassContext);
  const onChange = (event) => {
    onSearch(event.target.value);
  };
  return (
    <TextField
      id="outlined-search"
      className={classes.root}
      label={t("search_passes")}
      fullWidth
      type="search"
      variant="outlined"
      onChange={onChange}
      size="small"
    />
  );
};

export default PassesSearch;
