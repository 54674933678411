import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Avatar, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./NavProfile.scss";
import { getUserFullname } from "utils/Utils";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const PROFILE_PATH = "/home/profile";
const useStyles = makeStyles((theme) => ({
  name: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    paddingLeft: "10px",
    fontFamily: "Gotham, Helvetica,Arial,sans-serif",
    textAlign: "center",
  },
}));

const NavProfile = (props) => {
  const history = useHistory();
  const { user, tokenFile, otherCompany } = props;
  const [image, setImage] = useState(null);
  const isExternalUser = otherCompany?.isExternalUser;
  const classes = useStyles();

  const onClick = () => {
    if (history.location.pathname !== PROFILE_PATH) history.push(PROFILE_PATH);
  };

  const cache = (path) => {
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(path);
      img.onerror = () => reject();
      img.src = path;
    });
  };

  useEffect(() => {
    if (tokenFile?.t && user.facePhoto && !isExternalUser) {
      cache(
        `${process.env.REACT_APP_IMAGES_URL}${user.facePhoto}?token=${tokenFile.t}`
      );
      setImage(
        `${process.env.REACT_APP_IMAGES_URL}${user.facePhoto}?token=${tokenFile.t}`
      );
    } else setImage(null);
  }, [tokenFile]);

  const setProps = !isExternalUser ? { onClick, button: true } : {};

  return (
    <ListItem {...setProps} className="nav-profile">
      {!isExternalUser && (
        <div className="avatar">
          <Avatar src={image}></Avatar>
        </div>
      )}
      <div className={classes.name}>{getUserFullname(user)}</div>
    </ListItem>
  );
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  otherCompany: state.authReducer.otherCompany,
  tokenFile: state.authReducer.token_file,
});

export default connect(mapStateToProps)(withTranslation()(NavProfile));
