import React from "react";
// @material-ui/core components
// @material-ui/icons
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import Button from "components/common/CustomButtons/Button.js";
import { getI18n } from "react-i18next";
// core components
import moment from "moment";

class Picker extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;

    const birthdate = value ? moment(value, "DD-MM-YYYY", true) : null;
    let day = null;
    let month = null;
    let year = null;

    if (birthdate && birthdate.isValid()) {
      day = birthdate.format("DD");
      month = birthdate.format("MM");
      year = birthdate.format("YYYY");
    }

    const ln = getI18n(); //language
    this.state = {
      open: false,
      day: day,
      month: month ? parseInt(month) : null,
      year: year,
      birthdate: year ? day + "-" + month + "-" + year : "",
      lng: ln.language,
      error: null,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (value) {
      switch (name) {
        case "day":
          if (value > 31 || value < 1) return null;
          break;
        case "year":
          if (value > 999) {
            const currentYear = new Date().getFullYear();
            if (value > currentYear || value < 1900) return null;
          }
          break;
        default:
      }
    }

    this.setState({
      [name]: value,
      error: null,
    });
  }

  save() {
    const { t, disableFuture } = this.props;
    const birthdate = this.getBirthdate();
    const birthdateMoment = moment(birthdate, "DD-MM-YYYY", true);
    if (birthdateMoment && birthdateMoment.isValid()) {
      if (
        !disableFuture ||
        moment(birthdateMoment).format("YYYY-MM-DD") <
          moment(disableFuture).format("YYYY-MM-DD")
      ) {
        this.setState({ birthdate: birthdate });
        this.props.onChange(birthdateMoment);
        this.close();
      } else {
        this.setState({ error: t("invalid_birthdate_future") });
      }
    } else {
      this.setState({ error: t("invalid_birthdate") });
    }
  }

  close() {
    this.setState({
      open: false,
      error: null,
    });
  }

  open() {
    this.setState({
      open: true,
      error: null,
    });
  }

  months() {
    const { t } = this.props;
    return [
      "",
      t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
    ];
  }

  getBirthdate() {
    let { day, month, year } = this.state;
    if (day < 10) {
      day = "0" + parseInt(day);
    }
    if (month < 10) {
      month = "0" + parseInt(month);
    }
    return day + "-" + month + "-" + year;
  }

  render() {
    const { t, label } = this.props;
    const { open, day, month, year, birthdate, lng, error } = this.state;
    const months = this.months();
    const propDay = { min: 1, max: 31 };
    const propYear = { min: 1900, max: new Date().getFullYear() };
    return (
      <div>
        <div onClick={this.open}>
          <FormControl component="fieldset" fullWidth>
            <TextField label={label} required value={birthdate} />
          </FormControl>
        </div>
        <Dialog
          onClose={this.close}
          onExited={this.close}
          open={open}
          className="formPicker"
        >
          <DialogContent className="center">
            {lng !== "en" && (
              <FormControl component="fieldset" className="formControl">
                <TextField
                  error={false}
                  label={t("day")}
                  name="day"
                  onChange={this.onChange}
                  value={day}
                  variant="outlined"
                  type="number"
                  inputProps={propDay}
                />
              </FormControl>
            )}
            <FormControl variant="outlined" className="formControl">
              <InputLabel>{t("month")}</InputLabel>
              <Select
                onChange={this.onChange}
                value={month}
                name="month"
                label={t("month")}
                displayEmpty
                defaultValue=""
              >
                {months &&
                  months.map((data, j) => (
                    <MenuItem value={j} key={j}>
                      {data}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {lng === "en" && (
              <FormControl component="fieldset" className="formControl">
                <TextField
                  error={false}
                  label={t("day")}
                  name="day"
                  onChange={this.onChange}
                  value={day}
                  variant="outlined"
                  type="number"
                  inputProps={propDay}
                />
              </FormControl>
            )}
            <FormControl component="fieldset" className="formControl">
              <TextField
                error={false}
                label={t("year")}
                name="year"
                onChange={this.onChange}
                value={year}
                variant="outlined"
                type="number"
                inputProps={propYear}
              />
            </FormControl>
            {error && (
              <FormHelperText className="text-error">{error}</FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button round onClick={this.close}>
              {t("cancel")}
            </Button>
            <Button round onClick={this.save} color="primary">
              {t("accept")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Picker;
