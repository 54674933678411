import {
  Avatar,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { importContactsPhone } from "../../actions/passes";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import ContactsIcon from "@material-ui/icons/Contacts";
import SyncIcon from "@material-ui/icons/Sync";
import { getUserPhotoUrl } from "../../utils/Utils";
import Setting3 from "../../assets/img/setting-3.png";
import Setting2 from "../../assets/img/setting-2.png";
import Setting1 from "../../assets/img/setting-1.png";
import Button from "../../components/common/CustomButtons/Button";
import { setFriends } from "../../actions/user";
import useBreakpoint from "../../hooks/useBreakpoint";
import { FixedSizeList } from "react-window";

const PassSelectContacts = ({ onChange }) => {
  const { t } = useTranslation();
  const [contactSelected, setContactSelected] = useState([]);
  const [loadingSync, setLoadingSync] = useState(false);
  const { friends } = useSelector((state) => state.userReducer);
  const { token_file: tokenFile } = useSelector((state) => state.authReducer);
  const [amountContact, setAmountContact] = useState(null);
  const [filterSearch, setFilterSearch] = useState([]);
  const [isIOS, setIsIOS] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const supported = "contacts" in navigator && "ContactsManager" in window;
  const dispatch = useDispatch();
  const [contactsPhone, setContactsPhone] = useState([]);
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    setIsIOS(iOS());
  }, []);

  const iOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
      /iPad|iPhone|iPod/.test(navigator.userAgent)
    );
  };

  const onSearch = (event) => {
    const q = event.target.value.toLowerCase();
    setFilterSearch(
      q
        ? friends.filter(
            (item) =>
              item.phone?.toLowerCase().includes(q) ||
              item.fullname?.toLowerCase().includes(q) ||
              contactSelected.some((contact) => contact.id === item.id)
          )
        : friends
    );
  };

  const handleToggle = (value) => {
    const exist = contactSelected.some((contact) => contact.id === value.id);
    if (exist) {
      setContactSelected((p) => p.filter((contact) => contact.id !== value.id));
    } else {
      setContactSelected((p) => [...p, value]);
    }
  };

  const openSelectContact = async () => {
    if (supported) {
      const supportedProperties = await navigator.contacts.getProperties();
      if (supportedProperties.some((prop) => prop === "tel")) {
        const props = ["tel"];
        const opts = { multiple: true };
        getContacts(props, opts);
      }
    } else importContactsWebView();
  };

  const getContacts = async (props, opts) => {
    try {
      const contacts = await navigator.contacts.select(props, opts);
      let newContacts = [];
      contacts.forEach((item) => {
        newContacts = newContacts.concat(item.tel);
      });
      importContacts(cleanContacts(newContacts));
    } catch (ex) {
      // Handle any errors here.
      console.error(ex);
      importContactsWebView();
    }
  };

  const getContactsWebView = new Promise((resolve, reject) => {
    if (contactsPhone?.length !== 0) resolve(contactsPhone);
    else if ("Android" in window) {
      try {
        let contacts = window.Android.getAllContacts() || [];
        contacts = JSON.parse(contacts);
        resolve(contacts);
      } catch (error) {
        reject(null);
      }
    }
  });

  const importContactsWebView = () => {
    getContactsWebView
      .then((contacts) => {
        let newContacts = [];
        setContactsPhone(contacts);
        contacts.forEach((item) => {
          newContacts = newContacts.concat(item.phone);
        });
        importContacts(cleanContacts(newContacts));
      })
      .catch((err) => {
        if (err) console.error("ERROR:", err);
      });
  };

  const cleanContacts = (contacts) => {
    return contacts.map((tel) => tel.replace(/\s|-/g, ""));
  };

  const importContacts = (contacts) => {
    if (contacts.length !== 0)
      dispatch(
        importContactsPhone(contacts, {
          onSuccess,
          setLoading: setLoadingSync,
          t,
        })
      );
  };

  const onSuccess = (response) => {
    setAmountContact(response.newFriends);
    setTimeout(() => {
      setAmountContact(null);
    }, 6500);
  };

  const openSettingIOS = () => {
    window.location.href = "App-prefs://prefs:root=Safari";
  };

  const onCloseDialogSetting = () => setOpenSetting(false);

  const openDialogSetting = () => setOpenSetting(true);

  const tRender = (key) => (
    <p
      style={{ lineHeight: 1.2 }}
      dangerouslySetInnerHTML={{ __html: t(key) }}
    ></p>
  );

  const matchNameContacts = (contacts) => {
    // const contacts = getContactsWebView() || [];
    let friendsWithoutName = [];
    let restFriends = [];
    let fillFriendsFullname = [];
    friends.forEach((item) => {
      if (
        (!item.firstname || item.firstname === "-pending-") &&
        (!item.lastname || item.lastname === "-pending-")
      )
        friendsWithoutName.push(item);
      else restFriends.push(item);
    });
    contacts.forEach((cPhone) => {
      fillFriendsFullname = friendsWithoutName.map((cPassapp) => {
        cPhone.phone.forEach((phone) => {
          if (cPassapp?.phone?.includes(phone.substring(phone.length - 8))) {
            cPassapp.lastname = `${cPhone.name} *`;
          }
        });
        return cPassapp;
      });
    });
    dispatch(setFriends([...fillFriendsFullname, ...restFriends]));
  };

  useEffect(() => {
    if (onChange) onChange(contactSelected);
  }, [contactSelected]);

  useEffect(() => {
    setTimeout(() => {
      if (contactsPhone?.length !== 0) matchNameContacts(contactsPhone);
      // matchNameContacts(contacts);
    }, 800);
  }, [contactsPhone]);

  useEffect(() => {
    if (friends) {
      setTimeout(() => {
        setFilterSearch(friends);
      }, 400);
    }
  }, [friends]);

  useEffect(() => {
    if (contactsPhone.length === 0) getContactsPhone();
  }, []);

  const getContactsPhone = () => {
    getContactsWebView
      .then((contacts) => {
        setContactsPhone(contacts);
      })
      .catch((err) => {
        if (err) console.error("ERROR:", err);
      });
  };

  const renderOptionOrLoading = () => {
    if (loadingSync) return <CircularProgress size={20} />;
    else {
      if (!supported && isIOS) {
        return (
          <IconButton color="primary" onClick={openDialogSetting}>
            <PhonelinkSetupIcon />
          </IconButton>
        );
      } else return null;
    }
  };

  const Row = ({ index, style }) => {
    const item = filterSearch[index];
    return (
      <ListItem
        button
        style={style}
        key={item.id}
        onClick={() => handleToggle(item)}
      >
        {!isMobile && (
          <ListItemAvatar>
            <Avatar
              alt="Profile image"
              src={
                tokenFile
                  ? `${getUserPhotoUrl(item)}?token=${tokenFile?.t}`
                  : null
              }
            />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={item.fullname}
          secondary={!item.fullname ? item.phone : ""}
        />
        <Checkbox
          edge="end"
          onClick={() => handleToggle(item)}
          checked={contactSelected.some((contact) => contact.id === item.id)}
          color="primary"
        />
      </ListItem>
    );
  };

  return (
    <div>
      <TextField
        id="outlined-search"
        label={t("send_pass_contact_search")}
        fullWidth
        type="search"
        variant="outlined"
        onChange={onSearch}
      />
      <List>
        {(supported || isIOS) && (
          <ListItem button onClick={openSelectContact} disabled={!supported}>
            <ListItemIcon>
              <SyncIcon />
            </ListItemIcon>
            <ListItemText primary={t("send_pass_contact_sync_button")} />
            <ListItemSecondaryAction>
              {renderOptionOrLoading()}
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {friends?.length === 0 && (
          <ListItem>
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText primary={t("send_pass_contact_norows")} />
          </ListItem>
        )}
        <FixedSizeList
          height={400}
          width="100%"
          itemSize={46}
          itemCount={filterSearch.length}
        >
          {Row}
        </FixedSizeList>{" "}
      </List>
      <Snackbar
        open={amountContact !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => setAmountContact(null)}
      >
        <Alert severity="success">
          {t("send_pass_contact_find", { amountContact })}
        </Alert>
      </Snackbar>

      <Dialog open={openSetting} onClose={onCloseDialogSetting}>
        <DialogContent>
          <Typography component="div">
            {tRender("send_pass_contact_iphone_setting_1")}
          </Typography>
          <Typography component="div">
            {tRender("send_pass_contact_iphone_setting_2")}
          </Typography>
          <img width="50%" src={Setting1} alt="setting advanced" />
          <Typography component="div">
            {tRender("send_pass_contact_iphone_setting_3")}
          </Typography>
          <img
            width="50%"
            src={Setting2}
            alt="setting experimental webkit features"
          />
          <Typography component="div">
            {tRender("send_pass_contact_iphone_setting_4")}
          </Typography>
          <img width="50%" src={Setting3} alt="setting contact picker api" />
        </DialogContent>
        <DialogActions>
          <Button round onClick={onCloseDialogSetting}>
            {t("send_pass_contact_iphone_setting_cancel")}
          </Button>
          <Button round color="primary" onClick={openSettingIOS}>
            {t("send_pass_contact_iphone_setting_toConfig")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PassSelectContacts;
