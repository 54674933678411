import React from "react";

import {
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Clear, Edit, AccountCircle } from "@material-ui/icons";
import moment from "moment";

const CellActions = (prop) => {
  const value = prop.data.value;
  const { t, handleUpdate, handleCancel } = prop;
  const data = prop.rowData.original;
  const column = prop.data.column;
  let cellContent = prop.data.render("Cell");
  let { btn_1, btn_2, btn_3 } = "";

  const handleChangeParkingGroup = (event) => {
    handleUpdate(data.id, "ParkingGroup", event.target.value);
  };
  const handleChangeEmployees = (event) => {
    handleUpdate(data.id, "Employees", event.target.value);
  };

  if (typeof column.action === "undefined" || !column.action) {
    return <div style={{ padding: "14px 0px" }}>{cellContent}</div>;
  }
  switch (column.action) {
    case "translate": {

      return (
        <div>
          {(value ? t("parking_" + value) : "") +
            " " +
            (data.released ? "(" + t("parking_released") + ")" : "")}
        </div>
      );
    }
    case "date_time": {
      let dateTime = moment(value, "YYYY-MM-DD HH:mm");
      if (dateTime && dateTime.isValid()) {
        return dateTime.format("DD/MM/YYYY - HH:mm");
      }
      return "";
    }
    case "selectParkingGroup": {
      let selected = data.parkingGroup;
      if (!selected || typeof selected === "undefined") selected = "";
      return (
        <FormControl className="box-input">
          <Select
            onChange={handleChangeParkingGroup}
            fullWidth={true}
            value={selected}
            displayEmpty
            defaultValue=""
          >
            {!selected && <MenuItem value={""}>Sin grupo</MenuItem>}
            {prop.parkingGroups &&
              prop.parkingGroups.map((data, j) => (
                <MenuItem value={data.id} key={j}>
                  {data.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    }
    case "selectEmployees": {
      if (!data.parkingGroup || typeof data.parkingGroup === "undefined") {
        return <div>-</div>;
      }
      if (data.user && typeof data.user !== "undefined") {
        return prop.listEmployees.map((itemUser) => {
          if (itemUser.id === data.user) {
            return itemUser.name;
          } else return "";
        });
      } else {
        return (
          <FormControl className="box-input">
            <Select
              onChange={handleChangeEmployees}
              fullWidth={true}
              value={""}
              displayEmpty
              defaultValue=""
            >
              <MenuItem value={""} disabled={false}>
                Sin asignación
              </MenuItem>
              {prop.listEmployees &&
                prop.listEmployees.map((itemUser, j) => {
                  if (Array.isArray(itemUser.parkingGroups)) {
                    if (
                      itemUser.available &&
                      itemUser.parkingGroups.includes(data.parkingGroup)
                    ) {
                      return (
                        <MenuItem value={itemUser.id} key={j} disabled={false}>
                          {itemUser.name}
                        </MenuItem>
                      );
                    } else return null;
                  } else return null;
                })}
            </Select>
          </FormControl>
        );
      }
    }
    case "checParkingGroup": {
      const parkingGroup = column.id;
      const userID = data.id;
      let checked = false;
      if (Array.isArray(data.parkingGroups)) {
        checked = data.parkingGroups.includes(parkingGroup);
      }
      // const add = checked ? 1 : 0;
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={() => handleUpdate(userID, parkingGroup, checked)}
              color="primary"
            />
          }
        />
      );
    }
    case "actionsByCompany": {
      if (prop.parkingReservationMode === "byCompany") {
        btn_1 = (
          <Button
            color="primary"
            title={t("edit")}
            onClick={() => handleUpdate(data, "item", null)}
          >
            <Edit />
          </Button>
        );
        btn_3 = (
          <Button
            onClick={() => handleCancel(data)}
            color="primary"
            title={t("delete")}
          >
            <Clear />
          </Button>
        );
      }
      let disable = false;
      if (!data.user || typeof data.user === "undefined") disable = true;
      btn_2 = (
        <Button
          onClick={() => handleUpdate(data, "unsetParkingLotUser", null)}
          color="primary"
          title={t("unsetParkingLotUser")}
          disabled={disable}
        >
          <AccountCircle />
        </Button>
      );
      break;
    }
    case "rejectParkingReserv": {
      if (data.showReject) {
        btn_3 = (
          <Button
            onClick={() =>
              handleCancel(
                data.parkingReservationId ? data.parkingReservationId : data.id
              )
            }
            color="primary"
            title={t("reject")}
          >
            <Clear />
          </Button>
        );
      }
      break;
    }
    case "actions": {
      btn_1 = (
        <Button
          color="primary"
          title={t("edit")}
          onClick={() => handleUpdate(data, "item", null)}
        >
          <Edit />
        </Button>
      );
      btn_2 = (
        <Button
          onClick={() => handleCancel(data)}
          color="primary"
          title={t("cancel")}
        >
          <Clear />
        </Button>
      );

      break;
    }
    default:
  }

  return (
    <div className="columnsContent">
      {btn_1}
      {btn_2}
      {btn_3}
    </div>
  );
};

export default CellActions;
