import { Chip as ChipUI, withStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const options = {
  ACCEPTED: "accepted",
  PENDING: "pending",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  CANCELED: "canceled",
  FINISHED: "finished",
  USED: "used",
  EXPIRED: "expired",
};

const Chip = (props) => {
  const CustomChip = withStyles((theme) => {
    const bgColor = props.color
      ? theme.palette[props.color].dark
      : theme.palette.grey[300];
    return {
      root: {
        backgroundColor: bgColor,
        color: theme.palette.getContrastText(bgColor),
        fontWeight: 400,
        height: "20px",
      },
    };
  })(ChipUI);

  return <CustomChip label={props.label} size="small" />;
};

const PassStatus = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case options.ACCEPTED:
      return <Chip color="success" label={t("status_acepted")} />;
    case options.PENDING:
      return <Chip color="info" label={t("status_pending")} />;
    case options.REJECTED:
      return <Chip color="error" label={t("status_rejected")} />;
    case options.CANCELLED:
      return <Chip color="error" label={t("status_canceled")} />;
    case options.CANCELED:
      return <Chip color="error" label={t("status_canceled")} />;
    case options.FINISHED:
      return <Chip label={t("status_finished")} />;
    case options.USED:
      return <Chip label={t("status_used")} />;
    case options.EXPIRED:
      return <Chip color="error" label={t("status_expired")} />;
    default:
      return null;
  }
};

export default PassStatus;
