import SnackbarMessage from "utils/SnackbarMessage";
import PassAppApi from "../services/PassAppApi";
import {
  CHARTERS_AVAILABILITY_REQUEST_ERROR,
  CHARTERS_AVAILABILITY_REQUEST,
  CHARTER_GET_REQUESTS,
  CHARTER_GET_REQUESTS_ERROR,
  CHARTER_GET_REQUESTS_LAST_MONTH,
  CHARTER_GET_REQUESTS_LAST_MONTH_ERROR,
  CHARTER_GET_RESERVATIONS_DETAIL,
  CHARTER_GET_RESERVATIONS_CHARTER,
  CHARTER_FILTERED_LOAD,
  CHARTER_LOADING,
  CHARTER_GET_REPORTS_CHARTER,
  CHARTER_GET_REPORTS_USERS,
} from "./types";

export const getChartersAvailabilityRequest = (token, company, t, dispatch) => {
  PassAppApi.getChartersAvailability(token, company)
    .then((opts) => {
      dispatch({
        type: CHARTERS_AVAILABILITY_REQUEST,
        payload: { data: opts },
      });
    })
    .catch((err) => {
      console.log("err", err);
      dispatch({ type: CHARTERS_AVAILABILITY_REQUEST_ERROR });
      SnackbarMessage.show(
        "error",
        t("error_getting_charters_availability") +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const getChartersReservationsRequest = (token, t, dispatch, company) => {
  PassAppApi.getChartersReservations(token, company)
    .then((opts) => {
      dispatch({
        type: CHARTERS_AVAILABILITY_REQUEST,
        payload: { data: opts },
      });
    })
    .catch((err) => {
      dispatch({ type: CHARTERS_AVAILABILITY_REQUEST_ERROR });
      SnackbarMessage.show(
        "error",
        t("error_getting_charters_availability") +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const getCharterRequests = (token, t, dispatch, company, period) => {
  PassAppApi.getCharterRequests(token, company, period)
    .then((opts) => {
      dispatch({
        type: CHARTER_GET_REQUESTS,
        payload: opts,
      });
    })
    .catch((err) => {
      dispatch({ type: CHARTER_GET_REQUESTS_ERROR });
      SnackbarMessage.show(
        "error",
        t("error_getting_charters_requests") +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const getCharterLastMonthRequests = (
  token,
  t,
  dispatch,
  company,
  period
) => {
  PassAppApi.getCharterRequests(token, company, period)
    .then((opts) => {
      dispatch({
        type: CHARTER_GET_REQUESTS_LAST_MONTH,
        payload: opts,
      });
    })
    .catch((err) => {
      dispatch({ type: CHARTER_GET_REQUESTS_LAST_MONTH_ERROR });
      SnackbarMessage.show(
        "error",
        t("error_getting_charters_last_month_requests") +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const setCharterRequests = (token, company, period, charters) => {
  PassAppApi.setCharterRequests(token, company, period, charters)
    .then(() => {
      console.log("");
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        "error_setting_charters_requests" + ": " + (err ? err.toString() : "")
      );
    });
};

export const getCharterReservationsByDetail = (t, from, to, status = "") => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;
  const companies = getState().userReducer.companies;
  const company = companies.find((c) => c.id === user.charterManager.company);
  dispatch({ type: CHARTER_LOADING });
  PassAppApi.getCharterReservationsByDetail(token, company.id, from, to, status)
    .then((response) => {
      dispatch({ type: CHARTER_GET_RESERVATIONS_DETAIL, payload: response });
      dispatch(filterList(response));
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        "error_getting_charters_reservations_by_detail" +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const getCharterReservationsByCharter = (t, date) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;
  const companies = getState().userReducer.companies;
  const company = companies.find((c) => c.id === user.charterManager.company);
  dispatch({ type: CHARTER_LOADING });
  PassAppApi.getCharterReservationsByCharter(token, company.id, date)
    .then((response) => {
      dispatch({ type: CHARTER_GET_RESERVATIONS_CHARTER, payload: response });
      dispatch(filterList(response));
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        "error_getting_charters_reservations_by_charter" +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const getCharterReportByCharter = (t, from, to) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;
  const companies = getState().userReducer.companies;
  const company = companies.find((c) => c.id === user.charterManager.company);
  dispatch({ type: CHARTER_LOADING });
  PassAppApi.getCharterReportByCharter(token, company.id, from, to)
    .then((response) => {
      dispatch({ type: CHARTER_GET_REPORTS_CHARTER, payload: response });
      dispatch(filterList(response));
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        "error_getting_charters_report_by_charter" +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const getCharterReportByUsers = (t, from, to) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;
  const companies = getState().userReducer.companies;
  const company = companies.find((c) => c.id === user.charterManager.company);
  dispatch({ type: CHARTER_LOADING });
  PassAppApi.getCharterReportByUsers(token, company.id, from, to)
    .then((response) => {
      dispatch({ type: CHARTER_GET_REPORTS_USERS, payload: response });
      dispatch(filterList(response));
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        "error_getting_charters_report_by_users" +
          ": " +
          (err ? err.toString() : "")
      );
    });
};

export const filterList = (result) => (dispatch) => {
  dispatch({
    type: CHARTER_FILTERED_LOAD,
    payload: { data: result },
  });
};
