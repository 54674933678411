import {
  DIALOG_MODIFIED,
  DIALOG_CLEAR_MODIFIED,
  DIALOG_HIDE_CONFIRMATION,
  DIALOG_OPEN,
  DIALOG_CLOSE,
  DIALOG_EXIT,
} from "./types";

export const onDialogOpen = (type, params) => (dispatch) => {
  dispatch({ type: DIALOG_OPEN, payload: { type: type, params: params } });
};
export const onDialogClose = () => (dispatch) => {
  dispatch({ type: DIALOG_CLOSE });
};
export const onDialogExited = () => (dispatch) => {
  dispatch({ type: DIALOG_EXIT });
};
export const onDialogModified = () => (dispatch) => {
  dispatch({ type: DIALOG_MODIFIED });
};
export const onDialogClear = () => (dispatch) => {
  dispatch({ type: DIALOG_CLEAR_MODIFIED });
};
export const onDialogHideConfirmation = () => (dispatch) => {
  dispatch({ type: DIALOG_HIDE_CONFIRMATION });
};
