import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import AreasTable from "components/Areas/AreasTable";
import SearchFilter from "components/common/Table/SearchFilter";
import "./Areas.scss";
import {
  getAreasList,
  filterAreasList,
} from "../../actions/areas";

const useStyles = createStyles(styles);

const Areas = (props) => {
  const {
    t,
    filteredList,
    filterList,
    allItems,
    getAllAreas,
  } = props;

  const [globalFilter, setGlobalFilter] = useState("");
  const [globalPageIndex, setGlobalPageIndex] = useState(0);

  const setCurrentPage = (page) => {
    setGlobalPageIndex(page);
  };
  
  const handleGlobalFilter = (param) => {
    setGlobalFilter(param);
    setGlobalPageIndex(0);
    filterList(allItems, param);
  };

  useEffect(() => {
    getAllAreas(t);
  }, []);

  return (
    <div className="content-box AreasContent scroll">
      <div className="header-box">
        <h1 className="title">{t("areas")}</h1>
        <div className="filter-box">
          <SearchFilter
            className="search-box"
            setGlobalFilter={handleGlobalFilter}
            globalFilter={globalFilter}
            t={t}
          />
        </div>
      </div>
      <AreasTable
        data={filteredList}
        t={t}
        globalPageIndex={globalPageIndex}
        setCurrentPage={setCurrentPage}
      ></AreasTable>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteredList: state.areas.currentList,
  allItems: state.areas.list,
});

const mapActionsToProps = {
  getAllAreas: getAreasList,
  filterList: filterAreasList
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(withStyles(useStyles)(Areas)));
