import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/logo.png";
import Loader from "utils/Loader";
import PassAppApi from "services/PassAppApi";
import { useSelector } from "react-redux";
import SnackbarMessage from "utils/SnackbarMessage";

const PassesMail = () => {
  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(true);
  const [emailSended, setEmailSended] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [loadingContinue, setLoadingContinue] = React.useState(false);
  const { externalToken } = useSelector((state) => state.authReducer);
  const { t } = useTranslation();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailValid(emailRegex.test(email) === true ? false : true);
  };

  useEffect(() => {
    setEmailValid(emailRegex.test(email) === true ? false : true);
  }, [email, emailSended]);

  const updateEmail = () => {
    setLoadingContinue(true);
    PassAppApi.updateEmailValidation(externalToken, email)
      .then(() => {
        SnackbarMessage.show("success", t("passes_mail_success_send"));
        setEmailSended(true);
        setShowLoader(true);
        setLoadingContinue(false);
        setTimeout(() => {
          setShowLoader(false);
        }, 30000);
      })
      .catch((err) => {
        setLoadingContinue(false);
        console.log(err);
        SnackbarMessage.show(
          "error",
          "error send mail" + ": " + (err ? err.toString() : "")
        );
      });
  };

  const changeEmail = () => {
    setEmailValid(false);
    setEmail("");
    setEmailSended(false);
  };

  return (
    <Container
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
      }}
      component="main"
      maxWidth="xs"
    >
      <div style={{ width: "100%" }}>
        <img alt="Web Logo" className="logo" src={logo} />
        {!emailSended ? (
          <div style={{ marginTop: 40 }}>
            <Typography variant="h8" style={{ marginTop: 40 }}>
              {t("passes_mail_input")}
            </Typography>
            <form>
              <TextField
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => handleEmailChange(e)}
                required
                fullWidth
                type="email"
                id="email"
                label={t("email")}
                name="email"
                autoComplete="email"
                autoFocus
                style={{ marginTop: 20 }}
              />
              <Button
                type="button"
                fullWidth
                color="primary"
                disabled={emailValid || loadingContinue}
                onClick={updateEmail}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: 15,
                  marginTop: 30,
                }}
              >
                {loadingContinue ? (
                  <CircularProgress style={{ height: 30, width: 30 }} />
                ) : (
                  t("passes_mail_continue")
                )}
              </Button>
            </form>
          </div>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item style={{ marginTop: 40 }}>
              <Typography variant="h8">
                {t("passes_mail_active_msj")} <br></br>
                <a href={`mailto:${email}`}>{email}</a>
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: 40, marginBottom: 10 }}>
              <Loader className="submit" visible={showLoader} />
            </Grid>

            <Grid item style={{ marginTop: 40 }}>
              <Button
                type="button"
                fullWidth
                disabled={showLoader || loadingContinue}
                className="submit"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: 15,
                  marginBottom: 3,
                }}
                onClick={updateEmail}
              >
                {loadingContinue ? (
                  <CircularProgress style={{ height: 30, width: 30 }} />
                ) : (
                  t("passes_mail_resend_title")
                )}
              </Button>
              <Button
                type="button"
                fullWidth
                disabled={showLoader}
                onClick={changeEmail}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: 15,
                  marginBottom: 3,
                  marginTop: 3,
                }}
              >
                {t("passes_mail_change_msj")}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
};
export default PassesMail;
