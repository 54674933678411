import {
  drawerWidth,
  transition,
  container,
} from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.up("lg")]: {
      overflow: "hidden",
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    display: "flex",
    height: "100%",
  },
  content: {
    width: "calc(100% - 60px)",
    marginTop: theme.mixins.toolbar.minHeight,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  subContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px 15px",
    },
    padding: "20px 30px",
    overflow: "auto",
    flexGrow: 1,
  },
  background: {
    background: "#fff",
  },
  container,
  map: {
    marginTop: "70px",
  },
});

export default appStyle;
