import {
  MENU_MY_GUESTS_CLICKED,
  MENU_MY_QR_CLICKED,
  MENU_NOTIFICATION_ITEM_TOUCHED,
  MENU_UPDATE_FACEPHOTO_UPLOAD,
  MENU_UPDATE_FACEPHOTO_TAKE,
  MENU_UPDATE_FACEPHOTO_TAKED,
} from "../actions/types";

const initialState = {
  isMyQrClicked: false,
  isMyGuestsClicked: null,
  // isNotificationClicked: null,
  isNotificationItemTouched: null, //{action: string, data: string}
  // isPassRequestClicked: null,
  isUpdateFacePhotoTake: false,
  isUpdateFacePhotoTaked: false,
  isUpdateFacePhotoUpload: false,
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_MY_QR_CLICKED:
      return {
        ...state,
        isMyQrClicked: action.payload,
      };
    case MENU_MY_GUESTS_CLICKED:
      return {
        ...state,
        isMyGuestsClicked: action.payload,
      };
    case MENU_NOTIFICATION_ITEM_TOUCHED:
      return {
        ...state,
        isNotificationItemTouched: action.payload,
      };
    case MENU_UPDATE_FACEPHOTO_TAKE:
      return {
        ...state,
        isUpdateFacePhotoTake: action.payload,
        isUpdateFacePhotoTaked: true,
      };
    case MENU_UPDATE_FACEPHOTO_UPLOAD:
      return {
        ...state,
        isUpdateFacePhotoUpload: action.payload,
      };
    case MENU_UPDATE_FACEPHOTO_TAKED:
      return {
        ...state,
        isUpdateFacePhotoTaked: action.payload,
      };
    default:
      return state;
  }
};
