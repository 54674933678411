export const columns = [
  {
    Header: "desk",
    accessor: "name",
  },
  {
    Header: "status",
    accessor: "status",
    action: "translate",
  },
  {
    Header: "user",
    accessor: "user",
  },
  {
    Header: "actions",
    action: "rejectDeskReserv",
    short: 0,
  },
];
