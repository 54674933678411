import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/common/Grid/GridItem.js";
import GridContainer from "components/common/Grid/GridContainer.js";
import Card from "components/common/Card/Card.js";
import CardHeader from "components/common/Card/CardHeader.js";
import CardBody from "components/common/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  ListItemText,
} from "@material-ui/core";
import Primary from "components/common/Typography/Primary";
import Moment from "react-moment";
import Loader from "utils/Loader";
import PerfectScrollbar from "perfect-scrollbar";
import { NOTIFICATION_REQUEST, NOTIFICATION_REQUEST_REJECTED } from "Constant";
import NotificationIcon from "@material-ui/icons/Notifications";
import "./Notifications.scss";
import { connect } from "react-redux";
import { getNotifications } from "../../actions/user";

const useStyles = createStyles(styles);

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
    this.ps = null;
    this.blockRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;

    this.ps = new PerfectScrollbar(this.blockRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
    this.props.getNotifications(t);
  }

  componentDidUpdate() {
    this.ps.update();
  }

  componentWillUnmount() {
    this.ps.destroy();
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleClick(notification) {
    if (
      notification.action === NOTIFICATION_REQUEST ||
      notification.action === NOTIFICATION_REQUEST_REJECTED
    ) {
      // this.props.onOpenRequest(notification.customData);
    }
  }

  render() {
    const { notifications } = this.props;

    const { classes, t } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className="single-card notifications">
            <CardHeader className="single-card-header" color="alternate">
              <h4 className={classes.singleHeader}>{t("notifications")}</h4>
            </CardHeader>
            <CardBody className={classes.noPadding}>
              <div className={classes.loader}>
                <Loader visible={notifications === null}></Loader>
              </div>
              <div>
                <List className="fixedList" ref={this.blockRef}>
                  {notifications?.map((notification) => {
                    const date = new Date(notification.timestamp * 1000);

                    return (
                      <ListItem
                        key={notification.id}
                        // button
                        // onClick={() => {
                        //   this.handleClick(notification);
                        // }}
                      >
                        <ListItemAvatar>
                          <Avatar hidden>
                            <NotificationIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <div>
                          <Typography className="pass-name">
                            {notification.message}
                          </Typography>
                          <Primary className="date">
                            <Moment format="LLL">{date}</Moment>
                          </Primary>
                        </div>
                      </ListItem>
                    );
                  })}
                  {notifications?.length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary={t("no_row_notifications")}
                        className="noRow"
                      />
                    </ListItem>
                  )}
                </List>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.userReducer.notifications,
});

const actionCreators = {
  getNotifications: getNotifications,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(Notifications)));
