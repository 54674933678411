import {
  ESTABLISHMENT_JOINING,
  ESTABLISHMENT_JOINED,
  ESTABLISHMENT_SET_PENDING,
  ESTABLISHMENT_CLEAR_PENDING,
} from "./types";
import PassAppApi from "../services/PassAppApi";
import SnackbarMessage from "../utils/SnackbarMessage";
import {
  buildJoinCountry,
  buildUpdateCountry,
} from "../utils/EstablishmentsUtils";
import { onDialogClose, onDialogOpen } from "./dialog";
import MessageDialog from "../components/common/MessageDialog/MessageDialog";
import history from "../history";
import { getGlobalOptions } from "./user";
import { getUser } from "./auth";

export const joinCountry = (country, user, countries, t) => (
  dispatch,
  getState
) => {
  dispatch({ type: ESTABLISHMENT_JOINING });

  const token = getState().authReducer.token;
  const data = buildJoinCountry(user, country, countries);

  PassAppApi.updateUser(token, data)
    .then(() => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      dispatch(getGlobalOptions(t));
      dispatch(getUser(t));
      dispatch(onDialogClose());
      dispatch(
        onDialogOpen(MessageDialog, {
          message: t("joined_country_success"),
          onClose: () => {
            history.push("/home/myEstablishments");
          },
        })
      );
    })
    .catch((err) => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      SnackbarMessage.show(
        "error",
        t("error_joining_establishment") + ": " + (err ? err.toString() : "")
      );
    });
};

export const updateCountry = (country, user, countries, t) => (
  dispatch,
  getState
) => {
  dispatch({ type: ESTABLISHMENT_JOINING });

  const token = getState().authReducer.token;
  const data = buildUpdateCountry(user, country, countries);

  PassAppApi.updateUser(token, data)
    .then(() => {
      SnackbarMessage.show("success", t("updated_country"));

      dispatch({ type: ESTABLISHMENT_JOINED });
      dispatch(getGlobalOptions(t));
      dispatch(getUser(t));
      dispatch(onDialogClose());
    })
    .catch((err) => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      SnackbarMessage.show(
        "error",
        t("error_joining_establishment") + ": " + (err ? err.toString() : "")
      );
    });
};

export const silentlyJoinCountry = (country, user, countries, t) => (
  dispatch,
  getState
) => {
  dispatch({ type: ESTABLISHMENT_JOINING });

  const token = getState().authReducer.token;
  const data = buildJoinCountry(user, country, countries);

  PassAppApi.updateUser(token, data)
    .then(() => {
      dispatch(getUser(t));
      dispatch({ type: ESTABLISHMENT_JOINED });
    })
    .catch((err) => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      SnackbarMessage.show(
        "error",
        t("error_joining_establishment") + ": " + (err ? err.toString() : "")
      );
    });
};

export const joinCompany = (data, t, message) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  dispatch({ type: ESTABLISHMENT_JOINING });

  PassAppApi.workplaces(token, data)
    .then(() => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      dispatch(getGlobalOptions(t));
      dispatch(getUser(t));
      dispatch(onDialogClose());
      dispatch(
        onDialogOpen(MessageDialog, {
          message: message,
          onClose: () => {
            history.push("/home/myEstablishments");
          },
        })
      );
    })
    .catch((err) => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      SnackbarMessage.show(
        "error",
        t("error_joining_establishment") + ": " + (err ? err.toString() : "")
      );
    });
};

export const silentlyJoinCompany = (data, t) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  dispatch({ type: ESTABLISHMENT_JOINING });

  PassAppApi.workplaces(token, data)
    .then(() => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      dispatch(getUser(t));
      dispatch(onDialogClose());
    })
    .catch((err) => {
      dispatch({ type: ESTABLISHMENT_JOINED });
      SnackbarMessage.show(
        "error",
        t("error_joining_establishment") + ": " + (err ? err.toString() : "")
      );
    });
};

export const saveRequest = (request, message) => (dispatch) => {
  dispatch({ type: ESTABLISHMENT_SET_PENDING, payload: { request } });
  dispatch(onDialogClose());
  dispatch(
    onDialogOpen(MessageDialog, {
      message: message,
      onClose: () => {
        history.push("/register/profile");
      },
    })
  );
};

export const clearPendingRequests = () => (dispatch) => {
  dispatch({ type: ESTABLISHMENT_CLEAR_PENDING });
};
