import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Route, Switch, Router } from "react-router-dom";
import App from "App";
import ConfirmationDialogStatic from "./components/common/ConfirmationDialog/ConfirmationDialogStatic";
import SnackbarMessage from "./utils/SnackbarMessage";
import history from "./history";
import { useSelector } from "react-redux";
import { getTheme } from "./utils/theme";

const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

const Theming = () => {
  const [theme, setTheme] = useState(getTheme());
  const { otherCompany } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (otherCompany?.customization?.primaryColor)
      setTheme(
        getTheme({
          palette: {
            primary: {
              main: otherCompany.customization.primaryColor,
              dark: shadeColor(otherCompany.customization.primaryColor, -30),
            },
          },
        })
      );
  }, [otherCompany?.customization?.primaryColor]);

  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
        <SnackbarMessage />
        <ConfirmationDialogStatic />
      </Router>
    </MuiThemeProvider>
  );
};

export default Theming;
