import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { CHARTER_SELECT_USER } from "actions/types";
import Loading from "components/common/MessageDialog/Loading";
import HeaderActions from "components/common/Table/HeaderActions";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PassAppApi from "services/PassAppApi";
import SnackbarMessage from "utils/SnackbarMessage";
//import { CharterUsersTable } from "./CharterUsersTable";
import MaUTable from "@material-ui/core/Table";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import { CHARTER_USER_SELECTED_REMOVE } from "actions/types";
import { CHARTER_USER_SELECTED_ADD } from "actions/types";
import ReactExport from "react-data-export";

let columns = [
  {
    Header: "name",
    accessor: "name",
  },
  {
    Header: "charters",
    accessor: "charters",
  },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const CharterUsers = () => {
  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState("");
  const [charterEmployees, setCharterEmployees] = React.useState([]);
  const [charterSearchData, setCharterSearchData] = React.useState([]);
  //const [selectedAll, setSelectedAll] = React.useState(true);
  const [globalPageIndex, setGlobalPageIndex] = React.useState(0);
  const [dialogConfirm, setDialogConfirm] = React.useState(false);
  const [company, setCompany] = React.useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.authReducer);
  const { companies } = useSelector((state) => state.userReducer);
  const { charter_select_user } = useSelector((state) => state.charterReducer);
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: charterEmployees,
      initialState: {
        pageIndex: globalPageIndex,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    () => {}
  );

  useEffect(() => {
    if (token && user) {
      getDataCharterEmployees();
    }
  }, [token, user, charter_select_user]);

  const dataExport = charterEmployees.map((e) => {
    let item = {
      name: e.name,
      adminComment: e.adminComment,
      allowCharterReservation:
        e.allowCharterReservation === true ? t("yes") : t("no"),
    };
    return item;
  });

  const handleSearch = (event) => {
    const text = event.target.value;
    setSearchText(text);
    if (searchText.length > 1) {
      const result = charterEmployees.filter((ch) => {
        if (ch.name && ch.name.search(new RegExp(text, "i")) > -1) return true;
        return false;
      });
      setCharterEmployees(result);
    } else {
      setCharterEmployees(charterSearchData);
    }
  };

  const searchCompany = (companies) => {
    return companies.find((c) => c.id === user.charterManager.company);
  };

  const getStatusCharterEmployees = (charterEmployees) => {
    const status = charterEmployees.filter(
      (c) => c.allowCharterReservation === false
    );
    return status.length === 0;
  };

  const getDataCharterEmployees = () => {
    const comp = searchCompany(companies);
    let initialStatus = true;
    setCompany(comp.id);
    PassAppApi.getCharterEmployees(token, comp.id)
      .then((response) => {
        setCharterEmployees(response);
        setCharterSearchData(response);
        initialStatus = getStatusCharterEmployees(response);
        dispatch({
          type: CHARTER_SELECT_USER,
          payload: initialStatus,
        });
        setLoading(false);
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          err ? err.toString() : t("error_getting_charter_employee")
        );
      });
  };

  const setDataCharterEmployee = (user) => {
    PassAppApi.setCharterEmployee(token, company, user)
      .then(() => {
        console.log("");
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          err ? err.toString() : t("error_setting_charter_employee_set")
        );
      });
  };

  const unsetDataCharterEmployee = (user) => {
    PassAppApi.unsetCharterEmployee(token, company, user)
      .then(() => {
        console.log("");
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          err ? err.toString() : t("error_setting_charter_employee_unset")
        );
      });
  };

  const setDataAllCharterEmployee = () => {
    const company = searchCompany(companies);
    PassAppApi.setCharterAllEmployees(token, company.id)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          err ? err.toString() : t("error_setting_charter_employee_set")
        );
      });
  };

  const unsetDataAllCharterEmployee = () => {
    const company = searchCompany(companies);
    PassAppApi.unsetCharterAllEmployees(token, company.id)
      .then(() => {
        console.log("");
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          err ? err.toString() : t("error_setting_charter_employee_set")
        );
      });
  };

  const processReserves = (event) => {
    if (event.target.checked === true) {
      setLoading(true);
      setCharterEmployees([]);
      //enableEmployeeStatus();
      setDataAllCharterEmployee();
      setTimeout(() => {
        dispatch({
          type: CHARTER_SELECT_USER,
          payload: true,
        });
      }, 1500);
      clearTimeout();
      setLoading(false);
    } else {
      setDialogConfirm(true);
    }
  };

  const deselectChanges = () => {
    setLoading(true);
    unsetDataAllCharterEmployee();
    setTimeout(() => {
      dispatch({
        type: CHARTER_SELECT_USER,
        payload: false,
      });
      setDialogConfirm(false);
    }, 1500);
    clearTimeout();
  };

  const cancelChanges = () => {
    setDialogConfirm(false);
  };

  const onCloseDialog = () => {
    setDialogConfirm(false);
  };

  const printColumnHead = (column) => {
    const title = t(`${column.render("Header")}`).split("<br/>");
    if (column.short !== 0) {
      return (
        <div style={{ marginLeft: "0em" }}>
          <div className="box-TableTh-tit">
            {title[0]}
            {typeof title[1] !== "undefined" && (
              <span>
                <br />
                {title[1]}
              </span>
            )}
          </div>
        </div>
      );
    }
    return <span>{t(`${column.render("Header")}`)}</span>;
  };
  const getColumnProp = (column, headerData) => {
    return Object.assign({}, column, { title: t(headerData.Header) });
  };

  const handleCheck = (e, row) => {
    let res = charterEmployees;
    if (e.target.checked === false) {
      unsetDataCharterEmployee(row.id);
      res.forEach((d) => {
        if (d.id === row.id) d.allowCharterReservation = false;
      });
      setCharterEmployees(res);
      dispatch({
        type: CHARTER_USER_SELECTED_REMOVE,
        payload: row,
      });
      dispatch({
        type: CHARTER_SELECT_USER,
        payload: false,
      });
    } else {
      setDataCharterEmployee(row.id);
      res.forEach((d) => {
        if (d.id === row.id) d.allowCharterReservation = true;
      });
      setCharterEmployees(res);
      dispatch({
        type: CHARTER_USER_SELECTED_ADD,
        payload: row,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs>
            <div></div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <p>{t("charter_user_select_all")}</p>
                </Grid>
                <Grid item>
                  <Checkbox
                    checked={charter_select_user}
                    color="primary"
                    onChange={processReserves}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginTop: "0.5em" }}
              >
                <Grid item>
                  <ExcelFile
                    element={
                      <Button
                        variant="outlined"
                        onClick={(e) => console.log(e)}
                        style={{ marginRight: 8 }}
                      >
                        {t("export_to_excel")}
                      </Button>
                    }
                  >
                    <ExcelSheet data={dataExport} name={t("users")}>
                      <ExcelColumn label={t("name")} value="name" />
                      <ExcelColumn label={t("admin_comment")} value="adminComment" />
                      <ExcelColumn
                        label={t("charters")}
                        value="allowCharterReservation"
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </Grid>

                <Grid item>
                  <FormControl className="box-input">
                    <TextField
                      type="text"
                      name="search"
                      placeholder={t("search")}
                      value={searchText}
                      onChange={handleSearch}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
      <FormGroup>
        {loading ? (
          <Loading loading={loading}></Loading>
        ) : (
          <>
            <TableContainer className={"fixed-height"}>
              <MaUTable {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup, i) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, j) => (
                        <TableCell
                          {...getColumnProp(
                            column.getHeaderProps(
                              column.getSortByToggleProps()
                            ),
                            headerGroup.headers[j]
                          )}
                          key={j}
                          className={j === 0 ? "col-left" : ""}
                        >
                          {column.headerAction ? (
                            <HeaderActions t={t} column={column} />
                          ) : (
                            printColumnHead(column)
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow key={i}>
                        <TableCell className="col-left">
                          <p>{row.original.name}</p>
                        </TableCell>
                        <TableCell className="col-right">
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={
                                  row.original.allowCharterReservation
                                }
                                color="primary"
                                onChange={(e) => handleCheck(e, row.original)}
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {!loading && page.length === 0 && (
                    <TableRow>
                      <TableCell style={{ padding: "14px" }} colSpan="7">
                        {t("no_result")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <Loading loading={loading}></Loading>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: t("all"), value: charterEmployees.length },
                      ]}
                      colSpan={7}
                      count={charterEmployees.length}
                      rowsPerPage={pageSize}
                      page={pageIndex}
                      labelRowsPerPage={t("rows_per_page")}
                      labelDisplayedRows={(params) => {
                        return t("pagination")
                          .replace("%%FROM%%", params.from)
                          .replace("%%TO%%", params.to)
                          .replace("%%TOTAL%%", params.count);
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </MaUTable>
            </TableContainer>
          </>
        )}
      </FormGroup>
      <Dialog
        onClose={onCloseDialog}
        className="confirmation-dialog"
        aria-labelledby="simple-dialog-title"
        open={dialogConfirm}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("charter_user_dialog_text")}
          </DialogContentText>
          <DialogActions className="dialog-buttons">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ flexGrow: 1 }}
            >
              <Grid item xs={3}>
                <Button onClick={deselectChanges} color="primary">
                  {t("confirm")}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button onClick={cancelChanges} color="secondary">
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
