import { IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import React from "react";
import { useLocation } from "react-router-dom";
import SupportMenu from "../../SupportMenu/SupportMenu";
import logoIcon from "../../../assets/img/logo-icon.svg";
import { drawerWidth } from "../../../assets/jss/material-dashboard-react";
import useBreakpoint from "../../../hooks/useBreakpoint";
import useRoutes from "../../../hooks/useRoutes";
import { HANDLE_MENU_MOBILE } from "actions/types";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    top: 0,
    left: "auto",
    right: 0,
    position: "fixed",
    width: "100%",
    display: "flex",
    zIndex: "1100",
    boxSizing: "border-box",
    flexShrink: 0,
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      paddingLeft: drawerWidth,
    },
  },
  toolbar: {
    justifyContent: "space-between",
    color: theme.palette.primary.dark,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subTitle: {
    fontStyle: "italic",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: "15px",
    paddingLeft: "5px",
  },
  hidden: {
    opacity: 0,
  },
}));
const NavbarApp = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isTablet } = useBreakpoint();
  const { routes } = useRoutes();
  const getTitle = () => {
    return routes.find((item) => location.pathname.includes(item.path)).name;
  };
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <SupportMenu />
        <div className={classes.titleContainer}>
          <Typography variant="button">{getTitle()}</Typography>
          <Typography variant="caption" className={classes.subTitle}>
            Powered by PassApp
            <img className={classes.logo} src={logoIcon} alt="logo icon" />
          </Typography>
        </div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            dispatch({
              type: HANDLE_MENU_MOBILE,
              payload: true,
            });
          }}
          className={!isTablet ? classes.hidden : ""}
        >
          <Menu />
        </IconButton>
      </Toolbar>
    </div>
  );
};
export default NavbarApp;
