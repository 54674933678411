import Typography from "@material-ui/core/Typography";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { withTranslation } from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {
  isPendingUser,
  findEstablishment,
  getUserFullname,
  getPassUser,
  getPassUsers,
  getUserPhotoUrl,
  getTimezoneLocal,
} from "../../utils/Utils";
import "./Pass.scss";
import { getPassStatus } from "utils/Status";
import { getDatetimeShort } from "utils/Utils";
import Primary from "components/common/Typography/Primary";
import Muted from "components/common/Typography/Muted";
import PassDetail from "components/PassDetail/PassDetail";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { onDialogOpen } from "../../actions/dialog";
import Danger from "../common/Typography/Danger";

class Pass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDialog: null,
    };

    this.openDetail = this.openDetail.bind(this);
    this.searchBlock = null;
  }

  openDetail() {
    const { pass, onClose } = this.props;

    this.props.onDialogOpen(PassDetail, {
      closeWarning: true,
      pass: pass,
      onPassUpdate: onClose,
    });
  }

  buildDate(pass, establishment) {
    const { t } = this.props;
    let timezone =
      establishment && establishment.timezone != null
        ? establishment.timezone
        : getTimezoneLocal();

    let start = moment(new Date(pass.beginTimestamp * 1000));
    if (pass.beginDatetime) {
      start = moment.tz(getDatetimeShort(pass.beginDatetime), timezone);
    }

    let end = moment(new Date(pass.endTimestamp * 1000));
    if (pass.endDatetime) {
      end = moment.tz(getDatetimeShort(pass.endDatetime), timezone);
    }

    if (pass.calendar) {
      // Is a permanent pass?
      return t("until_date").replace("%%DATE%%", end.format("LL"));
    }

    return t("full_day_from_hour_to_hour")
      .replace("%%DAY_NAME%%", start.format("dddd"))
      .replace("%%FULL_DAY%%", start.format("LL"))
      .replace("%%FROM_HOUR%%", start.format("HH:mm"))
      .replace("%%TO_HOUR%%", end.format("HH:mm"));
  }

  render() {
    const {
      t,
      pass,
      filter,
      companies,
      countries,
      friends,
      token,
      tokenFile,
    } = this.props;

    let establishment = findEstablishment(pass, companies, countries); // Country or company
    let date = this.buildDate(pass, establishment);

    let name = t("no_invited");
    let user = getPassUser(pass, friends, token);

    let passStatus = getPassStatus(pass, t, token);
    let StatusTypography = passStatus.typography;

    if (user != null) {
      if (!isPendingUser(user)) {
        name = getUserFullname(user);

        if (pass.guests.length > 1) {
          name += " (+" + (pass.guests.length - 1) + ")";
        }
      } else {
        name = "";
        if (pass.guests.length >= 1) {
          name = `${pass.guests.length} ${t("guests")}${
            pass.guests.length === 1 ? "" : "s"
          }`;
        }
      }
    }

    let searchTerm = filter.toLowerCase();
    let establishmentFilter = (establishment
      ? establishment.name
      : ""
    ).toLowerCase();
    let filterMatch = establishmentFilter.includes(searchTerm);

    let users = getPassUsers(pass, friends, token);

    for (let i in users) {
      if (users[i] != null) {
        if (!isPendingUser(users[i])) {
          let userFilter = getUserFullname(users[i]).toLowerCase();
          if (userFilter.includes(searchTerm)) {
            filterMatch = true;
          }
        }
      }
    }

    if (!filterMatch) {
      return <div />;
    }

    return (
      <div>
        <ListItem button className="pass" onClick={this.openDetail}>
          <ListItemAvatar>
            <Avatar
              className="avatar"
              src={
                tokenFile
                  ? `${getUserPhotoUrl(user)}?token=${tokenFile?.t}`
                  : null
              }
            ></Avatar>
          </ListItemAvatar>
          <div>
            <Typography className="pass-name">{name}</Typography>
            <Muted>{establishment ? establishment.name : ""}</Muted>
            <Primary className="date"> {date} </Primary>
            <StatusTypography className="bold">
              {passStatus.short}
            </StatusTypography>
            {!!this.props.pass.accessPrevalidationStatus &&
              (this.props.pass.accessPrevalidationStatus === "success" ? (
                <Primary className="date">
                  {t("access_prevalidation_status_success")}
                </Primary>
              ) : (
                <Danger className="date">
                  {t("access_prevalidation_status_error")}
                </Danger>
              ))}
          </div>
        </ListItem>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  friends: state.userReducer.friends,
  token: state.authReducer.token,
  tokenFile: state.authReducer.token_file,
});

const actionCreators = {
  onDialogOpen: onDialogOpen,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(Pass));
