import PassAppApi from "../services/PassAppApi";
import {
  EVENT_CHANGE_FILTER,
  EVENT_CHANGE_FILTER_QUERY,
  EVENT_CHANGE_FROM,
  EVENT_CHANGE_TO,
  EVENT_INIT,
  EVENT_REQUEST,
} from "./types";

export const getListEvents = (from, to) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  dispatch({ type: EVENT_REQUEST });
  PassAppApi.getEventsList(token, from, to).then((response) => {
    const events = response.map((event) => {
      return { ...event, id: event.timestamp };
    });
    dispatch({ type: EVENT_INIT, payload: events });
  });
};

export const changeDateFilterFrom = (from) => (dispatch) => {
  dispatch({ type: EVENT_CHANGE_FROM, payload: from });
};

export const changeDateFilterTo = (to) => (dispatch) => {
  dispatch({ type: EVENT_CHANGE_TO, payload: to });
};
export const changeFilterQuery = (query) => (dispatch) => {
  dispatch({ type: EVENT_CHANGE_FILTER_QUERY, payload: query });
};
export const changeFilterList = (eventsFilter) => (dispatch) => {
  dispatch({ type: EVENT_CHANGE_FILTER, payload: eventsFilter });
};
