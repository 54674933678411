import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const styles = {
  under_construction: {
    textAlign: "center",
    marginTop: "10rem",
    fontSize: "2rem",
  },
};

const useStyles = makeStyles(styles);

export default function Tutorials() {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.under_construction}>
        Sección actualmente en construcción
      </Typography>
    </div>
  );
}
