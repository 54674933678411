import React from "react";
import "react-dropzone-uploader/dist/styles.css";

import Dropzone from "react-dropzone-uploader";

const DropzoneFiles = (props) => {
  const {
    url,
    accept,
    label,
    textInfo1,
    textInfo2,
    maxFiles,
    onload,
    showMessaje,
    errorFileSize,
    rejectedFileType,
    autoRemove,
    maxSizeMb,
  } = props;

  // specify upload params and url for your files
  const getUploadParams = () => {
    return {
      url: url,
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ file, remove }, status) => {
    if (status === "error_file_size") {
      showMessaje(errorFileSize);
      remove();
    }
    if (status === "rejected_file_type") {
      showMessaje(rejectedFileType);
    }
    if (status === "done") {
      onload(file);
      if (autoRemove) remove();
    }
  };
  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      inputContent={() => (
        <div>
          {label}
          <br />
          <div>
            {textInfo1}
            <br />
            {textInfo2}
          </div>
        </div>
      )}
      multiple={false}
      maxFiles={maxFiles}
      accept={accept}
      maxSizeBytes={(maxSizeMb || 10) * 1024 * 1024}
    />
  );
};

export default DropzoneFiles;
