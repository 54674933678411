import React, { Component } from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Button from "components/common/CustomButtons/Button.js";
import { withTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  TextField,
  ListItem,
  ListItemAvatar,
  Typography,
  IconButton,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import moment from "moment-timezone";
import CustomMomentUtils from "utils/CustomMomentUtils";
import { connect } from "react-redux";
import {
  GUEST_TYPE_PARTICULAR,
  GUEST_TYPE_PROVIDER,
  PASS_STATUS_REJECTED,
  PASS_STATUS_ACCEPTED,
  PASS_STATUS_PENDING,
} from "Constant";
import Loader from "utils/Loader";
import { getTimezoneLocal } from "../../utils/Utils";
import {
  getUserPhotoUrl,
  getPassUser,
  getUserFullname,
  getUserWorkplace,
  getDatetimeShort,
} from "utils/Utils";
import "./PassRequest.scss";
import { updateInvite } from "../../actions/passes";
import Images from "components/common/Images/Images";
import CancelIcon from "@material-ui/icons/Cancel";
import withBreakpoint from "../../hooks/classHooks/withBreakpoint";

class PassRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destinationTextWidth: 0,
    };

    this.acceptPass = this.acceptPass.bind(this);
    this.rejectPass = this.rejectPass.bind(this);
    this.setTextInputRef = (element) => {
      if (element) {
        this.setState({
          destinationTextWidth: element.offsetWidth,
        });
      }
    };
  }

  renderSubmitButtons() {
    const { t, loading } = this.props;
    return (
      <div>
        <Button
          round
          disabled={loading}
          onClick={this.rejectPass}
          color="danger"
        >
          {t("decline")}
        </Button>
        <Button
          round
          disabled={loading}
          onClick={this.acceptPass}
          color="primary"
        >
          {t("accept")}
        </Button>
      </div>
    );
  }

  acceptPass() {
    const { t, pass, user } = this.props;

    let newPass = {
      ...pass,
      guests: undefined,
    };

    newPass.status = PASS_STATUS_ACCEPTED;

    if (newPass.company) {
      let workplace = getUserWorkplace(user, newPass.company);

      if (!workplace || !workplace.isAdmin) {
        newPass.status = PASS_STATUS_PENDING;
      }
      newPass.manager = workplace.manager;
    }

    this.props.updateInvite(newPass, t, t("invite_accepted"));
  }

  rejectPass() {
    const { t, pass } = this.props;

    let newPass = {
      ...pass,
      guests: undefined,
    };

    newPass.status = PASS_STATUS_REJECTED;

    this.props.updateInvite(newPass, t, t("invite_rejected"));
  }

  render() {
    const {
      t,
      open,
      classes,
      establishmentItems,
      pass,
      onClose,
      onExited,
      friends,
      token,
      loading,
      isMobile,
    } = this.props;
    const { destinationTextWidth } = this.state;
    let selectedEstablishment = pass.company || pass.country;

    let name = t("invited");
    let user = getPassUser(pass, friends, token);
    if (user) {
      name = getUserFullname(user);
    }

    let beginDate = moment(new Date(pass.beginTimestamp * 1000));
    let endDate = moment(new Date(pass.endTimestamp * 1000));

    if (selectedEstablishment) {
      let timezone =
        selectedEstablishment.timezone != null
          ? selectedEstablishment.timezone
          : getTimezoneLocal();

      if (pass.beginDatetime) {
        beginDate = moment.tz(getDatetimeShort(pass.beginDatetime), timezone);
      }

      if (pass.endDatetime) {
        endDate = moment.tz(getDatetimeShort(pass.endDatetime), timezone);
      }
    }

    return (
      <div className="pass-request">
        <Dialog
          className="pass-request"
          fullWidth={true}
          fullScreen={isMobile}
          maxWidth="sm"
          onClose={onClose}
          TransitionProps={{ onExited }}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {t("pass_request")}
            <div className="dialog-title-button">
              <IconButton onClick={onClose} aria-label="delete">
                <CancelIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className="dialog" dividers>
            <ListItem className="user">
              <ListItemAvatar>
                <Images
                  src={getUserPhotoUrl(user)}
                  class_name="avatar"
                  avatar="1"
                />
              </ListItemAvatar>
              <div>
                <Typography className="pass-name">{name}</Typography>
              </div>
            </ListItem>
            <Divider />
            <MuiPickersUtilsProvider utils={CustomMomentUtils}>
              <form className={classes.root}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={this.setTextInputRef}
                    htmlFor="outlined-age-simple"
                  >
                    {t("choose_destination")}
                  </InputLabel>
                  <Select
                    value={selectedEstablishment}
                    labelWidth={destinationTextWidth}
                    inputProps={{
                      readOnly: true,
                    }}
                  >
                    {establishmentItems}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <DateTimePicker
                    readOnly={true}
                    format="LLLL"
                    inputVariant="outlined"
                    label={
                      <div>
                        {t("authorized_from")} ({t("establishment_hour")})
                      </div>
                    }
                    value={beginDate}
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <DateTimePicker
                    readOnly={true}
                    format="LLLL"
                    inputVariant="outlined"
                    label={
                      <div>
                        {t("authorized_until")} ({t("establishment_hour")})
                      </div>
                    }
                    value={endDate}
                  />
                </FormControl>

                <FormControl
                  component="fieldset"
                  className={classes.formControl + " radio"}
                >
                  <FormLabel component="legend">{t("guest_type")}</FormLabel>
                  <RadioGroup
                    aria-label="particular"
                    name="particular"
                    value={pass.guestsType}
                  >
                    <FormControlLabel
                      value={GUEST_TYPE_PARTICULAR}
                      control={<Radio color="primary" />}
                      label={t("particular")}
                    />
                    <FormControlLabel
                      value={GUEST_TYPE_PROVIDER}
                      control={<Radio color="primary" />}
                      label={t("provider")}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>

                {pass.comments && (
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <TextField
                      id="outlined-full-width"
                      label={t("comment")}
                      fullWidth
                      variant="outlined"
                      value={pass.comments}
                    />
                  </FormControl>
                )}
              </form>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <div className="loader">
              <Loader className="submit" visible={loading} />
            </div>
            {this.renderSubmitButtons()}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  token: state.authReducer.token,
  friends: state.userReducer.friends,
  loading: state.passesReducer.loading,
});

const actionCreators = {
  updateInvite: updateInvite,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(withBreakpoint()(PassRequest))));
