import PassAppApi from "../services/PassAppApi";
import SnackbarMessage from "../utils/SnackbarMessage";
import {
  PASS_UPDATING,
  PASS_UPDATED,
  PASS_CREATING,
  PASS_CREATED,
  PASS_GETTING,
  PASS_ACQUIRED,
  DYNAMIC_LINK_CREATED,
  DYNAMIC_LINK_ERROR,
  DYNAMIC_LINK_CLEAR,
  PASS_ERROR,
  PASS_MANAGER_LOAD,
  PASS_MANAGER_UPD,
  PASS_MANAGER_REMOVE,
  GET_FRIENDS,
  GLOBAL_OPTIONS_ERROR,
} from "./types";
import {
  onDialogClear,
  onDialogClose,
  onDialogExited,
  onDialogOpen,
} from "./dialog";
import { PASS_INVITE_PLACEHOLDER } from "../Constant";
import { getUserFullname } from "../utils/Utils";
import moment from "moment-timezone";
import PassCreatedDialog from "../components/PassCreatedDialog/PassCreatedDialog";
import { getInvitesAsHost, getAuthorizations } from "./user";

export const updateInvite = (
  pass,
  t,
  message,
  closeDialog = true,
  onSuccess,
  onError
) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  dispatch({ type: PASS_UPDATING });

  PassAppApi.updateInvite(token, pass)
    .then(() => {
      SnackbarMessage.show(
        "success",
        message ? message : t("pass_updated_successfully")
      );
      dispatch({ type: PASS_UPDATED });
      if (closeDialog) {
        dispatch(onDialogClear());
        dispatch(onDialogClose());
      }
      if (onSuccess) {
        onSuccess();
      }
      dispatch(getInvitesAsHost(t));
    })
    .catch((err) => {
      if (onError) {
        onError();
      }
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("invite_update_error")
      );
      dispatch({ type: PASS_UPDATED });
    });
};

export const createInvite = (pass, t, data) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  dispatch({ type: PASS_CREATING });

  PassAppApi.invite(token, pass)
    .then((pass) => {
      const { selectedEstablishment, beginDate, endDate } = data;
      let dialog = {
        establishment: selectedEstablishment.name,
        beginDate: beginDate,
        endDate: endDate,
      };

      if (pass.guests.length !== 0) {
        dispatch(getInvitesAsHost(t));
        dispatch({ type: PASS_CREATED });
        dispatch(onDialogExited());
      } else {
        let onCompleteActions = [
          onDialogOpen(PassCreatedDialog, dialog),
          { type: PASS_CREATED },
          getInvitesAsHost(t),
        ];

        dispatch(createDynamicLink(pass, t, data, onCompleteActions));
      }
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("invite_create_error")
      );
      dispatch({ type: PASS_ERROR });
    });
};

export const createDynamicLink = (pass, t, data, onCompleteActions = []) => (
  dispatch
) => {
  dispatch({ type: DYNAMIC_LINK_CLEAR });

  const { user, selectedEstablishment, beginDate, endDate, appStrings } = data;

  PassAppApi.generateDynamicLink(pass, user, selectedEstablishment)
    .then((link) => {
      const url = link.shortLink;

      let newLink = appStrings[PASS_INVITE_PLACEHOLDER];

      newLink =
        newLink
          .replace("%%USERNAME%%", getUserFullname(user))
          .replace("%%ESTABLISHMENT_NAME%%", selectedEstablishment.name)
          .replace("%%DATE_FROM%%", moment(beginDate).format("LLL"))
          .replace("%%DATE_TO%%", moment(endDate).format("LLL")) +
        "\n" +
        url;

      dispatch({ type: DYNAMIC_LINK_CREATED, payload: { link: newLink } });
      onCompleteActions.forEach((action) => {
        dispatch(action);
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("dynamic_link_error") + ": " + (err ? err.toString() : "")
      );
      dispatch({ type: DYNAMIC_LINK_ERROR });
    });
};

export const createAuthorization = (authorization, t) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  dispatch({ type: PASS_CREATING });

  PassAppApi.addAuthorization(token, authorization)
    .then(() => {
      SnackbarMessage.show("success", t("authorization_added"));
      dispatch(getAuthorizations(t));
      dispatch({ type: PASS_CREATED });
      dispatch(onDialogClear());
      dispatch(onDialogClose());
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_adding_authorization")
      );
      dispatch({ type: PASS_ERROR });
    });
};

export const updateAuthorization = (id, authorization, t) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  dispatch({ type: PASS_CREATING });

  PassAppApi.revokeAuthorization(token, id, "update")
    .then(() => {
      authorization["action"] = "update";
      PassAppApi.addAuthorization(token, authorization)
        .then(() => {
          SnackbarMessage.show("success", t("authorization_updated"));
          dispatch(getAuthorizations(t));
          dispatch({ type: PASS_CREATED });
          dispatch(onDialogClear());
          dispatch(onDialogClose());
        })
        .catch((err) => {
          SnackbarMessage.show(
            "error",
            err ? err.toString() : t("error_updating_authorization")
          );
          dispatch({ type: PASS_ERROR });
        });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_updating_authorization")
      );
      dispatch({ type: PASS_ERROR });
    });
};

export const revokeAuthorization = (id, t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  dispatch({ type: PASS_CREATING });

  PassAppApi.revokeAuthorization(token, id)
    .then(() => {
      SnackbarMessage.show("success", t("authorization_cancelled"));
      dispatch(getAuthorizations(t));
      dispatch(onDialogClear());
      dispatch(onDialogClose());
      dispatch({ type: PASS_CREATED });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_cancelling_authorization")
      );
      dispatch({ type: PASS_ERROR });
    });
};

export const getPassManagerList = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  dispatch({ type: PASS_GETTING });
  PassAppApi.getPassManager(token)
    .then((response) => {
      dispatch({
        type: PASS_MANAGER_LOAD,
        payload: { data: response.data },
      });
      dispatch({ type: PASS_ACQUIRED });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_PassManagerList")
      );
      dispatch({ type: PASS_ERROR });
    });
};

export const changePassStatus = (t, item, status) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  if (item.status + "ed" === status) {
    SnackbarMessage.show("error", t("pass_status_error_current"));
    return;
  }

  switch (status) {
    case "accept":
      if (!item.showAccept) {
        SnackbarMessage.show("error", t("pass_status_error_notAllowed"));
        return;
      }
      break;
    case "reject":
      if (!item.showReject) {
        SnackbarMessage.show("error", t("pass_status_error_notAllowed"));
        return;
      }
      break;
    case "cancel":
      if (!item.showCancel) {
        SnackbarMessage.show("error", t("pass_status_error_notAllowed"));
        return;
      }
      break;
    default:
  }

  PassAppApi.updateStatus(token, { id: item.id, status: status })
    .then(() => {
      /* Load the updated Item */
      PassAppApi.getPassManager(token, { id: item.id })
        .then((response) => {
          if (typeof response?.id != "undefined") {
            dispatch({
              type: PASS_MANAGER_UPD,
              payload: { obj: response },
            });
            SnackbarMessage.show("success", t("pass_status_changed"));
          } else {
            dispatch({
              type: PASS_MANAGER_REMOVE,
              payload: { id: item.id },
            });
          }
        })
        .catch((err) => {
          SnackbarMessage.show(
            "error",
            t("error_getting_PassManagerList") +
              ": " +
              (err ? err.toString() : "")
          );
        });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_update_PassManager") + ": " + (err ? err.toString() : "")
      );
      dispatch({ type: PASS_ERROR });
    });
};

export const togglePassParking = (t, item) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  if (typeof item === "undefined") {
    SnackbarMessage.show("error", t("undefined"));
    return;
  }
  if (item.status !== "accepted") {
    return;
  }

  PassAppApi.toggleStatus(token, {
    id: item.id,
    currentFreeParking: item.freeParking,
  })
    .then(() => {
      /* Load the updated Item */
      PassAppApi.getPassManager(token, { id: item.id })
        .then((response) => {
          if (response) {
            dispatch({
              type: PASS_MANAGER_UPD,
              payload: { obj: response },
            });
            SnackbarMessage.show("success", t("pass_parking_changed"));
          } else {
            dispatch({
              type: PASS_MANAGER_REMOVE,
              payload: { id: item.id },
            });
          }
        })
        .catch((err) => {
          SnackbarMessage.show(
            "error",
            t("error_getting_PassManagerList") +
              ": " +
              (err ? err.toString() : "")
          );
        });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_update_PassManager") + ": " + (err ? err.toString() : "")
      );
      dispatch({ type: PASS_ERROR });
    });
};

export const importContactsPhone = (contacts, { onSuccess, setLoading, t }) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  if (setLoading) setLoading(true);
  PassAppApi.importContacts(token, contacts)
    .then((response) => {
      if (onSuccess) onSuccess(response);
      PassAppApi.getFriends(token)
        .then((friends) => {
          dispatch({
            type: GET_FRIENDS,
            payload: friends,
          });
        })
        .catch((err) => {
          dispatch({ type: GLOBAL_OPTIONS_ERROR, payload: err });
          SnackbarMessage.show(
            "error",
            err ? err.toString() : t("friends_error")
          );
        });
    })
    .finally(() => {
      if (setLoading) setLoading(false);
    });
};
