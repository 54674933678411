import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import JoinCountry from "../../JoinCountry/JoinCountry";
import { onDialogOpen } from "../../../actions/dialog";

class Country extends React.Component {
  constructor(props) {
    super(props);

    this.openCountry = this.openCountry.bind(this);
  }

  openCountry() {
    this.props.onDialogOpen(JoinCountry, {
      establishment: this.props.establishment,
    });
  }

  render() {
    const { establishment, pending, t, icon } = this.props;

    let pendingText = pending ? " [" + t("pending") + "]" : "";

    return (
      <ListItem button onClick={this.openCountry}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={establishment.name + pendingText} />
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
});

const actionCreators = {
  onDialogOpen: onDialogOpen,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(Country));
