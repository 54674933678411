import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  FormControl,
  TextField,
  createStyles,
  FormHelperText,
} from "@material-ui/core";
import Button from "components/common/CustomButtons/Button.js";
import { onDialogClose, onDialogExited } from "../../actions/dialog";
import Loader from "../../utils/Loader";
import { withStyles } from "@material-ui/styles";
import { onFieldChange, sanitizeDni, isValidDni } from "../../utils/Utils";
import {
  joinCompany,
  clearPendingRequests,
} from "../../actions/establishments";
import { PENDING_NAME } from "../../Constant";
import person from "assets/img/person_placeholder.png";

import "./Profile.scss";
import { getCountryCode, getCountryName } from "../../lang/countries";
import CountrySelect from "../common/CountrySelect/CountrySelect";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { setEnableAssociation, updateUser } from "../../actions/auth";

import Picker from "components/common/Picker/Picker.js";
import Images from "components/common/Images/Images";
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: "",
      lastname: "",
      countryCode: "",
      dni: "",
      birthDate: new Date(),
      email: "",
      errors: {},
    };

    this.updateProfile = this.updateProfile.bind(this);
    this.onFieldChange = onFieldChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleBirthDate = this.handleBirthDate.bind(this);
    this.handleDniChange = this.handleDniChange.bind(this);
  }

  updateProfile(ev) {
    ev.preventDefault();

    const { t, setEnableAssociation } = this.props;
    const { firstname, lastname, countryCode, dni, birthDate } = this.state;

    if (this.isValidInfo()) {
      this.props
        .updateUser(
          {
            firstname: firstname,
            lastname: lastname,
            countryCode: countryCode,
            dni: dni,
            birthdate: moment(birthDate).format("DD-MM-YYYY"),
          },
          t
        )
        .then(() => {
          this.props.pendingRequests.forEach((f) => f());
          this.props.clearPendingRequests();
          setEnableAssociation(false);
        });
    }
  }

  isValidInfo() {
    let errors = {};
    const { birthDate } = this.state;

    if (!birthDate) {
      errors.birthDate = true;
    }

    this.setState({
      errors: errors,
    });
    return Object.entries(errors).length === 0;
  }

  handleBirthDate(date) {
    this.setState({
      birthDate: date,
    });
  }

  handleCountryChange(country) {
    this.setState({
      countryCode: getCountryCode(country),
    });
  }

  handleDniChange(ev) {
    const { t } = this.props;
    const { errors } = this.state;

    errors.dni = null;
    if (!isValidDni(ev.target.value)) {
      errors.dni = t("invalid_dni");
    }

    this.setState({
      dni: sanitizeDni(ev.target.value),
    });
  }

  componentDidMount() {
    const { user, setEnableAssociation } = this.props;
    setEnableAssociation(true);
    this.setState({
      firstname: user.firstname === PENDING_NAME ? "" : user.firstname,
      lastname: user.lastname === PENDING_NAME ? "" : user.lastname,
      countryCode: user.countryCode,
      dni: user.dni === "-1" ? "" : user.dni,
      birthDate: user.birthdate ? moment(user.birthdate, "DD-MM-YYYY") : null,
      email: user.email,
    });
  }

  renderSubmitButtons() {
    const { t, loading } = this.props;

    return (
      <Button onClick={this.onSubmit} disabled={loading} round color="primary">
        {t("join")}
      </Button>
    );
  }

  render() {
    const { t, user, classes, loading } = this.props;
    const { firstname, lastname, countryCode, dni, email, errors } = this.state;

    let facePhoto = person;
    if (user.facePhoto) {
      facePhoto = process.env.REACT_APP_IMAGES_URL + user.facePhoto;
    }

    return (
      <div className="profile">
        <div className="avatar">
          <Images src={facePhoto} avatar="1"></Images>
        </div>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <form onSubmit={this.updateProfile}>
            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl}
            >
              <TextField
                error={false}
                label={t("name")}
                name="firstname"
                inputProps={{ autoComplete: "new-password" }}
                required
                onChange={this.onFieldChange}
                value={firstname}
              />
            </FormControl>

            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl}
            >
              <TextField
                error={false}
                label={t("lastName")}
                name="lastname"
                inputProps={{ autoComplete: "new-password" }}
                required
                onChange={this.onFieldChange}
                value={lastname}
              />
            </FormControl>

            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl}
              error={errors.dni ? true : false}
            >
              <TextField
                error={false}
                label={t("dni_number")}
                name="dni"
                inputProps={{ autoComplete: "new-password" }}
                required
                onChange={this.handleDniChange}
                value={dni}
              />
              {<FormHelperText>{errors.dni}</FormHelperText>}
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <CountrySelect
                onCountrySelected={this.handleCountryChange}
                value={getCountryName(countryCode)}
              />
            </FormControl>

            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl}
              error={errors.birthDate ? true : false}
            >
              <Picker
                disableFuture={new Date()}
                format="DD-MM-YYYY"
                label={t("birth_date")}
                value={user.birthdate}
                onChange={this.handleBirthDate}
                t={t}
              />
              {errors.birthDate && (
                <FormHelperText>{t("please_specify_birthdate")}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl}
            >
              <FormControl
                component="fieldset"
                fullWidth
                className={classes.formControl}
              >
                <TextField
                  label={t("email")}
                  disabled={true}
                  value={email}
                  onChange={this.handleBirthDate}
                />
              </FormControl>
            </FormControl>

            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl + " add "}
            >
              <Button color="primary" type="submit" disabled={loading} round>
                {t("confirm")}
              </Button>
              <div>
                <Loader className="submit" visible={loading} />
              </div>
            </FormControl>
          </form>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}
const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  officeBuildings: state.userReducer.officeBuildings,
  establishment: state.dialogReducer.params.establishment,
  loading: state.authReducer.loading,
  modeAdm: state.authReducer.modeAdm,
  pendingRequests: state.establishmentReducer.pendingRequests,
});

const actionCreators = {
  onDialogClose: onDialogClose,
  onDialogExited: onDialogExited,
  joinCompany: joinCompany,
  updateUser: updateUser,
  clearPendingRequests: clearPendingRequests,
  setEnableAssociation: setEnableAssociation,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(Profile)));
