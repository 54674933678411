import PassAppApi from "../services/PassAppApi";
import {
  GLOBAL_OPTIONS_ERROR,
  GLOBAL_OPTIONS_REQUEST,
  GLOBAL_OPTIONS_SUCCESS,
  HOST_INVITES_SUCCESS,
  HOST_INVITES_ERROR,
  AUTHORIZATIONS_ERROR,
  AUTHORIZATIONS_SUCCESS,
  NOTIFICATIONS_FINISHED,
  NOTIFICATIONS_ERROR,
  GET_FRIENDS,
  SET_CUSTOM_EXTERNAL_USER,
} from "./types";
import SnackbarMessage from "utils/SnackbarMessage";
import { setAllowFaceIdExternalUser } from "./auth";

export const getGlobalOptions = (t) => (dispatch, getState) => {
  const { token, user } = getState().authReducer;

  dispatch({ type: GLOBAL_OPTIONS_REQUEST });

  PassAppApi.getCustomization(token).then((response) => {
    dispatch({
      type: SET_CUSTOM_EXTERNAL_USER,
      payload: response,
    });
  });

  PassAppApi.getGlobalOptions()
    .then((opts) => {
      dispatch({
        type: GLOBAL_OPTIONS_SUCCESS,
        payload: {
          appStrings: opts.appStrings,
          companies: opts.companies,
          countries: opts.countries,
          buildingsGroups: opts.buildingsGroups,
          officeBuildings: opts.officeBuildings,
          professionTypes: opts.professionTypes,
        },
      });
      let isAllow = false;
      isAllow = opts.countries.some(
        (c) => user?.residences?.some((r) => r === c.id) && c?.allowFaceId
      );
      dispatch(setAllowFaceIdExternalUser(isAllow));
    })
    .catch((err) => {
      dispatch({ type: GLOBAL_OPTIONS_ERROR, payload: err });
      SnackbarMessage.show("error", t("global_options_error"));
    });

  PassAppApi.getFriends(token)
    .then((friends) => {
      dispatch({
        type: GET_FRIENDS,
        payload: friends,
      });
    })
    .catch((err) => {
      dispatch({ type: GLOBAL_OPTIONS_ERROR, payload: err });
      SnackbarMessage.show("error", err ? err.toString() : t("friends_error"));
    });
};

export const getAuthorizations = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  PassAppApi.getAuthorizations(token)
    .then((authorizations) => {
      dispatch({ type: AUTHORIZATIONS_SUCCESS, payload: authorizations });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_authorizations")
      );
      dispatch({ type: AUTHORIZATIONS_ERROR });
    });
};

export const getInvitesAsHost = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  PassAppApi.getInvitesAsHost(token)
    .then((passes) => {
      dispatch({ type: HOST_INVITES_SUCCESS, payload: passes });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_passes")
      );
      dispatch({ type: HOST_INVITES_ERROR, payload: err });
    });
};

export const getNotifications = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  PassAppApi.getNotifications(token)
    .then((notifications) => {
      dispatch({ type: NOTIFICATIONS_FINISHED, payload: notifications });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_notifications")
      );
      dispatch({ type: NOTIFICATIONS_ERROR });
    });
};

export const setFriends = (friends) => (dispatch) => {
  dispatch({
    type: GET_FRIENDS,
    payload: friends,
  });
};
