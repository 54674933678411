import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import { TextField, FormControl, Button } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DesksTable from "../DesksTables";
import { columns } from "../columns/reservationsByDesks";
import { getUserEstablishments, getTimezoneLocal } from "../../../utils/Utils";
import {
  getDeskReservationsByDesks,
  filterList,
  rejectDeskReservation,
} from "../../../actions/desk";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ByDesks extends React.Component {
  constructor(props) {
    super(props);
    const { t, user, companies, company } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmReject = this.handleConfirmReject.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

    let userEstablishments = getUserEstablishments(user, companies, []);
    let selectedEstablishment = userEstablishments.find(
      (e) => e.id === company
    );
    let timezone =
      selectedEstablishment && selectedEstablishment.timezone
        ? selectedEstablishment.timezone
        : getTimezoneLocal();

    let date = moment().tz(timezone);

    this.state = {
      searchText: "",
      timezone: timezone,
      date: date,
      itemID: null,
      showConfirm: false,
      globalPageIndex: 0,
    };
    this.props.getList(t, date.format("YYYY-MM-DD"));
  }

  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }

  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        if (v.name && v.name.search(new RegExp(text, "i")) > -1) return true;
        if (v.user && v.user.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }

  handleDateChange(date) {
    if (date && date.isValid()) {
      this.setState({ date: date });
    }
  }

  handleFilter() {
    const { t } = this.props;
    const { date } = this.state;

    if (date) {
      this.props.getList(t, date.format("YYYY-MM-DD"));
    }
  }

  handleCancel(itemID) {
    this.setState({ showConfirm: true, itemID: itemID });
  }

  handleConfirmReject() {
    const { itemID, date } = this.state;
    this.props.rejectDeskReservation(
      this.props.t,
      itemID,
      date.format("YYYY-MM-DD")
    );
    this.closeConfirm();
  }

  closeConfirm() {
    this.setState({ showConfirm: false, itemID: null });
  }

  render() {
    const { t, list, handleList, loading } = this.props;
    const { searchText, showConfirm, date, globalPageIndex } = this.state;

    const dataExport = list.map((item) => {
      return {
        name: item.name,
        adminComment: item.adminComment,
        status: t("desk_" + item.status),
        user: item.user,
      };
    });

    return (
      <div className="body-box">
        <div className="search-box-2">
          <div className="column-left cols-1">
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
              <div className="filter_field">
                <label>{t("date")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={date}
                    onChange={(date) => this.handleDateChange(date)}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
            </MuiPickersUtilsProvider>
            <div className="filter_field">
              <Button onClick={this.handleFilter} className="filterButton">
                {t("filter")}
              </Button>
            </div>
          </div>
          <div className="column-rigth">
            <Button onClick={handleList}>{t("change_list_to_details")}</Button>
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name="Usuarios">
                <ExcelColumn label={t("desk")} value="name" />
                <ExcelColumn label={t("status")} value="status" />
                <ExcelColumn label={t("user")} value="user" />
                <ExcelColumn label={t("admin_comment")} value="adminComment" />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
        </div>
        <DesksTable
          data={list}
          columns={columns}
          handleCancel={(data) => this.handleCancel(data)}
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></DesksTable>
        <ConfirmationDialog
          open={showConfirm}
          onConfirm={this.handleConfirmReject}
          onClose={this.closeConfirm}
          title={t("confirm_reject_DeskReserv")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  company: state.authReducer.user.parkingManager.company,
  companies: state.userReducer.companies,
  list: state.desks.currentList,
  listAll: state.desks.reservationsByDeskLots,
  DeskGroups: state.desks.listGroups,
  loading: state.desks.loading,
});

const mapActionsToProps = {
  getList: getDeskReservationsByDesks,
  filterList,
  rejectDeskReservation,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(ByDesks));
