import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Images from "../common/Images/Images";

const useStyles = () => ({
  fullImg: {
    width: "max-content",
    margin: "auto",
  },
  title: {
    flex: 1,
    marginLeft: "16px",
  },
  mediumImg: {
    width: "100%",
  },
  box: {
    width: "31%",
    padding: "1%",
    float: "left",
  },
});
class ImgDiag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { classes, src, title } = this.props;
    if (!src || src === "" || typeof src === undefined) return "";

    return (
      <div className={classes.box}>
        <Images
          src={process.env.REACT_APP_IMAGES_URL + src}
          class_name={classes.mediumImg}
          alt={title}
          handleClick={() => this.handleOpen()}
        />
        <Dialog fullScreen open={open} onClose={this.handleClose}>
          <AppBar className="">
            <Toolbar>
              <h4 className={classes.title}>{title}</h4>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Images
            src={process.env.REACT_APP_IMAGES_URL + src}
            class_name={classes.fullImg}
            alt={title}
          />
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(ImgDiag);
