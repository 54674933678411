import { createStyles, Drawer, Hidden, withStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { logout } from "../../actions/auth";
import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { clearStorage } from "../../utils/Store";
import Brand from "../common/Sidebar/Brand/Brand";
import MenuList from "../common/Sidebar/MenuList/MenuList";
import NavProfile from "../NavProfile/NavProfile";
const Sidebar = (props) => {
  const {
    logoutUser,
    classes,
    image,
    t,
    open,
    handleDrawerToggle,
    otherCompany,
  } = props;
  const logout = () => {
    const { t } = props;
    logoutUser(t);
    clearStorage();
    window.location.replace("/");
  };
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerApp]: otherCompany?.isExternalUser,
            }),
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.sidebarWrapper}>
            <NavProfile />
            <MenuList handleDrawerToggle={handleDrawerToggle} />
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
          <Brand />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerApp]: otherCompany?.isExternalUser,
            }),
          }}
        >
          <div className={classes.sidebarWrapper}>
            <NavProfile />
            <MenuList />
          </div>
          {/* <div className={classes.background} /> */}
          <Brand />
          {!otherCompany?.isExternalUser && (
            <NavLink to={"/logout"} onClick={logout} className={classes.logout}>
              {t("logout")}
            </NavLink>
          )}
        </Drawer>
      </Hidden>
    </div>
  );
};
const useStyles = createStyles(styles);
const mapStateToProps = (state) => ({
  otherCompany: state.authReducer.otherCompany,
});
const actionCreators = {
  logoutUser: logout,
};
export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(Sidebar)));
