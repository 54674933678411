import React, { useEffect } from "react";
import "./CharterReserveMain.scss";
import { Box, List, Fab } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import PassAppApi from "services/PassAppApi";
import SnackbarMessage from "utils/SnackbarMessage";
import {
  CHARTER_SELECTED_PARAMS,
  CHARTER_SELECTED_TOKEN,
  GET_USER_ERROR,
  CHARTER_COMPANY_NAME,
  CHARTERS_AVAILABILITY_REQUEST,
} from "actions/types";
import { CHARTER_UPDATE_DATA } from "actions/types";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import ItemReserveDelete from "components/ItemReserveDelete/ItemReserveDelete";
import { Add } from "@material-ui/icons";
import CharterReserve from "views/CharterReserve/CharterReserve";
import { CHARTERS_RESERVATIONS_REQUEST } from "actions/types";
const qs = require("query-string");

const CharterReserveMain = () => {
  const [loadingData, setLoadingData] = React.useState(true);
  const [tokenGenerated, setTokenGenerated] = React.useState("");
  const [percentage, setPercentage] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [chartersAvailability, setChartersAvailability] = React.useState([]);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [
    charterReservationActive,
    setCharterReservationActive,
  ] = React.useState([]);
  const [paramsUrl, setParamsUrl] = React.useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let location = useLocation();
  useEffect(() => {
    getParams();
  }, []);
  useEffect(() => {
    if (paramsUrl.token) {
      loginToken(paramsUrl.token);
    }
  }, [paramsUrl]);
  useEffect(() => {
    if (tokenGenerated && paramsUrl) {
      //Aqui iban dos funciones para obtener datos
      getCharterAvailability(tokenGenerated, paramsUrl.company);
      getCharterReservations(tokenGenerated, paramsUrl.company);
      setTimeout(() => {
        setLoadingData(false);
        dispatch({
          type: CHARTER_UPDATE_DATA,
          payload: false,
        });
      }, 1000);
      clearTimeout();
    }
  }, [tokenGenerated, paramsUrl]);
  useEffect(() => {
    if (
      chartersAvailability.length > 0 &&
      charterReservationActive.length > 0 &&
      loadingData
    ) {
      addPropToCharterReservations();
    }
  }, [chartersAvailability, charterReservationActive]);
  const getParams = () => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setParamsUrl(params);
    dispatch({
      type: CHARTER_SELECTED_PARAMS,
      payload: params,
    });
  };
  const getUser = (token) => {
    PassAppApi.getUser(token)
      .then((opts) => {
        let company = opts.allowedCharterCompanies.find(
          (a) => a.id === paramsUrl.company
        );
        setCompanyName(company.name);
        dispatch({
          type: CHARTER_COMPANY_NAME,
          payload: company.name,
        });
      })
      .catch((err) => {
        dispatch({ type: GET_USER_ERROR, payload: err });
        SnackbarMessage.show(
          "error",
          err ? err.toString() : "error_getting_user_info"
        );
      });
  };
  const loginToken = (token) => {
    try {
      PassAppApi.checkExternalUserToken(token).then((data) => {
        setTokenGenerated(data.token);
        dispatch({
          type: CHARTER_SELECTED_TOKEN,
          payload: data.token,
        });
        getUser(data.token);
      });
    } catch (err) {
      SnackbarMessage.show(
        "error",
        "error loginToken" + ": " + (err ? err.toString() : "")
      );
    }
  };
  const getCharterReservations = (tokenGenerated, company) => {
    if (tokenGenerated !== "") {
      PassAppApi.getCharterReservations(tokenGenerated, company)
        .then((opts) => {
          setCharterReservationActive(opts.charterReservations);
          setPercentage(opts.percentage);
          dispatch({
            type: CHARTERS_RESERVATIONS_REQUEST,
            payload: opts.charterReservations,
          });
        })
        .catch((err) => {
          SnackbarMessage.show(
            "error",
            "error GetReservations" + ": " + (err ? err.toString() : "")
          );
        });
    }
  };
  const getCharterAvailability = (tokenGenerated, company) => {
    if (tokenGenerated !== "") {
      PassAppApi.getChartersAvailability(tokenGenerated, company)
        .then((opts) => {
          setChartersAvailability(opts);
          dispatch({
            type: CHARTERS_AVAILABILITY_REQUEST,
            payload: opts,
          });
        })
        .catch((err) => {
          SnackbarMessage.show(
            "error",
            "error GetAvailability" + ": " + (err ? err.toString() : "")
          );
        });
    }
  };
  const addPropToCharterReservations = () => {
    charterReservationActive.forEach((res) => {
      const result = chartersAvailability.find(
        (ava) => res.charter === ava.charter.id
      );
      res.name = result.charter.name;
      res.hour = result.charter.hour;
    });
    charterReservationActive.sort(
      (x, y) => Date.parse(x.date) - Date.parse(y.date)
    );
  };
  return (
    <div className="root">
      {loadingData ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ flexGrow: 1 }}
        >
          <Grid item></Grid>
        </Grid>
      ) : showCalendar ? (
        <CharterReserve
          tokenGenerated={tokenGenerated}
          companyId={paramsUrl.company}
          companyName={companyName}
        />
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ paddingTop: "0.5em" }}
                textAlign="center"
                variant="h2"
                fontWeight={700}
                component={"span"}
              >
                {percentage}%
              </Typography>
              <Typography
                textAlign="center"
                variant="h6"
                fontWeight={700}
                component={"span"}
              >
                {t("charter_reserve_use_compliance")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              style={{ marginBottom: "4em", height: "100%" }}
            >
              <Grid item xs={12}>
                <Box>
                  {charterReservationActive.length ? (
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 400,
                        bgcolor: "background.paper",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader2"
                    >
                      {charterReservationActive.map((o, i) => {
                        return (
                          <ItemReserveDelete
                            data={o}
                            key={i}
                            tokenGenerated={tokenGenerated}
                            charterReservationActive={charterReservationActive}
                            setCharterReservationActive={
                              setCharterReservationActive
                            }
                            company={paramsUrl.company}
                          />
                        );
                      })}
                    </List>
                  ) : (
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ marginTop: "4em" }}
                    >
                      <Grid item>
                        <Typography
                          textAlign="center"
                          variant="h5"
                          fontWeight={300}
                          component={"span"}
                        >
                          {t("charter_reserve_no_reservations")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <Box>
                  <Fab
                    color="primary"
                    onClick={() => setShowCalendar(true)}
                    aria-label="add"
                    style={{
                      color: "white",
                      width: "5em",
                      height: "5em",
                      position: "fixed",
                      right: "1rem",
                      bottom: "1rem",
                      zIndex: "20",
                    }}
                  >
                    <Add fontSize="large" />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default CharterReserveMain;
