export const COUNTRY_NAMES = [
  { name: "Andorra", code: "AND" },
  { name: "United Arab Emirates", code: "ARE" },
  { name: "Afghanistan", code: "AFG" },
  { name: "Antigua and Barbuda", code: "ATG" },
  { name: "Anguilla", code: "AIA" },
  { name: "Albania", code: "ALB" },
  { name: "Armenia", code: "ARM" },
  { name: "Netherlands Antilles", code: "ANT" },
  { name: "Angola", code: "AGO" },
  { name: "Antarctica", code: "ATA" },
  { name: "Argentina", code: "ARG" },
  { name: "American Samoa", code: "ASM" },
  { name: "Austria", code: "AUT" },
  { name: "Australia", code: "AUS" },
  { name: "Aruba", code: "ABW" },
  { name: "Åland Islands", code: "ALA" },
  { name: "Azerbaijan", code: "AZE" },
  { name: "Bosnia and Herzegovina", code: "BIH" },
  { name: "Barbados", code: "BRB" },
  { name: "Bangladesh", code: "BGD" },
  { name: "Belgium", code: "BEL" },
  { name: "Burkina Faso", code: "BFA" },
  { name: "Bulgaria", code: "BGR" },
  { name: "Bahrain", code: "BHR" },
  { name: "Burundi", code: "BDI" },
  { name: "Benin", code: "BEN" },
  { name: "Saint Barthélemy", code: "BLM" },
  { name: "Bermuda", code: "BMU" },
  { name: "Brunei", code: "BRN" },
  { name: "Bolivia", code: "BOL" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BES" },
  { name: "Brazil", code: "BRA" },
  { name: "Bahamas", code: "BHS" },
  { name: "Bhutan", code: "BTN" },
  { name: "Bouvet Island", code: "BVT" },
  { name: "Botswana", code: "BWA" },
  { name: "Belarus", code: "BLR" },
  { name: "Belize", code: "BLZ" },
  { name: "Canada", code: "CAN" },
  { name: "Cocos Islands", code: "CCK" },
  { name: "The Democratic Republic Of Congo", code: "COD" },
  { name: "Central African Republic", code: "CAF" },
  { name: "Congo", code: "COG" },
  { name: "Switzerland", code: "CHE" },
  { name: "Cook Islands", code: "COK" },
  { name: "Chile", code: "CHL" },
  { name: "Cameroon", code: "CMR" },
  { name: "China", code: "CHN" },
  { name: "Colombia", code: "COL" },
  { name: "Costa Rica", code: "CRI" },
  { name: "Cuba", code: "CUB" },
  { name: "Cape Verde", code: "CPV" },
  { name: "Curaçao", code: "CUW" },
  { name: "Christmas Island", code: "CXR" },
  { name: "Cyprus", code: "CYP" },
  { name: "Czech Republic", code: "CZE" },
  { name: "Germany", code: "DEU" },
  { name: "Djibouti", code: "DJI" },
  { name: "Denmark", code: "DNK" },
  { name: "Dominica", code: "DMA" },
  { name: "Dominican Republic", code: "DOM" },
  { name: "Algeria", code: "DZA" },
  { name: "Ecuador", code: "ECU" },
  { name: "Estonia", code: "EST" },
  { name: "Egypt", code: "EGY" },
  { name: "Western Sahara", code: "ESH" },
  { name: "Eritrea", code: "ERI" },
  { name: "Spain", code: "ESP" },
  { name: "Ethiopia", code: "ETH" },
  { name: "Finland", code: "FIN" },
  { name: "Fiji", code: "FJI" },
  { name: "Falkland Islands", code: "FLK" },
  { name: "Micronesia", code: "FSM" },
  { name: "Faroe Islands", code: "FRO" },
  { name: "France", code: "FRA" },
  { name: "Gabon", code: "GAB" },
  { name: "United Kingdom", code: "GBR" },
  { name: "Grenada", code: "GRD" },
  { name: "Georgia", code: "GEO" },
  { name: "French Guiana", code: "GUF" },
  { name: "Guernsey", code: "GGY" },
  { name: "Ghana", code: "GHA" },
  { name: "Gibraltar", code: "GIB" },
  { name: "Greenland", code: "GRL" },
  { name: "Gambia", code: "GMB" },
  { name: "Guinea", code: "GIN" },
  { name: "Guadeloupe", code: "GLP" },
  { name: "Equatorial Guinea", code: "GNQ" },
  { name: "Greece", code: "GRC" },
  { name: "South Georgia And The South Sandwich Islands", code: "SGS" },
  { name: "Guatemala", code: "GTM" },
  { name: "Guam", code: "GUM" },
  { name: "Guinea-Bissau", code: "GNB" },
  { name: "Guyana", code: "GUY" },
  { name: "Hong Kong", code: "HKG" },
  { name: "Heard Island And McDonald Islands", code: "HMD" },
  { name: "Honduras", code: "HND" },
  { name: "Croatia", code: "HRV" },
  { name: "Haiti", code: "HTI" },
  { name: "Hungary", code: "HUN" },
  { name: "Indonesia", code: "IDN" },
  { name: "Ireland", code: "IRL" },
  { name: "Israel", code: "ISR" },
  { name: "Isle Of Man", code: "IMN" },
  { name: "India", code: "IND" },
  { name: "British Indian Ocean Territory", code: "IOT" },
  { name: "Iraq", code: "IRQ" },
  { name: "Iran", code: "IRN" },
  { name: "Iceland", code: "ISL" },
  { name: "Italy", code: "ITA" },
  { name: "Jersey", code: "JEY" },
  { name: "Jamaica", code: "JAM" },
  { name: "Jordan", code: "JOR" },
  { name: "Japan", code: "JPN" },
  { name: "Kenya", code: "KEN" },
  { name: "Kyrgyzstan", code: "KGZ" },
  { name: "Cambodia", code: "KHM" },
  { name: "Kiribati", code: "KIR" },
  { name: "Comoros", code: "COM" },
  { name: "Saint Kitts And Nevis", code: "KNA" },
  { name: "North Korea", code: "PRK" },
  { name: "South Korea", code: "KOR" },
  { name: "Kuwait", code: "KWT" },
  { name: "Cayman Islands", code: "CYM" },
  { name: "Kazakhstan", code: "KAZ" },
  { name: "Laos", code: "LAO" },
  { name: "Lebanon", code: "LBN" },
  { name: "Saint Lucia", code: "LCA" },
  { name: "Liechtenstein", code: "LIE" },
  { name: "Sri Lanka", code: "LKA" },
  { name: "Liberia", code: "LBR" },
  { name: "Lesotho", code: "LSO" },
  { name: "Lithuania", code: "LTU" },
  { name: "Luxembourg", code: "LUX" },
  { name: "Latvia", code: "LVA" },
  { name: "Libya", code: "LBY" },
  { name: "Morocco", code: "MAR" },
  { name: "Monaco", code: "MCO" },
  { name: "Moldova", code: "MDA" },
  { name: "Montenegro", code: "MNE" },
  { name: "Saint Martin", code: "MAF" },
  { name: "Madagascar", code: "MDG" },
  { name: "Marshall Islands", code: "MHL" },
  { name: "Macedonia", code: "MKD" },
  { name: "Mali", code: "MLI" },
  { name: "Myanmar", code: "MMR" },
  { name: "Mongolia", code: "MNG" },
  { name: "Macao", code: "MAC" },
  { name: "Northern Mariana Islands", code: "MNP" },
  { name: "Martinique", code: "MTQ" },
  { name: "Mauritania", code: "MRT" },
  { name: "Montserrat", code: "MSR" },
  { name: "Malta", code: "MLT" },
  { name: "Mauritius", code: "MUS" },
  { name: "Maldives", code: "MDV" },
  { name: "Malawi", code: "MWI" },
  { name: "Mexico", code: "MEX" },
  { name: "Malaysia", code: "MYS" },
  { name: "Mozambique", code: "MOZ" },
  { name: "Namibia", code: "NAM" },
  { name: "New Caledonia", code: "NCL" },
  { name: "Niger", code: "NER" },
  { name: "Norfolk Island", code: "NFK" },
  { name: "Nigeria", code: "NGA" },
  { name: "Nicaragua", code: "NIC" },
  { name: "Netherlands", code: "NLD" },
  { name: "Norway", code: "NOR" },
  { name: "Nepal", code: "NPL" },
  { name: "Nauru", code: "NRU" },
  { name: "Niue", code: "NIU" },
  { name: "New Zealand", code: "NZL" },
  { name: "Oman", code: "OMN" },
  { name: "Panama", code: "PAN" },
  { name: "Peru", code: "PER" },
  { name: "French Polynesia", code: "PYF" },
  { name: "Papua New Guinea", code: "PNG" },
  { name: "Philippines", code: "PHL" },
  { name: "Pakistan", code: "PAK" },
  { name: "Poland", code: "POL" },
  { name: "Saint Pierre And Miquelon", code: "SPM" },
  { name: "Pitcairn", code: "PCN" },
  { name: "Puerto Rico", code: "PRI" },
  { name: "Palestine", code: "PSE" },
  { name: "Portugal", code: "PRT" },
  { name: "Palau", code: "PLW" },
  { name: "Paraguay", code: "PRY" },
  { name: "Qatar", code: "QAT" },
  { name: "Reunion", code: "REU" },
  { name: "Romania", code: "ROU" },
  { name: "Serbia", code: "SRB" },
  { name: "Russia", code: "RUS" },
  { name: "Rwanda", code: "RWA" },
  { name: "Saudi Arabia", code: "SAU" },
  { name: "Solomon Islands", code: "SLB" },
  { name: "Seychelles", code: "SYC" },
  { name: "Sudan", code: "SDN" },
  { name: "Sweden", code: "SWE" },
  { name: "Singapore", code: "SGP" },
  { name: "Saint Helena", code: "SHN" },
  { name: "Slovenia", code: "SVN" },
  { name: "Svalbard And Jan Mayen", code: "SJM" },
  { name: "Slovakia", code: "SVK" },
  { name: "Sierra Leone", code: "SLE" },
  { name: "San Marino", code: "SMR" },
  { name: "Senegal", code: "SEN" },
  { name: "Somalia", code: "SOM" },
  { name: "Suriname", code: "SUR" },
  { name: "South Sudan", code: "SSD" },
  { name: "Sao Tome And Principe", code: "STP" },
  { name: "El Salvador", code: "SLV" },
  { name: "Sint Maarten (Dutch part)", code: "SXM" },
  { name: "Syria", code: "SYR" },
  { name: "Swaziland", code: "SWZ" },
  { name: "Turks And Caicos Islands", code: "TCA" },
  { name: "Chad", code: "TCD" },
  { name: "French Southern Territories", code: "ATF" },
  { name: "Togo", code: "TGO" },
  { name: "Thailand", code: "THA" },
  { name: "Tajikistan", code: "TJK" },
  { name: "Tokelau", code: "TKL" },
  { name: "Timor-Leste", code: "TLS" },
  { name: "Turkmenistan", code: "TKM" },
  { name: "Tunisia", code: "TUN" },
  { name: "Tonga", code: "TON" },
  { name: "Turkey", code: "TUR" },
  { name: "Trinidad and Tobago", code: "TTO" },
  { name: "Tuvalu", code: "TUV" },
  { name: "Taiwan", code: "TWN" },
  { name: "Tanzania", code: "TZA" },
  { name: "Ukraine", code: "UKR" },
  { name: "Uganda", code: "UGA" },
  { name: "United States Minor Outlying Islands", code: "UMI" },
  { name: "United States", code: "USA" },
  { name: "Uruguay", code: "URY" },
  { name: "Uzbekistan", code: "UZB" },
  { name: "Vatican", code: "VAT" },
  { name: "Saint Vincent And The Grenadines", code: "VCT" },
  { name: "Venezuela", code: "VEN" },
  { name: "British Virgin Islands", code: "VGB" },
  { name: "U.S. Virgin Islands", code: "VIR" },
  { name: "Vietnam", code: "VNM" },
  { name: "Vanuatu", code: "VUT" },
  { name: "Wallis And Futuna", code: "WLF" },
  { name: "Samoa", code: "WSM" },
  { name: "Yemen", code: "YEM" },
  { name: "Mayotte", code: "MYT" },
  { name: "South Africa", code: "ZAF" },
  { name: "Zambia", code: "ZMB" },
  { name: "Zimbabwe", code: "ZWE" },
];

export function getCountryCode(countryName) {
  let country = COUNTRY_NAMES.find((c) => c.name === countryName);
  if (country) {
    return country.code;
  }
  return null;
}
export function getCountryName(countryCode) {
  let country = COUNTRY_NAMES.find((c) => c.code === countryCode);
  if (country) {
    return country.name;
  }
  return null;
}
