import React from "react";

import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
} from "@material-ui/core";
import {
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import CellActions from "./CellActions";
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import GlobalFilter from "components/common/Table/GlobalFilter";

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

const ValidatePassesTable = ({
  columns,
  data,
  changeStatus,
  toggleParking,
  handleOpenComment,
  t,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    () => {}
  );
  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };
  // Render the UI for your table

  const getColumnProp = (column, headerData) => {
    return Object.assign({}, column, { title: t(headerData.Header) });
  };

  return (
    <>
      <div className="header-box">
        <h1 className="title">{t("validate_passes")}</h1>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          t={t}
        />
      </div>
      <div className="body-box scroll">
        <TableContainer className="expand-height">
          <MaUTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, i) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, j) => (
                    <TableCell
                      {...getColumnProp(
                        column.getHeaderProps(column.getSortByToggleProps()),
                        headerGroup.headers[j]
                      )}
                      key={j}
                      className={j === 0 ? "fist-col col-left" : ""}
                    >
                      <span className="cell-text">
                        {t(`${column.render("Header")}`)}
                      </span>
                      <TableSortLabel
                        active={column.isSorted}
                        // react-table has a unsorted state which is not treated here
                        direction={column.isSortedDesc ? "desc" : "asc"}
                      />
                      {!column.isSorted && (
                        <SyncAltIcon className="colSort"></SyncAltIcon>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, j) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          key={j}
                          className={j === 0 ? "fist-col col-left" : ""}
                        >
                          <CellActions
                            data={cell}
                            handleChangeStatus={changeStatus}
                            handleToggleParking={toggleParking}
                            handleOpenComment={handleOpenComment}
                            rowData={row}
                            t={t}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: t("all"), value: data.length },
                  ]}
                  colSpan={9}
                  count={data.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={(params) => {
                    return t("pagination")
                      .replace("%%FROM%%", params.from)
                      .replace("%%TO%%", params.to)
                      .replace("%%TOTAL%%", params.count);
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </MaUTable>
        </TableContainer>
      </div>
    </>
  );
};

ValidatePassesTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  changeStatus: PropTypes.func,
};

export default ValidatePassesTable;
