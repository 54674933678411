import {
  defaultFont,
  infoColor,
  warningColor,
  dangerColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const typographyStyle = (theme) => ({
  defaultFontStyle: {
    ...defaultFont,
    fontSize: "0.8rem",
  },
  defaultHeaderMargins: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  quote: {
    padding: "10px 20px",
    margin: "0 0 20px",
    fontSize: "17.5px",
    borderLeft: "5px solid " + grayColor[10],
  },
  quoteText: {
    margin: "0 0 10px",
    fontStyle: "italic",
  },
  quoteAuthor: {
    display: "block",
    fontSize: "80%",
    lineHeight: "1.42857143",
    color: grayColor[1],
  },
  mutedText: {
    color: grayColor[1],
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  infoText: {
    color: infoColor[0],
  },
  successText: {
    color: "#00d809",
  },
  warningText: {
    color: warningColor[0],
  },
  dangerText: {
    color: dangerColor[0],
  },
});

export default typographyStyle;
