export const columns = [
  {
    Header: "reserv_create_date",
    accessor: "creationDate",
    class: "cell-text-br",
  },
  {
    Header: "reserv_date",
    accessor: "date",
    class: "cell-text-br",
  },
  {
    Header: "reserv_user_employee",
    accessor: "user",
    class: "cell-text-br",
  },
  {
    Header: "reserv_parkingLot",
    accessor: "parkingLot",
    class: "cell-text-br",
  },
  {
    Header: "status",
    accessor: "status",
    action: "translate",
  },
  {
    Header: "reserv_modification_date",
    accessor: "modificationDate",
    class: "cell-text-br",
  },
  {
    Header: "actions",
    action: "rejectParkingReserv",
    short: 0,
  },
];
