import { Collapse, Grid, List, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ListItemButton } from "@mui/material";
import { CHARTER_RESERVED_ADD } from "actions/types";
import CustomCalendar from "components/common/CustomCalendar/CustomCalendar";
import "dayjs/locale/es";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useDispatch, useSelector } from "react-redux";
import "./ItemReserve.scss";

export default function ItemReserve(props) {
  const { data, isFirst, reservations, availabilities } = props;

  const expirationDate =
    data.charter.expirationDate &&
    moment(data.charter.expirationDate).format("YYYY-MM-DD");
  const [availabilityDates, setAvailabilityDates] = React.useState([]);
  const maximumDate = expirationDate
    ? {
        year: moment(expirationDate).get("year"),
        month: moment(expirationDate).get("month") + 1,
        day: moment(expirationDate).get("date"),
      }
    : null;

  const [open, setOpen] = React.useState(isFirst);
  const {
    charters_selected_cancel,
    charters_availability,
    params,
    token_generated,
  } = useSelector((state) => state.charterReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    processReservationsDates(reservations);
    processAvailabilityDates(data);
    getDisableReserveDates(reservations);
  }, [data, reservations]);

  useEffect(() => {}, [availabilityDates]);

  const handleClick = () => {
    setOpen(!open);
  };

  const processAvailabilityDates = (data) => {
    const availabilityDates = data.availability;
    const charterData = data.charter;
    let currentDate = moment().format("YYYY-MM-DD");
    availabilityDates.forEach((d) => {
      const dateParsed = moment(d.date).format("YYYY-MM-DD");
      const monthSelected = moment(d.date).get("month") + 1;
      const yearSelected = moment(d.date).get("year");
      const daySelected = moment(d.date).get("date");
      if (d.total === 0 || d.percentage === 100) {
        setAvailabilityDates((oldArray) => [
          ...oldArray,
          {
            year: yearSelected,
            month: monthSelected,
            day: daySelected,
            hour: charterData.hour,
            charterId: charterData.id,
            charterName: charterData.name,
            charterDescription: charterData.description,
            status: true,
            className: "redPoint",
          },
        ]);
      } else {
        if (moment(dateParsed).isSameOrAfter(currentDate)) {
          setAvailabilityDates((oldArray) => [
            ...oldArray,
            {
              year: yearSelected,
              month: monthSelected,
              day: daySelected,
              hour: charterData.hour,
              charterId: charterData.id,
              charterName: charterData.name,
              charterDescription: charterData.description,
              status: true,
              className: "greenPoint",
            },
          ]);
        } else {
          setAvailabilityDates((oldArray) => [
            ...oldArray,
            {
              year: yearSelected,
              month: monthSelected,
              day: daySelected,
              hour: charterData.hour,
              charterId: charterData.id,
              charterName: charterData.name,
              charterDescription: charterData.description,
              status: true,
              className: "inactive",
            },
          ]);
        }
      }
    });
  };

  const processReservationsDates = (reservations) => {
    if (reservations.length !== 0) {
      let currentDate = moment().format("YYYY-MM-DD");
      const filterReservations = reservations.filter(
        (reservation) => reservation.charter === data.charter.id
      );
      filterReservations.forEach((r) => {
        const dateParsed = moment(r.date).format("YYYY-MM-DD");
        const monthSelected = moment(r.date).get("month") + 1;
        const yearSelected = moment(r.date).get("year");
        const daySelected = moment(r.date).get("date");
        if (moment(dateParsed).isSameOrAfter(currentDate)) {
          const charter = availabilities.find(
            (c) => c.charter.id === r.charter
          );
          dispatch({
            type: CHARTER_RESERVED_ADD,
            payload: {
              ...r,
              hour: charter.charter.hour,
              day: daySelected,
              month: monthSelected,
              year: yearSelected,
            },
          });
          setAvailabilityDates((oldArray) => [
            ...oldArray,
            {
              year: yearSelected,
              month: monthSelected,
              day: daySelected,
              hour: charter.charter.hour,
              charterId: r.charter,
              charterName: "",
              charterDescription: "",
              className: "greenSelected",
            },
          ]);
        } else {
          setAvailabilityDates((oldArray) => [
            ...oldArray,
            {
              year: yearSelected,
              month: monthSelected,
              day: daySelected,
              hour: "",
              charterId: r.charter,
              charterName: "",
              charterDescription: "",
              className: "selectedPast",
            },
          ]);
        }
      });
    }
  };

  //estas fechas se deben desabilitar por completo
  const getDisableReserveDates = (res) => {
    if (res.length !== 0) {
      const lastDate = moment().format("YYYY-MM-DD");
      const firstDate = res[0];
      const reservationDates = getReservationsDates(res);
      const dates = getDates(firstDate.date, lastDate);
      const totalDatesDisabled = dates.filter(
        (e) => reservationDates.indexOf(e) == -1
      );
      const result = convertDates(totalDatesDisabled);
      result.forEach((r) => {
        setAvailabilityDates((oldArray) => [
          ...oldArray,
          {
            year: r.year,
            month: r.month,
            day: r.day,
            hour: "",
            charterId: "",
            charterName: "",
            charterDescription: "",
            className: r.className,
          },
        ]);
      });
    }
  };

  const getReservationsDates = (res) => {
    let result = [];
    res.forEach((r) => {
      result.push(r.date);
    });
    return result;
  };

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stpDate = moment(stopDate);
    while (currentDate <= stpDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  const convertDates = (disabledDates) => {
    let result = [];
    disabledDates.forEach((d) => {
      result.push({
        year: moment(d).get("year"),
        month: moment(d).get("month") + 1,
        day: moment(d).get("date"),
        className: "inactive",
      });
    });
    return result;
  };

  const formatDatesToCalendar = (dates) => {
    const result = [];
    dates.forEach((d) => {
      result.push({
        year: d.year,
        month: d.month - 1,
        day: d.day,
        hour: d.hour,
        charterId: d.charterId,
        charterName: d.charterName,
        charterDescription: d.charterDescription,
        className: d.className,
      });
    });
    return result;
  };

  return (
    <div>
      <ListItemButton
        onClick={handleClick}
        style={{
          backgroundColor: "white",
          borderRadius: 4,
          marginTop: 15,
          marginLeft: 2,
          paddingTop: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 5,
          width: "23em",
        }}
      >
        <ListItemText
          primary={`${data.charter.hour}hs  ${data.charter.name}`}
          color="primary"
          style={{ paddingLeft: 8 }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <CustomCalendar
                defaultDates={formatDatesToCalendar(availabilityDates)}
                maximumMonth={maximumDate != null ? maximumDate.month - 1 : 13}
                data={data}
                charters_selected_cancel={charters_selected_cancel}
                charters_availability={charters_availability}
                params={params}
                token_generated={token_generated}
              />
            </Grid>
          </Grid>
        </List>
      </Collapse>
    </div>
  );
}
ItemReserve.propTypes = {
  data: PropTypes.object,
  isFirst: PropTypes.bool,
  reservations: PropTypes.array,
};
