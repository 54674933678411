import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tab: {
    "& .MuiTab-textColorPrimary": {
      color: "#43a047",
      backgroundColor: "#FFF",
      borderRadius: "25px 25px 0px 0px",
      marginRight: "7px",
      fontSize: "18px",
      fontFamily: "Gotham",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#FFF",
      backgroundColor: "#43a047",
    },
    "& .MuiTab-wrapper": {
      textTransform: "initial",
      padding: "0 1em",
    },
  },
  container: {
    backgroundColor: "#fff",
    padding: "10px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
});
