import React from "react";

import { Button } from "@material-ui/core";
import {
  TimerOff,
  Check,
  Alarm,
  Clear,
  NotInterested,
  Visibility,
  CloudDownload,
  DateRange,
} from "@material-ui/icons";

const DownloadFiles = (t, fileBaseUrl, name, url, jwt) => {
  return (
    <div className="file-download">
      <a
        href={fileBaseUrl + url + "?token=" + jwt}
        target="_blank"
        rel="noopener noreferrer"
        title={t("see_file")}
      >
        <Visibility color="primary"></Visibility>
      </a>
      <a
        href={fileBaseUrl + url + "?mode=attachement&token=" + jwt}
        download={name}
        title={t("download")}
      >
        <CloudDownload color="primary"></CloudDownload>
      </a>
      <span>{name}</span>
    </div>
  );
};

const CellActions = (prop) => {
  const value = prop.data.value;
  const token_file = prop.token_file;
  const { t, editReservation } = prop;
  const data = prop.rowData.original;
  const column = prop.data.column;
  const fileBaseUrl = prop.fileBaseUrl;
  let cellContent = prop.data.render("Cell");
  let { btn_1, btn_2 } = "";
  if (typeof column.action === "undefined" || !column.action) {
    return <div>{cellContent}</div>;
  }
  switch (column.action) {
    case "datetime": {
      return <div style={{ padding: "5px" }}>{data.datetime}</div>;
    }
    case "iconStatus": {
      let iconStatu = <TimerOff />;
      let text = t(value);
      let classCell = "cell-status ";
      switch (value) {
        case "approved":
          iconStatu = <Check color="primary" />;
          classCell += "primary";
          break;
        case "pending":
          iconStatu = <Alarm />;
          break;
        case "canceled":
          iconStatu = <Clear />;
          break;
        case "rejected":
          iconStatu = <NotInterested />;
          break;
        default:
      }
      return (
        <span className={classCell}>
          {iconStatu}
          {text}
        </span>
      );
    }
    case "documents": {
      const purchaseOrderUrl = data.purchaseOrderUrl;
      if (!token_file || typeof token_file == "undefined") {
        return null;
      }
      const jwt = token_file.t;
      if (!jwt || typeof jwt == "undefined") {
        return null;
      }

      return (
        <div>
          {!purchaseOrderUrl && t("no_purchase_order")}
          {purchaseOrderUrl && (
            <div>
              {DownloadFiles(
                t,
                fileBaseUrl,
                t("purchase_order"),
                purchaseOrderUrl,
                jwt
              )}
            </div>
          )}
          {data.otherFilesUrl.map((file, i) => (
            <div key={i}>
              {DownloadFiles(t, fileBaseUrl, file.name, file.url, jwt)}
            </div>
          ))}
        </div>
      );
    }
    case "actions": {
      if (data.showCancel) {
        btn_1 = (
          <Button
            onClick={() => prop.handleCancel(data)}
            color="primary"
            title={t("cancel")}
          >
            <Clear />
          </Button>
        );
      }

      if (data.showEdit) {
        btn_2 = (
          <Button
            color="primary"
            title={t("edit")}
            onClick={() => editReservation(data.id)}
          >
            <DateRange />
          </Button>
        );
      }
      break;
    }
    default:
      return null;
  }

  return (
    <div className="columnsContent">
      {btn_1}
      {btn_2}
    </div>
  );
};

export default CellActions;
