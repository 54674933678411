import React, { Component } from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";
import Button from "components/common/CustomButtons/Button.js";
import { withTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { parsePhoneNumber } from "../../utils/UserUtils";

class ChangePhoneNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
    };

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  handlePhoneChange(ev) {
    const { phone } = this.state;
    let s = ev.target.value;

    this.setState({
      phone: parsePhoneNumber(phone, s),
    });
  }

  render() {
    const { onClose, t, open, onSave } = this.props;
    const { phone } = this.state;

    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form
          className="center"
          onSubmit={(ev) => {
            ev.preventDefault();
            onSave(phone);
            onClose();
          }}
        >
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {" "}
            {t("change_phone_number")}
          </DialogTitle>
          <DialogContent className="dialog" dividers>
            <TextField
              fullWidth
              label={t("enter_phone")}
              value={phone}
              onChange={this.handlePhoneChange}
              margin="normal"
              variant="outlined"
              required
            />
          </DialogContent>
          <DialogActions className="dialog-buttons">
            <Button color="primary" type="submit" className="add" round>
              {t("accept")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
const useStyles = createStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
}));

export default withTranslation()(withStyles(useStyles)(ChangePhoneNumber));
