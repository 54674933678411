import { Card, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  label: {
    background: "#FAFAFA",
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
  },
  text: {
    textAlign: "center",
    color: "#767676",
  },
});

const ItemLabel = ({ label }) => {
  const classes = useStyles();
  return (
    <Card className={classes.label}>
      <ListItem>
        <ListItemText primary={label} className={classes.text} />
      </ListItem>
    </Card>
  );
};

export default ItemLabel;
