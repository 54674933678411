import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import Webcam from "react-webcam";
import { useState } from "react";
import { useEffect, useRef } from "react";
import FlipCameraIosIcon from "@material-ui/icons/FlipCameraIos";
import ReplayIcon from "@material-ui/icons/Replay";
import { useDispatch } from "react-redux";
import {
  updateFacePhotoTakedReset,
  updateFacePhotoTakeReset,
  updateFacePhotoUploadReset,
} from "../../actions/menu";
import { useSelector } from "react-redux";
import PassAppApi from "../../services/PassAppApi";
import { dataURLtoFile } from "../../utils/ImagesUtil";
import { FACE_PHOTO } from "../../Constant";
import SnackbarMessage from "../../utils/SnackbarMessage";
import { useTranslation } from "react-i18next";
import { getUser } from "../../actions/auth";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  switchButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#fff",
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 999,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const UpdateFacePhoto = () => {
  const webcamRef = useRef(null);
  const imgRef = useRef(null);
  const history = useHistory();
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [uploading, setUploading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isUpdateFacePhotoTake, isUpdateFacePhotoUpload } = useSelector(
    (state) => state.menuReducer
  );
  const { token } = useSelector((state) => state.authReducer);
  const { t } = useTranslation();
  //1
  useEffect(() => {
    if (isUpdateFacePhotoTake) {
      capturePhoto();
      dispatch(updateFacePhotoTakeReset());
    }
  }, [isUpdateFacePhotoTake]);
  //2
  useEffect(() => {
    if (isUpdateFacePhotoUpload) {
      uploadFacePhoto();
      dispatch(updateFacePhotoUploadReset());
    }
  }, [isUpdateFacePhotoUpload]);
  //3
  useEffect(() => {
    return () => {
      dispatch(updateFacePhotoTakedReset());
    };
  }, []);
  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCurrentPhoto(imageSrc);
  };
  const switchCamera = () => {
    if (currentPhoto !== null) {
      setCurrentPhoto(null);
      dispatch(updateFacePhotoTakedReset());
    } else {
      setIsFrontCamera(!isFrontCamera);
    }
  };
  const uploadFacePhoto = () => {
    setUploading(true);
    PassAppApi.uploadImage(token, dataURLtoFile(currentPhoto), FACE_PHOTO)
      .then(() => {
        dispatch(getUser(t));
        SnackbarMessage.show("success", t("facephoto_upload_success"));
        history.push("/home/passes");
      })
      .catch(() => {
        SnackbarMessage.show("error", t("facephoto_upload_error"));
      })
      .finally(() => {
        clearPhoto();
        setUploading(false);
      });
  };
  const clearPhoto = () => {
    setCurrentPhoto(null);
    dispatch(updateFacePhotoTakedReset());
  };
  const videoConstraints = {
    facingMode: isFrontCamera ? "user" : "environment",
  };
  return (
    <div style={{ position: "relative", height: "78vh" }}>
      <IconButton
        className={classes.switchButton}
        aria-label="switch camera"
        onClick={switchCamera}
      >
        {currentPhoto === null ? <FlipCameraIosIcon /> : <ReplayIcon />}
      </IconButton>
      {currentPhoto === null ? (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={{ height: "100%", width: "100%" }}
          mirrored={true}
        />
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <img
              src={currentPhoto}
              ref={imgRef}
              alt="mask"
              style={{
                marginTop: "100px",
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
        </Grid>
      )}
      <Backdrop className={classes.backdrop} open={uploading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default UpdateFacePhoto;
