import { SvgIcon } from "@material-ui/core";
import React from "react";

const PassRequestIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="25"
      viewBox="0 0 32 25"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M29.3333 0H2.66667C1.19444 0 0 1.19978 0 2.67857V3.57143H32V2.67857C32 1.19978 30.8056 0 29.3333 0ZM0 22.3214C0 23.8002 1.19444 25 2.66667 25H29.3333C30.8056 25 32 23.8002 32 22.3214V5.35714H0V22.3214ZM19.5556 9.375C19.5556 9.12946 19.7556 8.92857 20 8.92857H28C28.2444 8.92857 28.4444 9.12946 28.4444 9.375V10.2679C28.4444 10.5134 28.2444 10.7143 28 10.7143H20C19.7556 10.7143 19.5556 10.5134 19.5556 10.2679V9.375ZM19.5556 12.9464C19.5556 12.7009 19.7556 12.5 20 12.5H28C28.2444 12.5 28.4444 12.7009 28.4444 12.9464V13.8393C28.4444 14.0848 28.2444 14.2857 28 14.2857H20C19.7556 14.2857 19.5556 14.0848 19.5556 13.8393V12.9464ZM19.5556 16.5179C19.5556 16.2723 19.7556 16.0714 20 16.0714H28C28.2444 16.0714 28.4444 16.2723 28.4444 16.5179V17.4107C28.4444 17.6562 28.2444 17.8571 28 17.8571H20C19.7556 17.8571 19.5556 17.6562 19.5556 17.4107V16.5179ZM9.77778 8.92857C11.7389 8.92857 13.3333 10.5301 13.3333 12.5C13.3333 14.4699 11.7389 16.0714 9.77778 16.0714C7.81667 16.0714 6.22222 14.4699 6.22222 12.5C6.22222 10.5301 7.81667 8.92857 9.77778 8.92857ZM3.72778 20.3237C4.19444 18.8895 5.53333 17.8571 7.11111 17.8571H7.56667C8.25 18.1417 8.99444 18.3036 9.77778 18.3036C10.5611 18.3036 11.3111 18.1417 11.9889 17.8571H12.4444C14.0222 17.8571 15.3611 18.8895 15.8278 20.3237C16.0056 20.8761 15.5389 21.4286 14.9611 21.4286H4.59444C4.01667 21.4286 3.55 20.8705 3.72778 20.3237Z"
      />
    </SvgIcon>
  );
};

export default PassRequestIcon;
