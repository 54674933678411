import { createStyles, Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import React from "react";
import SpaceReserveReport from "components/Spaces/SpaceReserves/SpaceReservesReport";
import SpaceCreditUsers from "components/Spaces/SpaceCreditUsers";
import MessageDialog from "components/common/MessageDialog/MessageDialog";
import "./Spaces.scss";
import SpaceCreditBalance from "components/Spaces/SpaceCreditBalance";
const useStyles = createStyles(styles);
const Spaces = (props) => {
  const { t, user } = props;
  const [tabSel, setTabSel] = useState(0);
  const [diagMsj, setDiagMsj] = useState({
    open: false,
    message: "",
  });
  const closeMessaje = () => {
    setDiagMsj({ diagMsj: { open: false, message: "" } });
  };
  const onChangeTabs = (event, newValue) => {
    setTabSel(newValue);
  };
  return (
    <div className="content-box parkingsContent">
      <div className="clearfix">
        <Tabs
          value={tabSel}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChangeTabs}
          className="pull-left"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label={t("space_reserve_tab")} />
          {user.spaceManager.spaceCreditByUser && (
            <Tab className="large" label={t("space_credit_tab")} />
          )}
          {user.spaceManager.spaceCreditByCompany && (
            <Tab label={t("space_credit_balance_tab")} />
          )}
        </Tabs>
      </div>
      {tabSel === 0 && <SpaceReserveReport />}
      {tabSel === 1 && user.spaceManager.spaceCreditByUser && (
        <SpaceCreditUsers />
      )}
      {tabSel === 1 && user.spaceManager.spaceCreditByCompany && (
        <SpaceCreditBalance />
      )}
      <MessageDialog
        className="alertDialog"
        open={diagMsj.open}
        message={diagMsj.message}
        onClose={closeMessaje}
      ></MessageDialog>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.parkings.loading,
  user: state.authReducer.user,
});
export default connect(mapStateToProps)(
  withTranslation()(withStyles(useStyles)(Spaces))
);
