import React from "react";

import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import CellActions from "./CellActions";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import { columns } from "./columns";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

const CompanyStaffTable = ({
  data,
  handleChangeStatus,
  showUpdUser,
  t,
  showUser,
  tabRequests,
  tabUnassigneds,
  setCurrentPage,
  globalPageIndex,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: globalPageIndex },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    () => {}
  );
  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const getColumnProp = (column, headerData) => {
    return Object.assign({}, column, { title: t(headerData.Header) });
  };
  const printColumnHead = (column) => {
    if (column.short !== 0) {
      return (
        <span className="cell-text">
          {t(`${column.render("Header")}`)}

          <TableSortLabel
            active={column.isSorted}
            direction={column.isSortedDesc ? "desc" : "asc"}
          />
          {!column.isSorted && <SyncAltIcon className="colSort"></SyncAltIcon>}
        </span>
      );
    }
    return <span>{t(`${column.render("Header")}`)}</span>;
  };

  return (
    <div className="body-box">
      <TableContainer className="fixed-height">
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, i) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <TableCell
                    {...getColumnProp(
                      column.getHeaderProps(column.getSortByToggleProps()),
                      headerGroup.headers[j]
                    )}
                    key={j}
                    className={j === 1 ? "col-left" : ""}
                  >
                    {printColumnHead(column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        key={j}
                        className={j === 1 ? "col-left" : ""}
                      >
                        <CellActions
                          data={cell}
                          rowData={row}
                          t={t}
                          showUser={showUser}
                          handleChangeStatus={handleChangeStatus}
                          showUpdUser={showUpdUser}
                          tabRequests={tabRequests}
                          tabUnassigneds={tabUnassigneds}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {page.length === 0 && (
              <TableRow>
                <TableCell style={{ padding: "14px" }} colSpan="7">
                  {t("no_result")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: t("all"), value: data.length },
                ]}
                colSpan={7}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                labelRowsPerPage={t("rows_per_page")}
                labelDisplayedRows={(params) => {
                  return t("pagination")
                    .replace("%%FROM%%", params.from)
                    .replace("%%TO%%", params.to)
                    .replace("%%TOTAL%%", params.count);
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
    </div>
  );
};

CompanyStaffTable.propTypes = {
  data: PropTypes.array.isRequired,
  changeStatus: PropTypes.func,
};

export default CompanyStaffTable;
