import {
  COMPANY_STAFF_LOAD,
  COMPANY_STAFF_LOAD_ACTIVE,
  COMPANY_STAFF_LOAD_REQUEST,
  COMPANY_STAFF_LOAD_UNASSIG,
  COMPANY_STAFF_RELOAD_ACTIVE,
  COMPANY_STAFF_UPD_ACTIVE,
  COMPANY_STAFF_UPD_REQUEST,
  COMPANY_STAFF_UPD_UNASSIG,
  COMPANY_STAFF_REMOVE_ACTIVE,
  COMPANY_STAFF_REMOVE_REQUEST,
  COMPANY_STAFF_REMOVE_UNASSIG,
  COMPANY_STAFF_MANAGERS,
  COMPANY_STAFF_STATUS,
  COMPANY_STAFF_OPEN_PASS_MANAGER,
  COMPANY_STAFF_OPEN_STAFF_MANAGER
} from "../actions/types";

import { filterCompanyStaff } from "../actions/companyStaff";

const initialState = {
  loading: false,
  dynamicLink: null,
  staffList: [],
  requestsList: [],
  unassignedList: [],
  currentList: [],
  managers: [],
  passManagers: [],
  status: null,
  openPassManagers: false,
  openStaffManagers: false,
};

export function companyStaff(state = initialState, action) {
  switch (action.type) {
    case COMPANY_STAFF_LOAD:
      return {
        ...state,
        currentList: action.payload.data,
      };
    case COMPANY_STAFF_LOAD_ACTIVE:
      return {
        ...state,
        staffList: action.payload.data,
      };
    case COMPANY_STAFF_LOAD_REQUEST:
      return {
        ...state,
        requestsList: action.payload.data,
      };
    case COMPANY_STAFF_LOAD_UNASSIG:
      return {
        ...state,
        unassignedList: action.payload.data,
      };
    case COMPANY_STAFF_RELOAD_ACTIVE: {
      const compStafflist = action.payload.data;
      let currentList = [];

      for (let i in state.currentList) {
        let v = state.currentList[i];
        for (let j in compStafflist) {
          let item = compStafflist[j];
          if (item.id === v.id) {
            currentList.push(item);
            break;
          }
        }
      }

      return {
        ...state,
        staffList: compStafflist,
        currentList: currentList,
      };
    }
    case COMPANY_STAFF_UPD_ACTIVE: {
      const compStaffObj = action.payload.obj;
      let compStafflist = state.staffList.map((v) => {
        if (compStaffObj.id === v.id) {
          return compStaffObj;
        }
        return v;
      });
      if (action.payload.searchText) {
        compStafflist = filterCompanyStaff(
          compStafflist,
          action.payload.searchText
        );
      }
      return {
        ...state,
        staffList: compStafflist,
        currentList: compStafflist,
      };
    }
    case COMPANY_STAFF_UPD_REQUEST: {
      const compStaffObj = action.payload.obj;
      let compStafflist = state.requestsList.map((v) => {
        if (compStaffObj.id === v.id) {
          return compStaffObj;
        }
        return v;
      });
      if (action.payload.searchText) {
        compStafflist = filterCompanyStaff(
          compStafflist,
          action.payload.searchText
        );
      }
      return {
        ...state,
        requestsList: compStafflist,
        currentList: compStafflist,
      };
    }
    case COMPANY_STAFF_UPD_UNASSIG: {
      const compStaffObj = action.payload.obj;
      let compStafflist = state.unassignedList.map((v) => {
        if (compStaffObj.id === v.id) {
          return compStaffObj;
        }
        return v;
      });
      if (action.payload.searchText) {
        compStafflist = filterCompanyStaff(
          compStafflist,
          action.payload.searchText
        );
      }
      return {
        ...state,
        unassignedList: compStafflist,
        currentList: compStafflist,
      };
    }
    case COMPANY_STAFF_REMOVE_ACTIVE: {
      const compStaffID = action.payload.id;
      const compStafflist = state.staffList.filter((v) => {
        return compStaffID !== v.id;
      });
      return {
        ...state,
        staffList: compStafflist,
        currentList: compStafflist,
      };
    }
    case COMPANY_STAFF_REMOVE_REQUEST: {
      const compStaffID = action.payload.id;
      const compStafflist = state.requestsList.filter((v) => {
        return compStaffID !== v.id;
      });
      return {
        ...state,
        requestsList: compStafflist,
        currentList: compStafflist,
      };
    }
    case COMPANY_STAFF_REMOVE_UNASSIG: {
      const compStaffID = action.payload.id;
      const compStafflist = state.unassignedList.filter((v) => {
        return compStaffID !== v.id;
      });
      return {
        ...state,
        unassignedList: compStafflist,
        currentList: compStafflist,
      };
    }
    case COMPANY_STAFF_MANAGERS:
      return {
        ...state,
        managers: action.payload.data.managers,
        passManagers: action.payload.data.passManagers,
      };
    case COMPANY_STAFF_STATUS:
      return {
        ...state,
        status: action.payload.data,
      };
    case COMPANY_STAFF_OPEN_PASS_MANAGER:
      return {
        ...state,
        openPassManagers: action.payload.open,
      };
    case COMPANY_STAFF_OPEN_STAFF_MANAGER:
      return {
        ...state,
        openStaffManagers: action.payload.open,
      };
    default:
      return state;
  }
}
