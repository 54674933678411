import Typography from "@material-ui/core/Typography";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { withTranslation } from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {
  findEstablishment,
  getUserFullname,
  getTimezoneLocal,
  getDatetimeShort,
} from "utils/Utils";
import "../Pass/Pass.scss";
import { getAuthorizationStatus } from "utils/Status";
import Primary from "components/common/Typography/Primary";
import Muted from "components/common/Typography/Muted";
import police from "assets/img/ic_police_100.png";
import NotifySecurity from "components/NotifySecurity/NotifySecurity";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { onDialogOpen } from "../../actions/dialog";
import { GUEST_TYPE_PROVIDER } from "Constant";

class PassAuthorization extends React.Component {
  constructor(props) {
    super(props);
    this.closeDetail = this.closeDetail.bind(this);
    this.openDetail = this.openDetail.bind(this);
    this.state = {
      currentDialog: null,
    };
  }

  buildDate(authorization, establishment) {
    let timezone =
      establishment && establishment.timezone != null
        ? establishment.timezone
        : getTimezoneLocal();

    let start = moment(new Date(authorization.beginTimestamp * 1000));
    if (authorization.beginDatetime) {
      start = moment.tz(
        getDatetimeShort(authorization.beginDatetime),
        timezone
      );
    }

    let end = moment(new Date(authorization.endTimestamp * 1000));
    if (authorization.endDatetime) {
      end = moment.tz(getDatetimeShort(authorization.endDatetime), timezone);
    }

    return `${start.format("dddd")} ${start.format("LL")}, de ${start.format(
      "HH:mm"
    )}hs a ${end.format("HH:mm")}hs`;
  }

  getSectorName() {
    const { authorization, professionTypes } = this.props;
    if (
      authorization.guestType === GUEST_TYPE_PROVIDER &&
      professionTypes !== undefined &&
      authorization.providerType !== ""
    ) {
      let sectorSelected = professionTypes.find(
        (sector) => sector.id === authorization.providerType
      );
      if (sectorSelected) return sectorSelected.name;
      else return "";
    }
    return "";
  }

  openDetail() {
    this.props.onDialogOpen(NotifySecurity, {
      authorization: this.props.authorization,
    });
  }

  closeDetail() {
    this.setState({
      currentDialog: null,
    });
    this.props.onClose();
  }

  render() {
    const { t, authorization, filter, countries, companies } = this.props;

    let establishment = findEstablishment(authorization, companies, countries); // Country or company

    let name = "";
    let providerName = "";
    let guestType = authorization.guestType;
    let searchTerm = filter.toLowerCase();
    let passStatus = getAuthorizationStatus(authorization, t);
    let StatusTypography = passStatus.typography;

    let establishmentFilter = (establishment
      ? establishment.name
      : ""
    ).toLowerCase();
    let filterMatch = establishmentFilter.includes(searchTerm);
    if (guestType === GUEST_TYPE_PROVIDER) {
      providerName = authorization.providerName;
      let providerFilter = providerName.toLowerCase();
      if (providerFilter.includes(searchTerm)) filterMatch = true;
    } else {
      name = getUserFullname(authorization);
      let userFilter = name.toLowerCase();
      if (userFilter.includes(searchTerm)) {
        filterMatch = true;
      }
    }

    if (!filterMatch) {
      return <div />;
    }

    return (
      <div>
        <ListItem button className="pass" onClick={this.openDetail}>
          <ListItemAvatar>
            <Avatar className="avatar" src={police}></Avatar>
          </ListItemAvatar>
          <div>
            <Typography className="pass-name">
              {guestType === GUEST_TYPE_PROVIDER ? providerName : name}
            </Typography>
            {guestType === GUEST_TYPE_PROVIDER ? (
              <Muted className="sector">{this.getSectorName()}</Muted>
            ) : null}
            <Muted>{establishment ? establishment.name : ""}</Muted>
            <Primary className="date">
              {" "}
              {this.buildDate(authorization, establishment)}{" "}
            </Primary>
            <StatusTypography className="bold">
              {passStatus.short}
            </StatusTypography>
          </div>
        </ListItem>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  professionTypes: state.userReducer.professionTypes,
});

const actionCreators = {
  onDialogOpen: onDialogOpen,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(PassAuthorization));
