import {
  MENU_MY_GUESTS_CLICKED,
  MENU_MY_QR_CLICKED,
  MENU_UPDATE_FACEPHOTO_TAKE,
  MENU_NOTIFICATION_ITEM_TOUCHED,
  MENU_UPDATE_FACEPHOTO_UPLOAD,
  MENU_UPDATE_FACEPHOTO_TAKED,
} from "./types";

export const myQrRefresh = () => {
  return {
    type: MENU_MY_QR_CLICKED,
    payload: true,
  };
};

export const myQrRefreshReset = () => {
  return {
    type: MENU_MY_QR_CLICKED,
    payload: false,
  };
};

export const myGuestsOpenMenu = (target) => {
  return {
    type: MENU_MY_GUESTS_CLICKED,
    payload: target,
  };
};

export const notificationItemTouchedAction = (target) => {
  return {
    type: MENU_NOTIFICATION_ITEM_TOUCHED,
    payload: target,
  };
};

export const updateFacePhotoTake = () => {
  return {
    type: MENU_UPDATE_FACEPHOTO_TAKE,
    payload: true,
  };
};

export const updateFacePhotoTakeReset = () => {
  return {
    type: MENU_UPDATE_FACEPHOTO_TAKE,
    payload: false,
  };
};

export const updateFacePhotoUpload = () => {
  return {
    type: MENU_UPDATE_FACEPHOTO_UPLOAD,
    payload: true,
  };
};

export const updateFacePhotoUploadReset = () => {
  return {
    type: MENU_UPDATE_FACEPHOTO_UPLOAD,
    payload: false,
  };
};

export const updateFacePhotoTaked = () => {
  return {
    type: MENU_UPDATE_FACEPHOTO_TAKED,
    payload: true,
  };
};

export const updateFacePhotoTakedReset = () => {
  return {
    type: MENU_UPDATE_FACEPHOTO_TAKED,
    payload: false,
  };
};
