import React from "react";
import { withTranslation } from "react-i18next";
import { findEstablishment, getDatetimeShort } from "utils/Utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  createStyles,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import {
  getUserEstablishments,
  sanitizeDni,
  isValidDni,
  getTimezoneLocal,
} from "utils/Utils";
import {
  PASS_DATE,
  ESTABLISHMENT,
  COUNTRY,
  BUILDING,
  PASS_HOURS_LIMIT,
  DNI,
  SECTOR,
  GUEST_TYPE_PROVIDER,
  GUEST_TYPE_PARTICULAR,
  GUEST_TYPE,
} from "Constant";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import CustomMomentUtils from "utils/CustomMomentUtils";
import { withStyles } from "@material-ui/styles";
import Button from "components/common/CustomButtons/Button.js";
import CountrySelect from "components/common/CountrySelect/CountrySelect";
import { getCountryCode, getCountryName, COUNTRY_NAMES } from "lang/countries";
import Loader from "utils/Loader";
import moment from "moment-timezone";
import CancelIcon from "@material-ui/icons/Cancel";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import { connect } from "react-redux";
import {
  onDialogModified,
  onDialogClose,
  onDialogClear,
  onDialogExited,
} from "../../actions/dialog";
import {
  createAuthorization,
  revokeAuthorization,
  updateAuthorization,
} from "../../actions/passes";
import { TabPanel } from "components/common/TabPanel/TabPanel";
import SnackbarMessage from "utils/SnackbarMessage";
import withBreakpoint from "../../hooks/classHooks/withBreakpoint";
import { getLocaleLanguage } from "utils/Utils";
import "moment/locale/pt-br";
import "moment/locale/es";
import "moment/locale/en-ca";

class NotifySecurity extends React.Component {
  constructor(props) {
    super(props);

    let endDate = new Date();
    endDate.setHours(endDate.getHours() + 3);

    this.state = {
      establishments: [],
      errors: {},
      destinationTextWidth: 0,
      selectedEstablishmentId: "",
      selectedEstablishment: null,
      beginDate: new Date(),
      endDate: endDate,
      countryCode: "",
      guestCompany: "",
      guestEmail: "",
      freeParking: false,
      dni: "",
      firstname: "",
      lastname: "",
      showCancelWarning: false,
      timezone: getTimezoneLocal(),
      guestType: "",
      selectedSectorId: "",
      providerComments: "",
      providerFirstname: "",
      sectors: [],
    };
    this.handleEstablishmentSelect = this.handleEstablishmentSelect.bind(this);
    this.handleBeginDate = this.handleBeginDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleDniChange = this.handleDniChange.bind(this);
    this.submit = this.submit.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.update = this.update.bind(this);
    this.showCancelWarning = this.showCancelWarning.bind(this);
    this.closeCancelWarning = this.closeCancelWarning.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleParkingChange = this.handleParkingChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSectorSelect = this.handleSectorSelect.bind(this);

    this.countryList = COUNTRY_NAMES.map((c) => ({
      value: c.code,
      label: c.name,
    }));
  }

  handleSectorSelect(event) {
    const { sectors } = this.state;
    let selectedSector = sectors.find((e) => e.id === event.target.value);
    this.setState({
      selectedSectorId: selectedSector.id,
    });
  }

  handleRadioChange(event) {
    const { errors } = this.state;
    this.setState({
      guestType: event.target.value,
    });
    errors[GUEST_TYPE] = null;
  }

  handleCountryChange(country) {
    this.setState({
      countryCode: getCountryCode(country),
    });
    this.props.onDialogModified();
  }

  handleParkingChange() {
    this.setState({
      freeParking: !this.state.freeParking,
    });
  }

  closeCancelWarning() {
    this.setState({
      showCancelWarning: false,
    });
  }

  showCancelWarning() {
    this.setState({
      showCancelWarning: true,
    });
  }

  handleBeginDate(date) {
    const { timezone } = this.state;

    let beginDate = moment.tz(date, timezone);
    let endDate = moment.tz(date, timezone).add("3", "hours");

    this.setState({
      beginDate,
      endDate,
    });
    this.props.onDialogModified();
  }

  handleEndDate(date) {
    const { timezone } = this.state;

    let endDate = moment.tz(date, timezone);

    this.setState({
      endDate: endDate,
    });
    this.props.onDialogModified();
  }

  handleFieldChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
    this.props.onDialogModified();
  }

  handleDniChange(ev) {
    const { t } = this.props;
    const { errors } = this.state;

    errors[DNI] = null;
    if (!isValidDni(ev.target.value)) {
      errors[DNI] = t("invalid_dni");
    }

    this.setState({
      dni: sanitizeDni(ev.target.value),
    });
    this.props.onDialogModified();
  }

  submit(ev) {
    ev.preventDefault();
    const { t, createAuthorization } = this.props;

    if (this.isValidAuthorization()) {
      createAuthorization(this.buildAuthorization(), t);
    }
  }

  buildAuthorization() {
    const {
      selectedEstablishmentId,
      beginDate,
      endDate,
      countryCode,
      guestCompany,
      guestEmail,
      freeParking,
      dni,
      firstname,
      lastname,
      type,
      selectedEstablishment,
      guestType,
      selectedSectorId,
      providerComments,
      providerFirstname,
    } = this.state;
    return {
      beginTimestamp: Math.round(beginDate.toDate().getTime() / 1000),
      beginDatetime: moment(beginDate).format(),
      endTimestamp: Math.round(endDate.toDate().getTime() / 1000),
      endDatetime: moment(endDate).format(),
      country:
        type === COUNTRY || type === BUILDING ? selectedEstablishmentId : null,
      company:
        type !== COUNTRY && type !== BUILDING ? selectedEstablishmentId : null,
      countryCode,
      guestCompany,
      guestEmail,
      freeParking,
      dni,
      manager: selectedEstablishment.manager,
      firstname,
      lastname,
      guestType,
      providerName: providerFirstname,
      providerType: selectedSectorId,
      comments: providerComments,
    };
  }

  componentDidMount() {
    const {
      authorization,
      user,
      companies,
      countries,
      professionTypes,
    } = this.props;
    let newEstablishments = getUserEstablishments(
      user,
      companies,
      countries
    ).filter((e) => {
      return (
        ((e.type === COUNTRY || e.type === BUILDING) &&
          e.authorizationsEnabled) ||
        (e.type !== COUNTRY && e.type !== BUILDING)
      );
    });

    this.setState(
      {
        establishments: newEstablishments,
        sectors: professionTypes,
      },
      () => {
        if (authorization) {
          const { establishments } = this.state;
          let establishment = findEstablishment(
            authorization,
            companies,
            countries
          );
          let selectedEstablishment = establishments.find(
            (e) => e.id === establishment.id
          );
          let timezone =
            selectedEstablishment.timezone != null
              ? selectedEstablishment.timezone
              : getTimezoneLocal();

          let beginDate = moment(new Date(authorization.beginTimestamp * 1000));
          if (authorization.beginDatetime) {
            beginDate = moment.tz(
              getDatetimeShort(authorization.beginDatetime),
              timezone
            );
          }

          let endDate = moment(new Date(authorization.endTimestamp * 1000));
          if (authorization.endDatetime) {
            endDate = moment.tz(
              getDatetimeShort(authorization.endDatetime),
              timezone
            );
          }

          this.setState({
            selectedEstablishmentId: establishment.id,
            selectedEstablishment: selectedEstablishment,
            beginDate: beginDate,
            endDate: endDate,
            countryCode: authorization.countryCode,
            dni: authorization.dni,
            guestCompany: authorization.guestCompany,
            guestEmail: authorization.guestEmail,
            freeParking: authorization.freeParking,
            firstname: authorization.firstname,
            lastname: authorization.lastname,
            guestType: authorization.guestType,
            type: establishment.type,
            timezone: timezone,
            providerComments: authorization.comments,
            providerFirstname: authorization.providerName,
            selectedSectorId: authorization.providerType,
          });
        }
      }
    );
  }

  isValidAuthorization() {
    const { t } = this.props;
    const {
      selectedEstablishmentId,
      guestType,
      beginDate,
      endDate,
    } = this.state;

    let errors = {};
    if (!selectedEstablishmentId) {
      errors[ESTABLISHMENT] = t("must_select_destination");
    } else {
      if (guestType === "") {
        errors[GUEST_TYPE] = t("error_guest_type");
      }
    }

    if (moment(endDate).diff(moment(beginDate), "hours") > PASS_HOURS_LIMIT) {
      errors[PASS_DATE] = t("error_auth_length");
    }

    if (moment(endDate).diff(moment(beginDate), "seconds") < 0) {
      errors[PASS_DATE] = t("error_end_before_start");
    }

    this.setState({
      errors: errors,
    });
    return Object.entries(errors).length === 0;
  }

  handleEstablishmentSelect(ev) {
    const { errors, establishments, guestType } = this.state;
    const { t } = this.props;
    let selectedEstablishment = establishments.find(
      (e) => e.id === ev.target.value
    );
    let timezone =
      selectedEstablishment.timezone != null
        ? selectedEstablishment.timezone
        : getTimezoneLocal();

    let beginDateAux = moment().tz(timezone);
    let endDateAux = moment()
      .tz(timezone)
      .add("3", "hours");

    errors[ESTABLISHMENT] = null;
    if (
      guestType === GUEST_TYPE_PROVIDER &&
      selectedEstablishment.type !== BUILDING &&
      selectedEstablishment.type !== COUNTRY
    ) {
      this.setState({ guestType: GUEST_TYPE_PARTICULAR });
      SnackbarMessage.show("error", t("error_guest_type_provider_corporative"));
    }
    this.setState({
      timezone: timezone,
      beginDate: beginDateAux,
      endDate: endDateAux,
      selectedEstablishmentId: ev.target.value,
      selectedEstablishment,
      type: selectedEstablishment.type,
    });
    this.props.onDialogModified();
  }

  cancel() {
    const { authorization, t, revokeAuthorization } = this.props;

    this.setState({
      showCancelWarning: false,
    });

    revokeAuthorization(authorization.id, t);
  }

  update(ev) {
    ev.preventDefault();
    const { authorization, t, updateAuthorization } = this.props;

    let auth = this.buildAuthorization();
    updateAuthorization(authorization.id, auth, t);
  }

  render() {
    const {
      open,
      classes,
      t,
      authorization,
      onDialogExited,
      onDialogClose,
      loading,
      isMobile,
    } = this.props;
    const {
      errors,
      selectedEstablishmentId,
      destinationTextWidth,
      establishments,
      beginDate,
      endDate,
      countryCode,
      guestCompany,
      guestEmail,
      freeParking,
      dni,
      firstname,
      lastname,
      type,
      showCancelWarning,
      guestType,
      selectedSectorId,
      providerComments,
      providerFirstname,
      sectors,
    } = this.state;

    let establishmentItems = establishments.map((establishment) => {
      return (
        <MenuItem key={establishment.id} value={establishment.id}>
          {establishment.name}
        </MenuItem>
      );
    });

    let sectorItems = sectors.map((sector) => {
      return (
        <MenuItem key={sector.id} value={sector.id}>
          {sector.name}
        </MenuItem>
      );
    });

    const particular = (
      <>
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="particular-name"
            label={t("name")}
            name="firstname"
            fullWidth
            inputProps={{ autoComplete: "new-password" }}
            onChange={this.handleFieldChange}
            value={firstname}
            required
          />
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="particular-lastName"
            label={t("lastName")}
            name="lastname"
            fullWidth
            inputProps={{ autoComplete: "off" }}
            onChange={this.handleFieldChange}
            value={lastname}
            required
          />
        </FormControl>
        <FormControl
          component="fieldset"
          className={classes.formControl}
          error={errors[DNI] ? true : false}
        >
          <TextField
            id="particular-dni"
            label={t("dni_number")}
            fullWidth
            name="dni"
            inputProps={{ autoComplete: "off" }}
            onChange={this.handleDniChange}
            value={dni}
            required
          />
          {<FormHelperText>{errors[DNI]}</FormHelperText>}
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <CountrySelect
            onCountrySelected={this.handleCountryChange}
            value={getCountryName(countryCode)}
          />
        </FormControl>

        {type !== COUNTRY && type !== BUILDING && selectedEstablishmentId && (
          <div>
            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                label={t("guest_company")}
                inputProps={{ autoComplete: "new-password" }}
                name={"guestCompany"}
                onChange={this.handleFieldChange}
                value={guestCompany}
              />
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                label={t("guest_email")}
                inputProps={{ autoComplete: "new-password" }}
                name={"guestEmail"}
                onChange={this.handleFieldChange}
                value={guestEmail}
              />
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={freeParking}
                    onChange={this.handleParkingChange}
                  />
                }
                label={t("assign_parking")}
              />
            </FormControl>
          </div>
        )}
      </>
    );

    const provider = (
      <>
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="provider-name"
            label={t("supplier_or_company_name")}
            name="providerFirstname"
            fullWidth
            inputProps={{ autoComplete: "new-password" }}
            onChange={this.handleFieldChange}
            value={providerFirstname}
            required
          />
        </FormControl>

        <FormControl
          className={classes.formControl + " select-form"}
          error={errors[SECTOR] ? true : false}
        >
          <InputLabel htmlFor="outlined-age-simple">{t("sector")}</InputLabel>
          <Select
            value={selectedSectorId}
            onChange={this.handleSectorSelect}
            fullWidth
            defaultValue=""
            labelWidth={destinationTextWidth}
            required
          >
            {sectorItems}
          </Select>
          {<FormHelperText>{errors[SECTOR]}</FormHelperText>}
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="provider-commentary"
            label={t("comments_for_the_guard")}
            name="providerComments"
            fullWidth
            inputProps={{ autoComplete: "new-password" }}
            onChange={this.handleFieldChange}
            value={providerComments}
            multiline
            rows={4}
          />
        </FormControl>
      </>
    );

    return (
      <div>
        <ConfirmationDialog
          open={showCancelWarning}
          onConfirm={this.cancel}
          onClose={this.closeCancelWarning}
          title={t("confirm_cancel_pass")}
        />

        <Dialog
          className="pass-detail"
          fullWidth={true}
          fullScreen={isMobile}
          maxWidth="sm"
          aria-labelledby="customized-dialog-title"
          open={open}
          onClose={onDialogClose}
          onExited={onDialogExited}
        >
          <DialogTitle onClose={onDialogClose}>
            {t("invite_detail")}

            <div className="dialog-title-button">
              <IconButton onClick={onDialogClose} aria-label="delete">
                <CancelIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <form onSubmit={authorization ? this.update : this.submit}>
            <DialogContent className="dialog" dividers>
              <MuiPickersUtilsProvider
               
                utils={CustomMomentUtils}
                locale={getLocaleLanguage()}
              
                locale={getLocaleLanguage()}
              >
                <FormControl
                  className={classes.formControl + " select-form"}
                  error={errors[ESTABLISHMENT] ? true : false}
                >
                  <InputLabel htmlFor="outlined-age-simple">
                    {t("choose_destination")}
                  </InputLabel>
                  <Select
                    value={selectedEstablishmentId}
                    onChange={this.handleEstablishmentSelect}
                    labelWidth={destinationTextWidth}
                  >
                    {establishmentItems}
                  </Select>
                  {<FormHelperText>{errors[ESTABLISHMENT]}</FormHelperText>}
                </FormControl>
                <FormControl className={classes.formControl}>
                  <DateTimePicker
                    format="LLLL"
                    label={
                      <div>
                        {t("authorized_from")} ({t("establishment_hour")})
                      </div>
                    }
                    value={beginDate}
                    onChange={this.handleBeginDate}
                    error={errors[PASS_DATE] ? true : false}
                    helperText={errors[PASS_DATE]}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <DateTimePicker
                    format="LLLL"
                    label={
                      <div>
                        {t("authorized_until")} ({t("establishment_hour")})
                      </div>
                    }
                    value={endDate}
                    onChange={this.handleEndDate}
                    error={errors[PASS_DATE] ? true : false}
                    helperText={errors[PASS_DATE]}
                  />
                </FormControl>
                <FormControl
                  component="fieldset"
                  error={errors[GUEST_TYPE] ? true : false}
                >
                  <FormLabel component="legend">{t("guest_type")}</FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={guestType}
                    onChange={this.handleRadioChange}
                  >
                    <FormControlLabel
                      value={GUEST_TYPE_PARTICULAR}
                      control={<Radio color="primary" />}
                      label={t("particular")}
                    />
                    <FormControlLabel
                      value={GUEST_TYPE_PROVIDER}
                      control={<Radio color="primary" />}
                      label={t("provider")}
                      disabled={
                        type !== COUNTRY &&
                        type !== BUILDING &&
                        selectedEstablishmentId !== ""
                      }
                    />
                  </RadioGroup>
                  <TabPanel value={guestType} index={GUEST_TYPE_PARTICULAR}>
                    {particular}
                  </TabPanel>
                  <TabPanel value={guestType} index={GUEST_TYPE_PROVIDER}>
                    {provider}
                  </TabPanel>
                  {<FormHelperText>{errors[GUEST_TYPE]}</FormHelperText>}
                </FormControl>
              </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions className={"dialog-buttons"}>
              <div className="loader">
                <Loader className="submit" visible={loading} />
              </div>
              {this.renderButtons()}
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }

  renderButtons() {
    const { authorization, t, loading } = this.props;

    if (authorization) {
      return (
        <div>
          <Button
            onClick={this.showCancelWarning}
            disabled={loading}
            round
            color="danger"
          >
            {t("cancel_authorization")}
          </Button>
          <Button type="submit" disabled={loading} round color="primary">
            {t("update")}
          </Button>
        </div>
      );
    }

    return (
      <Button type="submit" disabled={loading} round color="primary">
        {t("send_to_security")}
      </Button>
    );
  }
}

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  professionTypes: state.userReducer.professionTypes,
  loading: state.passesReducer.loading,
});

const actionCreators = {
  onDialogModified: onDialogModified,
  onDialogClose: onDialogClose,
  onDialogExited: onDialogExited,
  onDialogClear: onDialogClear,
  createAuthorization: createAuthorization,
  revokeAuthorization: revokeAuthorization,
  updateAuthorization: updateAuthorization,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(withBreakpoint()(NotifySecurity))));
