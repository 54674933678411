import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import Button from "components/common/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import logo from "../../assets/img/logo.png";

import { Typography, FormControl, Grid, Link } from "@material-ui/core";

import { connect } from "react-redux";
import { unmaskPhone } from "../../utils/Utils";
import { whatsappActivation, getUser } from "../../actions/auth";
import "./PhoneVerification.scss";
import Loader from "../../utils/Loader";
import PhoneIcon from "@material-ui/icons/Phone";
import IconTextField from "../../components/common/IconTextField/IconTextField";
import history from "../../history";
import { getUserState, redirectionRegister } from "../../utils/UserUtils";
import {
  DEFAULT_PHONE_MASK,
  US_MASK,
  US_CODE,
  ARG_CODE,
  ARG_MASK,
  STATE_NEEDS_TO_JOIN,
} from "../../Constant";

const useStyles = createStyles(styles);

class PhoneVerification extends React.Component {
  constructor(props) {
    super(props);
    props.getUser(props.t);

    this.state = {
      phone: "",
      type: null,
      showPhone: false,
      loading: false,
    };
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.showSMS = this.showSMS.bind(this);
    this.hideSMS = this.hideSMS.bind(this);
    this.sendWhatsappp = this.sendWhatsappp.bind(this);
    this.cancel = this.cancel.bind(this);
    this.registerPhone = this.registerPhone.bind(this);
  }

  componentDidMount() {
    redirectionRegister(this.props.user);
  }
  componentDidUpdate() {
    const { modeAdm } = this.props;
    if (getUserState(this.props.user) === STATE_NEEDS_TO_JOIN) {
      if (modeAdm) history.push("/register/profile");
      else history.push("/register/joinEstablishment");
    }
  }
  showSMS() {
    this.setState({ showPhone: true });
  }
  hideSMS(ev) {
    ev.preventDefault();
    this.setState({ showPhone: false });
  }
  sendWhatsappp(ev) {
    const { whatsappActivation } = this.props;
    whatsappActivation();
  }

  handleNumberChange(ev) {
    const { phone } = this.state;
    let s = ev.target.value;
    let old = unmaskPhone(phone);
    let str = unmaskPhone(s);

    let mask = DEFAULT_PHONE_MASK;
    let newValue = "";

    if (s.startsWith(US_CODE)) {
      mask = US_MASK;
    } else if (s.startsWith(ARG_CODE)) {
      mask = ARG_MASK;
    }

    let i = 0;

    for (let k = 0; k < mask.length; k++) {
      const m = mask[k];
      if (
        m !== "X" &&
        ((m !== "#" && str.length > old.length) ||
          (m !== "#" && str.length < old.length && str.length !== i))
      ) {
        newValue += m;
        continue;
      }

      if (i < str.length) {
        if (m !== "X" || str[i] !== "0") newValue += str[i];
      } else {
        break;
      }
      i++;
    }

    this.setState({
      phone: newValue,
    });
  }

  cancel(ev) {
    ev.preventDefault();
    history.push("/register/verify");
  }
  registerPhone(ev) {
    ev.preventDefault();

    const { user } = this.props;
    const { phone } = this.state;
    user.phone = unmaskPhone(phone);
    history.push("/register/verify-SMS");
  }

  render() {
    const { t, classes, loading } = this.props;
    const { phone, showPhone } = this.state;

    return (
      <div className="verification">
        <img alt="Web Logo" className="logo" src={logo} />
        {showPhone && (
          <Typography className="info">
            {t("create_user_info_single")}
          </Typography>
        )}
        <br />
        {loading && (
          <div className="loader">
            <Loader className="submit" visible={loading} />
          </div>
        )}
        {showPhone && (
          <Grid container>
            <Grid item xs></Grid>
            <Grid item lg={4} sm={6} xs={11}>
              <form onSubmit={this.registerPhone}>
                <IconTextField
                  inputProps={{
                    autoComplete: "off",
                    required: true,
                  }}
                  onChange={this.handleNumberChange}
                  value={phone}
                  name="phone"
                  label={t("number_example")}
                  helperText={t("number_warning")}
                  icon={<PhoneIcon />}
                />

                <FormControl
                  component="fieldset"
                  fullWidth
                  className={classes.formControl}
                >
                  <Button
                    color="primary"
                    type="submit"
                    className="add"
                    disabled={loading}
                    round
                  >
                    {t("send_sms")}
                  </Button>
                  <Link
                    className="bold option cancel mt-0"
                    href=""
                    onClick={this.hideSMS}
                  >
                    {t("cancel_verification")}
                  </Link>
                  <div>
                    <Loader className="submit" visible={loading} />
                  </div>
                </FormControl>
              </form>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        )}
        {!showPhone && !loading && (
          <Grid container>
            <Grid item xs></Grid>
            <Grid item lg={4} sm={6} xs={11}>
              <h2>{t("activate_account")}</h2>
              <div>
                <p className="my-4-2">{t("text_active_by_whatsapp")}</p>
                <Button
                  color="primary"
                  type="button"
                  className="add"
                  disabled={loading}
                  onClick={this.sendWhatsappp}
                >
                  {t("active_by_whatsapp")}
                </Button>
              </div>

              <div>
                <p className="my-4-2">{t("text_active_by_sms")}</p>
                <Button type="button" className="addSMS" onClick={this.showSMS}>
                  {t("active_by_sms")}
                </Button>
              </div>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.userReducer.loading || state.authReducer.loading,
  user: state.authReducer.user,
  modeAdm: state.authReducer.modeAdm,
});

const actionCreators = {
  whatsappActivation: whatsappActivation,
  getUser: getUser,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(PhoneVerification)));
