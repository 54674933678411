import {
  SPACES_GET_RESERVATIONS,
  SPACES_GET_CREDIT_USERS,
  SPACES_GET_CREDIT_USERS_FILTERED,
  SPACES_EDIT_CREDIT_USERS,
  SPACES_RESERVATIONS_REQUEST_ERROR,
  SPACE_LOADING,
  SPACE_LOADING_END,
  SPACE_FILTERED_LOAD,
  SPACES_GET_CREDIT_BALANCE,
  SPACE_FILTERED_LOAD_CREDIT,
} from "../actions/types";
const initialState = {
  spaces_reservations: [],
  spaces_credit_users_list: [],
  spaces_credit_users_current_list: [],
  reservations_status: true,
  spaces_current_list: [],
  spaces_credit_balance: [],
  spaces_credit_balance_current: [],
  loading: false,
};
export function spaceReducer(state = initialState, action) {
  switch (action.type) {
    case SPACES_GET_CREDIT_BALANCE:
      return {
        ...state,
        spaces_credit_balance_current: action.payload,
      };
    case SPACES_GET_RESERVATIONS:
      return {
        ...state,
        spaces_reservations: action.payload,
      };
    case SPACES_GET_CREDIT_USERS:
      return {
        ...state,
        spaces_credit_users_list: action.payload,
      };
    case SPACES_GET_CREDIT_USERS_FILTERED:
      return {
        ...state,
        spaces_credit_users_current_list: action.payload.data,
        loading: false,
      };
    case SPACES_EDIT_CREDIT_USERS:
      for (let i = 0; i < state.spaces_credit_users_list.length; i++) {
        if (state.spaces_credit_users_list[i].id == action.payload.id) {
          state.spaces_credit_users_list[i].credit = action.payload.credit;
          state.spaces_credit_users_list[i].modified = true;
        }
      }
      for (let i = 0; i < state.spaces_credit_users_current_list.length; i++) {
        if (state.spaces_credit_users_current_list[i].id == action.payload.id) {
          state.spaces_credit_users_current_list[i].credit =
            action.payload.credit;
          state.spaces_credit_users_current_list[i].modified = true;
        }
      }
      return {
        ...state,
        spaces_credit_users_list: state.spaces_credit_users_list,
        spaces_credit_users_current_list:
          state.spaces_credit_users_current_list,
      };
    case SPACES_RESERVATIONS_REQUEST_ERROR:
      return {
        ...state,
        reservations_status: action.payload,
      };
    case SPACE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SPACE_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case SPACE_FILTERED_LOAD:
      return {
        ...state,
        spaces_credit_balance_current: action.payload.data,
        loading: false,
      };
    case SPACE_FILTERED_LOAD_CREDIT:
      return {
        ...state,
        spaces_credit_balance: action.payload,
      };
    default:
      return state;
  }
}
