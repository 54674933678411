import { List, ListItem, ListItemText, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getInvitesAsHost } from "../../actions/user";
import Card from "../../components/common/Card/Card";
import CardBody from "../../components/common/Card/CardBody";
import CardHeader from "../../components/common/Card/CardHeader";
import GridContainer from "../../components/common/Grid/GridContainer";
import GridItem from "../../components/common/Grid/GridItem";
import Pass from "../../components/Pass/Pass";
import PassesSearch from "../../components/PassesApp/PassesSearch";
import PassList from "../../components/PassesApp/PassList";
import { PASS_TYPE_REQUEST } from "../../Constant";
import { PassContextProvider } from "../../context/PassContext";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  search: {
    padding: "10px",

    "& input.MuiInputBase-input": {
      padding: "11px 14px",
      fontSize: "14px",
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
}));

const PassRequest = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { hostInvites: passes } = useSelector((state) => state.userReducer);
  const { otherCompany } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [passRequests, setPassRequests] = useState([]);

  const onClose = () => {
    refreshList();
  };

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const refreshList = () => dispatch(getInvitesAsHost(t));

  useEffect(() => {
    setPassRequests(
      passes?.filter((pass) => pass.type === PASS_TYPE_REQUEST) || []
    );
  }, [passes]);

  useEffect(() => refreshList(), []);

  return (
    <PassContextProvider>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {otherCompany?.isExternalUser ? (
            <>
              <PassesSearch />
              <PassList requests />
            </>
          ) : (
            <Card className="single-card notifications">
              <CardHeader className="single-card-header" color="alternate">
                <h4 className={classes.singleHeader}>
                  {t("pass_request_title")}
                </h4>
              </CardHeader>
              <CardBody className={classes.noPadding}>
                {passRequests.length !== 0 && (
                  <div className={classes.search}>
                    <TextField
                      id="outlined-search"
                      label={`${t("search")}...`}
                      fullWidth
                      type="search"
                      variant="outlined"
                      onChange={onSearch}
                    />
                  </div>
                )}
                <List>
                  {passRequests.length === 0 ? (
                    <ListItem>
                      <ListItemText
                        primary={t("no_row_requests")}
                        className="noRow"
                      />
                    </ListItem>
                  ) : (
                    passRequests.map((pass) => (
                      <Pass
                        onClose={onClose}
                        passes={passes}
                        key={pass.id}
                        pass={pass}
                        filter={search}
                      />
                    ))
                  )}
                </List>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </PassContextProvider>
  );
};

export default PassRequest;
