import {
  DIALOG_MODIFIED,
  DIALOG_CLEAR_MODIFIED,
  DIALOG_HIDE_CONFIRMATION,
  DIALOG_OPEN,
  DIALOG_CLOSE,
  DIALOG_EXIT,
} from "../actions/types";

const initialState = {
  detailOpen: false,
  visible: false,
  modified: false,
  showConfirmation: false,
  params: {},
  dialog: null,
};

export function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case DIALOG_OPEN:
      return {
        ...state,
        modified: false,
        showConfirmation: false,
        visible: true,
        detailOpen: true,
        params: action.payload.params,
        dialog: action.payload.type,
      };
    case DIALOG_CLOSE:
      if (state.modified) {
        return {
          ...state,
          showConfirmation: true,
        };
      }
      return {
        ...state,
        detailOpen: false,
      };

    case DIALOG_EXIT:
      return {
        ...state,
        visible: false,
        modified: false,
        showConfirmation: false,
        detailOpen: false,
        params: {},
        dialog: null,
      };
    case DIALOG_MODIFIED:
      return {
        ...state,
        modified: true,
      };
    case DIALOG_CLEAR_MODIFIED:
      return {
        ...state,
        modified: false,
      };

    case DIALOG_HIDE_CONFIRMATION:
      return {
        ...state,
        showConfirmation: false,
      };
    default:
      return state;
  }
}
