import {
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  IconButton,
  Popover,
  TextField,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import { useStyle } from "./AccessEventFilterStyle";
import MomentUtils from "@date-io/moment";
import { withTranslation } from "react-i18next";
import DateFilter from "./DateFilter";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterQuery } from "../../../actions/events";
import { extractContentHtml, getBrowserLanguage } from "../../../utils/Utils";
import "moment/locale/es";
import "moment/locale/en-au";
import "moment/locale/pt-br";
import moment from "moment";
import ExcelFile from "react-data-export/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet";

moment.locale("es");

const EventFilter = (props) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = props;
  const stateEvents = useSelector((state) => state.events);
  const dispatch = useDispatch();

  const onFilter = (value) => {
    dispatch(changeFilterQuery(value));
  };

  const clearSearch = () => {
    dispatch(changeFilterQuery(""));
  };

  const onClosePopover = () => setAnchorEl(null);
  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const multiDataSet = [
    {
      columns: [
        { title: t("access_event_table_incoming"), width: { wpx: 200 } },
        { title: t("admin_comment"), width: { wpx: 150 } },
        { title: t("access_event_table_type"), width: { wpx: 150 } },
        { title: t("access_event_table_date"), width: { wpx: 150 } },
        { title: t("access_event_table_company"), width: { wpx: 150 } },
        { title: t("access_event_table_host"), width: { wpx: 150 } },
        { title: t("access_event_table_manager"), width: { wpx: 200 } },
        {
          title: t("access_event_table_authorizationType"),
          width: { wpx: 150 },
        },
        { title: t("access_event_table_receptionist"), width: { wpx: 150 } },
      ],
      data: stateEvents.eventsFilter?.map((item) => {
        return [
          { value: extractContentHtml(item.who) },
          { value: item.adminComment ? extractContentHtml(item.adminComment) : '' },
          { value: extractContentHtml(item.type) },
          { value: moment.unix(item.timestamp).format("DD/MM/YYYY HH:mm:ss") },
          { value: extractContentHtml(item.companies) },
          { value: extractContentHtml(item.hosts) },
          { value: extractContentHtml(item.managers) },
          { value: extractContentHtml(item.authorizationsType) },
          { value: extractContentHtml(item.securityGuard) },
        ];
      }),
    },
  ];

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      locale={getBrowserLanguage()}
      utils={MomentUtils}
    >
      <div className={classes.filterContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <Hidden mdUp>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.filterButton}
                  onClick={openPopover}
                  startIcon={<FilterListIcon />}
                >
                  {t("access_event_filter_button")}
                </Button>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={onClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Card>
                    <CardContent>
                      <DateFilter onSubmit={onClosePopover} />
                    </CardContent>
                  </Card>
                </Popover>
              </div>
            </Hidden>
            <Hidden smDown>
              <DateFilter />
            </Hidden>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} style={{ display: "flex" }}>
                {stateEvents.eventsFilter?.length === 0 ? (
                  <Button
                    disabled={stateEvents.eventsFilter?.length === 0}
                    className={classes.button}
                  >
                    {t("export_to_excel")}
                  </Button>
                ) : (
                  <ExcelFile
                    filename={t("access_event_export_filename")}
                    element={
                      <Button className={classes.button}>
                        {t("export_to_excel")}
                      </Button>
                    }
                  >
                    <ExcelSheet
                      dataSet={multiDataSet}
                      name={t("access_event_export_filename")}
                    />
                  </ExcelFile>
                )}
                <TextField
                  variant="standard"
                  value={stateEvents.filterQuery}
                  onChange={(e) => onFilter(e.target.value)}
                  placeholder={t("search")}
                  className={classes.search}
                  InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{
                          visibility: stateEvents.filterQuery
                            ? "visible"
                            : "hidden",
                        }}
                        onClick={clearSearch}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default withTranslation()(EventFilter);
