import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { notificationItemTouchedAction } from "../../actions/menu";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `3px solid ${theme.palette.warning.main}`,
  },
}));

const NotificationAnimationWatchMe = ({ children, valueToReact }, props) => {
  const { isNotificationItemTouched } = useSelector(
    (state) => state.menuReducer
  );
  const dispatch = useDispatch();
  const [isAnimation, setIsAnimation] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (isNotificationItemTouched) {
      if (isNotificationItemTouched.data === valueToReact) {
        setIsAnimation(true);
        setTimeout(() => {
          setIsAnimation(false);
          dispatch(notificationItemTouchedAction(null));
        }, 5000);
      }
    }
  }, [isNotificationItemTouched]);

  return (
    <div className={isAnimation ? classes.root : ""} {...props}>
      {children}
    </div>
  );
};

export default NotificationAnimationWatchMe;
