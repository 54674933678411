export const columns = [
  {
    Header: "description",
    accessor: "name",
  },
  {
    Header: "group",
    accessor: "status",
    action: "selectParkingGroup",
  },
  {
    Header: "assigned_user",
    accessor: "",
    action: "selectEmployees",
  },
  {
    Header: "actions",
    action: "actionsByCompany",
    short: 0,
  },
];
