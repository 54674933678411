export const DATE_FORMAT = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
  },
};

export const MONTH_FORMAT = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
  },
};

export function getDays() {
  return [
    {
      value: 0,
      name: "sunday",
    },
    {
      value: 1,
      name: "monday",
    },
    {
      value: 2,
      name: "tuesday",
    },
    {
      value: 3,
      name: "wednesday",
    },
    {
      value: 4,
      name: "thursday",
    },
    {
      value: 5,
      name: "friday",
    },
    {
      value: 6,
      name: "saturday",
    },
  ];
}

export function getMonths() {
  return [
    {
      value: 0,
      name: "january",
    },
    {
      value: 1,
      name: "february",
    },
    {
      value: 2,
      name: "march",
    },
    {
      value: 3,
      name: "april",
    },
    {
      value: 4,
      name: "may",
    },
    {
      value: 5,
      name: "june",
    },
    {
      value: 6,
      name: "july",
    },
    {
      value: 7,
      name: "august",
    },
    {
      value: 8,
      name: "september",
    },
    {
      value: 9,
      name: "october",
    },
    {
      value: 10,
      name: "november",
    },
    {
      value: 11,
      name: "december",
    },
  ];
}

export function loadDates(year, month) {
  const aux = [];
  let firstDay = new Date(year, month).getDay();

  let date = 1;
  for (let i = 0; i < 6; i++) {
    aux.push([]);
    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < firstDay) {
        aux[i].push("");
      } else if (date > this.daysInMonth(month, year)) {
        break;
      } else {
        aux[i].push(date);
        if (
          date === this.today.getDate() &&
          year === this.today.getFullYear() &&
          month === this.today.getMonth()
        ) {
          // today
        }
        date++;
      }
    }
  }
  return aux;
}

export function daysInMonth(iMonth, iYear) {
  return 32 - new Date(iYear, iMonth, 32).getDate();
}

export function generateMonthsOfYear() {
  const d = new Date();
  if (+this.currentYear === d.getFullYear()) {
    this.arrMonth = getMonths().filter((item) => item.value >= d.getMonth());
  } else {
    this.arrMonth = getMonths();
  }

  this.calendar = new Array(12).fill(0).map((x, i) => {
    const auxMonth = getMonths()[i];
    return {
      month: auxMonth,
      days: getDays(),
      cells: this.loadDates(this.currentYear, auxMonth.value),
    };
  });
}

export function onChangeMonth(month) {
  this.monthOfYear = this.calendar[month];
}

export const monthsENG = [
  { id: 1, name: "january", number: "01" },
  { id: 2, name: "february", number: "02" },
  { id: 3, name: "march", number: "03" },
  { id: 4, name: "april", number: "04" },
  { id: 5, name: "may", number: "05" },
  { id: 6, name: "june", number: "06" },
  { id: 7, name: "july", number: "07" },
  { id: 8, name: "august", number: "08" },
  { id: 9, name: "september", number: "09" },
  { id: 10, name: "october", number: "10" },
  { id: 11, name: "november", number: "11" },
  { id: 12, name: "december", number: "12" },
];
export const monthsESP = [
  { id: 1, name: "enero", number: "01" },
  { id: 2, name: "febrero", number: "02" },
  { id: 3, name: "marzo", number: "03" },
  { id: 4, name: "abril", number: "04" },
  { id: 5, name: "mayo", number: "05" },
  { id: 6, name: "junio", number: "06" },
  { id: 7, name: "julio", number: "07" },
  { id: 8, name: "agosto", number: "08" },
  { id: 9, name: "septiembre", number: "09" },
  { id: 10, name: "octubre", number: "10" },
  { id: 11, name: "noviembre", number: "11" },
  { id: 12, name: "diciembre", number: "12" },
];
export const monthsPOR = [
  { id: 1, name: "janeiro", number: "01" },
  { id: 2, name: "fevereiro", number: "02" },
  { id: 3, name: "março", number: "03" },
  { id: 4, name: "abril", number: "04" },
  { id: 5, name: "maio", number: "05" },
  { id: 6, name: "junho", number: "06" },
  { id: 7, name: "julho", number: "07" },
  { id: 8, name: "agosto", number: "08" },
  { id: 9, name: "setembro", number: "09" },
  { id: 10, name: "outubro", number: "10" },
  { id: 11, name: "novembro", number: "11" },
  { id: 12, name: "dezembro", number: "12" },
];
