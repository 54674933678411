export const columns = [
  {
    Header: "host",
    accessor: "host",
  },
  {
    Header: "type",
    accessor: "type",
  },
  {
    Header: "guests",
    accessor: "guests",
  },
  {
    Header: "date",
    accessor: "date",
  },
  {
    Header: "hour",
    accessor: "hour",
  },
  {
    Header: "days",
    accessor: "days",
  },
  {
    Header: "status",
    accessor: "status",
    action: "iconStatus",
  },
  {
    Header: "parking",
    accessor: "freeParking",
    action: "toggleParking",
  },
  {
    Header: "actions",
    accessor: "comments",
    action: "actions",
  },
];
