import React from "react";

import { Visibility, Clear, Check } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import Images from "../common/Images/Images";

const CellActions = (prop) => {
  const value = prop.data.value;
  const { t, showUser, tabRequests, tabUnassigneds } = prop;
  const data = prop.rowData.original;
  const column = prop.data.column;

  let cellContent = prop.data.render("Cell");
  let { btn_1, btn_2 } = "";
  if (typeof column.action === "undefined" || !column.action) {
    return <div>{cellContent}</div>;
  }

  switch (column.action) {
    case "link":
      if (showUser) {
        return (
          <Button
            onClick={() => prop.showUpdUser(data)}
            title={t("see_personal")}
            style={{ textTransform: "inherit" }}
          >
            {cellContent}
          </Button>
        );
      } else {
        return <div>{cellContent}</div>;
      }
    case "avatar":
      cellContent = (
        <Images
          key={value}
          class_name="avatar"
          src={process.env.REACT_APP_IMAGES_URL + value}
          avatar="1"
        />
      );
      break;
    case "actions":
      if (showUser) {
        btn_1 = (
          <Button
            onClick={() => prop.showUpdUser(data)}
            color="primary"
            title={t("see_personal")}
          >
            <Visibility />
          </Button>
        );
        btn_2 = (
          <Button
            onClick={() => prop.handleChangeStatus(data, "remove")}
            title={t("company_staff_remove")}
          >
            <Clear />
          </Button>
        );
      }
      if (tabRequests) {
        btn_1 = (
          <Button
            onClick={() => prop.handleChangeStatus(data, "approve")}
            color="primary"
            title={t("approve")}
          >
            <Check />
          </Button>
        );
        btn_2 = (
          <Button
            onClick={() => prop.handleChangeStatus(data, "reject")}
            title={t("reject")}
          >
            <Clear />
          </Button>
        );
      }
      if (tabUnassigneds) {
        btn_1 = (
          <Button
            onClick={() => prop.handleChangeStatus(data, "claim")}
            color="primary"
            title={t("company_staff_your_namager")}
          >
            <Check />
          </Button>
        );
      }
      break;
    default:
  }

  return (
    <div className="columnsContent">
      {cellContent}
      {btn_1}
      {btn_2}
    </div>
  );
};

export default CellActions;
