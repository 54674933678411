import { createStyles, Divider, List, withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import sidebarStyle from "../../../../assets/jss/material-dashboard-react/components/sidebarStyle";
import useRoutes from "../../../../hooks/useRoutes";
import { controlRoute } from "../../../../services/roleControls";
import Link from "../../ButtonLink/Link";
import MenuItem from "../MenuItem/MenuItem";
const MenuList = (props) => {
  const { classes, user, otherCompany, handleDrawerToggle } = props;
  const { routes } = useRoutes();
  return (
    <List className={classes.list}>
      <Divider
        className={
          otherCompany?.isExternalUser ? classes.dividerApp : classes.divider
        }
      />
      {routes.map((prop, key) => {
        if (
          !controlRoute(
            prop.action,
            user.companyManager,
            user.parkingManager,
            user.deskManager,
            user.charterManager,
            user.areaManager,
            user.spaceManager,
            otherCompany
          )
        ) {
          return null;
        }
        return (
          <div key={key}>
            {prop.href ? (
              <Link to={prop.href} className={classes.item}>
                <MenuItem handleDrawerToggle={handleDrawerToggle} {...prop} />
              </Link>
            ) : (
              <MenuItem handleDrawerToggle={handleDrawerToggle} {...prop} />
            )}
          </div>
        );
      })}
    </List>
  );
};
const useStyles = createStyles(sidebarStyle);
const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  otherCompany: state.authReducer.otherCompany,
});
const actionCreators = {};
export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(useStyles)(MenuList));
