import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  FormHelperText,
  TextField,
  FormControl,
  Grid,
} from "@material-ui/core";

const useStyles = createStyles(styles);

class IconTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      icon,
      label,
      onChange,
      inputProps,
      error,
      helperText,
      value,
      name,
    } = this.props;
    return (
      <FormControl
        fullWidth
        className={classes.formControl}
        error={error ? true : false}
      >
        <Grid
          container
          spacing={1}
          alignItems={helperText || error ? "center" : "flex-end"}
        >
          <Grid item>{icon}</Grid>
          <Grid item className="field">
            <TextField
              error={error ? true : false}
              inputProps={inputProps}
              label={label}
              onChange={onChange}
              value={value}
              name={name}
            />
            {(helperText || error) && (
              <FormHelperText>{error ? error : helperText}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </FormControl>
    );
  }
}

export default withStyles(useStyles)(IconTextField);
