import { createStyles, Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import React from "react";
import { CharterTravels } from "components/Charters/CharterTravels/CharterTravels";
import { CharterUsers } from "components/Charters/CharterUsers/CharterUsers";
import CharterReserveReport from "components/Charters/CharterReserves/CharterReservesReport";
import MessageDialog from "components/common/MessageDialog/MessageDialog";
import CharterUsageReport from "components/Charters/CharterUsageReport/CharterUsageReport";

const useStyles = createStyles(styles);

const Charters = (props) => {
  const { t } = props;
  const [tabSel, setTabSel] = useState(0);
  const [diagMsj, setDiagMsj] = useState({
    open: false,
    message: "",
  });

  const showMessaje = (msj) => {
    setDiagMsj({ open: true, message: msj });
  };

  const closeMessaje = () => {
    setDiagMsj({ diagMsj: { open: false, message: "" } });
  };

  const onChangeTabs = (event, newValue) => {
    setTabSel(newValue);
  };

  return (
    <div className="content-box parkingsContent">
      <div className="clearfix">
        <Tabs
          value={tabSel}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChangeTabs}
          className="pull-left"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label={t("charter_travel_tab")} />
          <Tab label={t("charter_reserve_tab")} />
          <Tab label={t("charter_usage_report_tab")} />
          <Tab label={t("charter_users")} />
        </Tabs>
      </div>
      {tabSel === 0 && <CharterTravels />}
      {tabSel === 1 && <CharterReserveReport t={t} showMessaje={showMessaje} />}
      {tabSel === 2 && <CharterUsageReport t={t} showMessaje={showMessaje} />}
      {tabSel === 3 && <CharterUsers />}
      <MessageDialog
        className="alertDialog"
        open={diagMsj.open}
        message={diagMsj.message}
        onClose={closeMessaje}
      ></MessageDialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.parkings.loading,
});

export default connect(mapStateToProps)(
  withTranslation()(withStyles(useStyles)(Charters))
);
