export function toCountries(ids, countries, user) {
  let c = [];

  ids.forEach((id) => {
    let userCountry = findCountry(id, user.residencesInfo);

    if (userCountry) {
      c.push(userCountry);
    } else {
      userCountry = findCountry(id, countries);
      if (userCountry) {
        c.push({ id: userCountry.id, type: userCountry.type });
      }
    }
  });

  return c;
}

export function findCountry(id, countries) {
  if (!countries) return null;
  return countries.find((c) => c.id === id);
}

export function getCompanyLocation(company, buildings) {
  for (let i = 0; i < buildings.length; i++) {
    const building = buildings[i];
    if (company.building === building.id) {
      return building.address;
    }
  }

  return "";
}

export function buildJoinCountry(user, country, countries) {
  let userRequestedResidences = user.requestedResidences || [];
  let userResidences = user.residences || [];

  let residencesInfo = toCountries(
    userRequestedResidences.concat(userResidences),
    countries,
    user
  );
  residencesInfo.push(country);
  let requestedResidences = userRequestedResidences;
  requestedResidences.push(country.id);

  return { residencesInfo, requestedResidences };
}

export function buildUpdateCountry(user, country, countries) {
  let userRequestedResidences = user.requestedResidences || [];
  let userResidences = user.residences || [];

  let residencesInfo = toCountries(
    userRequestedResidences.concat(userResidences),
    countries,
    user
  );
  let info = residencesInfo.find((c) => c.id === country.id);
  info.lat = country.lat;
  info.long = country.long;
  info.lot = country.lot;
  info.landlineNumber = country.landlineNumber;

  return { residencesInfo };
}
