export const controlRoute = (
  action,
  companyManager,
  parkingManager,
  deskManager,
  charterManager,
  areaManager,
  spaceManager,
  otherCompany
) => {
  switch (action) {
    case "accessEvent":
      return companyManager.isCompanyManager && !otherCompany?.isExternalUser;
    case "validatePasses":
      return companyManager.isPassManager && !otherCompany?.isExternalUser;
    case "companyStaff":
      return companyManager.isStaffManager && !otherCompany?.isExternalUser;
    case "roomReservation":
      return (
        companyManager.isFacilitiesManager && !otherCompany?.isExternalUser
      );
    case "Parkings":
      return parkingManager.isParkingManager && !otherCompany?.isExternalUser;
    case "Desks":
      return deskManager.isDeskManager && !otherCompany?.isExternalUser;
    case "Charters":
      return charterManager?.isCharterManager && !otherCompany?.isExternalUser;
    case "Areas":
      return areaManager?.isAreaManager && !otherCompany?.isExternalUser;
    case "Space":
      return spaceManager?.isSpaceManager && !otherCompany?.isExternalUser;
    case "joinEstablishment":
      return !otherCompany?.isExternalUser;
    case "myEstablishments":
      return !otherCompany?.isExternalUser;
    case "tutorials":
      return !otherCompany?.isExternalUser;
    case "myQR":
      return otherCompany?.isExternalUser;
    case "updateFacephoto":
      return otherCompany?.isExternalUser && otherCompany?.isAllowFaceId;
    default:
      return true;
  }
};
