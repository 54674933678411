import {
  GLOBAL_OPTIONS_ERROR,
  GLOBAL_OPTIONS_SUCCESS,
  GLOBAL_OPTIONS_REQUEST,
  HOST_INVITES_REQUEST,
  HOST_INVITES_SUCCESS,
  HOST_INVITES_ERROR,
  AUTHORIZATIONS_REQUEST,
  AUTHORIZATIONS_SUCCESS,
  AUTHORIZATIONS_ERROR,
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_FINISHED,
  USER_CLEAR,
  GET_FRIENDS,
} from "../actions/types";

const initialState = {
  appStrings: [],
  companies: [],
  countries: [],
  buildingsGroups: [],
  officeBuildings: [],
  friends: [],
  authorizations: null,
  hostInvites: null,
  notifications: null,
  professionTypes: [],
  loading: false,
};

const sortAscendingFriends = (a, b) => {
  if (a.fullname < b.fullname) {
    return -1;
  }
  if (a.fullname > b.fullname) {
    return 1;
  }
  return 0;
};

const getFullnameFriend = (friend) => {
  const LABEL_PENDING = "-pending-";
  let fullname = "";
  if (friend.firstname !== LABEL_PENDING) fullname = friend.firstname + " ";
  if (friend.lastname !== LABEL_PENDING) fullname += friend.lastname;
  return fullname;
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GLOBAL_OPTIONS_SUCCESS:
      return {
        ...state,
        appStrings: action.payload.appStrings,
        companies: action.payload.companies,
        countries: action.payload.countries,
        buildingsGroups: action.payload.buildingsGroups,
        officeBuildings: action.payload.officeBuildings,
        professionTypes: action.payload.professionTypes,
        loading: false,
      };
    case GLOBAL_OPTIONS_ERROR:
      return {
        ...state,
        loading: true,
      };

    case HOST_INVITES_REQUEST:
      return {
        ...state,
      };
    case HOST_INVITES_SUCCESS:
      return {
        ...state,
        hostInvites: action.payload || [],
      };
    case HOST_INVITES_ERROR:
      return {
        ...state,
      };
    case AUTHORIZATIONS_REQUEST:
      return {
        ...state,
      };
    case AUTHORIZATIONS_SUCCESS:
      return {
        ...state,
        authorizations: action.payload || [],
      };
    case AUTHORIZATIONS_ERROR:
      return {
        ...state,
      };

    case NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATIONS_FINISHED:
      return {
        ...state,
        notifications: action.payload || [],
      };
    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_FRIENDS:
      return {
        ...state,
        friends: action.payload
          .map((friend) => {
            return {
              ...friend,
              fullname: getFullnameFriend(friend),
            };
          })
          .sort(sortAscendingFriends),
      };
    case USER_CLEAR:
      return initialState;
    default:
      return state;
  }
}
