import { createTheme } from "@material-ui/core/styles";

export const getTheme = (customTheme) => {
  let mainTheme = createTheme({
    palette: {
      primary: {
        main: customTheme?.palette?.primary?.main || "#4caf50",
        dark: customTheme?.palette?.primary?.dark || "#2e7d32",
      },
    },
    mixins: {
      toolbar: {
        minHeight: "60px",
      },
      bottomMenu: {
        minHeight: "55px",
      },
    },
  });

  return createTheme(mainTheme, {
    overrides: {
      MuiDialogTitle: {
        root: {
          background: mainTheme.palette.primary.dark,
          color: "#FFF",
        },
      },
    },
  });
};
