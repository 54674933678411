import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { notificationItemTouchedAction } from "../../actions/menu";
import useRoutes from "../../hooks/useRoutes";

const useStyles = makeStyles({
  root: {
    "& .MuiCardContent-root": {
      padding: "10px",
      paddingBottom: "10px",
    },
    marginBottom: "5px",
    background: "#FAFAFA",
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
  },
  label: {
    lineHeight: 1.2,
  },
});

const CardContentAction = ({ action, data, children }) => {
  const [onAction, setOnAction] = useState(null);
  const history = useHistory();
  const { routePath } = useRoutes();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (action) {
      case "addGuest": {
        setOnAction(() => () => {
          dispatch(
            notificationItemTouchedAction({
              data,
              action,
            })
          );
          history.push(`/home${routePath.PASSES}`);
        });
        break;
      }

      case "acceptCountry": {
        setOnAction(null);
        break;
      }

      default:
        break;
    }
  }, []);
  return (
    <>
      {onAction ? (
        <CardActionArea onClick={onAction}>
          <CardContent>{children}</CardContent>
        </CardActionArea>
      ) : (
        <CardContent>{children}</CardContent>
      )}
    </>
  );
};

const Notification = ({ message, action, customData, timestamp, noRow }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      {noRow ? (
        <CardContent>
          <Typography className={classes.label}>
            {t("no_row_notifications")}
          </Typography>
        </CardContent>
      ) : (
        <CardContentAction action={action} data={customData}>
          <Typography className={classes.label}>{message}</Typography>
          <Typography color="primary" variant="caption">
            <Moment format="LLL">{new Date(timestamp * 1000)}</Moment>
          </Typography>
        </CardContentAction>
      )}
    </Card>
  );
};

export default Notification;
