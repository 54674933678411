import React from "react";
import ByCharters from "../reports/ByCharters";
import ByDetail from "../reports/ByDetail";

class CharterReserveReport extends React.Component {
  constructor(props) {
    super(props);
    this.handleList = this.handleList.bind(this);

    this.state = {
      list: "charters",
    };
  }

  handleList() {
    const { list } = this.state;
    if (list === "charters") this.setState({ list: "details" });
    else this.setState({ list: "charters" });
  }

  render() {
    const { list } = this.state;
    if (list === "charters")
      return <ByCharters handleList={this.handleList}></ByCharters>;
    else return <ByDetail handleList={this.handleList}></ByDetail>;
  }
}

export default CharterReserveReport;
