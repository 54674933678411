import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import ReactExport from "react-data-export";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { monthsENG, monthsESP, monthsPOR } from "utils/CalendarUtils";
import SnackbarMessage from "utils/SnackbarMessage";
import { filterListCredit, getRemainingSpaceCredit } from "../../actions/space";
import "./../common/ConfirmationDialog/ConfirmationDialog.scss";
import SpaceTables from "./SpaceTables";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class SpaceCreditBalance extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleConfirmChanges = this.handleConfirmChanges.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.getBalanceYears = this.getBalanceYears.bind(this);
    this.handleYearFilter = this.handleYearFilter.bind(this);
    let columns = [
      {
        Header: "space_credit_balance_year_month",
        accessor: "period",
        action: "balance_credit_date",
      },
      {
        Header: "space_credit_balance",
        accessor: "credit",
        action: "balance_credit",
      },
    ];
    this.state = {
      yearSelected: String(new Date().getFullYear()),
      searchText: "",
      columns: columns,
      globalPageIndex: 0,
      showConfirm: false,
      loadingConfirm: false,
      warning: "",
      search: [],
    };
    this.props.getList(t, this.state.yearSelected);
  }
  getBalanceYears() {
    const years = [];
    const currentYear = new Date().getFullYear();
    const beforeYear = currentYear - 1;
    const afterYear = currentYear + 1;
    years.push({ id: 0, value: String(beforeYear) });
    years.push({ id: 1, value: String(currentYear) });
    years.push({ id: 2, value: String(afterYear) });
    return years;
  }
  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }
  handleSearch(event) {
    let currentLanguage =
      navigator.language || navigator.userLanguage || "desconocido";
    currentLanguage = currentLanguage.toUpperCase();
    let monthNumbers = [];
    let result = [];
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    if (currentLanguage.includes("PT")) {
      monthNumbers = monthsPOR.filter((m) =>
        m.name.includes(text.toLowerCase())
      );
      this.props.listAll.forEach((e) => {
        const monthPeriod = e.period.slice(-2);
        const res = monthNumbers.find((n) => {
          return n.number === monthPeriod;
        });
        if (res) {
          result.push({
            period: e.period,
            credit: e.credit,
          });
        }
      });
    } else {
      if (currentLanguage.includes("EN")) {
        monthNumbers = monthsENG.filter((m) =>
          m.name.includes(text.toLowerCase())
        );
        this.props.listAll.forEach((e) => {
          const monthPeriod = e.period.slice(-2);
          const res = monthNumbers.find((n) => {
            return n.number === monthPeriod;
          });
          if (res) {
            result.push({
              period: e.period,
              credit: e.credit,
            });
          }
        });
      } else {
        monthNumbers = monthsESP.filter((m) =>
          m.name.includes(text.toLowerCase())
        );
        this.props.listAll.forEach((e) => {
          const monthPeriod = e.period.slice(-2);
          const res = monthNumbers.find((n) => {
            return n.number === monthPeriod;
          });
          if (res) {
            result.push({
              period: e.period,
              credit: e.credit,
            });
          }
        });
      }
    }
    this.props.filterList(result);
  }
  handleConfirmChanges(check) {
    const { t, listAll } = this.props;
    let users = [];
    for (let row of listAll) {
      if (row.modified) {
        users.push({ id: row.id, credit: row.credit });
      }
    }
    if (users.length) {
      this.setState({ loadingConfirm: true });
      this.props.setSpaceCreditUsers(t, users, check).then((res) => {
        this.setState({ loadingConfirm: false });
        if (typeof res != "undefined") {
          let userNames = [];
          if (res.length) {
            for (let id of res) {
              for (let row of listAll) {
                if (row.id === id) {
                  userNames.push(row.name);
                }
              }
            }
            let warning = (
              <>
                {t("space_credit_warning_text")}
                <br />
                <ul>
                  {userNames.map((user, index) => (
                    <li key={user + index}>
                      <b>{user}</b>
                    </li>
                  ))}
                </ul>
              </>
            );
            this.setState({ warning: warning });
            this.setState({ showConfirm: true });
          } else {
            SnackbarMessage.show("success", t("space_credit_success"));
            this.closeConfirm();
          }
        }
      });
    }
  }
  handleYearFilter(event) {
    this.setState({ yearSelected: event.target.value });
    this.props.getList(event.target.value);
  }
  closeConfirm() {
    this.setState({ showConfirm: false });
  }
  render() {
    const { t, list, listAll, loading } = this.props;
    const { searchText, columns, globalPageIndex, yearSelected } = this.state;
    const dataExport = list.map((c) => {
      let item = { period: c.period, credit: c.credit };
      return item;
    });
    return (
      <>
        <div className="body-box">
          <div className="search-box-2">
            <div className="column-left cols-2">
              <div className="filter_field">
                <label>{t("year")}</label>
                <FormControl className="input_status">
                  <Select
                    onChange={this.handleYearFilter}
                    fullWidth={true}
                    value={yearSelected}
                    displayEmpty
                    defaultValue=""
                  >
                    {this.getBalanceYears().map((data, j) => (
                      <MenuItem value={data.value} key={j}>
                        {data.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name={t("space_credit_balance")}>
                <ExcelColumn
                  label={t("space_credit_balance_year_month")}
                  value="period"
                />
                <ExcelColumn
                  label={t("space_balance_money_excel")}
                  value="credit"
                />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
          <SpaceTables
            data={searchText !== "" ? list : listAll}
            columns={columns}
            handleUpdate={(value) => this.handleUpdate(value)}
            t={t}
            globalPageIndex={globalPageIndex}
            setCurrentPage={this.setCurrentPage}
            loading={loading}
            isBalance={true}
          ></SpaceTables>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  list: state.spaceReducer.spaces_credit_balance_current,
  listAll: state.spaceReducer.spaces_credit_balance,
});
const mapActionsToProps = {
  getList: getRemainingSpaceCredit,
  filterList: filterListCredit,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(SpaceCreditBalance));
