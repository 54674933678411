import SnackbarMessage from "utils/SnackbarMessage";
import PassAppApi from "../services/PassAppApi";
import {
  SPACE_FILTERED_LOAD,
  SPACES_GET_RESERVATIONS,
  SPACES_GET_CREDIT_USERS,
  SPACES_GET_CREDIT_USERS_FILTERED,
  SPACE_LOADING,
  SPACE_LOADING_END,
  SPACES_GET_CREDIT_BALANCE,
  SPACE_FILTERED_LOAD_CREDIT,
} from "./types";
export const filterList = (result) => (dispatch) => {
  dispatch({
    type: SPACE_FILTERED_LOAD,
    payload: { data: result },
  });
};
export const filterListCredit = (result) => (dispatch) => {
  dispatch({
    type: SPACE_FILTERED_LOAD,
    payload: { data: result },
  });
};
export const getSpaceReservationsRequest = (t, from, to, status = "") => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;
  dispatch({ type: SPACE_LOADING });
  PassAppApi.getSpaceReservations(
    token,
    user.spaceManager.company,
    from,
    to,
    status
  )
    .then((response) => {
      dispatch({ type: SPACES_GET_RESERVATIONS, payload: response });
      dispatch(filterList(response));
      dispatch({ type: SPACE_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: SPACE_LOADING_END });
      SnackbarMessage.show(
        "error",
        "error_getting_charters_reservations_by_detail" +
          ": " +
          (err ? err.toString() : "")
      );
    });
};
export const getSpaceReservationsFilter = (t, from, to, status = "") => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;
  dispatch({ type: SPACE_LOADING });
  PassAppApi.getSpaceReservations(
    token,
    user.spaceManager.company,
    from,
    to,
    status
  )
    .then((response) => {
      dispatch({ type: SPACES_GET_RESERVATIONS, payload: response });
      dispatch(filterList(response));
      dispatch({ type: SPACE_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: SPACE_LOADING_END });
      SnackbarMessage.show(
        "error",
        "error_getting_charters_reservations_by_detail" +
          ": " +
          (err ? err.toString() : "")
      );
    });
};
export const filterCreditUsersList = (result) => (dispatch) => {
  dispatch({
    type: SPACES_GET_CREDIT_USERS_FILTERED,
    payload: { data: result },
  });
};
export const getSpaceCreditUsers = () => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;
  dispatch({ type: SPACE_LOADING });
  PassAppApi.getSpaceCreditUsers(token, user.spaceManager.company)
    .then((response) => {
      dispatch({ type: SPACES_GET_CREDIT_USERS, payload: response });
      dispatch(filterCreditUsersList(response));
      dispatch({ type: SPACE_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: SPACE_LOADING_END });
      SnackbarMessage.show(
        "error",
        "error_getting_space_credit_users" + ": " + (err ? err.toString() : "")
      );
    });
};
export const setSpaceCreditUsers = (t, users, check) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.spaceManager.company;
  return PassAppApi.setSpaceCreditUsers(token, company, users, check).catch(
    (err) => {
      SnackbarMessage.show(
        "error",
        t("error_set_space_credit_users") + ": " + (err ? err.toString() : "")
      );
    }
  );
};
export const getRemainingSpaceCredit = (year) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.spaceManager.company;
  return PassAppApi.getRemainingSpaceCredit(token, company, year)
    .then((res) => {
      dispatch({ type: SPACES_GET_CREDIT_BALANCE, payload: res });
      dispatch({ type: SPACE_FILTERED_LOAD_CREDIT, payload: res });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        "error get remaining space credit" + ": " + (err ? err.toString() : "")
      );
    });
};
