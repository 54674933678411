import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  createStyles,
  TextField,
  FormLabel,
} from "@material-ui/core";
import Button from "components/common/CustomButtons/Button.js";
import CancelIcon from "@material-ui/icons/Cancel";
import { onDialogClose, onDialogExited } from "../../actions/dialog";
import Loader from "../../utils/Loader";
import { withStyles } from "@material-ui/styles";
import GoogleMap from "../common/GoogleMap/GoogleMap";
import Muted from "../common/Typography/Muted";
import "./JoinCountry.scss";
import {
  joinCountry,
  saveRequest,
  silentlyJoinCountry,
  updateCountry,
} from "../../actions/establishments";
import { COUNTRY, STATE_LOGGED_IN } from "../../Constant";

class JoinCountry extends React.Component {
  constructor(props) {
    super(props);
    const { establishment, user } = this.props;

    let country = null;
    if (user.residencesInfo) {
      country = user.residencesInfo.find((r) => r.id === establishment.id);
    }

    let userResidences = user.residences || [];
    let userResquestedResidences = user.requestedResidences || [];

    this.state = {
      lot: country && country.lot ? country.lot : "",
      lat: country && country.lat ? country.lat : 0,
      long: country && country.long ? country.long : 0,
      landlineNumber:
        country && country.landlineNumber ? country.landlineNumber : "",
      errors: {},
      editMode:
        country &&
        userResidences
          .concat(userResquestedResidences)
          .indexOf(establishment.id) !== -1
          ? true
          : false,
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectedPosition = this.onSelectedPosition.bind(this);
    this.join = this.join.bind(this);
    this.update = this.update.bind(this);
  }

  renderSubmitButtons() {
    const { t, loading } = this.props;
    const { editMode } = this.state;

    if (editMode) {
      return (
        <Button onClick={this.update} disabled={loading} round color="primary">
          {t("update")}
        </Button>
      );
    }

    return (
      <Button onClick={this.join} disabled={loading} round color="primary">
        {t("join")}
      </Button>
    );
  }

  update() {
    const { establishment, countries, t, user } = this.props;
    const { lot, landlineNumber, lat, long } = this.state;

    this.props.updateCountry(
      {
        id: establishment.id,
        lat: lat,
        long: long,
        lot: lot,
        landlineNumber: landlineNumber,
        type: establishment.type,
      },
      user,
      countries,
      t
    );
  }

  join() {
    if (this.validInfo()) {
      const { establishment, countries, t, user, state } = this.props;
      const { lot, landlineNumber, lat, long } = this.state;

      if (state === STATE_LOGGED_IN) {
        this.props.joinCountry(
          {
            id: establishment.id,
            lat: lat,
            long: long,
            lot: lot,
            landlineNumber: landlineNumber,
            type: establishment.type,
          },
          user,
          countries,
          t
        );
      } else {
        this.props.saveRequest(() => {
          this.props.silentlyJoinCountry(
            {
              id: establishment.id,
              lat: lat,
              long: long,
              lot: lot,
              landlineNumber: landlineNumber,
              type: establishment.type,
            },
            user,
            countries,
            t
          );
        }, t("register_joined_country_success"));
      }
    }
  }

  onFieldChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      errors: {},
    });
  }

  validInfo() {
    const { establishment } = this.props;
    const { lot, landlineNumber, lat, long } = this.state;

    let errors = {};

    if (!lot) {
      errors = {
        lot: true,
      };
    }
    if (establishment.requiresLandlineNumber && !landlineNumber) {
      errors = {
        ...errors,
        landlineNumber: true,
      };
    }

    if (lat === 0 && long === 0 && establishment.type === COUNTRY) {
      errors = {
        ...errors,
        pinHouse: true,
      };
    }

    if (Object.entries(errors).length === 0) {
      return true;
    }

    this.setState({
      errors,
    });

    return false;
  }

  onSelectedPosition(pos) {
    pos.long = pos.lng;
    this.setState(pos);
  }

  render() {
    const {
      classes,
      t,
      open,
      loading,
      onDialogClose,
      onDialogExited,
      establishment,
    } = this.props;
    const { lot, landlineNumber, errors } = this.state;

    let currentLots = establishment ? establishment.lots : null;

    let lots = [];
    if (currentLots) {
      lots = currentLots
        .filter((c) => !c.hidden)
        .map((m) => {
          return (
            <MenuItem key={m.id} value={m.id}>
              {m.name}
            </MenuItem>
          );
        });
    }

    return (
      <Dialog
        className="join-country"
        fullWidth={true}
        maxWidth="sm"
        onClose={onDialogClose}
        onExited={onDialogExited}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>
          {establishment.type === COUNTRY
            ? t("join_country")
            : t("join_building")}
          <div className="dialog-title-button">
            <IconButton onClick={onDialogClose} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent className="dialog" dividers>
          <form className={classes.root}>
            <FormControl error={false} className={classes.formControl}>
              <InputLabel>{t("choose_destination")}</InputLabel>
              <Select value={"selected"} disabled={true}>
                <MenuItem value="selected">{establishment.name}</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              component="fieldset"
              className={classes.formControl}
              error={errors.lot}
            >
              <InputLabel>
                {establishment.type === COUNTRY
                  ? t("lot_number")
                  : t("floor_number")}
              </InputLabel>
              <Select name="lot" onChange={this.onFieldChange} value={lot}>
                {lots}
              </Select>
              {errors.lot && (
                <FormHelperText>
                  {establishment.type === COUNTRY
                    ? t("error_no_lot_number")
                    : t("error_no_floor_number")}
                </FormHelperText>
              )}
            </FormControl>

            {establishment.requiresLandlineNumber && (
              <FormControl
                component="fieldset"
                className={classes.formControl}
                error={errors.landlineNumber}
              >
                <TextField
                  error={errors.landlineNumber}
                  label={t("personal_number")}
                  name="landlineNumber"
                  fullWidth
                  inputProps={{ autoComplete: "new-password" }}
                  onChange={this.onFieldChange}
                  value={landlineNumber}
                  required
                />
                {errors.landlineNumber && (
                  <FormHelperText>
                    {t("error_no_particular_phone")}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            <FormControl
              component="fieldset"
              className={classes.formControl + " map "}
              error={errors.pinHouse}
            >
              {establishment.type === COUNTRY && (
                <FormLabel component="legend">{t("map_location")}</FormLabel>
              )}
              {errors.pinHouse && (
                <FormHelperText>{t("error_choose_location")}</FormHelperText>
              )}

              {establishment.type === COUNTRY && (
                <Muted>{t("map_location_info")}</Muted>
              )}

              <GoogleMap
                locked={establishment.type !== COUNTRY}
                establishment={establishment}
                onSelect={this.onSelectedPosition}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <div className="loader">
            <Loader className="submit" visible={loading} />
          </div>
          {this.renderSubmitButtons()}
        </DialogActions>
      </Dialog>
    );
  }
}

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  state: state.authReducer.state,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  establishment: state.dialogReducer.params.establishment,
  loading: state.establishmentReducer.loading,
});

const actionCreators = {
  joinCountry: joinCountry,
  updateCountry: updateCountry,
  silentlyJoinCountry: silentlyJoinCountry,
  saveRequest: saveRequest,
  onDialogClose: onDialogClose,
  onDialogExited: onDialogExited,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(JoinCountry)));
