import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  loadingContainer: {
    flexGrow: 1,
  },
  root: {},
  search: {
    flexGrow: 1,
    padding: "2px 7px",
    border: "1px solid #e6e6e6",
    borderRadius: "6px",
    backgroundColor: "#FFF",
  },
});
