import { Button, FormControl, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ParkingsTable from "components/Parkings/ParkingsTables";
import moment from "moment";
import React from "react";
import ReactExport from "react-data-export";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getUserEstablishments } from "utils/Utils";
import { getTimezoneLocal } from "utils/Utils";
import MomentUtils from "@date-io/moment";
import { filterList } from "actions/charter";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import { getCharterReservationsByCharter } from "actions/charter";

const columns = [
  {
    Header: "charter_report_travel",
    accessor: "name",
  },
  {
    Header: "charter_report_available_seats",
    accessor: "available",
    class: "cell-text-br",
  },
  {
    Header: "charter_report_reserved_seats",
    accessor: "reserved",
    class: "cell-text-br",
  },
  {
    Header: "charter_report_percentage_use",
    accessor: "percentage",
    class: "cell-text-br",
  },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ByCharters extends React.Component {
  constructor(props) {
    super(props);
    const { t, user, companies, company } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmReject = this.handleConfirmReject.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.processList = this.processList.bind(this);

    let userEstablishments = getUserEstablishments(user, companies, []);
    let selectedEstablishment = userEstablishments.find(
      (e) => e.id === company
    );
    let timezone =
      selectedEstablishment && selectedEstablishment.timezone
        ? selectedEstablishment.timezone
        : getTimezoneLocal();

    let date = moment().tz(timezone);

    this.state = {
      searchText: "",
      timezone: timezone,
      date: date,
      itemID: null,
      showConfirm: false,
      globalPageIndex: 0,
    };
    this.props.getList(t, date.format("YYYY-MM-DD"));
  }

  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }

  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        if (v.name && v.name.search(new RegExp(text, "i")) > -1) return true;
        if (v.user && v.user.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }

  handleDateChange(date) {
    if (date && date.isValid()) {
      this.setState({ date: date });
    }
  }

  handleFilter() {
    const { t } = this.props;
    const { date } = this.state;

    if (date) {
      this.props.getList(t, date.format("YYYY-MM-DD"));
    }
  }

  handleCancel(itemID) {
    this.setState({ showConfirm: true, itemID: itemID });
  }

  handleConfirmReject() {
    const { itemID, date } = this.state;
    this.props.rejectParkingReservation(
      this.props.t,
      itemID,
      date.format("YYYY-MM-DD")
    );
    this.closeConfirm();
  }

  closeConfirm() {
    this.setState({ showConfirm: false, itemID: null });
  }

  processList(list) {
    let res = [];
    list.forEach((l) => {
      res.push({
        ...l,
        name: l.hour + "hs " + l.name,
      });
    });
    return res;
  }

  render() {
    const { t, list, handleList, loading } = this.props;
    const { searchText, showConfirm, date, globalPageIndex } = this.state;

    const dataExport = list.map((item) => {
      return {
        name: item.name,
        available: item.available,
        percentage: item.percentage,
        hour: item.hour,
        reserved: item.reserved,
      };
    });

    return (
      <div className="body-box">
        <div className="search-box-2">
          <div className="column-left cols-1">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="filter_field">
                <label>{t("date")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={date}
                    onChange={(date) => this.handleDateChange(date)}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
            </MuiPickersUtilsProvider>
            <div className="filter_field">
              <Button onClick={this.handleFilter} className="filterButton">
                {t("filter")}
              </Button>
            </div>
          </div>
          <div className="column-rigth">
            <Button onClick={handleList}>{t("change_list_to_details")}</Button>
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name="Charters">
                <ExcelColumn label={t("reserv_travel_excel")} value="name" />
                <ExcelColumn label={t("hour")} value="hour" />
                <ExcelColumn
                  label={t("charter_report_excel_available_seat")}
                  value="available"
                />
                <ExcelColumn
                  label={t("charter_report_excel_reserved_seat")}
                  value="reserved"
                />
                <ExcelColumn
                  label={t("charter_report_excel_percentage_use")}
                  value="percentage"
                />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
        </div>
        <ParkingsTable
          data={this.processList(list)}
          columns={columns}
          handleCancel={(data) => this.handleCancel(data)}
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></ParkingsTable>
        <ConfirmationDialog
          open={showConfirm}
          onConfirm={this.handleConfirmReject}
          onClose={this.closeConfirm}
          title={t("confirm_reject_parkingReserv")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  company: state.authReducer.user.parkingManager.company,
  companies: state.userReducer.companies,
  list: state.charterReducer.charter_current_list,
  listAll: state.charterReducer.charter_reservation_charter,
  loading: state.charterReducer.loading,
});

const mapActionsToProps = {
  getList: getCharterReservationsByCharter,
  filterList,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(ByCharters));
