//RoomReservation
import PassAppApi from "../services/PassAppApi";
import SnackbarMessage from "../utils/SnackbarMessage";
import {
  AREAS_INITIAL,
  AREAS_LOAD,
} from "./types";

export const getAreasList = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const user = getState().authReducer.user;

  PassAppApi.getAreas(token, user.areaManager.company,)
    .then((response) => {
      const items = response.data.map((item) => {
        return { ...item };
      });
      dispatch({
        type: AREAS_INITIAL,
        payload: { data: items },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_areas")
      );
    });
};

export const filterAreas = (listItems, searchText) => {
  searchText = searchText ? searchText.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
  return listItems.filter((v) => {
    if (v.name && v.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').search(new RegExp(searchText, "i")) > -1)
      return true;
    if (v.description && v.description.normalize('NFD').replace(/[\u0300-\u036f]/g, '').search(new RegExp(searchText, "i")) > -1)
      return true;
    if (v.quantity && v.quantity.search(new RegExp(searchText, "i")) > -1)
      return true;
    if (v.type && v.type.search(new RegExp(searchText, "i")) > -1)
      return true;

    return false;
  });
};

export const filterAreasList = (list, text) => (dispatch) => {
  const result = filterAreas(list, text);
  dispatch({
    type: AREAS_LOAD,
    payload: { data: result },
  });
};