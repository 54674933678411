import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import Muted from "components/common/Typography/Muted";
import CopyComponent from "components/common/CopyComponent/CopyComponent";
import Button from "components/common/CustomButtons/Button.js";
import ShareIcon from "@material-ui/icons/Share";

import { connect } from "react-redux";
import { onDialogClose, onDialogExited } from "../../actions/dialog";

const useStyles = createStyles(styles);

class PassCreatedDialog extends React.Component {
  constructor(props) {
    super(props);

    this.onShare = this.onShare.bind(this);
    this.isEnableShare = this.isEnableShare.bind(this);
  }
  onShare() {
    const { dynamicLink, t } = this.props;
    try {
      if ("share" in navigator) navigator.share({ text: dynamicLink });
      if ("Android" in window)
        window.Android.share(dynamicLink, t("send_pass_share"));
    } catch (e) {
      console.error("share error", e);
    }
  }

  isEnableShare() {
    const { dynamicLink } = this.props;
    if ("canShare" in navigator)
      return navigator.canShare({ text: dynamicLink });
    if ("Android" in window) return true;
    return false;
  }

  render() {
    const { t, open, onDialogExit, onDialogClose, dynamicLink } = this.props;

    return (
      <Dialog
        onClose={onDialogClose}
        onExited={onDialogExit}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title" className="titleDialog">
          {t("pass_created")}
          <div className="iconRight">
            {this.isEnableShare() && (
              <IconButton onClick={this.onShare} aria-label="delete">
                <ShareIcon style={{ color: "#FFF" }} />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent className="center">
          <Muted>{t("pass_link_created")}</Muted>

          {dynamicLink && (
            <CopyComponent
              placement="bottom"
              button={(onClick) => {
                return (
                  <div>
                    <Button onClick={onClick} round color="primary">
                      {t("copy_link")}
                    </Button>
                  </div>
                );
              }}
              msg={t("link_copied")}
              show={true}
              link={dynamicLink}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button round onClick={onDialogClose} color="primary">
            {t("accept")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  appStrings: state.userReducer.appStrings,
  user: state.authReducer.user,
  dynamicLink: state.passesReducer.dynamicLink,
});

const actionCreators = {
  onDialogClose: onDialogClose,
  onDialogExit: onDialogExited,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(PassCreatedDialog)));
