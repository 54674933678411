import {
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Notifications from "@material-ui/icons/Notifications";
import PassRequestIcon from "../../assets/img/PassRequestIcon";
import QrCodeIcon from "../../assets/img/QrCodeIcon";
import GroupIcon from "@material-ui/icons/Group";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useActionButtonMenu from "../../hooks/useActionButtonMenu";

const menuValue = {
  NOTIFICATION: "notifications",
  PASSES: "passes",
  ACTION: "action",
  QR: "qr",
  REQUEST: "request",
};

const getItems = (t) => [
  {
    label: t("bottom_menu_notification"),
    value: menuValue.NOTIFICATION,
    icon: <Notifications />,
    path: "/home/notifications",
  },
  {
    label: t("bottom_menu_myguest"),
    value: menuValue.PASSES,
    icon: <GroupIcon />,
    path: "/home/passes",
  },
  {
    value: menuValue.ACTION,
  },
  {
    label: t("bottom_menu_myqr"),
    value: menuValue.QR,
    icon: <QrCodeIcon />,
    path: "/home/qr",
  },
  {
    label: t("bottom_menu_passrequest"),
    value: menuValue.REQUEST,
    icon: <PassRequestIcon />,
    path: "/home/request",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: theme.mixins.bottomMenu.minHeight,
    position: "relative",
    "& .MuiBottomNavigationAction-root": {
      minWidth: "60px",
    },
  },
  menu: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.dark,
    "& .MuiBottomNavigationAction-wrapper": {
      color: "rgba(255,255,255,0.5)",
    },
    "& .MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly": {
      paddingTop: "9px",
    },
    "& .MuiBottomNavigationAction-label": {
      display: "none",
    },
    "& .MuiBottomNavigationAction-label.Mui-selected": {
      fontSize: "0.7rem",
      display: "inline",
    },
  },
  selected: {
    "& .MuiBottomNavigationAction-wrapper": {
      color: "#fff",
    },
  },
  containerAction: {
    position: "absolute",
    top: `-${Number.parseInt(theme.mixins.bottomMenu.minHeight) / 2}px`,
    left: "0px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  action: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#fff",
    height: "fit-content",
    width: "fit-content",
    zIndex: 999,
  },
  spacingVoid: {
    width: "9%",
    height: "100%",
  },
}));

const BottomMenuItem = (props) => {
  const classes = useStyles();
  if (props.value === menuValue.ACTION) {
    if (props.noAction) return null;
    else
      return (
        <div aria-label="delete" className={classes.spacingVoid} disabled></div>
      );
  }

  return (
    <BottomNavigationAction
      {...props}
      className={props?.selected ? classes.selected : ""}
      disabled={props.value === menuValue.ACTION}
    />
  );
};

const BottomMenu = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = useState("");
  const { action } = useActionButtonMenu();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const redirect = (path) => history.push(path);

  useEffect(() => {
    if (
      getItems(t).some((item) => history.location.pathname.includes(item.path))
    ) {
      getItems(t).forEach((item) => {
        if (history.location.pathname.includes(item.path)) setValue(item.value);
      });
    } else setValue("");
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className={classes.menu}
      >
        {getItems(t).map((item) => (
          <BottomMenuItem
            key={item.value}
            selected={value === item.value}
            onClick={() => redirect(item.path)}
            noAction={!action}
            {...item}
          />
        ))}
      </BottomNavigation>
      <div className={classes.containerAction}>
        {action && (
          <IconButton
            aria-label="delete"
            className={classes.action}
            onClick={action.onClick}
          >
            {action.icon}
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default BottomMenu;
