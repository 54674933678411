import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import Button from "components/common/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import photoPlaceholder from "../../assets/img/upload_photo.png";

import { Typography, FormControl, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { FACE_PHOTO } from "../../Constant";
import { requestVerificationCode, getUser } from "../../actions/auth";
import "./UploadUserPhoto.scss";
import Loader from "../../utils/Loader";
import history from "../../history";
import { dataURLtoFile } from "../../utils/ImagesUtil";
import PassAppApi from "../../services/PassAppApi";
import SnackbarMessage from "../../utils/SnackbarMessage";

const useStyles = createStyles(styles);

class UploadUserPhoto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: null,
      ready: false,
      cameraAvailable: false,
      loading: false,
    };
    this.capture = this.capture.bind(this);
    this.reset = this.reset.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.next = this.next.bind(this);
    this.video = null;
    this.canvas = null;
    this.hiddenInput = null;
  }

  componentDidMount() {
    this.initCamera();
  }

  initCamera() {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          this.video = document.querySelector("video");
          this.canvas = document.querySelector("canvas");
          this.video.srcObject = mediaStream;

          this.video.onloadedmetadata = () => {
            this.canvas.width = this.video.videoWidth;
            this.canvas.height = this.video.videoHeight;
            this.setState({
              ready: true,
              cameraAvailable: true,
            });
          };
        })
        .catch(() => {
          this.setState({
            cameraAvailable: false,
          });
        });
    }
  }

  capture() {
    this.canvas.getContext("2d").drawImage(this.video, 0, 0);

    this.setState({
      currentImage: this.canvas.toDataURL("image/png"),
    });
  }

  next() {
    const { token, t } = this.props;
    const { currentImage } = this.state;

    this.setState({
      loading: true,
    });

    PassAppApi.uploadImage(token, dataURLtoFile(currentImage), FACE_PHOTO)
      .then(() => {
        this.setState({
          loading: false,
        });
        this.closeCamera();
        this.props.getUser(t);
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        SnackbarMessage.show("error", t("error_uploading_image"));
      });
  }

  closeCamera() {
    if (this.video && this.video.srcObject) {
      this.video.srcObject.getTracks().forEach(function(track) {
        track.stop();
      });
    }
  }

  reset() {
    this.setState({
      currentImage: null,
    });
  }

  uploadPhoto() {
    this.hiddenInput.click();
  }

  onImageChanged() {
    let file = this.hiddenInput.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        currentImage: reader.result,
      });
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  }

  render() {
    const { t, classes, user } = this.props;
    const { currentImage, ready, cameraAvailable, loading } = this.state;

    if (user.facePhoto && user.facePhoto !== "undefined") {
      history.push("/register/verify");
    }

    return (
      <div className="upload-user-photo">
        <Typography color="primary" className="title">
          {t("add_profile_photo")}
        </Typography>
        <Grid container className="photo-container">
          <Grid item xs></Grid>
          <Grid item lg={5} sm={5} xs={12}>
            {!cameraAvailable && !currentImage && (
              <img className="image" src={photoPlaceholder} alt="profile" />
            )}
            {currentImage && (
              <img className="image" src={currentImage} alt="profile" />
            )}
            <video
              className={currentImage || !cameraAvailable ? "hidden" : null}
              autoPlay
            ></video>
            <canvas className="hidden"></canvas>
            <input
              hidden
              ref={(e) => {
                this.hiddenInput = e;
                if (e) {
                  e.addEventListener("change", () => {
                    this.onImageChanged();
                  });
                }
              }}
              type="file"
              accept="image/*"
            />

            <FormControl
              component="fieldset"
              fullWidth
              className={classes.formControl}
            >
              <Grid container>
                <Grid item xs={4} className="upload">
                  {!currentImage && (
                    <Button
                      color="primary"
                      disabled={false}
                      round
                      onClick={this.uploadPhoto}
                    >
                      {t("upload_photo")}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!currentImage && cameraAvailable && (
                    <Button
                      color="primary"
                      disabled={!ready}
                      onClick={this.capture}
                      round
                    >
                      {t("capture")}
                    </Button>
                  )}
                  {currentImage && cameraAvailable && (
                    <Button
                      color="primary"
                      disabled={!ready}
                      onClick={this.reset}
                      round
                    >
                      {t("capture_again")}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4} className="right">
                  {currentImage && (
                    <Button color="primary" onClick={this.next} round>
                      {t("continue")}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <div>
                <Loader className="submit" visible={loading} />
              </div>
            </FormControl>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.authReducer.loading,
  token: state.authReducer.token,
  ready: state.authReducer.ready,
  user: state.authReducer.user,
});

const actionCreators = {
  requestVerificationCode: requestVerificationCode,
  getUser: getUser,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(UploadUserPhoto)));
