import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  Checkbox,
  MenuItem,
} from "@material-ui/core";
import Button from "components/common/CustomButtons/Button.js";
import ImputField from "components/common/Forms/ImputField.js";
import {
  onDialogOpen,
  onDialogClose,
  onDialogExited,
} from "../../actions/dialog";
import ImgDiag from "./ImgDiag";
import LloadPassManagers from "./loadPassManagers";
import LloadStaffManagers from "./loadStaffManagers";
import { Grid } from "@material-ui/core";

import {
  getCompanyManagers,
  saveCompanyStaff,
  checkPassManagerEmployees,
  checkStaffManagerEmployees,
  openPassManagers,
  openStaffManagers,
} from "../../actions/companyStaff";

import { withStyles } from "@material-ui/core/styles";

const useStyles = () => ({
  compStafDiag: {
    width: "100%",
    margin: "1em 0",
    minWidth: "270px",
  },
  formControlCompS: {
    width: "100%",
    margin: "1em 0",
    minWidth: "270px",
  },
  subtitle: {
    margin: 0,
    fontWeight: 600,
    color: "#43a047",
  },
});

class CompanyStaffShow extends React.Component {
  constructor(props) {
    super(props);
    const { t, object } = this.props;
    this.state = { ...object };
    this.onClose = this.onClose.bind(this);
    this.savePersonal = this.savePersonal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.cancelPassManagers = this.cancelPassManagers.bind(this);
    this.cancelStaffManagers = this.cancelStaffManagers.bind(this);
    this.newPassManagers = this.newPassManagers.bind(this);
    this.newStaffManagers = this.newStaffManagers.bind(this);
    this.props.getManagers(t);

    this.props.checkPassManagerEmployees(t, object).then((response) => {
      this.setState({ passManagerHasEmployees: response.hasEmployees });
    });

    this.props.checkStaffManagerEmployees(t, object).then((response) => {
      this.setState({ staffManagerHasEmployees: response.hasEmployees });
    });
  }

  cancelPassManagers() {
    this.props.openPassManagers(false);
  }

  cancelStaffManagers() {
    this.props.openStaffManagers(false);
  }

  newPassManagers(data) {
    const state = this.state;
    if (data) {
      this.setState({ ...state, newPassManager: data, isPassManager: false });
    } else {
      this.setState({ ...state, isPassManager: true });
    }
    this.props.openPassManagers(false);
  }

  newStaffManagers(data) {
    const state = this.state;
    if (data) {
      this.setState({ ...state, newStaffManager: data, isStaffManager: false });
    } else {
      this.setState({ ...state, isStaffManager: true });
    }
    this.props.openStaffManagers(false);
  }

  handleCheckChange(event) {
    const { object } = this.props;
    const state = this.state;

    if (
      event.target.name === "isStaffManager" &&
      !event.target.checked &&
      object.isStaffManager
    ) {
      if (state.staffManagerHasEmployees) {
        this.props.openStaffManagers(true);
      } else {
        this.setState({ isStaffManager: false });
      }
    } else if (
      event.target.name === "isPassManager" &&
      !event.target.checked &&
      object.isPassManager
    ) {
      if (state.passManagerHasEmployees) {
        this.props.openPassManagers(true);
      } else {
        this.setState({ isPassManager: false });
      }
    } else {
      this.setState({ ...state, [event.target.name]: event.target.checked });
    }
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  savePersonal() {
    const { t, saveCompanyStaff, onDialogClose, areas } = this.props;

    if (areas && !areas.length) {
      delete this.state.areas;
    }

    saveCompanyStaff(t, this.state);
    onDialogClose();
    this.cancelPassManagers();
  }
  onClose() {
    this.cancelPassManagers();
  }

  onConfirm() {}

  render() {
    const {
      t,
      open,
      onDialogExit,
      onDialogClose,
      managers,
      passManagers,
      areas,
      classes,
      allowParkingReservation,
      allowDeskReservation,
    } = this.props;
    const object = this.state;

    let showAreas = areas && areas.length > 0;

    return (
      <Dialog
        onClose={onDialogClose}
        onExited={onDialogExit}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle name="simple-dialog-title">{t("user_detail")}</DialogTitle>
        <DialogContent className="center">
          <Grid container className="block-user-staff" spacing={2}>
            <Grid item xs={12} sm={6}>
              <ImputField
                label={t("name")}
                name="firstname"
                inputProps={{ readOnly: true }}
                value={object.firstname}
              ></ImputField>
              <ImputField
                label={t("lastName")}
                name="lastname"
                inputProps={{ readOnly: true }}
                value={object.lastname}
              ></ImputField>
              <ImputField
                label={t("dni")}
                name="dni"
                inputProps={{ readOnly: true }}
                value={object.dni}
              ></ImputField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImputField
                label={t("phone")}
                name="phone"
                inputProps={{ readOnly: true }}
                value={object.phone}
              ></ImputField>
              <ImputField
                label={t("email")}
                name="email"
                inputProps={{ readOnly: true }}
                value={object.email}
              ></ImputField>
              <ImputField
                label={t("birth_date")}
                name="birthdate"
                inputProps={{ readOnly: true }}
                value={object.birthdate}
              ></ImputField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImputField
                label={t("dni_country")}
                name="dni_country"
                inputProps={{ readOnly: true }}
                value={object.countryCode}
              ></ImputField>
              <ImgDiag src={object.facePhoto} title={t("face_photo")} />
              <ImgDiag src={object.frontDNI} title={t("frontDNI_photo")} />
              <ImgDiag src={object.backDNI} title={t("backDNI_photo")} />
              <ImputField
                label={t("admin_comment")}
                name="adminComment"
                value={object.adminComment}
                onChange={this.handleChange}
              ></ImputField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <p className={classes.subtitle} color="primary">
                {t("special_permissions")}
              </p>
              <FormGroup row>
                <Grid item xs={6} className="specialPermissions">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={object.isStaffManager}
                        onChange={this.handleCheckChange}
                        name="isStaffManager"
                        color="primary"
                      />
                    }
                    label={t("user_manager")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={object.isFacilitiesManager}
                        onChange={this.handleCheckChange}
                        name="isFacilitiesManager"
                        color="primary"
                      />
                    }
                    label={t("room_reservation")}
                  />
                </Grid>
                <Grid item xs={6} className="specialPermissions">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={object.isPassManager}
                        onChange={this.handleCheckChange}
                        name="isPassManager"
                        color="primary"
                      />
                    }
                    label={t("passes_validator")}
                  />
                  {allowParkingReservation && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={object.isParkingManager}
                          onChange={this.handleCheckChange}
                          name="isParkingManager"
                          color="primary"
                        />
                      }
                      label={t("parking_manager")}
                    />
                  )}
                </Grid>
                <Grid item xs={6} className="specialPermissions">
                  {allowDeskReservation && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={object.isDeskManager}
                          onChange={this.handleCheckChange}
                          name="isDeskManager"
                          color="primary"
                        />
                      }
                      label={t("desk_manager")}
                    />
                  )}
                </Grid>
              </FormGroup>

              <FormControl className={classes.formControlCompS}>
                <InputLabel name="manager-label">
                  {t("user_manager")}
                </InputLabel>
                <Select
                  name="manager"
                  value={object.manager}
                  onChange={this.handleChange}
                  label={t("user_manager")}
                  fullWidth={true}
                >
                  {managers.map((data) => (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControlCompS}>
                <InputLabel name="passManager-label">
                  {t("passes_validator")}
                </InputLabel>
                <Select
                  labelname="passManager-label"
                  name="passManager"
                  value={object.passManager}
                  onChange={this.handleChange}
                  label={t("passes_validator")}
                  fullWidth={true}
                >
                  {passManagers.map((data) => (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {showAreas && (
                <FormControl className={classes.formControlCompS}>
                  <InputLabel name="areas-label">
                    {t("access_permissions")}
                  </InputLabel>
                  <Select
                    labelname="areas-label"
                    name="areas"
                    value={object.areas}
                    onChange={this.handleChange}
                    label={t("access_permissions")}
                    fullWidth={true}
                    multiple={true}
                    renderValue={(selected) =>
                      areas
                        .filter((area) => selected.includes(area.id))
                        .map((area) => area.name)
                        .join(", ")
                    }
                  >
                    {areas.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        <Checkbox
                          checked={object.areas.includes(data.id)}
                          color="primary"
                        />
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControlLabel
                className="full-width"
                control={
                  <Checkbox
                    checked={object.isAdmin}
                    onChange={this.handleCheckChange}
                    name="isAdmin"
                    color="primary"
                  />
                }
                label={t("super_range")}
              />
            </Grid>
          </Grid>
          <LloadPassManagers
            currentCompanyManager={object.companyManager}
            handleClose={this.cancelPassManagers}
            savePassManagers={this.newPassManagers}
            classe={classes.formControlCompS}
            current={object.newPassManagers}
          ></LloadPassManagers>
          <LloadStaffManagers
            currentCompanyManager={object.companyManager}
            handleClose={this.cancelStaffManagers}
            saveStaffManagers={this.newStaffManagers}
            classe={classes.formControlCompS}
            current={object.newStaffManagers}
          ></LloadStaffManagers>          
        </DialogContent>
        <DialogActions>
          <Button round onClick={onDialogClose}>
            {t("cancel")}
          </Button>
          <Button round onClick={this.savePersonal} color="primary">
            {t("accept")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  managers: state.companyStaff.managers,
  passManagers: state.companyStaff.passManagers,
  areas: state.authReducer.user.companyManager.areas,
  status: state.companyStaff.status,
  allowParkingReservation:
    state.authReducer.user.companyManager.allowParkingReservation,
  allowDeskReservation:
    state.authReducer.user.companyManager.allowDeskReservation,
});

const mapActionsToProps = {
  onDialogOpen: onDialogOpen,
  onDialogClose: onDialogClose,
  onDialogExit: onDialogExited,
  getManagers: getCompanyManagers,
  saveCompanyStaff: saveCompanyStaff,
  checkPassManagerEmployees: checkPassManagerEmployees,
  checkStaffManagerEmployees: checkStaffManagerEmployees,
  openPassManagers: openPassManagers,
  openStaffManagers: openStaffManagers,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(withStyles(useStyles)(CompanyStaffShow)));
