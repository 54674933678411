import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import { onDialogOpen } from "../../../actions/dialog";
import JoinCompany from "../../JoinCompany/JoinCompany";

class Company extends React.Component {
  constructor(props) {
    super(props);

    this.openCompany = this.openCompany.bind(this);
  }

  openCompany() {
    this.props.onDialogOpen(JoinCompany, {
      establishment: this.props.establishment,
    });
  }

  render() {
    const { establishment, pending, t } = this.props;
    let pendingText = pending ? " [" + t("pending") + "]" : "";

    return (
      <ListItem button onClick={this.openCompany}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary={establishment.name + pendingText} />
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  dialogParams: state.dialogReducer,
});

const actionCreators = {
  onDialogOpen: onDialogOpen,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(Company));
