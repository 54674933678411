import React from "react";
import ByUsers from "./reports/ByUsers";
import ByParkings from "./reports/ByParkings";

class ParkingsUsageReports extends React.Component {
  constructor(props) {
    super(props);
    this.handleList = this.handleList.bind(this);

    this.state = {
      list: "users",
    };
  }

  handleList() {
    const { list } = this.state;
    if (list === "parkings") this.setState({ list: "users" });
    else this.setState({ list: "parkings" });
  }

  render() {
    const { list } = this.state;

    if (list === "parkings")
      return <ByParkings handleList={this.handleList}></ByParkings>;
    else return <ByUsers handleList={this.handleList}></ByUsers>;
  }
}

export default ParkingsUsageReports;
