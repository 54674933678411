import React from "react";
import PropTypes from "prop-types";
import {
  ListItemText,
  ListItem,
  ListItemIcon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import "./ItemReserveDelete.scss";
import { Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import useCalendar from "hooks/useCalendar";
import PassAppApi from "services/PassAppApi";
import SnackbarMessage from "utils/SnackbarMessage";

const ItemReserveDelete = (props) => {
  const {
    data,
    tokenGenerated,
    company,
    charterReservationActive,
    setCharterReservationActive,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const { getMonthName, getWeekDayName } = useCalendar();
  const closeDialogDelete = () => {
    setIsOpen(false);
  };

  const confirmReservationDelete = () => {
    const reservation = [
      {
        datesToCancel: [data.date],
        charter: data.charter,
      },
    ];
    setCharterReservationsDelete(tokenGenerated, company, reservation);
    closeDialogDelete();
  };

  const handleClick = () => {
    setIsOpen(true);
  };
  const setCharterReservationsDelete = (
    tokenGenerated,
    company,
    reservations
  ) => {
    PassAppApi.setCharterReservations(tokenGenerated, company, reservations)
      .then(() => {
        SnackbarMessage.show("success", t("charter_reserve_cancel_msj"));
        setCharterReservationActive(
          charterReservationActive.filter((elm) => data.id !== elm.id)
        );
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          "error SetReservations" + ": " + (err ? err.toString() : "")
        );
      });
  };

  function convertDate(date) {
    let result;
    const dat = new Date(date);
    let arr = date.split("-");
    let weekDay = getWeekDayName(dat.getDay()).toLowerCase();
    const nameDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);
    let month = getMonthName(Number(arr[1]) - 1).toLowerCase();
    result =
      nameDay +
      " " +
      Number(arr[2]) +
      " " +
      t("chartar_reserve_of") +
      " " +
      month;
    return result;
  }
  return (
    <React.Fragment key={data.id}>
      <ListItem style={{ width: "23em" }}>
        <ListItemText
          primary={
            <Typography
              variant="subtitle1"
              fontWeight={700}
              color="#4caf50"
              component={"span"}
            >
              {convertDate(data.date)}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle1"
              fontWeight={700}
              color="#3c4858"
              component={"span"}
            >
              {data.hour}hs {data.name}
            </Typography>
          }
          style={{ paddingLeft: 8 }}
        />
        <ListItemIcon
          onClick={handleClick}
          style={{ justifyContent: "flex-end", display: "flex" }}
        >
          <Delete color="primary" fontSize="medium" />
        </ListItemIcon>
      </ListItem>
      <Dialog
        fullWidth={true}
        onClose={closeDialogDelete}
        className="confirmation-dialog"
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("charter_reserve_cancel_reservation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <Button onClick={closeDialogDelete} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={confirmReservationDelete} color="primary">
            {t("accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ItemReserveDelete;
ItemReserveDelete.propTypes = {
  data: PropTypes.object,
  tokenGenerated: PropTypes.string,
  company: PropTypes.string,
  charterReservationActive: PropTypes.array,
};
