import { refreshToken } from "./actions/auth";
import moment from "moment-timezone";

let isWaitToken = false;
export default function jwt({ dispatch, getState }) {
  return (next) => (action) => {
    if (!action || action === "undefined") {
      return null;
    }

    // is no logged user
    let obj = getState().authReducer.user;
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return next(action);
    }
    // is logged user
    let tokenFile = getState().authReducer.token_file;
    if (typeof tokenFile != "undefined") {
      if (!tokenFile.wait) {
        //already send
        let tokenExpiration = tokenFile.exp;
        if (tokenExpiration - moment().unix() < 60 && !isWaitToken) {
          isWaitToken = true;
          let refreshTokenProm = refreshToken(dispatch, getState);
          setTimeout(() => {
            isWaitToken = false;
          }, 15000);
          if (typeof refreshTokenProm != "undefined")
            return refreshTokenProm.then(() => next(action));
          else next(action);
        }
      }
    }
    return next(action);
  };
}

document.oncontextmenu = function() {
  return false;
};
