import { SvgIcon } from "@material-ui/core";
import React from "react";

const SpaceIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="60px"
      viewBox="0 0 640.000000 561.000000"
      fill="black"
    >
      <g
        transform="translate(0.000000,561.000000) scale(0.112000,-0.112000)"
        id="SpaceIcon"
      >
        <path
          d="M2458 3852 l-1428 -1427 0 -49 0 -50 298 -299 c163 -165 315 -312
336 -328 58 -43 102 -40 163 13 26 23 77 55 113 72 58 27 75 31 161 31 90 0
100 -2 171 -38 89 -43 157 -112 197 -197 23 -49 26 -67 26 -165 0 -99 -3 -115
-27 -165 -15 -31 -43 -74 -61 -95 -50 -59 -60 -82 -52 -124 6 -31 53 -82 317
-348 171 -171 325 -319 343 -329 75 -40 -26 -134 1531 1419 l1425 1422 -1 49
0 50 -317 318 c-174 174 -332 325 -351 334 -49 23 -87 12 -144 -42 -27 -25
-79 -60 -116 -77 -61 -29 -75 -32 -162 -31 -126 0 -188 25 -274 105 -161 150
-174 401 -28 561 72 79 80 121 33 184 -16 21 -163 173 -328 337 l-299 297 -50
0 -49 0 -1427 -1428z m1901 683 c-66 -98 -109 -235 -109 -350 0 -263 185 -511
445 -597 56 -18 92 -23 180 -23 131 0 214 22 315 84 l65 40 223 -222 222 -222
-320 -320 -320 -320 -37 37 c-21 19 -49 41 -62 48 -37 19 -85 4 -121 -37 -45
-51 -38 -98 22 -160 l43 -43 -920 -920 -920 -920 -221 221 -222 222 39 75
c147 287 54 636 -216 812 -186 121 -428 133 -627 31 l-75 -39 -222 222 -221
221 918 917 917 917 47 -44 c40 -39 53 -45 88 -45 34 0 48 6 76 34 53 53 44
115 -25 181 l-32 29 323 323 323 323 222 -222 222 -222 -20 -31z"
        />
        <path
          d="M3605 3961 c-50 -22 -81 -87 -65 -135 15 -48 305 -332 347 -341 66
-14 133 45 133 116 0 29 -19 52 -162 196 -137 138 -168 164 -198 168 -19 3
-44 1 -55 -4z"
        />
        <path
          d="M4230 3313 c-54 -37 -72 -91 -47 -139 23 -43 297 -311 331 -324 37
-14 84 1 117 36 18 19 24 37 24 70 0 44 -1 46 -166 209 -150 150 -168 165
-200 165 -19 0 -46 -8 -59 -17z"
        />
      </g>
    </SvgIcon>
  );
};

export default SpaceIcon;
