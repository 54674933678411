import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const headerStyle = (theme) => ({
  appBar: {
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    zIndex: "1029",
    color: grayColor[7],
    border: "0",
    borderRadius: "3px",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    backgroundColor: "white",
  },
  container: {
    ...container,
    minHeight: "70px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  moreInfo: {
    color: "#bbbbbb",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit,minmax(210px,220px))",
  },
  infoItem: {
    display: "inline-block",
    justifyContent: "center",
    paddingRight: "10px",
    color: "#656565",
    fontFamily: '"Gotham-Light",Helvetica,Arial,sans-serif',
    fontWeight: "400",
  },
  infoItems: {
    pointerEvents: "all",
    padding: "12px 30px",
    position: "relative",
    whiteSpace: "nowrap",
    fontSize: "16px",
    opacity: "1",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 12px",
    },
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
});

export default headerStyle;
