import MomentUtils from "@date-io/moment";
import { Button, FormControl, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filterList, getCharterReportByCharter } from "actions/charter";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import ParkingsTable from "components/Parkings/ParkingsTables";
import moment from "moment";
import React from "react";
import ReactExport from "react-data-export";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getTimezoneLocal, getUserEstablishments } from "utils/Utils";

const columns = [
  {
    Header: "charter_report_travel",
    accessor: "name",
    class: "cell-text-br",
  },
  {
    Header: "report_total",
    accessor: "total",
    class: "cell-text-br",
  },
  {
    Header: "report_used",
    accessor: "used",
    class: "cell-text-br",
  },
  {
    Header: "report_percent",
    accessor: "percentage",
    class: "cell-text-br",
  },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ByTravels extends React.Component {
  constructor(props) {
    super(props);
    const { t, user, companies, company } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmReject = this.handleConfirmReject.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

    let userEstablishments = getUserEstablishments(user, companies, []);
    let selectedEstablishment = userEstablishments.find(
      (e) => e.id === company
    );
    let timezone =
      selectedEstablishment && selectedEstablishment.timezone
        ? selectedEstablishment.timezone
        : getTimezoneLocal();
    let date = moment().tz(timezone);

    this.state = {
      searchText: "",
      from: date,
      to: date,
      status: "",
      itemID: null,
      showConfirm: false,
      statusLst: [
        "reserved",
        "canceled",
        "rejected",
        "used",
        "unused",
        "using",
      ],
      globalPageIndex: 0,
    };
    this.props.getList(t, date.format("YYYY-MM-DD"), date.format("YYYY-MM-DD"));
  }

  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }

  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        if (v.name && v.name.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }

  handleDateChange(date, field) {
    if (date && date.isValid()) {
      if (field === "from") {
        this.setState({ from: date });
      }

      if (field === "to") {
        this.setState({ to: date });
      }
    }
  }

  handleStatusFilter(event) {
    this.setState({ status: event.target.value });
  }

  handleFilter() {
    const { from, to, status } = this.state;
    this.props.getList(
      this.props.t,
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      status
    );
  }

  handleCancel(itemID) {
    this.setState({ showConfirm: true, itemID: itemID });
  }

  handleConfirmReject() {
    const { itemID, from, to, status } = this.state;
    this.props.rejectParkingReservation(
      this.props.t,
      itemID,
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      status
    );
    this.closeConfirm();
  }

  closeConfirm() {
    this.setState({ showConfirm: false, itemID: null });
  }

  render() {
    const { t, list, handleList, loading } = this.props;
    const { searchText, showConfirm, from, to, globalPageIndex } = this.state;

    const dataExport = list.map((item) => {
      return {
        name: item.name,
        total: item.total,
        used: item.used,
        percentage: item.percentage,
      };
    });

    return (
      <div className="body-box">
        <div className="search-box-2">
          <div className="column-left cols-2">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="filter_field">
                <label>{t("dete_from")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={from}
                    onChange={(date) => this.handleDateChange(date, "from")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
              <div className="filter_field">
                <label>{t("date_to")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={to}
                    onChange={(date) => this.handleDateChange(date, "to")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
            </MuiPickersUtilsProvider>
            <div className="filter_field">
              <Button onClick={this.handleFilter} className="filterButton">
                {t("filter")}
              </Button>
            </div>
          </div>
          <div className="column-rigth">
            <Button onClick={handleList}>{t("change_report_by_users")}</Button>
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name="Charters">
                <ExcelColumn label={t("reserv_travel_excel")} value="name" />
                <ExcelColumn label={t("report_total_excel")} value="total" />
                <ExcelColumn label={t("report_used_excel")} value="used" />
                <ExcelColumn
                  label={t("report_percent_excel")}
                  value="percentage"
                />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
        </div>
        <ParkingsTable
          data={list}
          columns={columns}
          handleCancel={(data) => this.handleCancel(data)}
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></ParkingsTable>
        <ConfirmationDialog
          open={showConfirm}
          onConfirm={this.handleConfirmReject}
          onClose={this.closeConfirm}
          title={t("confirm_reject_parkingReserv")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  company: state.authReducer.user.parkingManager.company,
  companies: state.userReducer.companies,
  list: state.charterReducer.charter_current_list,
  listAll: state.charterReducer.charter_reports_charter,
  loading: state.charterReducer.loading,
});

const mapActionsToProps = {
  getList: getCharterReportByCharter,
  filterList,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(ByTravels));
