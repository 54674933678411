export const columns = [
  {
    Header: "name",
    accessor: "name",
  },
  {
    Header: "description",
    accessor: "description",
  },
  {
    Header: "area_quantity",
    accessor: "quantity",
    action: "quantity",
  },
  {
    Header: "type",
    accessor: "type",
    action: "type",
  }
];
