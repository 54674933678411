import React from "react";
import ByTravels from "../reports/ByTravels";
import ByUsers from "../reports/ByUsers";

class CharterUsageReport extends React.Component {
  constructor(props) {
    super(props);
    this.handleList = this.handleList.bind(this);

    this.state = {
      list: "users",
    };
  }

  handleList() {
    const { list } = this.state;
    if (list === "users") this.setState({ list: "travels" });
    else this.setState({ list: "users" });
  }

  render() {
    const { list } = this.state;
    if (list === "users")
      return <ByUsers handleList={this.handleList}></ByUsers>;
    else return <ByTravels handleList={this.handleList}></ByTravels>;
  }
}

export default CharterUsageReport;
