import React from "react";
// @material-ui/core
import { withStyles } from "@material-ui/core/styles";
import { FormHelperText, TextField, FormControl } from "@material-ui/core";

const useStyles = () => ({
  formControlCompS: {
    width: "100%",
    margin: "1em 0",
  },
});

class ImputField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      label,
      onChange,
      inputProps,
      error,
      helperText,
      value,
      name,
    } = this.props;
    const classesForm = `${classes.formControlCompS} ${classes.formControl}`;

    return (
      <FormControl
        fullWidth
        className={classesForm}
        error={error ? true : false}
      >
        <TextField
          error={error ? true : false}
          inputProps={inputProps}
          label={label}
          onChange={onChange}
          value={value}
          name={name}
        />
        {(helperText || error) && (
          <FormHelperText>{error ? error : helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(useStyles)(ImputField);
