//companyStaff
import PassAppApi from "../services/PassAppApi";
import SnackbarMessage from "../utils/SnackbarMessage";
import {
  PARKINGS_LOAD,
  PARKINGS_LOADING,
  PARKINGS_LOADING_END,
  PARKINGS_GROUP_LOAD,
  PARKINGS_FILTERED_LOAD,
  PARKINGS_EMPLOYEES_LOAD,
  PARKINGS_CHECK_PARKING_GROUP,
  PARKINGS_CHECK_PARKING_LOT,
  PARKINGS_LOAD_RESERV_BY_PARKING,
  PARKINGS_LOAD_RESERV_BY_DETAIL,
  PARKINGS_LOAD_REPORT_BY_USERS,
  PARKINGS_LOAD_REPORT_BY_PARKING,
  PARKINGS_ACTION,
} from "./types";

export const filterList = (result) => (dispatch) => {
  dispatch({
    type: PARKINGS_FILTERED_LOAD,
    payload: { data: result },
  });
};
export const getGarageList = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });

  dispatch({
    type: PARKINGS_ACTION,
    payload: "getGarageList",
  });

  PassAppApi.parkingManagerSend_POST("getParkingLots", token, company)
    .then((response) => {
      dispatch({
        type: PARKINGS_LOAD,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_parking")
      );
    });
};

export const addParkingLots = (t, parkingLots) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;

  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("addParkingLots", token, company, {
    parkingLots: parkingLots,
  })
    .then(() => {
      dispatch(getGarageList(t));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_add_parkingLot") + ": " + (err ? err.toString() : "")
      );
    });
};

export const editParkingLot = (t, id, name) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("editParkingLot", token, company, {
    id: id,
    name: name,
  })
    .then(() => {
      dispatch(getGarageList(t));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_parkingLot") + ": " + (err ? err.toString() : "")
      );
    });
};

export const setParkingLotParkingGroup = (t, id, parkingGroupID) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;

  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST(
    "setParkingLotParkingGroup",
    token,
    company,
    { id: id, parkingGroup: parkingGroupID }
  )
    .then(() => {
      dispatch(getGarageList(t));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_parkingLot") + ": " + (err ? err.toString() : "")
      );
    });
};

export const setParkingLotUser = (t, id, userID) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;

  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("setParkingLotUser", token, company, {
    id: id,
    user: userID,
  })
    .then(() => {
      dispatch(getGarageList(t));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_parkingLot") + ": " + (err ? err.toString() : "")
      );
    });
};

export const unsetParkingLotUser = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("unsetParkingLotUser", token, company, {
    id: id,
  })
    .then(() => {
      dispatch(getGarageList(t));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_parkingLot") + ": " + (err ? err.toString() : "")
      );
    });
};

export const deleteParkingLot = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;

  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("deleteParkingLot", token, company, {
    id: id,
  })
    .then(() => {
      dispatch(getGarageList(t));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_deleting_parking") + ": " + (err ? err.toString() : "")
      );
    });
};

export const getParkingGroupsList = (t, filter = true) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_ACTION,
    payload: "getParkingGroupsList",
  });
  PassAppApi.parkingManagerSend_POST("getParkingGroups", token, company)
    .then((response) => {
      dispatch({
        type: PARKINGS_GROUP_LOAD,
        payload: { data: response },
      });
      if (filter) dispatch(filterList(response));
      dispatch({ type: PARKINGS_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_parking_group")
      );
    });
};
export const getEmployeesParkingGroups = (t, filter = true) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_ACTION,
    payload: "getEmployeesParkingGroups",
  });
  PassAppApi.parkingManagerSend_POST(
    "getEmployeesParkingGroups",
    token,
    company
  )
    .then((response) => {
      dispatch({
        type: PARKINGS_EMPLOYEES_LOAD,
        payload: { data: response },
      });
      if (filter) dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_parking_employees")
      );
    });
};
export const addParkingGroup = (t, name) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("addParkingGroup", token, company, {
    name: name,
  })
    .then(() => {
      dispatch(getParkingGroupsList(t));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_add_parking_group") + ": " + (err ? err.toString() : "")
      );
    });
};

export const editParkingGroup = (t, id, name) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("editParkingGroup", token, company, {
    id: id,
    name: name,
  })
    .then(() => dispatch(getParkingGroupsList(t)))
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_updating_parking_group") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: PARKINGS_LOADING_END }));
};

export const checkDeleteParkingLot = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_CHECK_PARKING_LOT,
    payload: false,
  });
  PassAppApi.parkingManagerSend_POST("checkDeleteParkingLot", token, company, {
    id: id,
  })
    .then((response) => {
      dispatch({
        type: PARKINGS_CHECK_PARKING_LOT,
        payload: response.count,
      });
      dispatch({ type: PARKINGS_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_deleting_parking_group") + ": " + (err ? err.toString() : "")
      );
    });
};
export const checkDeleteParkingGroup = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_CHECK_PARKING_LOT,
    payload: false,
  });
  PassAppApi.parkingManagerSend_POST(
    "checkDeleteParkingGroup",
    token,
    company,
    { id: id }
  )
    .then((response) => {
      dispatch({
        type: PARKINGS_CHECK_PARKING_GROUP,
        payload: response.count,
      });
      dispatch({ type: PARKINGS_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_deleting_parking_group") + ": " + (err ? err.toString() : "")
      );
    });
};

export const deleteParkingGroup = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST("deleteParkingGroup", token, company, {
    id: id,
  })
    .then(() => dispatch(getParkingGroupsList(t)))
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_deleting_parking_group") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: PARKINGS_LOADING_END }));
};
export const addEmployeeParkingGroup = (t, id, parkingGroup) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST(
    "addEmployeeParkingGroup",
    token,
    company,
    { id: id, parkingGroup: parkingGroup }
  )
    .then(() => dispatch(getEmployeesParkingGroups(t)))
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_add_employeeParkingGroup") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: PARKINGS_LOADING_END }));
};
export const removeEmployeeParkingGroup = (t, id, parkingGroup) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  PassAppApi.parkingManagerSend_POST(
    "removeEmployeeParkingGroup",
    token,
    company,
    { id: id, parkingGroup: parkingGroup }
  )
    .then(() => dispatch(getEmployeesParkingGroups(t)))
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_remove_employeeParkingGroup") +
          ": " +
          (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: PARKINGS_LOADING_END }));
};

/**
 * Parking Reservations
 */

export const getParkingReservationsByParkingLots = (t, date) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_ACTION,
    payload: "getParkingReservationsByParkingLots",
  });
  PassAppApi.parkingManagerSend_POST(
    "getParkingReservationsByParkingLots",
    token,
    company,
    { date: date }
  )
    .then((response) => {
      dispatch({
        type: PARKINGS_LOAD_RESERV_BY_PARKING,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_parkingLots")
      );
    });
};

export const getParkingReservationsByDetail = (t, from, to, status) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_ACTION,
    payload: "getParkingReservationsByDetail",
  });
  PassAppApi.parkingManagerSend_POST(
    "getParkingReservationsByDetail",
    token,
    company,
    { from: from, to: to, status: status }
  )
    .then((response) => {
      dispatch({
        type: PARKINGS_LOAD_RESERV_BY_DETAIL,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_details")
      );
    });
};

export const rejectParkingReservation = (t, item, from, to, status) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });

  PassAppApi.parkingManagerSend_POST(
    "rejectParkingReservation",
    token,
    company,
    { id: item }
  )
    .then(() => {
      if (to) {
        dispatch(getParkingReservationsByDetail(t, from, to, status));
      } else {
        dispatch(getParkingReservationsByParkingLots(t, from));
      }
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: PARKINGS_LOADING_END }));
};

/**
 * Parking Reports
 */
export const getParkingReportByUsers = (t, from, to) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_ACTION,
    payload: "getParkingReportByUsers",
  });
  PassAppApi.parkingManagerSend_POST(
    "getParkingReportByUsers",
    token,
    company,
    { from: from, to: to }
  )
    .then((response) => {
      dispatch({
        type: PARKINGS_LOAD_REPORT_BY_USERS,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_parkingLots")
      );
    });
};
export const getParkingReportByParkingGroups = (t, from, to) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.parkingManager.company;
  dispatch({ type: PARKINGS_LOADING });
  dispatch({
    type: PARKINGS_ACTION,
    payload: "getParkingReportByParkingGroups",
  });
  PassAppApi.parkingManagerSend_POST(
    "getParkingReportByParkingGroups",
    token,
    company,
    { from: from, to: to }
  )
    .then((response) => {
      dispatch({
        type: PARKINGS_LOAD_REPORT_BY_PARKING,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: PARKINGS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_parkingLots")
      );
    });
};
