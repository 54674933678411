import React from "react";
import { useTranslation } from "react-i18next";

const CellActions = (prop) => {
  const column = prop.data.column;
  let cellContent = prop.data.render("Cell"); 
  const { t } = useTranslation();

  switch (column.action) {
    case "type":
      return t("area_type_" + prop.data.value);
    case "quantity":
      if (prop.data.row.original?.maxQuantity) {
        return (
          <>
          {t("area_max_quantity") + ": " + prop.data.row.original?.maxQuantity} <br/> {t("area_max_current_quantity") + ": " + prop.data.row.original?.currentQuantity}
          </>
        );
      }
    default:
      return <div>{cellContent}</div>;
  }
};

export default CellActions;
