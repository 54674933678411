import React from "react";

import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import CellActions from "./CellActions";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import Loading from "components/common/MessageDialog/Loading";

import {
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";

import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";

const ParkingsTable = ({
  data,
  columns,
  handleUpdate,
  handleCancel,
  t,
  setCurrentPage,
  globalPageIndex,
  parkingGroups,
  listEmployees,
  parkingReservationMode,
  classTable,
  loading,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: globalPageIndex,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    () => {}
  );
  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };
  // Render the UI for your table

  const getColumnProp = (column, headerData) => {
    return Object.assign({}, column, { title: t(headerData.Header) });
  };
  const printColumnHead = (column) => {
    let classCSS = "cell-text ";
    if (typeof column.class !== "undefined") classCSS = classCSS + column.class;

    const title = t(`${column.render("Header")}`).split("<br/>");
    if (column.short !== 0) {
      return (
        <div className={classCSS}>
          <div className="box-TableTh-tit">
            {title[0]}
            {typeof title[1] !== "undefined" && (
              <span>
                <br />
                {title[1]}
              </span>
            )}
          </div>
          <div className="box-TableSort">
            <TableSortLabel
              active={column.isSorted}
              // react-table has a unsorted state which is not treated here
              direction={column.isSortedDesc ? "desc" : "asc"}
            />
            {!column.isSorted && (
              <SyncAltIcon className="colSort"></SyncAltIcon>
            )}
          </div>
        </div>
      );
    }
    return <span>{t(`${column.render("Header")}`)}</span>;
  };

  if (typeof classTable === "undefined" || !classTable) classTable = "";

  return (
    <TableContainer className={classTable + " fixed-height"}>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => (
                <TableCell
                  {...getColumnProp(
                    column.getHeaderProps(column.getSortByToggleProps()),
                    headerGroup.headers[j]
                  )}
                  key={j}
                  className={j === 0 ? "col-left" : ""}
                >
                  {printColumnHead(column)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      key={j}
                      className={j === 0 ? "col-left" : ""}
                    >
                      <CellActions
                        data={cell}
                        rowData={row}
                        t={t}
                        handleCancel={handleCancel}
                        handleUpdate={handleUpdate}
                        parkingGroups={parkingGroups}
                        listEmployees={listEmployees}
                        parkingReservationMode={parkingReservationMode}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {!loading && page.length === 0 && (
            <TableRow>
              <TableCell style={{ padding: "14px" }} colSpan="7">
                {t("no_result")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: t("all"), value: data.length },
              ]}
              colSpan={7}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              labelRowsPerPage={t("rows_per_page")}
              labelDisplayedRows={(params) => {
                return t("pagination")
                  .replace("%%FROM%%", params.from)
                  .replace("%%TO%%", params.to)
                  .replace("%%TOTAL%%", params.count);
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
      <Loading loading={loading}></Loading>
    </TableContainer>
  );
};

ParkingsTable.propTypes = {
  data: PropTypes.array.isRequired,
  changeStatus: PropTypes.func,
};

export default ParkingsTable;
