import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { Button, createStyles, Tab, Tabs } from "@material-ui/core";
import { onDialogOpen } from "../../actions/dialog";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CompanyStaffTable from "components/CompanyStaff/CompanyStaffTable";
import CompanyStaffShow from "components/CompanyStaff/CompanyStaffShow";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import SearchFilter from "components/common/Table/SearchFilter";
import "./CompanyStaff.scss";
import {
  getCompanyStaffList,
  getCompanyStaffRequests,
  getCompanyStaffUnassigned,
  filterCompanyStaffList,
  changeCompanyStaffList,
  changeCompanyStaffStatus,
} from "../../actions/companyStaff";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useStyles = createStyles(styles);

class CompanyStaff extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;
    this.onChangeTabs = this.onChangeTabs.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.setGlobalFilter = this.setGlobalFilter.bind(this);
    this.showUpdUser = this.showUpdUser.bind(this);
    this.handleConfirmUpd = this.handleConfirmUpd.bind(this);
    this.closeUpdStatus = this.closeUpdStatus.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

    this.props.getStaff(t);
    this.state = {
      tabSel: 0,
      currentDialog: null,
      globalFilter: "",
      showConfirmUpdStatus: false,
      dialogText: "",
      updStatus: null,
      itemID: null,
      globalPageIndex: 0,
    };
  }

  showUpdUser(item) {
    this.props.onDialogOpen(CompanyStaffShow, {
      object: item,
    });
  }
  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }
  handleChangeStatus(item, status) {
    const { t } = this.props;
    let text = t("confirm_text");
    switch (status) {
      case "remove":
        text = t("company_staff_confirm_status_remove");
        break;
      case "approve":
        text = t("company_staff_confirm_status_approve");
        break;
      case "reject":
        text = t("company_staff_confirm_status_reject");
        break;
      case "claim":
        text = t("company_staff_confirm_status_claim");
        break;
      default:
    }
    this.setState({
      showConfirmUpdStatus: true,
      dialogText: text,
      updStatus: status,
      item: item,
    });
  }

  setGlobalFilter(param) {
    const { tabSel } = this.state;
    const { staffList, requestsList, unassignedList, filterList } = this.props;
    switch (tabSel) {
      case 0:
        filterList(staffList, param);
        break;
      case 1:
        filterList(requestsList, param);
        break;
      case 2:
        filterList(unassignedList, param);
        break;
      default:
    }
    this.setState({
      globalFilter: param,
      globalPageIndex: 0,
    });
  }

  onChangeTabs(event, newValue) {
    const {
      t,
      staffList,
      requestsList,
      unassignedList,
      ChangeCurrentList,
      getStaff,
      getRequests,
      getUnassigned,
    } = this.props;
    switch (newValue) {
      case 0:
        ChangeCurrentList(staffList);
        getStaff(t);
        break;
      case 1:
        ChangeCurrentList(requestsList);
        getRequests(t);
        break;
      case 2:
        ChangeCurrentList(unassignedList);
        getUnassigned(t);
        break;
      default:
    }
    this.setState({
      tabSel: newValue,
      globalFilter: "",
      globalPageIndex: 0,
    });
  }

  closeUpdStatus = () => {
    this.setState({ showConfirmUpdStatus: false, dialogText: "" });
  };
  handleConfirmUpd = () => {
    this.setState({ showConfirmUpdStatus: false, dialogText: "" });
    const { t } = this.props;
    const { globalFilter, tabSel } = this.state;
    this.props.ChangeStatus(
      t,
      this.state.item,
      this.state.updStatus,
      tabSel,
      globalFilter
    );
  };

  render() {
    const { t, currentList } = this.props;
    const {
      tabSel,
      globalFilter,
      showConfirmUpdStatus,
      dialogText,
      globalPageIndex,
    } = this.state;
    const multiDataSet = [
      {
        columns: [
          { title: t("passapp_user_id"), width: { wpx: 200 } },
          { title: t("name"), width: { wpx: 100 } },
          { title: t("lastName"), width: { wpx: 100 } },
          { title: t("dni_number"), width: { wpx: 150 } },
          { title: t("dni_country"), width: { wpx: 150 } },
          { title: t("admin_comment"), width: { wpx: 150 } },
          { title: t("phone"), width: { wpx: 100 } },
          { title: t("email"), width: { wpx: 200 } },
          { title: t("association_date"), width: { wpx: 150 } },
        ],
        data: currentList?.map((item) => {
          return [
            { value: item.id || "" },
            { value: item.firstname || "" },
            { value: item.lastname || "" },
            { value: item.dni || "" },
            { value: item.countryCode || "" },
            { value: item.adminComment || "" },
            { value: item.phone || "" },
            { value: item.email || "" },
            { value: item.associationDate || "" },
          ];
        }),
      },
    ];
    return (
      <div className="content-box CompanyStaffContent">
        <div className="clearfix">
          <Tabs
            value={tabSel}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onChangeTabs}
            aria-label="disabled tabs example"
            className="pull-left"
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab label={t("actived_person")} />
            <Tab label={t("new_requests")} />
            <Tab label={t("unmanaged_staff")} />
          </Tabs>
        </div>

        <div className="body-box">
          <div className="search-box-2 containerRight">
            {tabSel === 0 && (
              <>
                {currentList?.length === 0 ? (
                  <Button disabled={currentList?.length === 0}>
                    {t("export_to_excel")}
                  </Button>
                ) : (
                  <ExcelFile
                    filename={t("actived_person")}
                    element={
                      <Button disabled={currentList?.length === 0}>
                        {t("export_to_excel")}
                      </Button>
                    }
                  >
                    <ExcelSheet
                      dataSet={multiDataSet}
                      name={t("actived_person")}
                    />
                  </ExcelFile>
                )}
              </>
            )}
            <SearchFilter
              className="search-box"
              setGlobalFilter={this.setGlobalFilter}
              globalFilter={globalFilter}
              t={t}
            />
          </div>
        </div>
        <CompanyStaffTable
          data={currentList}
          handleChangeStatus={this.handleChangeStatus}
          t={t}
          showUpdUser={this.showUpdUser}
          showUser={tabSel === 0}
          tabRequests={tabSel === 1}
          tabUnassigneds={tabSel === 2}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
        />
        <ConfirmationDialog
          open={showConfirmUpdStatus}
          onConfirm={this.handleConfirmUpd}
          onClose={this.closeUpdStatus}
          title={dialogText}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentList: state.companyStaff.currentList,
  staffList: state.companyStaff.staffList,
  requestsList: state.companyStaff.requestsList,
  unassignedList: state.companyStaff.unassignedList,
});

const mapActionsToProps = {
  getStaff: getCompanyStaffList,
  getRequests: getCompanyStaffRequests,
  getUnassigned: getCompanyStaffUnassigned,
  filterList: filterCompanyStaffList,
  ChangeCurrentList: changeCompanyStaffList,
  ChangeStatus: changeCompanyStaffStatus,
  onDialogOpen: onDialogOpen,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(withStyles(useStyles)(CompanyStaff)));
