import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class LloadPassManagers extends React.Component {
  constructor(props) {
    super(props);
    const { current } = this.props;
    this.state = { value: typeof current !== "undefined" ? current : "" };
    this.savePassManagers = this.savePassManagers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  savePassManagers() {
    const { savePassManagers } = this.props;
    savePassManagers(this.state.value);
  }
  render() {
    const {
      t,
      handleClose,
      openPassManagers,
      passManagers,
      currentCompanyManager,
      classe,
    } = this.props;
    return (
      <div>
        <Dialog
          open={openPassManagers}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {t("select_a_pass_validator1")}
            <br />
            {t("select_a_pass_validator2")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <FormControl className={classe}>
              <Select
                labelname="passManager-label"
                name="passManager"
                label={t("passes_validator")}
                onChange={this.handleChange}
                fullWidth={true}
                value={this.state.value}
              >
                {passManagers.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    disabled={data.id === currentCompanyManager}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("cancel")}
            </Button>
            <Button onClick={this.savePassManagers} color="primary">
              {t("save")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  openPassManagers: state.companyStaff.openPassManagers,
  passManagers: state.companyStaff.passManagers,
});

const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(LloadPassManagers));
