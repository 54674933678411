import { Typography } from "@material-ui/core";
import React from "react";

const PassesBlock = ({ title, children }) => {
  return (
    <div>
      <Typography color="primary" variant="h6">
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default PassesBlock;
