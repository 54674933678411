import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import {
  CHARTERS_AVAILABILITY_REQUEST,
  CHARTERS_SET_INVALID_DATA_STATUS,
  CHARTERS_SET_VALID_DATA_STATUS,
  CHARTER_RESERVES_MIXED_STATUS,
} from "actions/types";
import ItemReserve from "components/ItemReserve/ItemReserve";
import "dayjs/locale/es";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useDispatch, useSelector } from "react-redux";
import PassAppApi from "services/PassAppApi";
import SnackbarMessage from "utils/SnackbarMessage";
import "./CharterReserve.scss";
export default function CharterReserve(props) {
  const { tokenGenerated, companyId, companyName } = props;
  const [loadingData, setLoadingData] = React.useState(true);
  const [
    chartersAvailabilityFiltered,
    setCharterAvailabilityFiltered,
  ] = React.useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    charters_selected_add,
    charters_selected_cancel,
    validDateStatus,
    invalidDatesStatus,
    charter_reserves_mixed_status,
    charters_availability,
    charters_reservations,
  } = useSelector((state) => state.charterReducer);
  useEffect(() => {
    if (charters_availability.length) {
      setCharterAvailabilityFiltered(
        charters_availability.sort((x, y) =>
          compareHours(x.charter.hour, y.charter.hour)
        )
      );
      dispatch({
        type: CHARTERS_AVAILABILITY_REQUEST,
        payload: chartersAvailabilityFiltered,
      });
      setLoadingData(false);
    }
  }, [charters_availability]);
  const getNewCharterAvailability = (tokenGenerated, company) => {
    if (tokenGenerated !== "") {
      PassAppApi.getChartersAvailability(tokenGenerated, company)
        .then((opts) => {
          //se extraen las reservaciones seleccionadas
          const reservations = getReservationsSelected(opts);
          if (reservations.length !== 0)
            if (charter_reserves_mixed_status) {
              setCharterReservationsMixed(
                tokenGenerated,
                companyId,
                reservations
              );
            } else {
              setCharterReservations(tokenGenerated, companyId, reservations);
            }
        })
        .catch((err) => {
          console.log(err);
          SnackbarMessage.show(
            "error",
            "error GetAvailability" + ": " + (err ? err.toString() : "")
          );
        });
    }
  };
  const compareHours = (a, b) => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };
  const changeUrl = () => {
    let stateObj = { id: "100" };
    window.history.replaceState(
      stateObj,
      "Passapp Web",
      "/#status:complete#action:back"
    );
  };
  const closeDialogConfirmDateValid = () => {
    dispatch({
      type: CHARTERS_SET_VALID_DATA_STATUS,
      payload: false,
    });
  };
  const closeDialogConfirmDateInvalid = () => {
    dispatch({
      type: CHARTERS_SET_INVALID_DATA_STATUS,
      payload: false,
    });
  };
  const setCharterReservations = (tokenGenerated, company, reservations) => {
    PassAppApi.setCharterReservations(tokenGenerated, company, reservations)
      .then(() => {
        SnackbarMessage.show("success", t("charter_reserve_msg_confirm_dates"));
        changeUrl();
      })
      .catch((err) => {
        console.log(err);
        SnackbarMessage.show(
          "error",
          "error SetReservations" + ": " + (err ? err.toString() : "")
        );
      });
  };
  const setCharterReservationsMixed = (
    tokenGenerated,
    company,
    reservations
  ) => {
    PassAppApi.setCharterReservations(tokenGenerated, company, reservations)
      .then(() => {
        SnackbarMessage.show("success", t("charter_reserve_msg_confirm_dates"));
        dispatch({
          type: CHARTER_RESERVES_MIXED_STATUS,
          payload: false,
        });
      })
      .catch((err) => {
        console.log(err);
        SnackbarMessage.show(
          "error",
          "error SetReservations" + ": " + (err ? err.toString() : "")
        );
      });
  };
  const processDates = (dates) => {
    let res = [];
    let day;
    let month;
    let monthSel;
    dates.forEach((d) => {
      monthSel = d.month + 1;
      if (monthSel < 10) {
        month = "0" + String(monthSel);
      } else {
        month = monthSel;
      }
      if (d.day < 10) {
        day = "0" + String(d.day);
      } else {
        day = d.day;
      }
      res.push(`${d.year}-${month}-${day}`);
    });
    return res;
  };
  const processDatesMixed = (dates) => {
    let res = [];
    dates.forEach((d) => {
      res.push(d.date);
    });
    return res;
  };
  const verifyAvailability = (availability, datesAdd) => {
    let res = true;
    if (datesAdd.length) {
      datesAdd.forEach((da) => {
        const month = da.month + 1;
        const monthFormatted = month < 10 ? 0 + String(month) : String(month);
        const dayFormatted = da.day < 10 ? 0 + String(da.day) : String(da.day);
        const dateString = da.year + "-" + monthFormatted + "-" + dayFormatted;
        const found = availability.find(
          (f) => f.date === dateString && f.total !== 0 && f.percentage !== 100
        );
        if (!found) {
          res = false;
        }
      });
    } else {
      res = false;
    }
    return res;
  };
  const getReservesAvailable = (availability, datesAdd) => {
    let reservesAvailable = [];
    datesAdd.forEach((da) => {
      const month = da.month + 1;
      const monthFormatted = month < 10 ? 0 + String(month) : String(month);
      const dateString = da.year + "-" + monthFormatted + "-" + da.day;
      const found = availability.find(
        (f) => f.date === dateString && f.total !== 0 && f.percentage !== 100
      );
      if (found) {
        reservesAvailable.push(found);
      }
    });
    return reservesAvailable;
  };
  const getReservationsSelected = (availabilities) => {
    const res = [];
    let datesAdd = [];
    let datesCancel = [];
    let charterId;
    //recorro la lista de disponibles y filtro por charter
    availabilities.forEach((c) => {
      charterId = c.charter.id;
      let availability = c.availability;
      //Si hay nuevas reservas seleccionadas
      if (charters_selected_add.length !== 0) {
        datesAdd = charters_selected_add.filter(
          (c) => c.charterId === charterId
        );
      }
      //Si hay nuevas reservas canceladas
      if (charters_selected_cancel.length !== 0) {
        datesCancel = charters_selected_cancel.filter(
          (c) => c.charterId === charterId
        );
      }
      // Si hay reservas canceladas
      if (datesCancel.length > 0) {
        res.push({
          ...res,
          datesToCancel: processDates(datesCancel),
          charter: charterId,
        });
      }
      // Si hay hay nuevas reservas o reservas canceladas
      if (datesAdd.length > 0) {
        //verifico las disponibilidades
        const verify = verifyAvailability(availability, datesAdd);
        //Si las reservas estan disponibles
        if (verify) {
          res.push({
            ...res,
            ...res,
            datesToAdd: processDates(datesAdd),
            charter: charterId,
          });
        } else {
          dispatch({
            type: CHARTER_RESERVES_MIXED_STATUS,
            payload: false,
          });
          const reservesAvailable = getReservesAvailable(
            availability,
            datesAdd
          );
          res.push({
            ...res,
            datesToAdd: processDatesMixed(reservesAvailable),
            charter: charterId,
          });
          dispatch({
            type: CHARTERS_SET_INVALID_DATA_STATUS,
            payload: true,
          });
        }
      }
    });
    return res;
  };
  const onConfirmReserve = () => {
    if (
      charters_selected_add.length === 0 &&
      charters_selected_cancel.length === 0
    ) {
      SnackbarMessage.show(
        "error",
        t("charter_reserve_msg_missing_dates") + " "
      );
    } else {
      //se consulta las disponibilidades y se procesan las reservas
      getNewCharterAvailability(tokenGenerated, companyId);
    }
  };
  return (
    <div className="root">
      {loadingData ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ flexGrow: 1 }}
        >
          <Grid item></Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Box style={{ paddingTop: "2em" }} sx={{ minWidth: 100 }}>
                  {companyName.toUpperCase()}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              style={{ marginBottom: "4em" }}
            >
              <Grid item xs={12}>
                <Box>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 400,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    {chartersAvailabilityFiltered.map((prop, key) => {
                      return (
                        <ItemReserve
                          key={key}
                          data={prop}
                          reservations={charters_reservations}
                          availabilities={chartersAvailabilityFiltered}
                        />
                      );
                    })}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onConfirmReserve}
                    style={{
                      color: "white",
                      borderRadius: "18px",
                      width: "15em",
                      position: "fixed",
                      left: "6rem",
                      right: "2rem",
                      bottom: "1rem",
                      zIndex: "20",
                    }}
                  >
                    {t("charter_reserve_confirm_reservations")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        onClose={closeDialogConfirmDateValid}
        className="confirmation-dialog"
        aria-labelledby="simple-dialog-title"
        open={validDateStatus}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("charter_reserve_txt_dialog_invalid")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <Button
            onClick={closeDialogConfirmDateValid}
            color="primary"
            variant="contained"
            style={{ color: "white" }}
          >
            {t("accept")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={closeDialogConfirmDateInvalid}
        className="confirmation-dialog"
        aria-labelledby="simple-dialog-title"
        open={invalidDatesStatus}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("charter_reserve_txt_dialog_available")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <Button
            onClick={closeDialogConfirmDateInvalid}
            color="primary"
            style={{ color: "white" }}
          >
            {t("accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
CharterReserve.propTypes = {
  tokenGenerated: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
};
