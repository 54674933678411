import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  CHARTER_GET_REQUESTS,
  CHARTER_GET_REQUESTS_ERROR,
  CHARTER_GET_REQUESTS_LAST_MONTH,
  CHARTER_REMOVE_ALL_TRAVEL,
  CHARTER_TRAVEL_STATUS,
} from "actions/types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PassAppApi from "services/PassAppApi";
import SnackbarMessage from "utils/SnackbarMessage";
import CharterTravelsTable from "./CharterTravelsTable";

function getDisableWeek(id) {
  const dates = [
    {
      disabled: false,
      updated: false,
      id: id,
      day: 2,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 3,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 4,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 5,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 6,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 7,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 1,
      total: 0,
      count: 0,
      date: null,
    },
  ];
  return dates;
}

export const CharterTravels = () => {
  const processMonth = (month) => {
    let res;
    if (month < 10) {
      res = "0" + String(month);
    } else {
      res = String(month);
    }
    return res;
  };
  let date = new Date();
  let currYear = date.getFullYear();
  let currMonth = date.getMonth() + 2;
  let beforeCurrMonth = currMonth === 1 ? 12 : currMonth - 1;
  const [globalPageIndex, setGlobalPageIndex] = React.useState(0);
  const [maxCharterTime, setMaxCharterTime] = React.useState(0);
  const [currentYear, setCurrentYear] = React.useState(currYear);
  const [currentMonth, setCurrentMonth] = React.useState(currMonth);
  const [nextPrev, setNextPrev] = React.useState("");
  const { officeBuildings, companies } = useSelector(
    (state) => state.userReducer
  );
  const {
    charter_requests,
    charter_travel,
    charter_travel_status,
  } = useSelector((state) => state.charterReducer);
  const [period, setPeriod] = React.useState(
    String(currentYear) + "-" + String(currentMonth)
  );
  const { t } = useTranslation();
  const [lastCharters, setLastCharters] = React.useState([]);
  const [charterRepeat, setCharterRepeat] = React.useState([]);
  const [isComplete, setIsComplete] = React.useState(false);
  const { token, user } = useSelector((state) => state.authReducer);
  const [dialogConfirmMonth, setDialogConfirmMonth] = React.useState(false);
  const [loadingConfirm, setLoadingConfirm] = React.useState(false);
  const [loadingConfirmRepeat, setLoadingConfirmRepeat] = React.useState(false);
  const dispatch = useDispatch();

  const [currentData, setCurrentData] = React.useState([]);

  useEffect(() => {
    const company = searchCompany(companies);
    const max = officeBuildings.find((c) => c.id === company.building);
    if (max) setMaxCharterTime(Number(max.maximumCharterRequestTime));
    const updateYear = String(currentYear);
    const updateMonth = processMonth(currentMonth);
    const pe = updateYear + "-" + updateMonth;
    const updateMonthBefore = processMonth(
      currentMonth === 1 ? 12 : currentMonth - 1
    );
    const updateYearBefore =
      updateMonthBefore === "12" ? currentYear - 1 : currentYear;
    const pep = updateYearBefore + "-" + updateMonthBefore;
    setPeriod(pe);
    if (user.charterManager.company !== "" && token !== "") {
      setIsComplete(true);
      setCurrentData([]);
      getCurrentCharters(token, t, dispatch, user.charterManager.company, pe);
      getLastCharters(token, t, dispatch, user.charterManager.company, pep);
    }
  }, [currentMonth, currentYear]);

  const searchCompany = (companies) => {
    return companies.find((c) => c.id === user.charterManager.company);
  };

  const getCurrentCharters = (token, t, dispatch, company, period) => {
    PassAppApi.getCharterRequests(token, company, period)
      .then((opts) => {
        setCurrentData(opts);
        dispatch({
          type: CHARTER_GET_REQUESTS,
          payload: opts,
        });
      })
      .catch((err) => {
        dispatch({ type: CHARTER_GET_REQUESTS_ERROR });
        SnackbarMessage.show(
          "error",
          t("error_getting_charters_requests") +
            ": " +
            (err ? err.toString() : "")
        );
      });
  };

  const getLastCharters = (token, t, dispatch, company, period) => {
    PassAppApi.getCharterRequests(token, company, period)
      .then((opts) => {
        setLastCharters(opts);
        dispatch({
          type: CHARTER_GET_REQUESTS_LAST_MONTH,
          payload: opts,
        });
      })
      .catch((err) => {
        dispatch({ type: CHARTER_GET_REQUESTS_ERROR });
        SnackbarMessage.show(
          "error",
          t("error_getting_charters_requests") +
            ": " +
            (err ? err.toString() : "")
        );
      });
  };

  const setCharterReq = (token, company, period, charters) => {
    PassAppApi.setCharterRequests(token, company, period, charters)
      .then(() => {
        setLoadingConfirmRepeat(false);
        setLoadingConfirm(false);
        SnackbarMessage.show("success", "Charters reservados!");
      })
      .catch((err) => {
        SnackbarMessage.show(
          "error",
          "error_setting_charters_requests" + ": " + (err ? err.toString() : "")
        );
      });
  };

  const setCurrentPage = (page) => {
    setGlobalPageIndex(page);
  };

  const processDataRepeat = (lastCharters) => {
    lastCharters.forEach((c) => {
      c.calendar = getNewCalendar(c.calendar, c.id);
    });
  };

  const getNewCalendar = (calendar, id) => {
    let res = [];
    calendar.forEach((ca) => {
      res.push({ id, day: ca.day, total: ca.total });
    });
    return res;
  };

  const getNewFilterData = (data) => {
    let res = [];
    data.forEach((d) => {
      res.push({ day: d.day, total: d.total });
    });
    return res;
  };

  const setOrderCharter = () => {
    setLoadingConfirmRepeat(true);
    dispatch({
      type: CHARTER_TRAVEL_STATUS,
      payload: false,
    });
    let result = [];
    let filterData = [];
    let filterDays = [];
    setCurrentData([]);
    setTimeout(() => {
      processDataRepeat(lastCharters);
      setCurrentData(lastCharters);
      lastCharters.forEach((l) => {
        if (lastCharters.length) {
          if (l.calendar.length) {
            result = [...result, ...l.calendar];
          } else {
            result = [...result, ...getDisableWeek(l.id)];
          }
        }
      });
      charter_requests.forEach((c) => {
        filterDays = result.filter((r) => r.id === c.id);
        if (filterDays.length)
          filterData.push({
            charter: c.id,
            changes: getNewFilterData(filterDays),
          });
      });
      if (filterData.length) {
        setCharterReq(token, user.charterManager.company, period, filterData);
        dispatch({
          type: CHARTER_TRAVEL_STATUS,
          payload: true,
        });
      }
    }, 0.5);
    clearTimeout();
  };

  const prev = () => {
    if (charter_travel_status === false) {
      setNextPrev("prev");
      setDialogConfirmMonth(true);
    } else {
      setCurrentMonth(currentMonth - 1);
      if (currentMonth === 1) {
        setCurrentMonth(12);
        setCurrentYear(currentYear - 1);
      }
    }
  };

  const next = () => {
    if (charter_travel_status === false) {
      setNextPrev("next");
      setDialogConfirmMonth(true);
    } else {
      setCurrentMonth(currentMonth + 1);
      if (currentMonth === 12) {
        setCurrentMonth(1);
        setCurrentYear(currentYear + 1);
      }
    }
  };

  const closeDialogConfirmMonth = () => {
    setDialogConfirmMonth(false);
    setNextPrev("");
  };

  const discardChangesTravel = () => {
    dispatch({
      type: CHARTER_REMOVE_ALL_TRAVEL,
      payload: [],
    });
    setCharterRepeat([]);
    setLastCharters([]);
    if (nextPrev === "next" && currentMonth !== beforeCurrMonth) {
      setCurrentMonth(currentMonth + 1);
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      }
    } else {
      setCurrentMonth(currentMonth - 1);
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      }
    }
    dispatch({
      type: CHARTER_TRAVEL_STATUS,
      payload: true,
    });
    setDialogConfirmMonth(false);
    setNextPrev("");
  };

  const processTravelDates = () => {
    let changes = [];
    charter_requests.forEach((c) => {
      changes.push({
        charter: c.id,
        changes: processDays(c),
      });
    });
    return changes;
  };

  const processDays = (c) => {
    const res = [];
    let result = [];
    let last = [];
    let daysProcessed = [];
    charter_travel.forEach((a) => {
      if (c.id === a.id) {
        res.push({
          day: a.day,
          total: a.total,
          date: a.date,
          updated: a.updated,
        });
      }
    });
    res.forEach((r) => {
      if (r.updated === true) {
        result.push(r);
      }
    });
    for (let i = 0; i < result.length - 1; i++) {
      if (result[i].day !== result[i + 1].day) {
        last.push(result[i]);
      }
    }
    if (result.length) last.push(result[result.length - 1]);
    last.forEach((l) => {
      daysProcessed.push({ total: l.total, day: l.day });
    });
    return daysProcessed;
  };

  const confirmChanges = () => {
    setLoadingConfirm(true);
    if (charter_travel_status) {
      SnackbarMessage.show("error", t("charter_reserve_without_reserves_msj"));
    } else {
      const travelDates = processTravelDates();
      const travelDatesFiltered = travelDates.filter(
        (t) => t.changes.length !== 0
      );
      setCharterReq(
        token,
        user.charterManager.company,
        period,
        travelDatesFiltered
      );
      dispatch({
        type: CHARTER_TRAVEL_STATUS,
        payload: true,
      });
    }
  };

  const saveChanges = () => {
    const travelDates = processTravelDates();
    const travelDatesFiltered = travelDates.filter(
      (t) => t.changes.length !== 0
    );
    setCharterReq(
      token,
      user.charterManager.company,
      period,
      travelDatesFiltered
    );
    dispatch({
      type: CHARTER_TRAVEL_STATUS,
      payload: true,
    });
    if (nextPrev === "next" && currentMonth !== beforeCurrMonth) {
      setCurrentMonth(currentMonth + 1);
      if (currentMonth === 12) {
        setCurrentMonth(1);
        setCurrentYear(currentYear + 1);
      }
    } else {
      setCurrentMonth(currentMonth - 1);
      if (currentMonth === 1) {
        setCurrentMonth(12);
        setCurrentYear(currentYear - 1);
      }
    }
    setDialogConfirmMonth(false);
  };

  function getMonthNameOrder(num, t) {
    let result;
    switch (num) {
      case 1:
        result = t("charter_january");
        break;
      case 2:
        result = t("charter_february");
        break;
      case 3:
        result = t("charter_march");
        break;
      case 4:
        result = t("charter_april");
        break;
      case 5:
        result = t("charter_may");
        break;
      case 6:
        result = t("charter_june");
        break;
      case 7:
        result = t("charter_july");
        break;
      case 8:
        result = t("charter_august");
        break;
      case 9:
        result = t("charter_september");
        break;
      case 10:
        result = t("charter_october");
        break;
      case 11:
        result = t("charter_november");
        break;
      case 12:
        result = t("charter_december");
        break;
      default:
        break;
    }
    return result;
  }

  return (
    <>
      <div className="icons">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ flexGrow: 1 }}
        >
          <Grid item>
            <IconButton
              aria-label="back"
              onClick={prev}
              disabled={currentMonth === beforeCurrMonth}
              style={{ marginRight: "2em" }}
            >
              <ArrowBackIosIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid item>
            <p className="current-date" style={{ color: "#4caf50" }}>
              {getMonthNameOrder(currentMonth, t)} {currentYear}
            </p>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="forward"
              onClick={next}
              disabled={currentMonth === maxCharterTime}
              style={{ marginLeft: "2em" }}
            >
              <ArrowForwardIosIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <CharterTravelsTable
        data={currentData}
        t={t}
        globalPageIndex={globalPageIndex}
        setCurrentPage={setCurrentPage}
        currentMonth={currentMonth}
        currentYear={currentYear}
        isComplete={isComplete}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        style={{ marginTop: 10 }}
      >
        <Grid item>
          <Button
            style={{ margin: 3 }}
            variant="outlined"
            onClick={setOrderCharter}
            disabled={currentMonth === beforeCurrMonth}
          >
            {loadingConfirmRepeat ? (
              <CircularProgress style={{ height: 20, width: 20 }} />
            ) : (
              t("charter_button_repeat_last_month")
            )}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color={"primary"}
            variant="contained"
            onClick={confirmChanges}
            style={{ margin: 3, color: "white" }}
            disabled={currentMonth === beforeCurrMonth}
          >
            {loadingConfirm ? (
              <CircularProgress style={{ height: 20, width: 20, margin: 3 }} />
            ) : (
              t("charter_button_save_changes")
            )}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        onClose={closeDialogConfirmMonth}
        className="confirmation-dialog"
        aria-labelledby="simple-dialog-title"
        open={dialogConfirmMonth}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("charter_text_dialog_change_month")}
          </DialogContentText>
          <DialogActions className="dialog-buttons">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ flexGrow: 1 }}
            >
              <Grid item xs={12}>
                <Button onClick={saveChanges} color="primary">
                  {t("charter_button_save_changes")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={discardChangesTravel} color="secondary">
                  {t("charter_button_discard_changes")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
