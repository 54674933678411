export const PREF_TOKEN = "token";
export const PREF_STATE = "state";
export const DATA_COMPANIES = "companies";
export const DATA_COUNTRIES = "countries";
export const DATA_FRIENDS = "friends";
export const DATA_USER = "user";
export const APP_STRINGS = "app_strings";
export const PASS_STATUS_ACCEPTED = "accepted";
export const PASS_STATUS_PENDING = "pending";
export const PASS_STATUS_REJECTED = "rejected";
export const PASS_STATUS_CANCELLED = "cancelled";
export const PASS_STATUS_CANCELED = "canceled";
export const PASS_STATUS_FINISHED = "finished";
export const PASS_TYPE_REQUEST = "request";

export const FRIEND_INVITE_PLACEHOLDER = "FRIEND-INVITE";
export const PASS_INVITE_PLACEHOLDER = "PASS-INVITE";
export const PASS_REQUEST_PLACEHOLDER = "PASS-REQUEST";

export const AUTHORIZATION_STATUS_ACCEPTED = "accepted";
export const AUTHORIZATION_STATUS_PENDING = "pending";
export const AUTHORIZATION_STATUS_USED = "used";
export const AUTHORIZATION_STATUS_EXPIRED = "expired";

export const PASS_TYPE_OCCASIONAL = "occasional";
export const PASS_TYPE_PERMANENT = "permanent";

export const GUEST_TYPE_PROVIDER = "provider";
export const GUEST_TYPE_PARTICULAR = "any";
export const GUEST_TYPE = "inviteType";

export const PASS_TYPE_INVITE = "invite";
export const PASS_TYPE_AUTHORIZATION = "authorization";

export const ESTABLISHMENT = "establishment";
export const PASS_DATE = "passDate ";
export const DATE_ERROR = "dateError";
export const PERMANENT_PASS_DAY = "permanentPassDay";
export const PERMANENT_PASS_WRONG_HOURS = "permanentPassWrongHours";
export const MAX_PERMANENT_PASSES = "maxPermanentPasses";
export const MAX_PERMANENT_PASSES_USER = "maxPermanentPassesUser";
export const DNI = "dni";
export const SECTOR = "sector";

export const QR_SEPARATOR = "@@";
export const QR_SEPARATOR_IMAGE = ",.,ESP,.,";

export const MD5_SALT = ":0303456";

export const MD5_SALT_2 = "0303456";

export const NOTIFICATION_REQUEST = "sendRequest";
export const NOTIFICATION_REQUEST_REJECTED = "rejectRequest";

export const COUNTRY = "country";
export const BUILDING = "building";
export const COMPANY = "company";
export const PASS_HOURS_LIMIT = 12;

export const DEFAULT_PHONE_MASK = "+X################";
export const US_MASK = "+# (###) ###-####";
export const ARG_MASK = "+## X##########";
export const US_CODE = "+1";
export const ARG_CODE = "+54";

export const TWOFA_TYPE_SMS = "sms";
export const TWOFA_TYPE_CALL = "call";

// Falta la verificación del número de teléfono (2FA)
export const STATE_VERIFICATION = "state_verification";
// Falta sacarse la selfie
export const STATE_PHOTOS = "state_photos";
// Ya pasó toda la registración pero no completó el perfil
export const STATE_INCOMPLETE_PROFILE = "state_incomplete_profile";
// Cuando ya tiene el perfil completo (ya sea a mano o con mrz)
export const STATE_LOGGED_IN = "state_logged_in";
// Necesita asociarse a un complejo
export const STATE_NEEDS_TO_JOIN = "state_needs_to_join";

export const PENDING_NAME = "-pending-";
export const REQUEST_ESTABLISHMENT = "request";

export const FACE_PHOTO = "facePhoto";
