import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  TextField,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Schedule from "./Schedule";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import DropzoneFiles from "../common/DropzoneFiles/DropzoneFiles";
import {
  updReservation,
  sendRoomReservation,
  sendPostFiles,
} from "../../actions/RoomReservation";

import { Visibility, CloudDownload, Clear } from "@material-ui/icons";

const InputNumber = (props) => {
  const sharedFacilities = props.sharedFacilities;
  const layout = props.layout;

  let maximun = 0;
  if (sharedFacilities && typeof sharedFacilities !== undefined) {
    for (let i = 0; i < props.maxLst.length; i++) {
      if (
        layout === props.maxLst[i].layout &&
        sharedFacilities.indexOf(props.maxLst[i].sharedFacility) > -1
      ) {
        let max = props.maxLst[i][props.id];
        if (max !== null && max !== "") {
          maximun += parseInt(max);
        }
      }
    }
  }

  let error = false;
  let helperText = null;
  if (props.value !== null && props.value !== "") {
    if (parseInt(props.value) > maximun || parseInt(props.value) < 0) {
      error = true;
      if (maximun !== 0) {
        helperText = "Min: 0 - Máx: " + maximun;
      } else {
        helperText = "Máx: 0";
      }
    }
  }

  return (
    <FormControl className="box-input">
      <TextField
        type="number"
        error={error}
        helperText={helperText}
        label={props.name}
        name={props.id}
        value={props.value !== null ? props.value : ""}
        onChange={(data) => props.handleChange(data, maximun)}
        InputProps={{ inputProps: { max: maximun, min: 0 } }}
      ></TextField>
    </FormControl>
  );
};

function get_urlFile(url, baseURL, token_file) {
  if (!token_file || typeof token_file == "undefined") {
    return null;
  }
  const jwt = token_file.t;

  if (!jwt || typeof jwt == "undefined") {
    return null;
  }

  return baseURL + url + "?token=" + jwt;
}

const ListFiles = (props) => {
  const { t, fileName, url, remove, baseURL, token_file } = props;

  const urlFile = get_urlFile(url, baseURL, token_file);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {fileName}
      </Grid>
      <Grid item xs={6} className="text-right">
        {url && (
          <span>
            <a
              href={urlFile + "&mode=attachement"}
              download={fileName}
              title={t("download")}
            >
              <CloudDownload color="primary"></CloudDownload>
            </a>
            <a
              href={urlFile}
              target="_blank"
              without
              rel="noopener noreferrer"
              title={t("see_file")}
            >
              <Visibility color="primary"></Visibility>
            </a>
          </span>
        )}
        <Tooltip title={t("delete_file")} aria-label={t("delete_file")}>
          <Clear onClick={remove} />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

class RoomReservationEdit extends React.Component {
  constructor(props) {
    super(props);
    const { itemID } = this.props;

    this.state = {
      value: typeof itemID !== "undefined" ? itemID : "",
      reservation: this.props.reservation,
      file1: null,
      file2: null,
      tyc: false,
      loading: false,
      open: false,
      newFiles: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeRequirements = this.handleChangeRequirements.bind(this);
    this.handleChangeSinMobiliario = this.handleChangeSinMobiliario.bind(this);
    this.timeNoFree = this.timeNoFree.bind(this);
    this.upload = this.upload.bind(this);
    this.removePurchaseOrder = this.removePurchaseOrder.bind(this);
    this.uploadOthers = this.uploadOthers.bind(this);
    this.removeOthersFiles = this.removeOthersFiles.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.sendReservation = this.sendReservation.bind(this);
    this.closeSummary = this.closeSummary.bind(this);
    this.handleTyC = this.handleTyC.bind(this);
  }

  handleChange(event) {
    let reserv = { ...this.props.reservation };
    reserv[event.target.name] = event.target.value;
    this.props.updReservation(reserv);
  }

  handleChangeRequirements(event) {
    let reserv = { ...this.props.reservation };
    reserv.requirements[event.target.name] = event.target.value;
    this.props.updReservation(reserv);
  }

  handleChangeSinMobiliario(event) {
    let reserv = { ...this.props.reservation };
    reserv.sinMobiliario = event.target.checked;

    /*if(reserv.sinMobiliario){
      Object.keys(reserv.requirements).map((key) => {
        if(key != "hasRequirements"){
          reserv.requirements[key] = null;
        }
      });
    }*/

    this.props.updReservation(reserv);
  }

  handleTyC(event) {
    this.setState({ tyc: event.target.checked });
  }

  upload(files) {
    const { t, itemID } = this.props;
    this.props.sendPostFiles(t, itemID, files, "purchase_order");
  }

  removePurchaseOrder() {
    let reserv = { ...this.props.reservation };
    reserv.purchaseOrder = "";
    reserv.purchaseOrderName = null;
    reserv.purchaseOrderUrl = null;
    this.props.updReservation(reserv);
  }

  uploadOthers(files) {
    const { t, itemID } = this.props;
    const newIDFile = this.props.sendPostFiles(t, itemID, files, "otherFiles");

    const newFiles = { ...this.state.newFiles };
    this.setState({ newFiles: newFiles });
  }

  removeOthersFiles(file) {
    let reserv = { ...this.props.reservation };
    reserv.otherFiles = reserv.otherFiles.filter((v, k) => {
      return k !== file;
    });
    reserv.otherFilesUrl = reserv.otherFilesUrl.filter((v, k) => {
      return k !== file;
    });
    this.props.updReservation(reserv);
  }

  onClose() {
    this.setState({ open: false, loading: false });
  }

  closeSummary() {
    this.setState({ open: false, loading: false });
  }

  showConfirm() {
    const tyc = this.state.tyc;
    const { reservation, t } = this.props;
    if (
      !reservation.hasOwnProperty("id") &&
      typeof reservation.id == "undefined"
    ) {
      if (!tyc) {
        this.props.showMessaje(t("error_TandC"));
        return;
      }
    }

    if (!reservation.date) {
      this.props.showMessaje(t("error_date"));
      return;
    }
    if (!reservation.beginTime) {
      this.props.showMessaje(t("error_beginTime"));
      return;
    }
    if (!reservation.endTime) {
      this.props.showMessaje(t("error_endTime"));
      return;
    }

    let date = reservation.date.split(" ")[0];
    let beginHour = reservation.beginTime.split(" ")[1];
    let endHour = reservation.endTime.split(" ")[1];

    if (beginHour > endHour) {
      this.props.showMessaje(t("error_time_inverted"));
      return;
    }
    if (
      beginHour > "18:00" ||
      beginHour < "08:00" ||
      endHour > "18:00" ||
      endHour < "08:00"
    ) {
      this.props.showMessaje(t("error_time_range"));
      return;
    }

    if (
      !reservation.hasOwnProperty("id") &&
      typeof reservation.id === "undefined"
    ) {
      let twoDays = moment()
        .add("days", 2)
        .format("YYYY-MM-DD HH:mm");
      if (date + " " + beginHour < twoDays) {
        this.props.showMessaje(t("error_time_range_2days"));
        return;
      }
    }

    if (
      !reservation.sharedFacilities ||
      reservation.sharedFacilities.length === 0
    ) {
      this.props.showMessaje(t("error_sharedFacilities"));
      return;
    }

    if (!reservation.sinMobiliario) {
      let faltaMobiliario = false;
      Object.keys(reservation.requirements).forEach((key) => {
        if (key !== "hasRequirements") {
          if (
            reservation.requirements[key] === null ||
            reservation.requirements[key] === ""
          ) {
            faltaMobiliario = true;
          }
        }
      });

      if (faltaMobiliario) {
        this.props.showMessaje(t("error_mobiliario"));
        return;
      }
    }

    this.setState({ open: true });
  }

  sendReservation() {
    const tyc = this.state.tyc;
    const { t, reservation, baseURL, settings, token_file } = this.props;

    if (!reservation.hasOwnProperty("id") && !tyc) {
      this.props.showMessaje(t("error_TandC"));
    } else {
      let data = {
        id: reservation.id,
        sharedFacilities: reservation.sharedFacilities,
        layout: reservation.layout,
        date: moment(reservation.date, "YYYY-MM-DD").format("YYYY-MM-DD"),
        beginTime: moment(reservation.beginTime, "YYYY-MM-DD HH:mm").format(
          "HH:mm"
        ),
        endTime: moment(reservation.endTime, "YYYY-MM-DD HH:mm").format(
          "HH:mm"
        ),
        notes: reservation.notes,
        purchaseOrder: reservation.purchaseOrder,
        otherFiles: reservation.otherFiles,
        sinMobiliario: reservation.sinMobiliario,
      };

      Object.keys(reservation.requirements).forEach((key) => {
        if (key !== "hasRequirements") {
          data["requirements." + key] = reservation.requirements[key];
        }
      });

      const termsAndConditionsUrl = get_urlFile(
        settings.termsAndConditionsUrl,
        baseURL,
        token_file
      );
      this.props.sendRoomReservation(
        t,
        data,
        this.props.openPdf,
        termsAndConditionsUrl
      );
      this.setState({ open: false, loading: false });
    }
  }

  handleDateChangeOld(date, elem) {
    const reserv = { ...this.props.reservation };
    if (date && date.isValid()) {
      const minutes = parseInt(date.format("mm"));
      const hours = parseInt(date.format("HH"));
      if (minutes !== 0 && minutes !== 30) {
        if (minutes < 30) date.add(30 - minutes, "minutes");
        else date.add(60 - minutes, "minutes");
      }

      reserv[elem] = date.format("YYYY-MM-DD HH:mm");
      if (elem === "beginTime") {
        if (hours < 8 || hours > 18) {
          reserv[elem] = date.format("YYYY-MM-DD") + " 08:00";
        }
        if (date.diff(reserv.endTime, "minutes") > 0) {
          reserv["endTime"] = date
            .add(30, "minutes")
            .format("YYYY-MM-DD HH:mm");
        }
      }
      if (elem === "endTime") {
        let startTime = moment(reserv.beginTime, "YYYY-MM-DD HH:mm");
        if (
          hours < 8 ||
          hours > 18 ||
          date.format("HH:mm") < startTime.format("HH:mm")
        ) {
          reserv[elem] = startTime.add(30, "minutes");
        }
      }
      this.props.updReservation(reserv);
    } else {
      reserv[elem] = "00:00";
      this.props.updReservation(reserv);
    }
  }

  handleDateChange(date, elem) {
    const reserv = { ...this.props.reservation };
    const { t } = this.props;
    let beginTimeError = false;
    let beginTimeErrorText = null;
    let endTimeError = false;
    let endTimeErrorText = null;

    if (date && date.isValid()) {
      let dateAux = null;
      let beginTime = null;
      let endTime = null;
      reserv[elem] = date.format("YYYY-MM-DD HH:mm");

      if (reserv.date) {
        dateAux = moment(reserv.date, "YYYY-MM-DD HH:mm");
      }

      if (reserv.beginTime) {
        beginTime = moment(reserv.beginTime, "YYYY-MM-DD HH:mm");
        if (
          beginTime.format("HH:mm") < "08:00" ||
          beginTime.format("HH:mm") > "18:00"
        ) {
          beginTimeError = true;
          beginTimeErrorText = "Min 08:00 - Máx: 18:00";
        }
      }

      if (reserv.endTime) {
        endTime = moment(reserv.endTime, "YYYY-MM-DD HH:mm");
        if (
          endTime.format("HH:mm") < "08:00" ||
          endTime.format("HH:mm") > "18:00"
        ) {
          endTimeError = true;
          endTimeErrorText = "Min 08:00 - Máx: 18:00";
        }
      }

      if (beginTime && endTime) {
        if (endTime.format("HH:mm") < beginTime.format("HH:mm")) {
          endTimeError = true;
          this.props.showMessaje(t("error_time_inverted"));
        }
      }

      if (!reserv.hasOwnProperty("id") && typeof reserv.id == "undefined") {
        if (dateAux && beginTime) {
          let twoDays = moment()
            .add("days", 2)
            .format("YYYY-MM-DD HH:mm");
          if (
            dateAux.format("YYYY-MM-DD") + " " + beginTime.format("HH:mm") <
            twoDays
          ) {
            beginTimeError = true;
            this.props.showMessaje(t("error_time_range_2days"));
          }
        }
      }

      this.setState({
        beginTimeError,
        beginTimeErrorText,
        endTimeError,
        endTimeErrorText,
      });

      this.props.updReservation(reserv);
    }
  }

  timeNoFree() {
    /*const reserv = { ...this.props.reservation };
    reserv["endTime"] = null;
    reserv["beginTime"] = null;
    this.props.updReservation(reserv);
    this.props.showMessaje(this.props.t("room_not_avail"));*/
  }

  render() {
    const {
      t,
      settings,
      baseURL,
      reservation,
      onClose,
      closeReservation,
      token_file,
    } = this.props;
    const {
      open,
      tyc,
      beginTimeError,
      beginTimeErrorText,
      endTimeError,
      endTimeErrorText,
    } = this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      variant: "menu",
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    let layoutError = true;
    let layoutErrorText = t("error_layout");
    let sharedFacilitiesLayouts = [];
    if (
      reservation.sharedFacilities &&
      typeof reservation.sharedFacilities !== undefined
    ) {
      for (let i in settings.sharedFacilitiesLayouts) {
        let sfl = settings.sharedFacilitiesLayouts[i];
        let enabled = sfl.enabled;
        if (enabled) {
          enabled = false;
          for (let j in settings.sharedFacilitiesLimits) {
            if (
              sfl.id === settings.sharedFacilitiesLimits[j].layout &&
              reservation.sharedFacilities.indexOf(
                settings.sharedFacilitiesLimits[j].sharedFacility
              ) !== -1
            ) {
              enabled = true;
            }
          }
          if (sfl.name.toLowerCase().replace(" ", "") === "otrosarmados") {
            enabled = true;
          }
        }
        if (reservation.layout === sfl.id && enabled) {
          layoutError = false;
          layoutErrorText = null;
        }
        sharedFacilitiesLayouts.push({
          id: sfl.id,
          name: sfl.name,
          enabled: enabled,
        });
      }
    }

    if (typeof reservation == undefined || reservation.length < 1) {
      return <p>Loading...</p>;
    }

    return (
      <div className="body-box">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="form-box">
              {reservation.hasOwnProperty("id") && (
                <div className="owner">
                  <h3 className="reservation_owner">
                    {t("reservation_owner")} <span>{reservation.owner}</span>
                  </h3>
                </div>
              )}
              <h2 className="form-title">1. {t("room_reserv_step1")}</h2>
              <Grid container spacing={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className="box-input date">
                      <DatePicker
                        minDate={
                          new Date(new Date().getTime() + 86400 * 2 * 1000)
                        }
                        format="DD/MM/YYYY"
                        label={t("date")}
                        value={reservation.date}
                        onChange={(date) => this.handleDateChange(date, "date")}
                        invalidDateMessage={t("invalid_date_format")}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className="box-input" error={beginTimeError}>
                      <TimePicker
                        initialFocusedDate={
                          new Date().toJSON().slice(0, 10) + " 08:00"
                        }
                        error={beginTimeError}
                        label={t("beginTime")}
                        value={reservation.beginTime}
                        ampm={false}
                        onChange={(date) =>
                          this.handleDateChange(date, "beginTime")
                        }
                        invalidDateMessage={t("invalid_time_format")}
                      />
                      <FormHelperText>{beginTimeErrorText}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className="box-input" error={endTimeError}>
                      <TimePicker
                        initialFocusedDate={reservation.beginTime}
                        error={endTimeError}
                        label={t("endTime")}
                        value={reservation.endTime}
                        ampm={false}
                        onChange={(date) =>
                          this.handleDateChange(date, "endTime")
                        }
                        invalidDateMessage={t("invalid_time_format")}
                      />
                      <FormHelperText>{endTimeErrorText}</FormHelperText>
                    </FormControl>
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className="box-input" error={layoutError}>
                    <InputLabel className="dropdown">{t("layout")}</InputLabel>
                    <Select
                      label={t("layout")}
                      onChange={this.handleChange}
                      fullWidth={true}
                      error={layoutError}
                      value={reservation.layout}
                      name="layout"
                    >
                      {sharedFacilitiesLayouts.map((data, j) => (
                        <MenuItem
                          value={data.id}
                          disabled={!data.enabled}
                          key={j}
                        >
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{layoutErrorText}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <FormControl className="box-input pr-0">
                    <InputLabel className="dropdown">
                      {t("sharedFacilities")}
                    </InputLabel>
                    <Select
                      name="sharedFacilities"
                      multiple
                      value={reservation.sharedFacilities}
                      onChange={this.handleChange}
                      input={<Input />}
                      MenuProps={MenuProps}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id) =>
                              settings.sharedFacilities.find(
                                (facility) => facility.id === id
                              ).name
                          )
                          .join(", ")
                      }
                    >
                      {settings.sharedFacilities.map((data, j) => (
                        <MenuItem value={data.id} key={j}>
                          <ListItemIcon>
                            <Checkbox
                              color="primary"
                              checked={
                                reservation.sharedFacilities.indexOf(data.id) >
                                -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={data.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <h2 className="form-title">2. {t("room_reserv_step2")}</h2>
              <Grid container spacing={3}>
                {Object.keys(reservation.requirements).map((key) => {
                  return (
                    key !== "hasRequirements" && (
                      <Grid item xs={12} sm={4} key={key}>
                        <InputNumber
                          handleChange={this.handleChangeRequirements}
                          id={key}
                          name={t(key)}
                          value={reservation.requirements[key]}
                          maxLst={settings.sharedFacilitiesLimits}
                          layout={reservation.layout}
                          sharedFacilities={reservation.sharedFacilities}
                        />
                      </Grid>
                    )
                  );
                })}
                <Grid item xs={12} sm={4} className="sinMobiliario">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reservation.sinMobiliario || false}
                        onChange={this.handleChangeSinMobiliario}
                        color="Primary"
                      />
                    }
                    label={t("sinMobiliario")}
                  />
                </Grid>
              </Grid>
              <h2 className="form-title">3. {t("room_reserv_step3")}</h2>
              <FormControl className="box-input full table">
                {reservation.purchaseOrder && (
                  <ListFiles
                    className="file-list"
                    t={t}
                    fileName={reservation.purchaseOrderName}
                    url={reservation.purchaseOrderUrl}
                    remove={this.removePurchaseOrder}
                    baseURL={baseURL}
                    token_file={token_file}
                  ></ListFiles>
                )}
                <DropzoneFiles
                  maxFiles="1"
                  label={t("textDropzoneFile")}
                  textInfo1={t("textDropzoneFileInfo1")}
                  textInfo2={t("textDropzoneFileInfo2")}
                  url={
                    baseURL +
                    "/ws/company-manager/uploadSharedFacilityReservation"
                  }
                  autoRemove
                  onload={this.upload}
                  showMessaje={this.props.showMessaje}
                  errorFileSize={t("textDropzoneErrorFileSize")}
                  rejectedFileType={t("textDropzoneRejectedFileType")}
                  accept="application/pdf, image/*, .txt"
                  t={t}
                />
              </FormControl>
              <h2 className="form-title">4. {t("room_reserv_step4")}</h2>
              <FormControl className="box-input full table">
                {reservation.otherFilesUrl &&
                  reservation.otherFilesUrl.map((file, i) => (
                    <ListFiles
                      className="file-list"
                      t={t}
                      key={i}
                      fileName={file.name}
                      url={file.url}
                      baseURL={baseURL}
                      remove={() => this.removeOthersFiles(i)}
                      token_file={token_file}
                    ></ListFiles>
                  ))}
                <DropzoneFiles
                  maxFiles="1"
                  label={t("textDropzoneFile")}
                  textInfo1={t("textDropzoneFileInfo1")}
                  textInfo2={t("textDropzoneFileInfo2")}
                  url={
                    baseURL +
                    "/ws/company-manager/uploadSharedFacilityReservation"
                  }
                  autoRemove
                  onload={this.uploadOthers}
                  onRemove={this.removeOthersFiles}
                  showMessaje={this.props.showMessaje}
                  errorFileSize={t("textDropzoneErrorFileSize")}
                  rejectedFileType={t("textDropzoneRejectedFileType")}
                  accept="application/pdf, image/*, .txt"
                  t={t}
                />
                {settings.reservationFilesDisclaimer && (
                  <div className="text_other_files_RoomReservation">
                    {settings.reservationFilesDisclaimer}
                  </div>
                )}
              </FormControl>
              <h2 className="form-title">5. {t("room_reserv_step5")}</h2>
              <FormControl className="box-input full">
                <TextareaAutosize
                  placeholder={t("notes")}
                  name="notes"
                  onChange={this.handleChange}
                >
                  {reservation.notes}
                </TextareaAutosize>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Schedule
              columns={reservation.sharedFacilities}
              allColumns={settings.sharedFacilities}
              schedules={settings.sharedFacilitiesReservationsCalendar}
              currentDate={moment(reservation.date).format("YYYY-MM-DD")}
              currentID={reservation.id}
              currentSF={reservation.sharedFacilities}
              currentStart={moment(reservation.beginTime).format("HH:mm")}
              currentEnd={moment(reservation.endTime).format("HH:mm")}
              timeNoFree={this.timeNoFree}
            ></Schedule>
            <div className="Schedule-layout">
              <div>
                <span className="circle current"></span>
                {t("room_reserv_current")}
              </div>
              <div>
                <span className="circle approved"></span>
                {t("room_reserv_approved")}
              </div>
              <div>
                <span className="circle pending"></span>
                {t("room_reserv_pending")}
              </div>
            </div>

            <div className="linkLayoutInformationUrl">
              <Grid container>
                {settings.facilitiesLayoutInformationUrl && (
                  <Grid item sm={6}>
                    <Button
                      className="buttom-reservation"
                      onClick={() => {
                        this.props.openPdf(
                          t("layout_information_text"),
                          get_urlFile(
                            settings.facilitiesLayoutInformationUrl,
                            baseURL,
                            token_file
                          )
                        );
                      }}
                    >
                      {" "}
                      {t("layout_information_text_see")}
                    </Button>
                  </Grid>
                )}
                {settings.facilitiesDimensionInformationUrl && (
                  <Grid item sm={6}>
                    <Button
                      className="buttom-reservation"
                      onClick={() => {
                        this.props.openPdf(
                          t("dimension_information_text"),
                          get_urlFile(
                            settings.facilitiesDimensionInformationUrl,
                            baseURL,
                            token_file
                          )
                        );
                      }}
                    >
                      {" "}
                      {t("dimension_information_text_see")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>

            {!reservation.hasOwnProperty("id") && (
              <div className="link_tyc">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tyc}
                      onChange={this.handleTyC}
                      name="tyc"
                      color="Primary"
                    />
                  }
                  label={t("text_TC_1")}
                />
                <span> </span>
                <a
                  onClick={() => {
                    this.props.openPdf(
                      t("text_TC_2"),
                      get_urlFile(
                        settings.termsAndConditionsUrl,
                        baseURL,
                        token_file
                      )
                    );
                  }}
                >
                  {t("text_TC_2")}
                </a>
              </div>
            )}

            <div className="button-group">
              <Button
                color="primary"
                onClick={this.showConfirm}
                className="buttom-reservation"
              >
                {t("accept")}
              </Button>
              <Button onClick={closeReservation} className="buttom-reservation">
                {" "}
                {t("cancel")}
              </Button>
            </div>
          </Grid>
        </Grid>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {t("check_data_before_to_continue")}
          </DialogTitle>
          <DialogContent className="dialog" dividers>
            <div className="summary">
              <label>{t("date")}: </label>
              {moment(reservation.date, "YYYY-MM-DD").format("ll")}{" "}
              {t("from") + " "}
              {moment(reservation.beginTime, "YYYY-MM-DD HH:mm").format(
                "HH:mm"
              )}{" "}
              {t("to")}{" "}
              {moment(reservation.endTime, "YYYY-MM-DD HH:mm").format("HH:mm")}
            </div>
            <div className="summary">
              <label>{t("layout")}: </label>
              {settings.sharedFacilitiesLayouts.map((data, j) => {
                return data.id === reservation.layout ? (
                  <span key={j}>{data.name}</span>
                ) : (
                  ""
                );
              })}
            </div>
            <div className="summary">
              <label>{t("sharedFacilities")}:</label>
              {settings.sharedFacilities.map((data, j) => {
                return reservation.sharedFacilities.indexOf(data.id) >= 0 ? (
                  <span key={j}>{data.name} - </span>
                ) : (
                  ""
                );
              })}
            </div>
            <div className="summary">
              <label>
                {reservation.sinMobiliario
                  ? t("sinMobiliario")
                  : t("mobiliario") + ": "}
              </label>
              <ul>
                {reservation.sinMobiliario
                  ? ""
                  : Object.keys(reservation.requirements).map((key) => {
                      return (
                        key !== "hasRequirements" &&
                        !isNaN(parseInt(reservation.requirements[key])) && (
                          <li key={key}>
                            {t(key)}: <b>{reservation.requirements[key]}</b>
                          </li>
                        )
                      );
                    })}
              </ul>
            </div>
            <div className="summary">
              <label>{t("purchase_order")}:</label>
              {reservation.purchaseOrder ? (
                <span>{t("loaded")}</span>
              ) : (
                <span className="danger">
                  {t("text_without_purchase_order")}
                </span>
              )}
            </div>
            <div className="summary">
              <label>
                {reservation.notes ? (
                  t("notes")
                ) : (
                  <span>{t("without_notes")}</span>
                )}{" "}
              </label>
              {reservation.notes}
            </div>
          </DialogContent>
          <DialogActions className="button-group">
            <Button className="buttom-reservation" onClick={this.closeSummary}>
              {" "}
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              onClick={this.sendReservation}
              className="buttom-reservation"
            >
              {t("accept")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reservation: state.roomReservation.item,
  settings: state.roomReservation.settings,
  baseURL: state.roomReservation.baseURL,
  token_file: state.authReducer.token_file,
});

const mapActionsToProps = {
  updReservation: updReservation,
  sendPostFiles: sendPostFiles,
  sendRoomReservation: sendRoomReservation,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(RoomReservationEdit));
