import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid, List, createStyles, TextField } from "@material-ui/core";
import { hasWorkplace, hasResidence } from "../../utils/UserUtils";
import { COUNTRY, BUILDING } from "../../Constant";
import Company from "../common/Establishment/Company";
import Country from "../common/Establishment/Country";
import HomeIcon from "@material-ui/icons/Home";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Card from "components/common/Card/Card.js";
import CardHeader from "components/common/Card/CardHeader.js";
import CardBody from "components/common/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from "perfect-scrollbar";
import "./JoinEstablishment.scss";

const useStyles = createStyles(styles);

class JoinEstablishment extends React.Component {
  constructor(props) {
    super(props);

    this.countriesRef = React.createRef();
    this.companiesRef = React.createRef();
    this.countryScroll = null;
    this.companyScroll = null;
    this.onSearch = this.onSearch.bind(this);

    this.state = {
      search: "",
    };
  }

  onSearch(ev) {
    this.setState({
      search: ev.target.value,
    });
  }

  componentDidMount() {
    this.countryScroll = new PerfectScrollbar(this.countriesRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
    this.companyScroll = new PerfectScrollbar(this.companiesRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
  }

  componentDidUpdate() {
    this.countryScroll.update();
    this.companyScroll.update();
  }

  componentWillUnmount() {
    this.countryScroll.destroy();
    this.companyScroll.destroy();
  }

  createBuildingGroups() {
    const { companies, buildingsGroups, officeBuildings, user } = this.props;
    const { search } = this.state;

    let groups = [];

    buildingsGroups.forEach((group) => {
      group.companies = [];
      let groupedBuildings = officeBuildings.filter(
        (of) => of.buildingGroup === group.id
      );

      groupedBuildings.forEach((building) => {
        companies.forEach((company) => {
          if (
            company.building === building.id &&
            !hasWorkplace(company, user) &&
            !company.hidden
          ) {
            group.companies.push(company);
          }
        });
      });

      if (group.companies.length !== 0) {
        groups.push(group);
      }
    });

    companies.forEach((c) => {
      officeBuildings.forEach((building) => {
        if (
          c.building === building.id &&
          building.buildingGroup === null &&
          !hasWorkplace(c, user) &&
          !c.hidden
        ) {
          groups.push(c);
        }
      });
    });

    return groups
      .sort((a, b) => ("" + a.name).localeCompare(b.name))
      .filter((g) => g.name.toLowerCase().includes(search.toLowerCase()));
  }

  render() {
    const { countries, user, t, classes } = this.props;
    const { search } = this.state;

    let _countries = countries
      .sort((a, b) => ("" + a.name).localeCompare(b.name))
      .filter((c) => !c.hidden)
      .filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
      .filter((c) => !hasResidence(c, user))
      .map((c) => {
        switch (c.type) {
          case COUNTRY:
            return <Country icon={<HomeIcon />} key={c.id} establishment={c} />;
          case BUILDING:
            return (
              <Country icon={<ApartmentIcon />} key={c.id} establishment={c} />
            );
          default:
            return null;
        }
      });

    let groups = this.createBuildingGroups().map((c) => (
      <Company key={c.id} establishment={c} />
    ));

    let registerClass =
      window.location.href.indexOf("/register") > -1 ? " search-register " : "";
    let fixedHeightClass =
      window.location.href.indexOf("/register") > -1 ? "" : " fixed-height ";

    return (
      <div className="join-establishment">
        <div className={"search" + registerClass}>
          <TextField
            label={t("search_establishment")}
            fullWidth
            type="search"
            variant="outlined"
            onChange={this.onSearch}
          />
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card className="single-card">
              <CardHeader className="single-card-header" color="alternate">
                <h4 className={classes.singleHeader}>
                  {t("countries_and_buildings")}
                </h4>
              </CardHeader>
              <CardBody className={classes.noPadding}>
                <div>
                  <List
                    className={classes.fixedList + fixedHeightClass}
                    ref={this.countriesRef}
                  >
                    {_countries}
                  </List>
                </div>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className="single-card">
              <CardHeader className="single-card-header" color="alternate">
                <h4 className={classes.singleHeader}>{t("companies")}</h4>
              </CardHeader>
              <CardBody className={classes.noPadding}>
                <div>
                  <List
                    className={classes.fixedList + fixedHeightClass}
                    ref={this.companiesRef}
                  >
                    {groups}
                  </List>
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  buildingsGroups: state.userReducer.buildingsGroups,
  officeBuildings: state.userReducer.officeBuildings,
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(JoinEstablishment)));
