import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import { ClickAwayListener, Tooltip } from "@material-ui/core";

const useStyles = createStyles(styles);

class CopyComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
    };

    this.handleTooltipClose = this.handleTooltipClose.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  handleTooltipClose() {
    this.setState({
      showTooltip: false,
    });
  }

  onCopy(hideTooltip) {
    this.setState({
      showTooltip: !hideTooltip,
    });
    this.textArea.select();
    document.execCommand("copy");
  }

  render() {
    const { link, classes, button, show, msg, placement } = this.props;
    const { showTooltip } = this.state;

    let renderButton = button(this.onCopy);

    if (!show) {
      return <div />;
    }

    return (
      <div className="copy-button">
        <textarea
          className={classes.hidden}
          ref={(textarea) => (this.textArea = textarea)}
          value={link}
          readOnly
        />

        <ClickAwayListener onClickAway={this.handleTooltipClose}>
          <Tooltip
            placement={placement}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.handleTooltipClose}
            open={showTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={msg}
          >
            {renderButton}
          </Tooltip>
        </ClickAwayListener>
      </div>
    );
  }
}
export default withTranslation()(withStyles(useStyles)(CopyComponent));
