import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components

import { withTranslation } from "react-i18next";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Fab,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";

import moment from "moment";

class PermanentType extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.handleAllDayChange = this.handleAllDayChange.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
  }

  getInitialState() {
    const { t } = this.props;

    return {
      days: [
        {
          name: t("day_letter_monday"),
          number: 2,
        },
        {
          name: t("day_letter_tuesday"),
          number: 3,
        },
        {
          name: t("day_letter_wednesday"),
          number: 4,
        },
        {
          name: t("day_letter_thursday"),
          number: 5,
        },
        {
          name: t("day_letter_friday"),
          number: 6,
        },
        {
          name: t("day_letter_saturday"),
          number: 7,
        },
        {
          name: t("day_letter_sunday"),
          number: 1,
        },
      ],
      calendar: {
        days: [],
        fromTime: moment(new Date())
          .hours(8)
          .minutes(0),
        toTime: moment(new Date())
          .hours(18)
          .minutes(0),
      },
      allDay: false,
    };
  }

  componentDidMount() {
    const { calendar } = this.props;

    // Super weird bug fix
    let newCalendar = JSON.parse(JSON.stringify(calendar));

    if (newCalendar && newCalendar.fromTime) {
      let parsedFromTime = newCalendar.fromTime
        .split(":")
        .map((n) => parseInt(n));
      let parsedToTime = newCalendar.toTime.split(":").map((n) => parseInt(n));
      let calendar = {
        days: newCalendar.days,
        fromTime: moment(new Date())
          .hours(parsedFromTime[0])
          .minutes(parsedFromTime[1]),
        toTime: moment(new Date())
          .hours(parsedToTime[0])
          .minutes(parsedToTime[1]),
      };
      this.setState({
        calendar,
      });
    }

    this.props.onCalendarUpdate(null);
  }

  toggleDay(number) {
    const { calendar } = this.state;

    const index = calendar.days.indexOf(number);
    if (index > -1) {
      calendar.days.splice(index, 1);
    } else {
      calendar.days.push(number);
    }

    this.setState({
      calendar,
    });

    this.props.onCalendarUpdate(calendar);
  }

  handleAllDayChange() {
    const { calendar, allDay } = this.state;

    let newAllDay = !allDay;

    if (newAllDay) {
      calendar.fromTime = moment(new Date())
        .hours(0)
        .minutes(0);
      calendar.toTime = moment(new Date())
        .hours(23)
        .minutes(59);
    } else {
      calendar.fromTime = moment(new Date())
        .hours(8)
        .minutes(0);
      calendar.toTime = moment(new Date())
        .hours(18)
        .minutes(0);
    }

    this.setState({
      allDay: newAllDay,
      calendar,
    });
    this.props.onCalendarUpdate(calendar);
  }

  handleEndDate(ev) {
    const { calendar } = this.state;

    calendar.toTime = ev;
    this.setState({
      calendar,
    });
    this.props.onCalendarUpdate(calendar);
  }

  handleStartDate(ev) {
    const { calendar } = this.state;

    calendar.fromTime = ev;
    this.setState({
      calendar,
    });
    this.props.onCalendarUpdate(calendar);
  }

  render() {
    const { t, classes } = this.props;
    const { days, calendar, allDay } = this.state;

    const dayButtons = days.map((day, i) => {
      return (
        <Fab
          key={i}
          size="small"
          color={calendar.days.includes(day.number) ? "primary" : "default"}
          aria-label="add"
          className={classes.day}
          onClick={() => {
            this.toggleDay(day.number);
          }}
        >
          {day.name}
        </Fab>
      );
    });

    return (
      <div>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel>{t("days")}: </FormLabel>
          <FormGroup>{dayButtons}</FormGroup>

          <FormLabel className="allowed-times">
            {t("allowed_times_label")}:{" "}
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={allDay}
                  onChange={this.handleAllDayChange}
                />
              }
              label={t("all_day")}
            />
          </FormGroup>

          {!allDay && (
            <FormGroup>
              <TimePicker
                ampm={false}
                label={t("entry_time_label") + ":"}
                value={calendar.fromTime}
                onChange={this.handleStartDate}
              />

              <TimePicker
                ampm={false}
                label={t("out_time_label") + ":"}
                value={calendar.toTime}
                onChange={this.handleEndDate}
              />
            </FormGroup>
          )}
        </FormControl>
      </div>
    );
  }
}
const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  day: {
    marginRight: "4px",
    width: "30px",
    height: "30px",
    minHeight: "0",
  },
  formControl: {
    marginBottom: "15px",
    display: "inline-block",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default withTranslation()(withStyles(useStyles)(PermanentType));
