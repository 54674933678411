import React, { useState } from "react";
import { TextField } from "@material-ui/core";

function RegisterIconTextField(props) {
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(props.value || "");

  return (
    <TextField
      variant="outlined"
      margin="normal"
      value={currentValue}
      onChange={(e) => {
        setCurrentValue(e.target.value);
        if (props.onChange) props.onChange(e);
      }}
      label={props.label}
      name={props.name}
      error={!!props.error}
      helperText={props.error}
      fullWidth
      InputProps={{
        startAdornment: props.icon,
      }}
      {...props.inputProps}
      InputLabelProps={{
        shrink: focused || currentValue,
        style: {
          marginLeft: focused || props.error || currentValue !== "" ? 0 : 30,
        },
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
}

export default RegisterIconTextField;
