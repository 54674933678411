import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid, List, createStyles } from "@material-ui/core";
import {
  hasWorkplace,
  hasResidence,
  isPendingResidence,
  isPendingWorkplace,
} from "../../utils/UserUtils";
import { COUNTRY, BUILDING } from "../../Constant";
import Country from "../../components/common/Establishment/Country";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import HomeIcon from "@material-ui/icons/Home";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Company from "../../components/common/Establishment/Company";
import PerfectScrollbar from "perfect-scrollbar";
import { withStyles } from "@material-ui/styles";
import Card from "components/common/Card/Card.js";
import CardHeader from "components/common/Card/CardHeader.js";
import CardBody from "components/common/Card/CardBody.js";

const useStyles = createStyles(styles);

class MyEstablishments extends React.Component {
  constructor(props) {
    super(props);

    this.countriesRef = React.createRef();
    this.companiesRef = React.createRef();
    this.countryScroll = null;
    this.companyScroll = null;
  }

  componentDidMount() {
    this.countryScroll = new PerfectScrollbar(this.countriesRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
    this.companyScroll = new PerfectScrollbar(this.companiesRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
  }

  componentDidUpdate() {
    this.countryScroll.update();
    this.companyScroll.update();
  }

  componentWillUnmount() {
    this.countryScroll.destroy();
    this.companyScroll.destroy();
  }

  render() {
    const { companies, countries, user, t, classes } = this.props;

    let _countries = countries
      .sort((a, b) => ("" + a.name).localeCompare(b.name))
      .filter((c) => hasResidence(c, user))
      .map((c) => {
        switch (c.type) {
          case COUNTRY:
            return (
              <Country
                icon={<HomeIcon />}
                key={c.id}
                establishment={c}
                pending={isPendingResidence(c, user)}
              />
            );
          case BUILDING:
            return (
              <Country
                icon={<ApartmentIcon />}
                key={c.id}
                establishment={c}
                pending={isPendingResidence(c, user)}
              />
            );
          default:
            return null;
        }
      });

    let _companies = companies
      .sort((a, b) => ("" + a.name).localeCompare(b.name))
      .filter((c) => hasWorkplace(c, user))
      .map((c) => {
        return (
          <Company
            key={c.id}
            user={user}
            establishment={c}
            pending={isPendingWorkplace(c, user)}
          />
        );
      });

    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs>
            <Card className="single-card">
              <CardHeader className="single-card-header" color="alternate">
                <h4 className={classes.singleHeader}>
                  {t("countries_and_buildings")}
                </h4>
              </CardHeader>
              <CardBody className={classes.noPadding}>
                <div>
                  <List className={classes.fixedList} ref={this.countriesRef}>
                    {_countries}
                  </List>
                </div>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs>
            <Card className="single-card">
              <CardHeader className="single-card-header" color="alternate">
                <h4 className={classes.singleHeader}>{t("companies")}</h4>
              </CardHeader>
              <CardBody className={classes.noPadding}>
                <div>
                  <List className={classes.fixedList} ref={this.companiesRef}>
                    {_companies}
                  </List>
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  buildingsGroups: state.userReducer.buildingsGroups,
  officeBuildings: state.userReducer.officeBuildings,
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(MyEstablishments)));
