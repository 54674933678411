export function saveItem(name, content, isString) {
  if (isString) localStorage.setItem(name, content);
  else localStorage.setItem(name, JSON.stringify(content));
}

export function loadItem(name, isString) {
  if (isString) return localStorage.getItem(name);
  return JSON.parse(localStorage.getItem(name));
}

export function clearStorage() {
  return localStorage.clear();
}
