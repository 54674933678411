import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CHARTER_RESERVED_DELETE } from "actions/types";
import { CHARTER_RESERVED_ADD } from "actions/types";
import {
  CHARTERS_SET_VALID_DATA_STATUS,
  CHARTER_SELECTED_ADD_CLEAR,
  CHARTER_SELECTED_ADD_NEW,
  CHARTER_SELECTED_ADD_REMOVE,
  CHARTER_SELECTED_CANCEL_CLEAR,
  CHARTER_SELECTED_CANCEL_NEW,
  CHARTER_SELECTED_CANCEL_REMOVE,
  CHARTER_SELECTED_DATE,
  CHARTER_UNSELECTED_DATE,
} from "actions/types";
import useCalendar from "hooks/useCalendar";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PassAppApi from "services/PassAppApi";
import SnackbarMessage from "utils/SnackbarMessage";
import { generateUUID } from "utils/Utils";
import "./CustomCalendar.scss";

export default function CustomCalendar(props) {
  const {
    defaultDates,
    maximumMonth,
    data,
    charters_selected_cancel,
    charters_availability,
    params,
    token_generated,
  } = props;
  let date = new Date();
  let currYear = date.getFullYear();
  let currMonth = date.getMonth();
  const { getMonthName } = useCalendar();
  let beforeCurrMonth = currMonth === 0 ? 11 : date.getMonth() - 1;
  const [currentYear, setCurrentYear] = React.useState(currYear);
  const [currentMonth, setCurrentMonth] = React.useState(currMonth);
  const [calendarDates, setCalendarDates] = React.useState([]);
  const [selectedDatesAdd, setSelectedDatesAdd] = React.useState([]);
  const [dialogConfirmMonth, setDialogConfirmMonth] = React.useState(false);
  const [datesToAdd, setDatesToAdd] = React.useState([]);
  const [datesToCancel, setDatesToCancel] = React.useState([]);
  const [validDates, setValidDates] = React.useState([]);
  const [intentNext, setIntentNext] = React.useState(false);
  const [intentPrev, setIntentPrev] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const [changeMonth, setChangeMonth] = React.useState(true);
  const [charterUpdateData, setCharterUpdateData] = React.useState([]);
  const [charterDefaultDates, setCharterDefaultDates] = React.useState(
    defaultDates
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { charters_selected_add, charters_reserved } = useSelector(
    (state) => state.charterReducer
  );

  useEffect(() => {
    if (charterDefaultDates.length && charters_availability) {
      renderCalendar();
    }
  }, [currentMonth, currentYear, charterDefaultDates]);

  useEffect(() => {}, [charters_selected_add]);

  useEffect(() => {
    if (charterDefaultDates.length) {
      const filterDates = charterDefaultDates.filter(
        (d) => d.month === currentMonth
      );
      const filterValidDates = filterDates.filter(
        (f) =>
          f.className === "selectedPast" ||
          f.className === "greenSelected" ||
          f.className === "greenPoint" ||
          f.className === "redPoint"
      );
      setValidDates(filterValidDates);
      if (filterValidDates.length && currentYear !== currYear) {
        setValidDates(false);
      }
    }
  }, [charterDefaultDates, currentMonth, currentYear]);

  const renderCalendar = () => {
    if (!selected || !changeMonth) {
      setCalendarDates([]);
      //busco el primer dia del mes
      let firstDayofMonth = new Date(currentYear, currentMonth, 1).getDay();
      //busco el ultimo fecha del mes
      let lastDateofMonth = new Date(
        currentYear,
        currentMonth + 1,
        0
      ).getDate();
      let lastDayofMonth = new Date(
        currentYear,
        currentMonth,
        lastDateofMonth
      ).getDay();
      let lastDateofLastMonth = new Date(
        currentYear,
        currentMonth,
        0
      ).getDate();
      //marca los primeros dias del mes anterior
      for (let i = firstDayofMonth; i > 0; i--) {
        setCalendarDates((oldArray) => [
          ...oldArray,
          {
            year: currentYear,
            month: currentMonth,
            day: lastDateofLastMonth - i + 1,
            hour: "",
            charterId: "",
            charterName: "",
            charterDescription: "",
            className: "inactive",
            status: false,
            id: generateUUID(),
            type: "firstDates",
          },
        ]);
      }
      //marca los dias restantes
      for (let i = 1; i <= lastDateofMonth; i++) {
        let ad = true;
        if (charterDefaultDates.length) {
          const filterDefaultDates = charterDefaultDates.filter(
            (f) => f.month === currentMonth && f.year === currentYear
          );
          const dateSearch = filterDefaultDates.find((d) => d.day === i);
          if (dateSearch) {
            i = dateSearch.day;
            setCalendarDates((oldArray) => [
              ...oldArray,
              {
                year: dateSearch.year,
                month: dateSearch.month,
                day: i,
                hour: dateSearch.hour,
                charterId: dateSearch.charterId,
                charterName: dateSearch.charterName,
                charterDescription: dateSearch.charterDescription,
                type: "activeDates",
                className: dateSearch.className,
                id: generateUUID(),
              },
            ]);
            ad = false;
          }
        }
        if (ad) {
          setCalendarDates((oldArray) => [
            ...oldArray,
            {
              year: currentYear,
              month: currentMonth,
              day: i,
              hour: "",
              charterId: "",
              charterName: "",
              charterDescription: "",
              className: "inactive",
              type: "inactiveDates",
              id: generateUUID(),
            },
          ]);
        }
      }
      //marca los ultimos dias del mes proximo
      for (let i = lastDayofMonth; i < 6; i++) {
        setCalendarDates((oldArray) => [
          ...oldArray,
          {
            year: currentYear,
            month: currentMonth,
            day: i - lastDayofMonth + 1,
            hour: "",
            charterId: "",
            charterName: "",
            charterDescription: "",
            className: "inactive",
            type: "lastDates",
            id: generateUUID(),
          },
        ]);
      }
      setDatesToAdd(
        calendarDates.filter(
          (c) =>
            c.month === currentMonth &&
            c.year === currentYear &&
            c.className === "greenPoint"
        )
      );
      setDatesToCancel(
        calendarDates.filter(
          (c) =>
            c.month === currentMonth &&
            c.year === currentYear &&
            c.className === "greenSelected"
        )
      );
    }
  };

  const prev = () => {
    setIntentPrev(true);
    if (charters_selected_add.length || charters_selected_cancel.length) {
      const filterAdds = charters_selected_add.find(
        (a) => a.charterId === data.charter.id
      );
      const cancelAdds = charters_selected_cancel.find(
        (d) => d.charterId === data.charter.id
      );
      if (filterAdds || cancelAdds) {
        setDialogConfirmMonth(true);
      } else {
        setCurrentMonth(currentMonth - 1);
        if (currentMonth === 0) {
          setCurrentMonth(11);
          setCurrentYear(currentYear - 1);
        }
      }
    } else {
      setCurrentMonth(currentMonth - 1);
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      }
    }
  };

  const next = () => {
    setIntentNext(true);
    if (currentMonth === maximumMonth) {
      SnackbarMessage.show("info", t("charter_reserve_max_reserve_msj"));
    } else {
      if (charters_selected_add.length || charters_selected_cancel.length) {
        const filterAdds = charters_selected_add.find(
          (a) => a.charterId === data.charter.id
        );
        const cancelAdds = charters_selected_cancel.find(
          (d) => d.charterId === data.charter.id
        );
        if (filterAdds || cancelAdds) {
          setDialogConfirmMonth(true);
        } else {
          setCurrentMonth(currentMonth + 1);
          if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
          }
        }
      } else {
        setCurrentMonth(currentMonth + 1);
        if (currentMonth === 11) {
          setCurrentMonth(0);
          setCurrentYear(currentYear + 1);
        }
      }
    }
  };

  const selectDate = (t) => {
    setSelected(true);
    if (t.className === "selectedPoint") {
      dispatch({
        type: CHARTER_SELECTED_CANCEL_REMOVE,
        payload: t,
      });
    } else {
      dispatch({
        type: CHARTER_SELECTED_DATE,
        payload: t,
      });
      t.className = "selected";
      dispatch({
        type: CHARTER_SELECTED_ADD_NEW,
        payload: t,
      });
      dispatch({
        type: CHARTER_RESERVED_ADD,
        payload: {
          hour: t.hour,
          day: t.day,
          month: t.month,
          year: t.year,
          id: t.id,
          charter: t.charterId,
          date: "",
        },
      });
    }
    const newDates = [...calendarDates];
    t.className = "selected";
    newDates.forEach((n) => {
      if (n.day === t.day) {
        n = t;
      }
    });
    setCalendarDates(newDates);
  };

  const validateDifferenceHours = (hourOne, hourTwo) => {
    let res = true;
    let hourOneDateNumber = Number(hourOne.substring(0, 2));
    let hourTwoDateNumber = Number(hourTwo.substring(0, 2));
    let differenceHours = hourOneDateNumber - hourTwoDateNumber;
    if (differenceHours < 0) differenceHours = differenceHours * -1;
    if (differenceHours < 2) {
      res = false;
    }
    return res;
  };

  const validateReservedDates = (t) => {
    let res = true;
    charters_reserved.forEach((c) => {
      const date = new Date(c.date);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const day = date.getDate() + 1;
      if (day === t.day && month === t.month + 1 && year === t.year) {
        if (validateDifferenceHours(c.hour, t.hour) === false) {
          res = false;
          dispatch({
            type: CHARTERS_SET_VALID_DATA_STATUS,
            payload: true,
          });
        }
      }
    });
    return res;
  };

  const validateAddDates = (t) => {
    let res = true;
    if (charters_selected_add.length) {
      charters_selected_add.forEach((c) => {
        const month = c.month + 1;
        if (c.day === t.day && month === t.month + 1 && c.year === t.year) {
          if (validateDifferenceHours(c.hour, t.hour) === false) {
            res = false;
            dispatch({
              type: CHARTERS_SET_VALID_DATA_STATUS,
              payload: true,
            });
          }
        }
      });
    }
    return res;
  };

  //Desmarcar la fecha
  const unmarkDateCalendar = (t) => {
    const newDates = calendarDates;
    if (t.className === "greenSelected") {
      setSelected(true);
      t.className = "selectedPoint";
      newDates.forEach((n) => {
        if (n.day === t.day) n = t;
      });
      setCalendarDates(newDates);
      dispatch({
        type: CHARTER_RESERVED_DELETE,
        payload: t,
      });
      dispatch({
        type: CHARTER_SELECTED_CANCEL_NEW,
        payload: t,
      });
    } else {
      const datesFiltered = selectedDatesAdd.filter((s) => s.day !== t.day);
      setSelectedDatesAdd(datesFiltered);
      t.className = "greenPoint";
      newDates.forEach((n) => {
        if (n.day === t.day) n = t;
      });
      setCalendarDates(newDates);
      dispatch({
        type: CHARTER_UNSELECTED_DATE,
        payload: t,
      });
      dispatch({
        type: CHARTER_SELECTED_ADD_REMOVE,
        payload: t,
      });
    }
  };
  //Marcar la fecha
  const markDateCalendar = (t) => {
    if (validateReservedDates(t)) {
      if (validateAddDates(t)) {
        selectDate(t);
      }
    }
  };
  //Procesar fecha
  const markOrUnmarkDay = (t) => {
    const isGreenPoint = t.className === "greenPoint";
    if (t.className === "selectedPoint" || t.className === "greenPoint") {
      markDateCalendar(t);
    } else {
      if (t.className === "greenSelected" || t.className === "selected") {
        unmarkDateCalendar(t, isGreenPoint);
      }
    }
  };

  const closeDialogConfirmMonth = () => {
    setDialogConfirmMonth(false);
  };

  const onConfirmChangeMonth = () => {
    setDialogConfirmMonth(false);
  };

  const filterAddDataUpdate = () => {
    let res = {};
    let newCal = [];
    charterDefaultDates.forEach((c) => {
      res = charters_selected_add.find(
        (s) =>
          s.day === c.day &&
          s.month === c.month &&
          c.year === s.year &&
          c.hour === s.hour &&
          c.charterId === s.charterId
      );
      if (res) {
        newCal.push(res);
      }
      newCal.push(c);
    });
    setCharterDefaultDates(newCal);
  };

  const filterCancelDataUpdate = () => {
    let res = {};
    let newCal = [];
    charterDefaultDates.forEach((c) => {
      res = charters_selected_cancel.find(
        (s) =>
          s.day === c.day &&
          s.month === c.month &&
          c.year === s.year &&
          c.hour === s.hour &&
          c.charterId === s.charterId
      );
      if (res) {
        newCal.push({ ...res, className: "greenPoint" });
      }
      newCal.push(c);
    });
    setCharterDefaultDates(newCal);
  };

  const filterFullDataUpdate = () => {
    let resCancel = {};
    let resAdd = {};
    let newCal = [];
    charterDefaultDates.forEach((c) => {
      resCancel = charters_selected_cancel.find(
        (s) =>
          s.day === c.day &&
          s.month === c.month &&
          c.year === s.year &&
          c.hour === s.hour &&
          c.charterId === s.charterId
      );
      resAdd = charters_selected_add.find(
        (s) =>
          s.day === c.day &&
          s.month === c.month &&
          c.year === s.year &&
          c.hour === s.hour &&
          c.charterId === s.charterId
      );
      if (resCancel) {
        newCal.push({ ...resCancel, className: "greenPoint" });
      }
      if (resAdd) {
        newCal.push({ ...resAdd, className: "selected" });
      }
      newCal.push(c);
    });
    setCharterDefaultDates(newCal);
  };

  const onConfirmReserve = () => {
    setChangeMonth(false);
    setCharterReservations(
      token_generated,
      params.company,
      getReservationsSelected()
    );
    if (charters_selected_add.length && !charters_selected_cancel.length) {
      filterAddDataUpdate();
    }
    if (charters_selected_cancel.length && !charters_selected_add.length) {
      filterCancelDataUpdate();
    }
    if (charters_selected_cancel.length && charters_selected_add.length) {
      filterFullDataUpdate();
    }
    if (intentNext) {
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      }
      setCurrentMonth(currentMonth + 1);
      setIntentNext(false);
      setDialogConfirmMonth(false);
      setChangeMonth(false);
      dispatch({
        type: CHARTER_SELECTED_ADD_CLEAR,
        payload: [],
      });
      dispatch({
        type: CHARTER_SELECTED_CANCEL_CLEAR,
        payload: [],
      });
    } else if (intentPrev) {
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      }
      setCurrentMonth(currentMonth - 1);
      setIntentPrev(false);
      setDialogConfirmMonth(true);
    }
  };

  const getReservationsSelected = () => {
    const res = [];
    let datesAdd = [];
    let datesCancel = [];
    let charterId;
    charters_availability.forEach((c) => {
      charterId = c.charter.id;
      if (charters_selected_add.length !== 0) {
        const array3 = charterUpdateData.concat(charters_selected_add);
        setCharterUpdateData(array3);
        datesAdd = charters_selected_add.filter(
          (c) => c.charterId === charterId
        );
      }
      if (charters_selected_cancel.length !== 0) {
        const array3 = charterUpdateData.concat(charters_selected_cancel);
        setCharterUpdateData(array3);
        datesCancel = charters_selected_cancel.filter(
          (c) => c.charterId === charterId
        );
      }
      if (datesAdd.length > 0 || datesCancel.length > 0) {
        res.push({
          datesToAdd: processDates(datesAdd),
          datesToCancel: processDates(datesCancel),
          charter: charterId,
        });
      }
    });
    return res;
  };

  const processDates = (dates) => {
    let res = [];
    let day;
    let month;
    let monthSel;
    dates.forEach((d) => {
      monthSel = d.month + 1;
      if (monthSel < 10) {
        month = "0" + String(monthSel);
      } else {
        month = monthSel;
      }
      if (d.day < 10) {
        day = "0" + String(d.day);
      } else {
        day = d.day;
      }
      res.push(`${d.year}-${month}-${day}`);
    });
    return res;
  };

  const setCharterReservations = (tokenGenerated, company, reservations) => {
    PassAppApi.setCharterReservations(tokenGenerated, company, reservations)
      .then(() => {
        SnackbarMessage.show("success", t("charter_reserve_msg_confirm_dates"));
      })
      .catch((err) => {
        console.log(err);
        SnackbarMessage.show(
          "error",
          t("charter_reserve_error_send") + ": " + (err ? err.toString() : "")
        );
      });
  };

  return (
    <div className="wrapper">
      <header>
        <div className="icons">
          <IconButton
            aria-label="back"
            onClick={prev}
            disabled={
              currentMonth === beforeCurrMonth && currYear === currentYear
            }
            style={{ marginRight: "2em" }}
          >
            <ArrowBackIosIcon color="primary" />
          </IconButton>
          <p className="current-date">
            {getMonthName(currentMonth)} {currentYear}
          </p>
          <IconButton
            aria-label="forward"
            onClick={next}
            style={{ marginLeft: "2em" }}
          >
            <ArrowForwardIosIcon color="primary" />
          </IconButton>
        </div>
      </header>
      <div className="description">
        <p className="title">{t("charter_reserve_description_travel")}</p>
        <p className="parrafo">{data.charter.description}</p>
      </div>
      {validDates.length ? (
        <div className="calendar">
          <ul className="weeks">
            <li>{t("day_letter_sunday")}</li>
            <li>{t("day_letter_monday")}</li>
            <li>{t("day_letter_tuesday")}</li>
            <li>{t("day_letter_wednesday")}</li>
            <li>{t("day_letter_thursday")}</li>
            <li>{t("day_letter_friday")}</li>
            <li>{t("day_letter_saturday")}</li>
          </ul>
          <ul className="days">
            {calendarDates.map((t, key) => (
              <li
                key={key}
                className={t.className}
                style={
                  t.className === "selectedPast" ||
                  t.className === "greenSelected" ||
                  t.className === "selected"
                    ? {
                        color: "white",
                      }
                    : t.className === "inactive"
                    ? { color: "gray" }
                    : { color: "black" }
                }
                onClick={() => markOrUnmarkDay(t)}
              >
                {t.day}
              </li>
            ))}
          </ul>
          <div className="detail">
            <Grid
              container
              direction="row"
              style={{ flexGrow: 1 }}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={1}>
                <span className="greenReserve"></span>
              </Grid>
              <Grid item xs={7}>
                <p style={{ marginTop: 20, marginLeft: -14, fontWeight: 600 }}>
                  {t("charter_reserve_color_detail_green")}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ flexGrow: 1 }}
            >
              <Grid item xs={1}>
                <span className="redReserve"></span>
              </Grid>
              <Grid item xs={7}>
                <p style={{ marginTop: 2, marginLeft: -14, fontWeight: 600 }}>
                  {t("charter_reserve_color_detail_red")}
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className="message">
          <p>{t("charter_reserve_whitout_reserves")}</p>
        </div>
      )}

      <Dialog
        onClose={closeDialogConfirmMonth}
        className="confirmation-dialog"
        aria-labelledby="simple-dialog-title"
        open={dialogConfirmMonth}
        style={{ height: "20em", marginTop: "20em" }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("charter_reserve_dialog_change_mont_txt")}
          </DialogContentText>
          <DialogActions className="dialog-buttons">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ flexGrow: 1 }}
            >
              <Grid item xs={12}>
                <Button
                  onClick={onConfirmReserve}
                  color="primary"
                  variant="contained"
                  style={{ color: "white" }}
                >
                  {t("charter_reserve_dialog_confirm_reserves")}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={onConfirmChangeMonth} color="primary">
                  {t("charter_reserve_change_month")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

CustomCalendar.propTypes = {
  defaultDates: PropTypes.array,
  maximumMonth: PropTypes.number,
  data: PropTypes.object,
  charters_selected_cancel: PropTypes.array,
  charters_availability: PropTypes.array,
  params: PropTypes.object,
  token_generated: PropTypes.string,
};
