import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { createStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withStyles } from "@material-ui/styles";
import { Tab, Tabs } from "@material-ui/core";
import "./Desks.scss";
import DesksList from "components/Desks/DesksList";
import DesksGroups from "components/Desks/DesksGroups";
import DesksReservations from "components/Desks/DesksReservations";
import DesksUsageReports from "components/Desks/DesksUsageReports";
import DesksUsers from "components/Desks/DesksUsers";
import MessageDialog from "components/common/MessageDialog/MessageDialog";

import { getDeskGroupsList, getEmployeesDeskGroups } from "../../actions/desk";

const useStyles = createStyles(styles);

const Desks = (props) => {
  const { t, getDeskGroupsList, getEmployeesDeskGroups } = props;
  const [tabSel, setTabSel] = useState(0);
  const [diagMsj, setDiagMsj] = useState({ open: false, message: "" });

  useEffect(() => {
    getDeskGroupsList(t, false);
    getEmployeesDeskGroups(t, false);
  }, []);

  const showMessaje = (msj) => {
    setDiagMsj({ open: true, message: msj });
  };
  const closeMessaje = () => {
    setDiagMsj({ open: false, message: "" });
  };

  const onChangeTabs = (event, newValue) => {
    setTabSel(newValue);
  };

  return (
    <div className="content-box DesksContent">
      <div className="clearfix">
        <Tabs
          value={tabSel}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChangeTabs}
          className="pull-left"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label={t("desks")} />
          <Tab label={t("desks_groups")} />
          <Tab label={t("desks_reserv")} />
          <Tab label={t("desks_reports")} />
          <Tab label={t("desks_users")} />
        </Tabs>
      </div>
      {tabSel === 0 && <DesksList t={t} showMessaje={showMessaje}></DesksList>}
      {tabSel === 1 && (
        <DesksGroups t={t} showMessaje={showMessaje}></DesksGroups>
      )}
      {tabSel === 2 && (
        <DesksReservations t={t} showMessaje={showMessaje}></DesksReservations>
      )}
      {tabSel === 3 && (
        <DesksUsageReports t={t} showMessaje={showMessaje}></DesksUsageReports>
      )}
      {tabSel === 4 && (
        <DesksUsers t={t} showMessaje={showMessaje}></DesksUsers>
      )}
      <MessageDialog
        className="alertDialog"
        open={diagMsj.open}
        message={diagMsj.message}
        onClose={closeMessaje}
      ></MessageDialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.desks.loading,
});

const actionCreators = {
  getDeskGroupsList: getDeskGroupsList,
  getEmployeesDeskGroups: getEmployeesDeskGroups,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(Desks)));
