import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import jwt from "./jwt";
import rootReducer from "./reducers";
import throttle from "lodash.throttle";
import { saveItem, loadItem } from "./utils/Store";

const persistedState = loadItem("state");
export const store = createStore(
  rootReducer,
  persistedState || {},
  applyMiddleware(thunkMiddleware, jwt)
);

store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveItem("state", {
      authReducer: {
        token: state.authReducer.token,
        token_files: state.authReducer.token_files,
        user: state.authReducer.user,
        state: state.authReducer.state,
        modeAdm: state.authReducer.modeAdm,
        activationToken: state.authReducer.activationToken,
        association: state.authReducer.association,
        enableAssociation: state.authReducer.enableAssociation,
        otherCompany: state.authReducer.otherCompany,
        externalToken: state.authReducer.externalToken,
      },
      userReducer: state.userReducer,
    });
  }, 0)
);
