import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { onDialogOpen } from "../../actions/dialog";
import { myGuestsOpenMenu } from "../../actions/menu";
import { getAuthorizations, getInvitesAsHost } from "../../actions/user";
import NotifySecurity from "../../components/NotifySecurity/NotifySecurity";
import PassDetail from "../../components/PassDetail/PassDetail";
import PassesBlock from "../../components/PassesApp/PassesBlock";
import PassesSearch from "../../components/PassesApp/PassesSearch";
import PassList from "../../components/PassesApp/PassList";
import { PassContextProvider } from "../../context/PassContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
});

const PassesApp = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMyGuestsClicked } = useSelector((state) => state.menuReducer);

  const onCloseMenu = () => {
    dispatch(myGuestsOpenMenu(null));
  };

  const newInvite = () => {
    dispatch(onDialogOpen(PassDetail, {}));
    onCloseMenu();
  };

  const openSecurityDetail = () => {
    dispatch(onDialogOpen(NotifySecurity, {}));
    onCloseMenu();
  };

  useEffect(() => {
    dispatch(getInvitesAsHost(t));
    dispatch(getAuthorizations(t));
  }, []);

  return (
    <PassContextProvider>
      <div className={classes.root}>
        <PassesSearch />
        <PassesBlock title={t("pass_title_ocasional")}>
          <PassList ocasional />
        </PassesBlock>
        <PassesBlock title={t("pass_title_permanent")}>
          <PassList permanent />
        </PassesBlock>
        <div style={{ height: 30 }}></div>
        {isMyGuestsClicked && (
          <Menu
            anchorEl={isMyGuestsClicked}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            open={Boolean(isMyGuestsClicked)}
            onClose={onCloseMenu}
          >
            <MenuItem onClick={newInvite}>{t("send_pass")}</MenuItem>
            <MenuItem onClick={openSecurityDetail}>
              {t("notify_security")}
            </MenuItem>
          </Menu>
        )}
      </div>
    </PassContextProvider>
  );
};

export default PassesApp;
