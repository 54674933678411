import React from "react";
import ByReserve from "../reports/ByReserve";

class SpaceReserveReport extends React.Component {
  render() {
    return <ByReserve handleList={this.handleList}></ByReserve>;
  }
}

export default SpaceReserveReport;
