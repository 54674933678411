import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import md5 from "md5";
import moment from "moment";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { myQrRefreshReset } from "../../actions/menu";
import { MD5_SALT, QR_SEPARATOR, QR_SEPARATOR_IMAGE } from "../../Constant";
import LOGO from "../../assets/img/logo-icon.svg";

const MAX_TIME_RELOAD = 60;

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: "100%",
    maxWidth: "400px",
    height: "fit-content",
  },
  qr: {
    height: "auto !important",
    width: "100% !important",
    paddingTop: 10,
  },
});

const MyQR = () => {
  const classes = useStyles();
  const { token, otherCompany } = useSelector((state) => state.authReducer);
  const { isMyQrClicked } = useSelector((state) => state.menuReducer);
  const id = token.split(":")[0];
  const [timer, setTimer] = useState(0);
  const [code, setCode] = useState(
    `${id}${QR_SEPARATOR_IMAGE}${moment().valueOf()}${QR_SEPARATOR_IMAGE}`
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const time = setTimeout(() => setTimer((p) => p + 1), 1000);
    if (timer > MAX_TIME_RELOAD) {
      setTimer(0);
      setCode(
        `${id}${QR_SEPARATOR_IMAGE}${moment().valueOf()}${QR_SEPARATOR_IMAGE}`
      );
    }
    return () => {
      clearTimeout(time);
    };
  }, [timer]);

  useEffect(() => {
    if (isMyQrClicked) {
      setTimer(500);
      dispatch(myQrRefreshReset());
    }
  }, [isMyQrClicked]);

  const getCodeToEncript = (code) => {
    if (typeof code === "string") {
      const replace = code?.replaceAll(QR_SEPARATOR_IMAGE, QR_SEPARATOR);
      return `${replace.substring(0, replace.length - 2)}${MD5_SALT}`;
    } else return "";
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent style={{ width: "auto" }}>
          {!otherCompany?.isExternalUser && (
            <Typography variant="h6" color="primary">
              {t("my_qr_title")}
            </Typography>
          )}
          <QRCode
            className={classes.qr}
            value={`${code}${md5(getCodeToEncript(code))}`}
            imageSettings={{
              src: LOGO,
              excavate: true,
              height: 20,
              width: 20,
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default MyQR;
