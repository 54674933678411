import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  label: {
    lineHeight: "1.42857",
    color: "#aaaaaa",
    fontWeight: "400",
    marginInline: "5px",
  },
  input: {
    flexGrow: 1,
    borderRadius: "6px",
    backgroundColor: "#FFF",
    border: "1px solid #c3c3c3",
    minWidth: "8em",
    color: "#969696",
    "& .MuiInputBase-input": {
      padding: "7px 6px",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    "& .MuiInput-underline:before": {
      borderRadius: "6px",
    },
  },
  button: {
    backgroundColor: "#FFF",
    padding: "5px 10px",
    marginRight: "4px",
    border: "1px solid #c3c3c3",
    color: "#969696",

    "&:hover": {
      backgroundColor: "rgb(248, 248, 248)",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ecebeb",
    padding: "7px",
  },
  filterButton: {
    backgroundColor: "#439f47",
    color: "#FFF",
    borderRadius: "12px",
    "&.MuiButton-root:hover": {
      backgroundColor: "#439f47a8",
    },
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
  },
  search: {
    flexGrow: 1,
    padding: "2px 7px",
    border: "1px solid #e6e6e6",
    borderRadius: "6px",
    backgroundColor: "#FFF",
    "& .MuiInput-underline:before": {
      borderBottom: "none !important",
    },
    "& .MuiInput-underline:hover": {
      borderBottom: "none",
    },
  },
}));
