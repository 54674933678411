import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import RoomReservationTable from "components/RoomReservation/RoomReservationTable";
import RoomReservationEdit from "components/RoomReservation/RoomReservationEdit";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import SearchFilter from "components/common/Table/SearchFilter";
import MessageDialog from "components/common/MessageDialog/MessageDialog";
import { FormControl, Select, MenuItem, Fab } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import "./RoomReservation.scss";
import {
  getRoomReservationList,
  filterRoomReservationList,
  cancelRoomReservation,
  getRoomReservationSettings,
  getRoomReservation,
} from "../../actions/RoomReservation";

const useStyles = createStyles(styles);

const RoomReservation = (props) => {
  const {
    t,
    filteredList,
    filterList,
    baseURL,
    allItems,
    token_file,
    reservation,
    getRoomReservation,
    getAllRoomReservations,
    cancelRoomReservation,
    getSettings,
  } = props;

  const [globalFilter, setGlobalFilter] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [item, setItem] = useState(null);
  const [globalPageIndex, setGlobalPageIndex] = useState(0);
  const [showConfirmUpdStatus, setShowConfirmUpdStatus] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [viewEdit, setViewEdit] = useState(false);
  const [pdfSettings, setPdfSettings] = useState({
    openPdf: false,
    openPdfTitle: null,
    openPdfUrl: null,
  });
  const [reservID, setReservID] = useState(null);
  const [diagMsj, setDiagMsj] = useState({
    open: false,
    message: "",
  });

  const setCurrentPage = (page) => {
    setGlobalPageIndex(page);
  };
  const editReservation = (ID = null) => {
    getRoomReservation(t, ID);
    setReservID(ID);
    setViewEdit(true);
  };
  const closeReservation = () => {
    setViewEdit(false);
  };
  const showPdf = (title, url) => {
    setPdfSettings({
      openPdf: true,
      openPdfTitle: title,
      openPdfUrl: url,
    });
  };
  const closePdf = () => {
    setPdfSettings({
      openPdf: false,
      openPdfTitle: null,
      openPdfUrl: null,
    });
  };
  const handleGlobalFilter = (param) => {
    setGlobalFilter(param);
    setGlobalPageIndex(0);
    filterList(allItems, param, filterStatus);
  };

  const filterByStatus = (event) => {
    setFilterStatus(event.target.value);
    setGlobalPageIndex(0);
    filterList(allItems, globalFilter, event.target.value);
  };
  const handleCancel = (item) => {
    let text = t("confirm_text");
    setShowConfirmUpdStatus(true);
    setDialogText(text);
    setItem(item);
  };

  const closeCancel = () => {
    setShowConfirmUpdStatus(false);
    setDialogText("");
  };
  const showMessaje = (msj) => {
    setDiagMsj({
      open: true,
      message: msj,
    });
  };
  const closeMessaje = () => {
    setDiagMsj({
      open: false,
      message: "",
    });
  };
  const handleConfirmCancel = () => {
    setShowConfirmUpdStatus(false);
    setDialogText("");
    cancelRoomReservation(t, item);
  };

  useEffect(() => {
    getAllRoomReservations(t);
    getSettings(t);
  }, []);

  return (
    <div className="content-box RoomReservationContent scroll">
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={closePdf}
        open={pdfSettings.openPdf}
        className="pdfDialog"
      >
        <DialogTitle>
          {pdfSettings.openPdfTitle}
          <div className="dialog-title-button">
            <IconButton onClick={closePdf} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="dialog">
          <iframe src={pdfSettings.openPdfUrl}></iframe>
        </DialogContent>
      </Dialog>

      {viewEdit && reservation ? (
        <RoomReservationEdit
          showMessaje={showMessaje}
          itemID={reservID}
          closeReservation={closeReservation}
          openPdf={showPdf}
        ></RoomReservationEdit>
      ) : (
        <>
          <div className="header-box">
            <h1 className="title">{t("room_reseration")}</h1>
            <div className="filter-box">
              <FormControl className="seach-dropdown">
                <Select
                  name="filterStatus"
                  label={t("passes_validator")}
                  onChange={filterByStatus}
                  fullWidth={true}
                  value={filterStatus}
                >
                  <MenuItem value="all">{t("all_results")}</MenuItem>
                  <MenuItem value="pending">{t("pending")}</MenuItem>
                  <MenuItem value="approved">{t("approved")}</MenuItem>
                  <MenuItem value="rejected">{t("rejected")}</MenuItem>
                  <MenuItem value="canceled">{t("canceled")}</MenuItem>
                </Select>
              </FormControl>
              <SearchFilter
                className="search-box"
                setGlobalFilter={handleGlobalFilter}
                globalFilter={globalFilter}
                t={t}
              />
            </div>
          </div>
          <RoomReservationTable
            data={filteredList}
            handleCancel={handleCancel}
            t={t}
            token_file={token_file}
            globalPageIndex={globalPageIndex}
            setCurrentPage={setCurrentPage}
            fileBaseUrl={baseURL}
            editReservation={editReservation}
          ></RoomReservationTable>
          <ConfirmationDialog
            open={showConfirmUpdStatus}
            onConfirm={handleConfirmCancel}
            onClose={closeCancel}
            title={dialogText}
          />
          <Fab
            color="primary"
            onClick={() => editReservation(null)}
            className="addNewItem"
          >
            <AddIcon />
          </Fab>
        </>
      )}
      <MessageDialog
        className="alertDialog"
        open={diagMsj.open}
        message={diagMsj.message}
        onClose={closeMessaje}
      ></MessageDialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteredList: state.roomReservation.currentList,
  allItems: state.roomReservation.list,
  reservation: state.roomReservation.item,
  baseURL: state.roomReservation.baseURL,
  token_file: state.authReducer.token_file,
});

const mapActionsToProps = {
  getAllRoomReservations: getRoomReservationList,
  filterList: filterRoomReservationList,
  cancelRoomReservation: cancelRoomReservation,
  getRoomReservation: getRoomReservation,
  getSettings: getRoomReservationSettings,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(withStyles(useStyles)(RoomReservation)));
