import React from "react";

import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  inputInput: {
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const SearchFilter = ({ setGlobalFilter, globalFilter, t }) => {
  const classes = useStyles();
  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.
  return (
    <div className="search-box">
      <InputBase
        value={globalFilter || ""}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={t("search")}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};

SearchFilter.propTypes = {
  setGlobalFilter: PropTypes.func.isRequired,
};

export default SearchFilter;
