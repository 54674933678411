import { Button, Grid } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDateFilterFrom,
  changeDateFilterTo,
  getListEvents,
} from "../../../actions/events";
import { useStyle } from "./AccessEventFilterStyle";

const DateFilter = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { t, onSubmit } = props;
  const stateEvents = useSelector((state) => state.events);

  const onChangeDate = (date, field) =>
    field === "from"
      ? dispatch(changeDateFilterFrom(date))
      : field === "to" && dispatch(changeDateFilterTo(date));

  const onFilter = () => {
    stateEvents.from.set({hour:0, minute:0, second:0, millisecond:0});
    dispatch(getListEvents(stateEvents.from.unix(), stateEvents.to.unix()));
    if (onSubmit) onSubmit();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5} lg={5}>
        <div className={classes.formContainer}>
          <label className={classes.label}>{t("dete_from")}</label>
          <DatePicker
            className={classes.input}
            format="DD/MM/YYYY"
            value={stateEvents.from}
            onChange={(date) => onChangeDate(date, "from")}
            invalidDateMessage=""
            color="primary"
            autoOk
          />
        </div>
      </Grid>
      <Grid item xs={12} md={5} lg={5}>
        <div className={classes.formContainer}>
          <label className={classes.label}>{t("date_to")}</label>
          <DatePicker
            className={classes.input}
            format="DD/MM/YYYY"
            value={stateEvents.to}
            onChange={(date) => onChangeDate(date, "to")}
            invalidDateMessage=""
            disableFuture
            autoOk
          />
        </div>
      </Grid>
      <Grid item>
        <Button className={classes.filterButton} onClick={onFilter}>
          {t("filter")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(DateFilter);
