//companyStaff
import PassAppApi from "../services/PassAppApi";
import SnackbarMessage from "../utils/SnackbarMessage";
import {
  DESKS_LOAD,
  DESKS_LOADING,
  DESKS_LOADING_END,
  DESKS_GROUP_LOAD,
  DESKS_FILTERED_LOAD,
  DESKS_EMPLOYEES_LOAD,
  DESKS_CHECK_DESKS_GROUP,
  DESKS_CHECK_DESKS_LOT,
  DESKS_LOAD_RESERV_BY_DESK,
  DESKS_LOAD_RESERV_BY_DETAIL,
  DESKS_LOAD_REPORT_BY_USERS,
  DESKS_LOAD_REPORT_BY_DESK,
  DESKS_ACTION,
  DESKS_LAYOUT,
} from "./types";

export const filterList = (result) => (dispatch) => {
  dispatch({
    type: DESKS_FILTERED_LOAD,
    payload: { data: result },
  });
};
export const getDeskList = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });

  dispatch({
    type: DESKS_ACTION,
    payload: "getDeskList",
  });

  PassAppApi.deskManagerSend_POST("getDesks", token, company)
    .then((response) => {
      dispatch({
        type: DESKS_LOAD,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_desk")
      );
    });
};

export const addDesks = (t, Desks) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;

  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("addDesks", token, company, { desks: Desks })
    .then(() => {
      dispatch(getDeskList(t));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_add_desk") + ": " + (err ? err.toString() : "")
      );
    });
};

export const editDesk = (t, id, name) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("editDesk", token, company, {
    id: id,
    name: name,
  })
    .then(() => {
      dispatch(getDeskList(t));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_desk") + ": " + (err ? err.toString() : "")
      );
    });
};

export const setDeskDeskGroup = (t, id, DeskGroupID) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;

  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("setDeskDeskGroup", token, company, {
    id: id,
    deskGroup: DeskGroupID,
  })
    .then(() => {
      dispatch(getDeskList(t));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_desk") + ": " + (err ? err.toString() : "")
      );
    });
};

export const setDeskUser = (t, id, userID) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;

  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("setDeskUser", token, company, {
    id: id,
    user: userID,
  })
    .then(() => {
      dispatch(getDeskList(t));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_desk") + ": " + (err ? err.toString() : "")
      );
    });
};

export const unsetDeskUser = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("unsetDeskUser", token, company, { id: id })
    .then(() => {
      dispatch(getDeskList(t));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_edit_desk") + ": " + (err ? err.toString() : "")
      );
    });
};

export const deleteDesk = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;

  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("deleteDesk", token, company, { id: id })
    .then(() => {
      dispatch(getDeskList(t));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_deleting_desk") + ": " + (err ? err.toString() : "")
      );
    });
};

export const getDeskGroupsList = (t, filter = true) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_ACTION,
    payload: "getDeskGroupsList",
  });
  PassAppApi.deskManagerSend_POST("getDeskGroups", token, company)
    .then((response) => {
      dispatch({
        type: DESKS_GROUP_LOAD,
        payload: { data: response },
      });
      if (filter) dispatch(filterList(response));
      dispatch({ type: DESKS_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_desk_group")
      );
    });
};
export const getEmployeesDeskGroups = (t, filter = true) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_ACTION,
    payload: "getEmployeesDeskGroups",
  });
  PassAppApi.deskManagerSend_POST("getEmployeesDeskGroups", token, company)
    .then((response) => {
      dispatch({
        type: DESKS_EMPLOYEES_LOAD,
        payload: { data: response },
      });
      if (filter) dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_desk_employees")
      );
    });
};
export const addDeskGroup = (t, name) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("addDeskGroup", token, company, {
    name: name,
  })
    .then(() => dispatch(getDeskGroupsList(t)))
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_add_desk_group") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: DESKS_LOADING_END }));
};

export const editDeskGroup = (t, id, name) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("editDeskGroup", token, company, {
    id: id,
    name: name,
  })
    .then(() => dispatch(getDeskGroupsList(t)))
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_updating_desk_group") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: DESKS_LOADING_END }));
};

export const checkDeleteDesk = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_CHECK_DESKS_LOT,
    payload: false,
  });
  PassAppApi.deskManagerSend_POST("checkDeleteDesk", token, company, { id: id })
    .then((response) => {
      dispatch({
        type: DESKS_CHECK_DESKS_LOT,
        payload: response.count,
      });
      dispatch({ type: DESKS_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_deleting_desk_group") + ": " + (err ? err.toString() : "")
      );
    });
};
export const checkDeleteDeskGroup = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_CHECK_DESKS_LOT,
    payload: false,
  });
  PassAppApi.deskManagerSend_POST("checkDeleteDeskGroup", token, company, {
    id: id,
  })
    .then((response) => {
      dispatch({
        type: DESKS_CHECK_DESKS_GROUP,
        payload: response.count,
      });
      dispatch({ type: DESKS_LOADING_END });
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_deleting_desk_group") + ": " + (err ? err.toString() : "")
      );
    });
};

export const deleteDeskGroup = (t, id) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("deleteDeskGroup", token, company, { id: id })
    .then(() => dispatch(getDeskGroupsList(t)))
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_deleting_desk_group") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: DESKS_LOADING_END }));
};
export const addEmployeeDeskGroup = (t, id, DeskGroup) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("addEmployeeDeskGroup", token, company, {
    id: id,
    deskGroup: DeskGroup,
  })
    .then(() => dispatch(getEmployeesDeskGroups(t)))
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_add_employeeDeskGroup") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: DESKS_LOADING_END }));
};
export const removeEmployeeDeskGroup = (t, id, DeskGroup) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  PassAppApi.deskManagerSend_POST("removeEmployeeDeskGroup", token, company, {
    id: id,
    deskGroup: DeskGroup,
  })
    .then(() => dispatch(getEmployeesDeskGroups(t)))
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        t("error_remove_employeeDeskGroup") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: DESKS_LOADING_END }));
};

/**
 * Desk Reservations
 */

export const getDeskReservationsByDesks = (t, date) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_ACTION,
    payload: "getDeskReservationsByDesks",
  });
  PassAppApi.deskManagerSend_POST(
    "getDeskReservationsByDesks",
    token,
    company,
    { date: date }
  )
    .then((response) => {
      dispatch({
        type: DESKS_LOAD_RESERV_BY_DESK,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_desks")
      );
    });
};

export const getDeskReservationsByDetail = (t, from, to, status) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_ACTION,
    payload: "getDeskReservationsByDetail",
  });
  PassAppApi.deskManagerSend_POST(
    "getDeskReservationsByDetail",
    token,
    company,
    { from: from, to: to, status: status }
  )
    .then((response) => {
      dispatch({
        type: DESKS_LOAD_RESERV_BY_DETAIL,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_details")
      );
    });
};

export const rejectDeskReservation = (t, item, from, to, status) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });

  PassAppApi.deskManagerSend_POST("rejectDeskReservation", token, company, {
    id: item,
  })
    .then(() => {
      if (to) {
        dispatch(getDeskReservationsByDetail(t, from, to, status));
      } else {
        dispatch(getDeskReservationsByDesks(t, from));
      }
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error") + ": " + (err ? err.toString() : "")
      );
    })
    .finally(() => dispatch({ type: DESKS_LOADING_END }));
};

/**
 * Desk Reports
 */
export const getDeskReportByUsers = (t, from, to) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_ACTION,
    payload: "getDeskReportByUsers",
  });
  PassAppApi.deskManagerSend_POST("getDeskReportByUsers", token, company, {
    from: from,
    to: to,
  })
    .then((response) => {
      dispatch({
        type: DESKS_LOAD_REPORT_BY_USERS,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_desks")
      );
    });
};
export const getDeskReportByDeskGroups = (t, from, to) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;
  const company = getState().authReducer.user.deskManager.company;
  dispatch({ type: DESKS_LOADING });
  dispatch({
    type: DESKS_ACTION,
    payload: "getDeskReportByDeskGroups",
  });
  PassAppApi.deskManagerSend_POST("getDeskReportByDeskGroups", token, company, {
    from: from,
    to: to,
  })
    .then((response) => {
      dispatch({
        type: DESKS_LOAD_REPORT_BY_DESK,
        payload: { data: response },
      });
      dispatch(filterList(response));
    })
    .catch((err) => {
      dispatch({ type: DESKS_LOADING_END });
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_reservations_by_desks")
      );
    });
};

export const uploadDeskLayout = (t, company, file) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  dispatch({
    type: DESKS_LAYOUT,
    payload: null,
  });
  PassAppApi.uploadDeskPlan(token, company, file)
    .then((response) => {
      dispatch({
        type: DESKS_LAYOUT,
        payload: { id: response.id, name: file.name },
      });
    })
    .catch((error) => {
      SnackbarMessage.show(
        "error",
        error ? error.toString() : "error to upload"
      );
    });
};

export const getDeskLayout = (t, company) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  PassAppApi.getDeskPlan(token, company).then((response) => {
    dispatch({
      type: DESKS_LAYOUT,
      payload: { id: response },
    });
  });
};

export const deleteDeskLayout = (t, company) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  PassAppApi.deleteDeskPlan(token, company).then(() => {
    dispatch({
      type: DESKS_LAYOUT,
      payload: null,
    });
  });
};
