import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { TextField, FormControl, Button } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DesksTable from "../DesksTables";
import { columns } from "../columns/reportsByDesks";
import { getUserEstablishments, getTimezoneLocal } from "../../../utils/Utils";
import { getDeskReportByDeskGroups, filterList } from "../../../actions/desk";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ByDesks extends React.Component {
  constructor(props) {
    super(props);
    const { t, user, companies, company } = this.props;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

    let userEstablishments = getUserEstablishments(user, companies, []);
    let selectedEstablishment = userEstablishments.find(
      (e) => e.id === company
    );
    let timezone =
      selectedEstablishment && selectedEstablishment.timezone
        ? selectedEstablishment.timezone
        : getTimezoneLocal();

    let date = moment().tz(timezone);

    this.state = {
      searchText: "",
      from: date,
      to: date,
      globalPageIndex: 0,
    };
    this.props.getList(t, date.format("YYYY-MM-DD"), date.format("YYYY-MM-DD"));
  }

  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }

  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        if (v.name && v.name.search(new RegExp(text, "i")) > -1) return true;
        return false;
      })
    );
  }

  handleDateChange(date, field) {
    if (date && date.isValid()) {
      if (field === "from") {
        this.setState({ from: date });
      }

      if (field === "to") {
        this.setState({ to: date });
      }
    }
  }

  handleFilter() {
    const { from, to } = this.state;
    this.props.getList(
      this.props.t,
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD")
    );
  }

  render() {
    const { t, list, handleList, loading } = this.props;
    const { searchText, from, to, globalPageIndex } = this.state;

    const dataExport = list.map((item) => {
      return {
        name: item.name,
        total: item.total,
        used: item.used,
        percentage: item.percentage,
      };
    });

    return (
      <div className="body-box">
        <div className="search-box-2">
          <div className="column-left cols-3">
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
              <div className="filter_field">
                <label>{t("dete_from")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={from}
                    onChange={(date) => this.handleDateChange(date, "from")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
              <div className="filter_field">
                <label>{t("date_to")}</label>
                <FormControl className="box-input date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={to}
                    onChange={(date) => this.handleDateChange(date, "to")}
                    invalidDateMessage=""
                  />
                </FormControl>
              </div>
            </MuiPickersUtilsProvider>
            <div className="filter_field">
              <Button onClick={this.handleFilter} className="filterButton">
                {t("filter")}
              </Button>
            </div>
          </div>
          <div className="column-rigth">
            <Button onClick={handleList}>{t("change_report_by_users")}</Button>
            <ExcelFile
              element={
                <Button onClick={this.exportList}>
                  {t("export_to_excel")}
                </Button>
              }
            >
              <ExcelSheet data={dataExport} name={t("Desks")}>
                <ExcelColumn label={t("report_group")} value="name" />
                <ExcelColumn label={t("report_total_excel")} value="total" />
                <ExcelColumn label={t("report_used_excel")} value="used" />
                <ExcelColumn
                  label={t("report_percent_excel")}
                  value="percentage"
                />
              </ExcelSheet>
            </ExcelFile>
            <FormControl className="box-input">
              <TextField
                type="text"
                name="search"
                placeholder={t("search")}
                value={searchText}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </div>
        </div>

        <DesksTable
          data={list}
          columns={columns}
          handleCancel={(data) => this.handleCancel(data)}
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></DesksTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  company: state.authReducer.user.parkingManager.company,
  companies: state.userReducer.companies,
  list: state.desks.currentList,
  listAll: state.desks.reportByDeskLots,
  loading: state.desks.loading,
});

const mapActionsToProps = {
  getList: getDeskReportByDeskGroups,
  filterList,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(ByDesks));
