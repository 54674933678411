import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  createStyles,
  FormHelperText,
} from "@material-ui/core";
import Button from "components/common/CustomButtons/Button.js";
import CancelIcon from "@material-ui/icons/Cancel";
import { onDialogClose, onDialogExited } from "../../actions/dialog";
import Loader from "../../utils/Loader";
import { withStyles } from "@material-ui/styles";
import { getCompanyLocation } from "../../utils/EstablishmentsUtils";
import { onFieldChange } from "../../utils/Utils";
import {
  joinCompany,
  saveRequest,
  silentlyJoinCompany,
} from "../../actions/establishments";
import { REQUEST_ESTABLISHMENT, STATE_LOGGED_IN } from "../../Constant";

class JoinCompany extends React.Component {
  constructor(props) {
    super(props);

    const { user, establishment } = this.props;

    let workplace = null;

    if (user.workplaces) {
      workplace = user.workplaces.find((w) => w.company === establishment.id);
    }

    if (user.requestedWorkplaces && !workplace) {
      workplace = user.requestedWorkplaces.find(
        (w) => w.company === establishment.id
      );
    }

    this.state = {
      selectedEstablishment: null,
      editing: false,
      selectedEstablishmentId: "",
      selectedManager: workplace ? workplace.manager : "",
      defaultSelected: {},
      address: "",
      location: workplace && workplace.location ? workplace.location : "",
      errors: {},
      editMode: workplace ? true : false,
    };

    this.handleManagerSelect = this.handleManagerSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFieldChange = onFieldChange.bind(this);
    this.handleEstablishmentSelect = this.handleEstablishmentSelect.bind(this);
  }

  onSubmit() {
    if (this.validInfo()) {
      const { t, state } = this.props;
      const { selectedEstablishment, selectedManager, location } = this.state;

      if (state === STATE_LOGGED_IN) {
        this.props.joinCompany(
          {
            action: REQUEST_ESTABLISHMENT,
            company: selectedEstablishment.id,
            location: location,
            manager: selectedManager,
          },
          t,
          this.isAllowedDomain()
            ? t("joined_company_simplified")
            : t("joined_country_success")
        );
      } else {
        this.props.saveRequest(
          () => {
            this.props.silentlyJoinCompany(
              {
                action: REQUEST_ESTABLISHMENT,
                company: selectedEstablishment.id,
                location: location,
                manager: selectedManager,
              },
              t
            );
          },
          this.isAllowedDomain()
            ? t("register_joined_company_simplified")
            : t("register_joined_country_success")
        );
      }
    }
  }

  isAllowedDomain() {
    const { user } = this.props;
    const { selectedEstablishment } = this.state;

    if (selectedEstablishment.allowedDomains) {
      for (let i = 0; i < selectedEstablishment.allowedDomains.length; i++) {
        const domain =
          "@" + selectedEstablishment.allowedDomains[i].replace("@", "");
        if (user.email.includes(domain)) {
          return true;
        }
      }
    }
    return false;
  }

  validInfo() {
    const { selectedEstablishmentId, selectedManager } = this.state;

    let errors = {};

    if (!selectedEstablishmentId) {
      errors = {
        selectedEstablishmentId: true,
      };
    }
    if (!selectedManager) {
      errors = {
        ...errors,
        selectedManager: true,
      };
    }

    if (Object.entries(errors).length === 0) {
      return true;
    }

    this.setState({
      errors,
    });

    return false;
  }

  componentDidMount() {
    const { establishment, officeBuildings } = this.props;
    let defaultSelected = {};

    if (establishment.companies && establishment.companies.length === 1) {
      establishment.companies.forEach((c) => {
        defaultSelected = c;
      });
      this.setState({
        selectedEstablishment: defaultSelected,
        selectedEstablishmentId: defaultSelected.id,
        address: getCompanyLocation(defaultSelected, officeBuildings),
      });
    } else if (!establishment.companies) {
      defaultSelected = establishment;
      this.setState({
        selectedEstablishment: defaultSelected,
        selectedEstablishmentId: defaultSelected.id,
        address: getCompanyLocation(defaultSelected, officeBuildings),
      });
    }
  }

  renderSubmitButtons() {
    const { t, loading } = this.props;
    const { editMode } = this.state;
    if (editMode) {
      return null;
    }

    return (
      <Button onClick={this.onSubmit} disabled={loading} round color="primary">
        {t("join")}
      </Button>
    );
  }

  handleEstablishmentSelect(ev) {
    const { companies, officeBuildings } = this.props;

    companies.forEach((c) => {
      if (c.id === ev.target.value) {
        this.setState({
          selectedEstablishment: c,
          selectedEstablishmentId: c.id,
          selectedManager: "",
          address: getCompanyLocation(c, officeBuildings),
        });
      }
    });
  }

  handleManagerSelect(ev) {
    this.setState({
      selectedManager: ev.target.value,
    });
  }

  hideManagerName(name) {
    return name.length < 10
      ? name.substr(0, 5) + "*****"
      : name.substr(0, name.length - 5) + "*****";
  }

  render() {
    const {
      t,
      open,
      loading,
      onDialogClose,
      onDialogExited,
      classes,
      establishment,
    } = this.props;
    const {
      selectedEstablishment,
      selectedManager,
      selectedEstablishmentId,
      address,
      location,
      editMode,
      errors,
    } = this.state;

    let items = [];
    let managers = [];

    if (establishment.companies) {
      establishment.companies.forEach((c) => {
        items.push(
          <MenuItem key={c.id} value={c.id}>
            {c.name}
          </MenuItem>
        );
      });
    } else {
      items.push(
        <MenuItem key={establishment.id} value={establishment.id}>
          {establishment.name}
        </MenuItem>
      );
    }

    let currentManagers = selectedEstablishment
      ? selectedEstablishment.managers
      : null;

    if (currentManagers) {
      managers = currentManagers.map((m) => {
        return (
          <MenuItem key={m.id} value={m.id}>
            {editMode ? m.name : this.hideManagerName(m.name)}
          </MenuItem>
        );
      });
    }

    return (
      <Dialog
        className="join-company"
        fullWidth={true}
        maxWidth="sm"
        onClose={onDialogClose}
        onExited={onDialogExited}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>
          {t("join_company")}
          <div className="dialog-title-button">
            <IconButton onClick={onDialogClose} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="dialog" dividers>
          <form className={classes.root}>
            <FormControl
              className={classes.formControl}
              error={errors.selectedEstablishmentId}
            >
              <InputLabel>{t("choose_destination")}</InputLabel>
              <Select
                onChange={this.handleEstablishmentSelect}
                value={selectedEstablishmentId}
                disabled={items.length === 1}
              >
                {items}
              </Select>
              {errors.selectedEstablishmentId && (
                <FormHelperText>{t("must_select_company")}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.formControl}
              error={errors.selectedManager}
            >
              <InputLabel>{t("manager")}</InputLabel>
              <Select
                onChange={this.handleManagerSelect}
                disabled={editMode}
                value={selectedManager}
              >
                {managers}
              </Select>
              {errors.selectedManager && (
                <FormHelperText>{t("must_select_manager")}</FormHelperText>
              )}
            </FormControl>
            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                label={t("company_location")}
                fullWidth
                value={address}
                disabled
              />
            </FormControl>
            <FormControl component="fieldset" className={classes.formControl}>
              <TextField
                label={t("company_details")}
                fullWidth
                disabled={editMode}
                multiline={true}
                name="location"
                onChange={this.onFieldChange}
                value={location}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <div className="loader">
            <Loader className="submit" visible={loading} />
          </div>
          {this.renderSubmitButtons()}
        </DialogActions>
      </Dialog>
    );
  }
}
const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => ({
  state: state.authReducer.state,
  user: state.authReducer.user,
  companies: state.userReducer.companies,
  countries: state.userReducer.countries,
  officeBuildings: state.userReducer.officeBuildings,
  establishment: state.dialogReducer.params.establishment,
  loading: state.establishmentReducer.loading,
});

const actionCreators = {
  onDialogClose: onDialogClose,
  onDialogExited: onDialogExited,
  joinCompany: joinCompany,
  silentlyJoinCompany: silentlyJoinCompany,
  saveRequest: saveRequest,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(JoinCompany)));
