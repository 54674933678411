import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

const CellSchedule = (props) => {
  const classCss = "CellSchedule " + props.status + " " + props.borded;
  return <TableCell className={classCss}></TableCell>;
};
class Schedule extends React.Component {
  constructor(props) {
    super(props);

    let times = [];
    for (let i = 8; i < 18; i++) {
      let hour = i < 10 ? "0" + i : i;
      for (let j = 0; j < 60; j++) {
        let minute = j < 10 ? "0" + j : j;
        times.push(hour + ":" + minute);
      }
    }
    times.push("18:00");

    this.state = {
      open: false,
      times: times,
    };
    this.createData = this.createData.bind(this);
  }

  createData() {
    const { times } = this.state;
    const {
      schedules,
      currentDate,
      currentSF,
      currentStart,
      currentEnd,
      currentID,
    } = this.props;
    let rowsEmpty = [];
    let items = {};
    let status = null;
    let startTime = null;
    let endtime = null;
    let sharedFacilities = null;
    for (let i = 0; i < times.length; i++) {
      rowsEmpty.push("");
    }
    let count_i = schedules.length;
    let count_j = 0;
    let keySF = 0;
    for (let i = 0; i < count_i; i++) {
      if (schedules[i].date === currentDate) {
        if (schedules[i].id === currentID) {
          continue;
        }

        status = schedules[i].status;
        startTime = times.indexOf(schedules[i].beginTime);
        endtime = times.indexOf(schedules[i].endTime);

        sharedFacilities = schedules[i].sharedFacilities;
        count_j = sharedFacilities.length;
        for (let j = 0; j < count_j; j++) {
          keySF = sharedFacilities[j];
          if (!items.hasOwnProperty(keySF)) {
            items[keySF] = [].concat(rowsEmpty);
          }

          for (let h = startTime; h < endtime; h++) {
            items[keySF][h] = status;
          }
        }
      }
    }

    count_j = currentSF ? currentSF.length : 0;
    startTime = times.indexOf(currentStart);
    endtime = times.indexOf(currentEnd) + 120; // +2hrs to cleam
    if (startTime >= 0 && startTime < endtime) {
      for (let j = 0; j < count_j; j++) {
        keySF = currentSF[j];
        if (!items.hasOwnProperty(keySF)) {
          items[keySF] = [].concat(rowsEmpty);
        }
        for (let h = startTime; h < endtime; h++) {
          if (items[keySF][h] !== undefined) {
            if (items[keySF][h] === "") {
              items[keySF][h] = "current";
            }
          }
        }
      }
    }
    return items;
  }

  render() {
    const { times } = this.state;
    const { columns, allColumns } = this.props;
    const rows = this.createData();

    if (typeof columns == "undefined") return "";
    return (
      <TableContainer component={Paper}>
        <Table className="table-schedule" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {allColumns.map((data, j) => {
                if (columns.indexOf(data.id) < 0) return "";
                return (
                  <TableCell key={"col" + j}>
                    {data.name.replace(" - ", `\n`)}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {times.map((time, i) => (
              <TableRow key={i}>
                {i % 60 === 0 && (
                  <TableCell
                    className="borded"
                    component="th"
                    scope="row"
                    rowSpan={i < 600 ? 60 : 1}
                  >
                    {time}
                  </TableCell>
                )}
                {allColumns.map((data, j) => {
                  if (columns.indexOf(data.id) < 0) return "";
                  // let borded = 'borded';
                  let borded = (i + 1) % 60 === 0 ? "borded" : "";
                  if (rows.hasOwnProperty(data.id)) {
                    return (
                      <CellSchedule
                        borded={borded}
                        status={rows[data.id][i]}
                        key={i + "_" + j}
                      />
                    );
                  }
                  return <CellSchedule borded={borded} key={i + "_" + j} />;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default Schedule;
