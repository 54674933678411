import {
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Slider,
} from "@material-ui/core";
import { refreshActionToken } from "actions/auth";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import clsx from "clsx";

const useStyles = makeStyles({
  image: {
    objectFit: "contain",
    cursor: "move",
  },
  imageContainer: {
    height: "50vh",
    overflow: "hidden",
  },
  documentContainer: {
    height: "66vh",
    overflow: "hidden",
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "row",
  },
  slider: {
    marginInline: 20,
  },
  document: {
    width: "100%",
    height: "100%",
  },
  hiddenScroll: {
    overflow: "hidden",
  },
});

const DeskShowLayout = (props) => {
  const [image, setImage] = useState(null);
  const [isDocument, setIsDocument] = useState(null);
  const [zoom, setZoom] = useState(0);
  const [isDraggable, setIsDraggable] = useState(false);
  const [initialPointer, setInitialPointer] = useState(null);
  const imageRef = useRef(null);
  const classes = useStyles();
  const { layoutFile, tokenFile, refreshToken } = props;
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}ws/files/${layoutFile?.id}/file?token=${tokenFile.t}`
    )
      .then((response) => response.blob())
      .then((response) => {
        if (response.size === 0) refreshToken();
        else {
          if (response.type.includes("application/")) {
            setIsDocument(true);
          } else {
            const imageObjectURL = URL.createObjectURL(response);
            setImage(imageObjectURL);
          }
        }
      })
      .catch(() => refreshToken());
  }, [props.tokenFile]);

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 100,
      label: "100",
    },
  ];

  const zoomIn = () => {
    if (zoom < 100) setZoom(zoom + 10);
  };

  const zoomOut = () => {
    if (zoom > 0) setZoom(zoom - 10);
  };

  const onPointerDown = (event) => {
    setIsDraggable(true);
    setInitialPointer({
      x: event.clientX + imageRef.current.scrollLeft,
      y: event.clientY + imageRef.current.scrollTop,
    });
  };

  const onPointerMove = (event) => {
    if (isDraggable) {
      if (initialPointer) {
        imageRef.current.scrollLeft = initialPointer.x - event.clientX;
        imageRef.current.scrollTop = initialPointer.y - event.clientY;
      }
    }
  };

  const onPointerUp = () => {
    setIsDraggable(false);
    setInitialPointer(null);
  };

  return (
    <>
      {image || isDocument ? (
        <Grid container justifyContent="center">
          <Grid
            item
            className={clsx(
              {},
              {
                [classes.imageContainer]: image,
                [classes.documentContainer]: isDocument,
              }
            )}
            xs={12}
            ref={imageRef}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onPointerMove={onPointerMove}
            onMouseUp={onPointerUp}
            onPointerLeave={onPointerUp}
          >
            {image && (
              <img
                id="imageShow"
                src={image}
                alt="layout desk file"
                className={classes.image}
                style={{ width: `${zoom + 100}%`, height: `${zoom + 100}%` }}
                draggable={false}
              />
            )}
            {isDocument && (
              <iframe
                className={classes.document}
                width="100%"
                height="600"
                src={`https://docs.google.com/gview?url=${`${process.env.REACT_APP_BASE_URL}ws/files/${layoutFile?.id}/file?token=${tokenFile.t}`}&embedded=true`}
              ></iframe>
            )}
          </Grid>
          {image && (
            <Grid item xs={12}>
              <div className={classes.sliderContainer}>
                <IconButton
                  aria-label="delete"
                  onClick={zoomOut}
                  color="primary"
                >
                  <RemoveCircleRoundedIcon />
                </IconButton>
                <Slider
                  defaultValue={20}
                  aria-labelledby="discrete-slider-custom"
                  step={10}
                  value={zoom}
                  onChange={(e, value) => setZoom(value)}
                  valueLabelDisplay="auto"
                  marks={marks}
                  className={classes.slider}
                />
                <IconButton
                  aria-label="delete"
                  onClick={zoomIn}
                  color="primary"
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </div>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  layoutFile: state.desks.layout,
  tokenFile: state.authReducer.token_file,
});

const mapActionsToProps = {
  refreshToken: refreshActionToken,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DeskShowLayout);
