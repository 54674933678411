import {
  PARKINGS_FILTERED_LOAD,
  PARKINGS_LOADING,
  PARKINGS_LOADING_END,
  PARKINGS_LOAD,
  PARKINGS_GROUP_LOAD,
  PARKINGS_EMPLOYEES_LOAD,
  PARKINGS_CHECK_PARKING_LOT,
  PARKINGS_CHECK_PARKING_GROUP,
  PARKINGS_LOAD_RESERV_BY_PARKING,
  PARKINGS_LOAD_RESERV_BY_DETAIL,
  PARKINGS_LOAD_REPORT_BY_PARKING,
  PARKINGS_LOAD_REPORT_BY_USERS,
  PARKINGS_ACTION,
} from "../actions/types";

const initialState = {
  loading: false,
  list: [],
  listEmployees: [],
  listGroups: [],
  type: null,
  currentList: [],
  item: null,
  countParkings: null,
  reservationsByParkingLots: [],
  reservationsByDetails: [],
  reportByParkingLots: [],
  reportByUsers: [],
  action: null,
};

export function parkings(state = initialState, action) {
  switch (action.type) {
    case PARKINGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PARKINGS_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case PARKINGS_FILTERED_LOAD:
      return {
        ...state,
        currentList: action.payload.data,
        loading: false,
      };
    case PARKINGS_LOAD:
      return {
        ...state,
        list: action.payload.data,
      };
    case PARKINGS_EMPLOYEES_LOAD:
      return {
        ...state,
        listEmployees: action.payload.data,
      };
    case PARKINGS_GROUP_LOAD:
      return {
        ...state,
        listGroups: action.payload.data,
      };
    case PARKINGS_CHECK_PARKING_LOT:
    case PARKINGS_CHECK_PARKING_GROUP:
      return {
        ...state,
        countParkings: action.payload,
      };
    case PARKINGS_LOAD_RESERV_BY_PARKING:
      return {
        ...state,
        reservationsByParkingLots: action.payload.data,
      };
    case PARKINGS_LOAD_RESERV_BY_DETAIL:
      return {
        ...state,
        reservationsByDetails: action.payload.data,
      };
    case PARKINGS_LOAD_REPORT_BY_PARKING:
      return {
        ...state,
        reportByParkingLots: action.payload.data,
      };
    case PARKINGS_LOAD_REPORT_BY_USERS:
      return {
        ...state,
        reportByUsers: action.payload.data,
      };
    case PARKINGS_ACTION:
      if (state.action === action.payload) return state;

      return {
        ...state,
        action: action.payload,
        currentList: [],
      };
    default:
      return state;
  }
}
