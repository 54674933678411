import React from "react";
// @material-ui/core
import { withStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/common/Grid/GridItem.js";
import Card from "components/common/Card/Card.js";
import CardHeader from "components/common/Card/CardHeader.js";
import CardBody from "components/common/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import Pass from "components/Pass/Pass";
import { List, ListItem, ListItemText, TextField } from "@material-ui/core";
import Loader from "utils/Loader";
import PerfectScrollbar from "perfect-scrollbar";
import "./PassesBlock.scss";
import PassAuthorization from "components/PassAuthorization/PassAuthorization";

class PassesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };

    this.onSearch = this.onSearch.bind(this);
    this.blockRef = React.createRef();
    this.ps = null;
  }

  onSearch(ev) {
    this.setState({
      search: ev.target.value,
    });
  }

  componentDidUpdate() {
    this.ps.update();
  }

  componentWillUnmount() {
    this.ps.destroy();
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar(this.blockRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
  }

  renderPass(pass) {
    const { loading, onClose } = this.props;
    const { search } = this.state;

    if (loading) {
      return null;
    }
    return <Pass onClose={onClose} key={pass.id} pass={pass} filter={search} />;
  }

  renderAuthorization(authorization) {
    const { search } = this.state;
    const { onClose } = this.props;
    return (
      <PassAuthorization
        onClose={onClose}
        key={authorization.id}
        authorization={authorization}
        filter={search}
      />
    );
  }

  render() {
    const { classes, t, passes, title, loading, authorizations } = this.props;

    const blockPasses = passes.map((pass) => {
      return this.renderPass(pass);
    });

    let blockAuthorizations = [];

    if (authorizations) {
      blockAuthorizations = authorizations.map((authorization) => {
        return this.renderAuthorization(authorization);
      });
    }

    return (
      <GridItem xs={12} sm={12} md={6} className="passes-block">
        <Card className="card rounded">
          <CardHeader className="header-card" color="primary">
            <h4 className={classes.passesTitle}>{title}</h4>
          </CardHeader>
          <CardBody className={classes.noPadding}>
            <div className="search">
              <TextField
                id="outlined-search"
                label={t("search_passes")}
                fullWidth
                type="search"
                variant="outlined"
                onChange={this.onSearch}
              />
            </div>

            <div className="loader" hidden={!loading}>
              <Loader visible={loading}></Loader>
            </div>
            <List ref={this.blockRef} className="pass-listing">
              {blockPasses}
              {blockAuthorizations}
              {!loading &&
                blockPasses.length === 0 &&
                blockAuthorizations.length === 0 && (
                  <ListItem>
                    <ListItemText
                      primary={t("no_row_passes")}
                      className="noRow"
                    />
                  </ListItem>
                )}
            </List>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}
export default withTranslation()(withStyles(styles)(PassesBlock));
