import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { createStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withStyles } from "@material-ui/styles";
import { Tab, Tabs } from "@material-ui/core";
import "./Parkings.scss";
import ParkingsList from "components/Parkings/ParkingsList";
import ParkingsGroups from "components/Parkings/ParkingsGroups";
import ParkingsReservations from "components/Parkings/ParkingsReservations";
import ParkingsUsageReports from "components/Parkings/ParkingsUsageReports";
import ParkingsUsers from "components/Parkings/ParkingsUsers";
import MessageDialog from "components/common/MessageDialog/MessageDialog";

import {
  getParkingGroupsList,
  getEmployeesParkingGroups,
} from "../../actions/parking";

const useStyles = createStyles(styles);
const Parkings = (props) => {
  const { t, getParkingGroupsList, getEmployeesParkingGroups } = props;
  const [tabSel, setTabSel] = useState(0);
  const [diagMsj, setDiagMsj] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    getParkingGroupsList(t, false);
    getEmployeesParkingGroups(t, false);
  }, []);

  const showMessaje = (msj) => {
    setDiagMsj({ open: true, message: msj });
  };
  const closeMessaje = () => {
    setDiagMsj({ diagMsj: { open: false, message: "" } });
  };

  const onChangeTabs = (event, newValue) => {
    setTabSel(newValue);
  };

  return (
    <div className="content-box parkingsContent">
      <div className="clearfix">
        <Tabs
          value={tabSel}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChangeTabs}
          className="pull-left"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label={t("parkings")} />
          <Tab label={t("parking_groups")} />
          <Tab label={t("parking_reserv")} />
          <Tab label={t("parking_reports")} />
          <Tab label={t("parking_users")} />
        </Tabs>
      </div>
      {tabSel === 0 && (
        <ParkingsList t={t} showMessaje={showMessaje}></ParkingsList>
      )}
      {tabSel === 1 && (
        <ParkingsGroups t={t} showMessaje={showMessaje}></ParkingsGroups>
      )}
      {tabSel === 2 && (
        <ParkingsReservations
          t={t}
          showMessaje={showMessaje}
        ></ParkingsReservations>
      )}
      {tabSel === 3 && (
        <ParkingsUsageReports
          t={t}
          showMessaje={showMessaje}
        ></ParkingsUsageReports>
      )}
      {tabSel === 4 && (
        <ParkingsUsers t={t} showMessaje={showMessaje}></ParkingsUsers>
      )}
      <MessageDialog
        className="alertDialog"
        open={diagMsj.open}
        message={diagMsj.message}
        onClose={closeMessaje}
      ></MessageDialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.parkings.loading,
});

const actionCreators = {
  getParkingGroupsList: getParkingGroupsList,
  getEmployeesParkingGroups: getEmployeesParkingGroups,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(Parkings)));
