import React from "react";
import ConfirmationDialog from "./ConfirmationDialog";

class ConfirmationDialogStatic extends React.Component {
  static dialog;
  static show({ title, labelNo, labelYes, onAccept }) {
    ConfirmationDialogStatic.dialog.setState({
      open: true,
      title: title || "",
      labelNo: labelNo || "",
      labelYes: labelYes || "",
      onAccept: onAccept ? onAccept : () => {},
    });
  }

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    ConfirmationDialogStatic.dialog = this;
    this.state = {
      open: false,
      title: "",
      labelNo: "",
      labelYes: "",
      onAccept: () => {},
    };
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  submit() {
    this.handleClose();
    this.state.onAccept();
  }

  render() {
    if (ConfirmationDialogStatic.dialog !== this) return <div />;
    const { labelNo, labelYes, open, title } = this.state;
    return (
      <ConfirmationDialog
        open={open}
        onConfirm={this.submit}
        onClose={this.handleClose}
        title={title}
        labelNo={labelNo}
        labelYes={labelYes}
      ></ConfirmationDialog>
    );
  }
}

export default ConfirmationDialogStatic;
