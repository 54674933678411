import {
  ESTABLISHMENT_JOINED,
  ESTABLISHMENT_JOINING,
  ESTABLISHMENT_SET_PENDING,
} from "../actions/types";

const initialState = {
  loading: false,
  pendingRequests: [],
  // Guardamos la request para enviarla despues de que el usuario complete su perfil, porque sino va a enviar una solicitud sin datos de usuario.
  // Gracias Pablo
};

export function establishmentReducer(state = initialState, action) {
  switch (action.type) {
    case ESTABLISHMENT_JOINING:
      return {
        ...state,
        loading: true,
      };
    case ESTABLISHMENT_JOINED:
      return {
        ...state,
        loading: false,
      };
    case ESTABLISHMENT_SET_PENDING:
      let requests = [];
      requests.push(action.payload.request);
      return {
        ...state,
        pendingRequests: requests,
      };
    default:
      return state;
  }
}
