import React from "react";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { createStyles, Fab } from "@material-ui/core";
import MessageDialog from "components/common/MessageDialog/MessageDialog";
import PassDetail from "components/PassDetail/PassDetail";
import { useState } from "react";
import ValidatePassesTable from "components/ValidatePasses/ValidatePassesTable";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import { columns } from "components/ValidatePasses/columns";
import AddIcon from "@material-ui/icons/Add";
import {
  getPassManagerList,
  changePassStatus,
  togglePassParking,
} from "../../actions/passes";
import { onDialogOpen } from "../../actions/dialog";
import "./ValidatePasses.scss";
const useStyles = createStyles(styles);

const ValidatePasses = (props) => {
  const { t, classes, passManagerList } = props;
  const [showConfirmUpdStatus, setShowConfirmUpdStatus] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [updStatus, setUpdStatus] = useState(null);
  const [item, setItem] = useState(null);

  const handleOpenComment = (text) => {
    props.onDialogOpen(MessageDialog, {
      message: text,
    });
  };

  const closeUpdStatus = () => {
    setShowConfirmUpdStatus(false);
    setDialogText("");
  };
  const handleConfirmUpd = () => {
    setShowConfirmUpdStatus(false);
    setDialogText("");
    props.ChangeStatus(t, item, updStatus);
  };

  const handleToggleParking = (item) => {
    props.toggleParking(t, item);
  };

  const handleChangeStatus = (item, status) => {
    let text = t("confirm_text");
    switch (status) {
      case "accepted":
        text = t("pass_mamager_confirm_status_accepted");
        break;
      case "rejected":
        text = t("pass_mamager_confirm_status_rejected");
        break;
      case "canceled":
        text = t("pass_mamager_confirm_status_canceled");
        break;
      default:
    }
    setShowConfirmUpdStatus(true);
    setDialogText(text);
    setUpdStatus(status);
    setItem(item);
  };

  const addPasseHandler = () => {
    props.onDialogOpen(PassDetail, {
      unlimitedPasses: true,
    });
  };

  useEffect(() => {
    props.getPassManagerList(t);
  }, []);

  return (
    <div className="content-box ValidatePassesContent">
      <ValidatePassesTable
        data={passManagerList}
        columns={columns}
        changeStatus={handleChangeStatus}
        toggleParking={handleToggleParking}
        handleOpenComment={handleOpenComment}
        t={t}
      />
      <Fab color="primary" className={classes.fab} onClick={addPasseHandler}>
        <AddIcon />
      </Fab>

      <ConfirmationDialog
        open={showConfirmUpdStatus}
        onConfirm={handleConfirmUpd}
        onClose={closeUpdStatus}
        title={dialogText}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  Passe: state.authReducer.Passe,
  passManagerList: state.passesReducer.passManagerList,
});

const actionCreators = {
  getPassManagerList: getPassManagerList,
  ChangeStatus: changePassStatus,
  toggleParking: togglePassParking,
  onDialogOpen: onDialogOpen,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(ValidatePasses)));
