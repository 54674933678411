import { createStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import sidebarStyle from "../../../../assets/jss/material-dashboard-react/components/sidebarStyle";
import LOGO from "../../../../assets/img/logo.png";

const Brand = (props) => {
  const { classes } = props;
  return (
    <div className={classes.logo}>
      <a href="/" className={classNames(classes.logoLink, {})}>
        <div className={classes.logoImage}>
          <img src={LOGO} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  );
};

const useStyles = createStyles(sidebarStyle);

export default withStyles(useStyles)(Brand);
