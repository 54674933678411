import {
  ROOM_RESERV_INITIAL,
  ROOM_RESERV_LOAD,
  ROOM_RESERV_BASEURL,
  ROOM_RESERV_RELOAD,
  ROOM_RESERV_UPD,
  ROOM_RESERV_REMOVE,
  ROOM_RESERV_GET,
  ROOM_RESERV_NEW,
  ROOM_RESERV_SETTINGS,
  ROOM_RESERV_PURCHASE_ORDER,
  ROOM_RESERV_FILES,
} from "../actions/types";

const initialState = {
  loading: false,
  list: [],
  currentList: [],
  item: null,
  baseURL: "",
  settings: [],
};

export function roomReservation(state = initialState, action) {
  switch (action.type) {
    case ROOM_RESERV_INITIAL:
      return {
        ...state,
        list: action.payload.data,
        currentList: action.payload.data,
      };
    case ROOM_RESERV_LOAD:
      return {
        ...state,
        currentList: action.payload.data,
      };
    case ROOM_RESERV_UPD:
      return {
        ...state,
        item: action.payload.data,
      };
    case ROOM_RESERV_RELOAD: {
      const oObj = action.payload.data;
      const currentList = state.currentList.map((v) => {
        if (oObj.id === v.id) {
          return oObj;
        }
        return v;
      });
      const list = state.list.map((v) => {
        if (oObj.id === v.id) {
          return oObj;
        }
        return v;
      });
      return {
        ...state,
        list: list,
        currentList: currentList,
      };
    }
    case ROOM_RESERV_REMOVE: {
      const ID = action.payload.id;
      const list = state.list.filter((v) => {
        return ID !== v.id;
      });
      const currentList = state.currentList.filter((v) => {
        return ID !== v.id;
      });

      return {
        ...state,
        list: list,
        currentList: currentList,
      };
    }
    case ROOM_RESERV_BASEURL:
      return {
        ...state,
        baseURL: action.payload.data,
      };
    case ROOM_RESERV_GET:
      return {
        ...state,
        item: action.payload.data,
      };
    case ROOM_RESERV_SETTINGS:
      return {
        ...state,
        settings: action.payload.data,
      };
    case ROOM_RESERV_PURCHASE_ORDER:
      return {
        ...state,
        item: {
          ...state.item,
          purchaseOrder: action.payload.id,
          purchaseOrderName: action.payload.name,
          purchaseOrderUrl: null,
        },
      };
    case ROOM_RESERV_FILES: {
      const { otherFiles, otherFilesUrl } = state.item;
      let newOtherFiles = null;
      let newOtherFilesUrl = null;

      if (otherFiles) {
        newOtherFiles = [...otherFiles, action.payload.otherFiles];
      } else newOtherFiles = [action.payload.otherFiles];

      if (otherFilesUrl) {
        newOtherFilesUrl = [...otherFilesUrl, action.payload.otherFilesUrl];
      } else newOtherFilesUrl = [action.payload.otherFilesUrl];

      return {
        ...state,
        item: {
          ...state.item,
          otherFiles: newOtherFiles,
          otherFilesUrl: newOtherFilesUrl,
        },
      };
    }
    case ROOM_RESERV_NEW: {
      /* const item = {"date":"","beginTime":"","endTime":"","sharedFacilities":[],"layout":"","notes":null,"purchaseOrder":null,"otherFiles":[],"requirements":{"chairs":null,"categingTablesInside":null,"categingTablesOutside":null,"speakerTables":null,"assistanceTables":null,"tableCloths":null,"staticScreens":null,"mobileScreens":null,"mobileProjectors":null,"microphone":null,"multipleExtensions":null},"owner":null,"aud_ins_date":"2020-06-23 13:22:14","aud_upd_date":null,"status":"pending","aud_ins_usr":"5eec2877c558452ecc005c02","building":"5e29cab1486f911798004ab2","company":"5e29cac8486f912e30005d42","datetime":"Viernes 26\/06 de 10:00 a 12:00","sharedFacilitiesText":"Sala 1","adminNotes":null,"purchaseOrderUrl":"uploads\/5e272dd2556baf54c75df645\/pdf.pdf","otherFilesUrl":[{"name":"certificado_27010831.pdf","url":"uploads\/5e272dd2556baf54c75df645\/certificado_27010831.pdf"}],"showCancel":false,"showEdit":false,"id":"5ef22c36c558454534000b64"},{"sharedFacilities":["5ee976c9c55845469c0021a2"],"layout":"5ccb56de556baf2a6f3b09e2","date":"2020-07-28","beginTime":"12:00","endTime":"13:00","purchaseOrder":"","requirements":{"chairs":null,"categingTablesInside":null,"categingTablesOutside":null,"speakerTables":null,"assistanceTables":null,"tableCloths":null,"staticScreens":null,"mobileScreens":null,"mobileProjectors":null,"microphone":null,"multipleExtensions":null,"hasRequirements":null},"owner":"Administrador","status":"canceled","notes":"","aud_ins_usr":"5eec2877c558452ecc005c02","building":"5e29cab1486f911798004ab2","company":"5e29cac8486f912e30005d42","datetime":"Martes 28\/07 de 12:00 a 13:00","sharedFacilitiesText":"Sala 1","adminNotes":null,"purchaseOrderUrl":null,"otherFilesUrl":[],"showCancel":false,"showEdit":false,"id":"5ef4260dc5584534dc000342"},*/
      return {
        ...state,
        item: action.payload.data,
      };
    }
    default:
      return state;
  }
}
