import {
  AREAS_INITIAL,
  AREAS_LOAD,
} from "../actions/types";

const initialState = {
  loading: false,
  list: [],
  currentList: [],
};

export function areas(state = initialState, action) {
  switch (action.type) {
    case AREAS_INITIAL:
      return {
        ...state,
        list: action.payload.data,
        currentList: action.payload.data,
      };
    case AREAS_LOAD:
      return {
        ...state,
        currentList: action.payload.data,
      };
    default:
      return state;
  }
}
