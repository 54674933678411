export const columns = [
  {
    Header: "date_time",
    accessor: "time",
    action: "datetime",
  },
  {
    Header: "status",
    accessor: "status",
    action: "iconStatus",
  },
  {
    Header: "instals",
    accessor: "sharedFacilitiesText",
  },
  {
    Header: "documents",
    accessor: "documents",
    action: "documents",
  },
  {
    Header: "admin_notes",
    accessor: "adminNotes",
  },
  {
    Header: "actions",
    action: "actions",
    short: 0,
  },
];
