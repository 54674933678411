import { CHARTER_TRAVEL_STATUS } from "actions/types";
import { CHARTER_ADD_TRAVEL } from "actions/types";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const CharterTravelCell = (props) => {
  const { d } = props;
  const [travelValue, setTravelValue] = React.useState(d.total);
  const [updatedTravel, setUpdatedTravel] = React.useState(d.updated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: CHARTER_ADD_TRAVEL,
      payload: {
        ...d,
        updated: updatedTravel,
        total: Number(travelValue),
        date: Date.now(),
      },
    });
  }, [travelValue]);

  const onChange = (e) => {
    setTravelValue(e);
    setUpdatedTravel(true);
    dispatch({
      type: CHARTER_TRAVEL_STATUS,
      payload: false,
    });
  };

  return (
    <input
      type="number"
      id="inputTravel"
      name="inputTravel"
      min="0"
      value={travelValue}
      onChange={(e) => onChange(e.target.value)}
      disabled={d.enabled}
      style={{
        width: 97,
        backgroundColor: "white",
        textAlign: "center",
        color: "black",
      }}
    />
  );
};

CharterTravelCell.propTypes = {
  d: PropTypes.object,
};

export default CharterTravelCell;
