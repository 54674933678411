import {
  CHARTERS_AVAILABILITY_REQUEST,
  CHARTERS_AVAILABILITY_REQUEST_ERROR,
  CHARTERS_RESERVATIONS_REQUEST,
  CHARTERS_RESERVATIONS_REQUEST_ERROR,
  CHARTERS_SET_CHANGE_MONTH_NEXT,
  CHARTERS_SET_CHANGE_MONTH_PREV,
  CHARTERS_SET_CHANGE_MONTH_STATUS,
  CHARTERS_SET_CURRENT_MONTH,
  CHARTERS_SET_CURRENT_YEAR,
  CHARTERS_SET_VALID_DATA_STATUS,
  CHARTER_RESET_DATA,
  CHARTER_SELECTED_ADD_CLEAR,
  CHARTER_SELECTED_ADD_NEW,
  CHARTER_SELECTED_ADD_REMOVE,
  CHARTER_SELECTED_CANCEL_CLEAR,
  CHARTER_SELECTED_CANCEL_NEW,
  CHARTER_SELECTED_CANCEL_REMOVE,
  CHARTER_SELECTED_DATE,
  CHARTER_UNSELECTED_DATE,
  CHARTER_SELECTED_PARAMS,
  CHARTER_SELECTED_TOKEN,
  CHARTER_GET_REQUESTS,
  CHARTER_GET_REQUESTS_ERROR,
  CHARTER_ADD_TRAVEL,
  CHARTER_REMOVE_TRAVEL,
  CHARTER_GET_REQUESTS_LAST_MONTH,
  CHARTER_GET_REQUESTS_LAST_MONTH_ERROR,
  CHARTER_ADD_ALL_TRAVEL,
  CHARTER_REMOVE_ALL_TRAVEL,
  CHARTER_TRAVEL_STATUS,
  CHARTER_USER_SELECTED_ADD,
  CHARTER_USER_SELECTED_REMOVE,
  CHARTER_GET_RESERVATIONS_DETAIL,
  CHARTER_GET_RESERVATIONS_CHARTER,
  CHARTER_FILTERED_LOAD,
  CHARTER_LOADING,
  CHARTER_LOADING_END,
  CHARTER_USER_ALL_ADD,
  CHARTER_USER_ALL_REMOVE,
  CHARTERS_SET_INVALID_DATA_STATUS,
  CHARTER_RESERVED_ADD,
  CHARTER_SELECT_USER,
  CHARTER_ADD_OLD_SELECTED,
  CHARTER_GET_REPORTS_CHARTER,
  CHARTER_GET_REPORTS_USERS,
  CHARTER_RESERVES_MIXED_STATUS,
  CHARTER_USERS_ALL_SET,
  CHARTER_USERS_ALL_UNSET,
  CHARTER_UPDATE_DATA,
  CHARTER_RESERVED_DELETE,
  CHARTER_RESERVED_ACTIVE_DELETE,
  CHARTER_COMPANY_NAME,
} from "../actions/types";
let date = new Date();
const initialState = {
  charter_reserves_mixed_status: false,
  params: null,
  token_generated: "",
  charter_selected_date: null,
  charters_availability: [],
  charters_reservations: [],
  charters_selected_add: [],
  charters_selected_cancel: [],
  charters_old_selected: [],
  enable_dates: [],
  disable_dates: [],
  available_dates: [],
  no_available_dates: [],
  selected_dates: [],
  availability_status: true,
  reservations_status: true,
  currentYear: date.getFullYear(),
  currentMonth: date.getMonth(),
  changeMonthStatus: false,
  validDateStatus: false,
  invalidDatesStatus: false,
  intentPrev: false,
  intentNext: false,
  charterDataValid: false,
  charter_requests: [],
  charter_request_status: true,
  charter_admin_token: "",
  charter_travel: [],
  charter_travel_status: true,
  charter_requests_last_month: [],
  charter_requests_last_month_status: false,
  charter_users_selected: [],
  charter_users_status: false,
  charter_reservations_detail: [],
  charter_reservation_charter: [],
  charter_reports_charter: [],
  charter_reports_users: [],
  charter_current_list: [],
  charters_reserved: [],
  loading: false,
  charter_select_user: false,
  charter_users_all: [],
  charter_update_data: false,
  company_name: "",
  charters_reservations_active: [],
};
export function charterReducer(state = initialState, action) {
  switch (action.type) {
    case CHARTER_UPDATE_DATA:
      return {
        ...state,
        charter_update_data: action.payload,
      };
    case CHARTER_USERS_ALL_SET:
      return {
        ...state,
        charter_users_all: action.payload,
      };
    case CHARTER_USERS_ALL_UNSET:
      return {
        ...state,
        charter_users_all: action.payload,
      };
    case CHARTER_RESERVES_MIXED_STATUS:
      return {
        ...state,
        charter_reserves_mixed_status: action.payload,
      };
    case CHARTER_GET_REPORTS_CHARTER:
      return {
        ...state,
        charter_reports_charter: action.payload,
      };
    case CHARTER_GET_REPORTS_USERS:
      return {
        ...state,
        charter_reports_users: action.payload,
      };
    case CHARTER_ADD_OLD_SELECTED:
      return {
        ...state,
        charters_old_selected: [...state.charters_old_selected, action.payload],
      };
    case CHARTER_SELECT_USER:
      return {
        ...state,
        charter_select_user: action.payload,
      };
    case CHARTERS_SET_INVALID_DATA_STATUS:
      return {
        ...state,
        invalidDatesStatus: action.payload,
      };
    case CHARTER_RESERVED_ADD:
      return {
        ...state,
        charters_reserved: [...state.charters_reserved, action.payload],
      };
    case CHARTER_RESERVED_DELETE:
      return {
        ...state,
        charters_reserved: state.charters_reserved.filter(
          (c) =>
            c.day !== action.payload.day &&
            c.month !== action.payload.month &&
            c.year !== action.payload.year &&
            c.hour !== action.payload.hour
        ),
      };
    case CHARTER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CHARTER_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case CHARTER_FILTERED_LOAD:
      return {
        ...state,
        charter_current_list: action.payload.data,
        loading: false,
      };
    case CHARTER_USER_SELECTED_ADD:
      return {
        ...state,
        charter_users_selected: [
          ...state.charter_users_selected,
          action.payload,
        ],
      };
    case CHARTER_USER_ALL_ADD:
      return {
        ...state,
        charter_users_selected: action.payload,
      };
    case CHARTER_USER_ALL_REMOVE:
      return {
        ...state,
        charter_users_selected: action.payload,
      };
    case CHARTER_GET_RESERVATIONS_DETAIL:
      return {
        ...state,
        charter_reservations_detail: action.payload,
      };
    case CHARTER_GET_RESERVATIONS_CHARTER:
      return {
        ...state,
        charter_reservation_charter: action.payload,
      };
    case CHARTER_USER_SELECTED_REMOVE:
      return {
        ...state,
        charter_users_selected: state.charter_users_selected.filter(
          (c) => c.id !== action.payload.id
        ),
      };
    case CHARTER_TRAVEL_STATUS:
      return {
        ...state,
        charter_travel_status: action.payload,
      };
    case CHARTER_GET_REQUESTS_LAST_MONTH_ERROR:
      return {
        ...state,
        charter_requests_last_month_status: false,
      };
    case CHARTER_GET_REQUESTS_LAST_MONTH:
      return {
        ...state,
        charter_requests_last_month: action.payload,
      };
    case CHARTER_REMOVE_TRAVEL:
      return {
        ...state,
        charter_travel: state.charter_travel.filter(
          (c) => c.id !== action.payload.id
        ),
      };
    case CHARTER_REMOVE_ALL_TRAVEL:
      return {
        ...state,
        charter_travel: action.payload,
      };
    case CHARTER_ADD_TRAVEL:
      return {
        ...state,
        charter_travel: [...state.charter_travel, action.payload],
      };
    case CHARTER_ADD_ALL_TRAVEL:
      return {
        ...state,
        charter_travel: action.payload,
      };
    case CHARTER_GET_REQUESTS_ERROR:
      return {
        ...state,
        charter_request_status: false,
      };
    case CHARTER_GET_REQUESTS:
      return {
        ...state,
        charter_requests: action.payload,
      };
    case CHARTER_SELECTED_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case CHARTER_SELECTED_TOKEN:
      return {
        ...state,
        token_generated: action.payload,
      };
    case CHARTER_RESET_DATA:
      return initialState;
    case CHARTERS_SET_CHANGE_MONTH_PREV:
      return {
        ...state,
        intentPrev: action.payload,
      };
    case CHARTERS_SET_CHANGE_MONTH_NEXT:
      return {
        ...state,
        intentNext: action.payload,
      };
    case CHARTERS_SET_CHANGE_MONTH_STATUS:
      return {
        ...state,
        changeMonthStatus: action.payload,
      };
    case CHARTERS_SET_VALID_DATA_STATUS:
      return {
        ...state,
        validDateStatus: action.payload,
      };
    case CHARTERS_SET_CURRENT_MONTH:
      return {
        ...state,
        currentMonth: action.payload,
      };
    case CHARTERS_SET_CURRENT_YEAR:
      return {
        ...state,
        currentYear: action.payload,
      };
    case CHARTERS_AVAILABILITY_REQUEST:
      return {
        ...state,
        charters_availability: action.payload,
      };
    case CHARTER_SELECTED_ADD_NEW:
      return {
        ...state,
        charters_selected_add: [...state.charters_selected_add, action.payload],
      };
    case CHARTER_SELECTED_ADD_REMOVE:
      return {
        ...state,
        charters_selected_add: state.charters_selected_add.filter(
          (c) => c.id !== action.payload.id
        ),
      };
    case CHARTER_SELECTED_ADD_CLEAR:
      return {
        ...state,
        charters_selected_add: action.payload,
      };
    case CHARTER_SELECTED_CANCEL_NEW:
      return {
        ...state,
        charters_selected_cancel: [
          ...state.charters_selected_cancel,
          action.payload,
        ],
      };
    case CHARTER_SELECTED_CANCEL_REMOVE:
      return {
        ...state,
        charters_selected_cancel: state.charters_selected_cancel.filter(
          (c) => c.day !== action.payload.day
        ),
      };
    case CHARTER_SELECTED_CANCEL_CLEAR:
      return {
        ...state,
        charters_selected_cancel: action.payload,
      };
    case CHARTERS_AVAILABILITY_REQUEST_ERROR:
      return {
        ...state,
        availability_status: action.payload,
      };
    case CHARTERS_RESERVATIONS_REQUEST:
      return {
        ...state,
        charters_reservations: action.payload,
      };
    case CHARTERS_RESERVATIONS_REQUEST_ERROR:
      return {
        ...state,
        reservations_status: action.payload,
      };
    case CHARTER_SELECTED_DATE:
      return {
        ...state,
        charter_selected_date: action.payload,
      };
    case CHARTER_UNSELECTED_DATE:
      return {
        ...state,
        charter_selected_date: action.payload,
      };
    case CHARTER_RESERVED_ACTIVE_DELETE:
      return {
        ...state,
        charters_reservations_active: state.charters_reservations_active.filter(
          (c) => c.id !== action.payload.id
        ),
      };
    case CHARTER_COMPANY_NAME:
      return {
        ...state,
        company_name: action.payload,
      };
    default:
      return state;
  }
}
