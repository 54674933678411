import { Button, Menu, MenuItem } from "@material-ui/core";
import { useGridSlotComponentProps } from "@material-ui/data-grid";
import { Pagination } from "@material-ui/lab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useState } from "react";

const CustomPagination = () => {
  const { state, apiRef } = useGridSlotComponentProps();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value) => {
    apiRef.current.setPageSize(value);
    handleClose();
  };

  const listRowsPerPage = [25, 50, 100];

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<VisibilityIcon />}
        onClick={handleClick}
      >
        {state.pagination.pageSize}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {listRowsPerPage.map((value, i) => (
          <MenuItem
            key={i}
            onClick={() => handleChange(value)}
            selected={state.pagination.pageSize === value}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
      <Pagination
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </div>
  );
};

export default CustomPagination;
