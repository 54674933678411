import React, { useEffect } from "react";

import {
  CircularProgress,
  Grid,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import MaUTable from "@material-ui/core/Table";
import InfoIcon from "@material-ui/icons/Info";
import { tooltipClasses } from "@mui/material";
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import moment from "moment";
import PropTypes from "prop-types";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import CharterTravelCell from "./CharterTravelCell";
import "./CharterTravelsTable.scss";

export const columns = [
  {
    Header: "travels",
    accessor: "time",
    action: "datetime",
  },
  {
    Header: "monday",
    accessor: "status",
    action: "iconStatus",
  },
  {
    Header: "tuesday",
    accessor: "sharedFacilitiesText",
  },
  {
    Header: "wednesday",
    accessor: "documents",
    action: "documents",
  },
  {
    Header: "thursday",
    accessor: "adminNotes",
  },
  {
    Header: "friday",
    accessor: "friday",
  },
  {
    Header: "saturday",
    action: "actions",
    short: 0,
  },
  {
    Header: "sunday",
    action: "actions",
    short: 0,
  },
];

export function getDisableWeek(id) {
  const dates = [
    {
      disabled: false,
      updated: false,
      id: id,
      day: 2,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 3,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 4,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 5,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 6,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 7,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: false,
      updated: false,
      id: id,
      day: 1,
      total: 0,
      count: 0,
      date: null,
    },
  ];
  return dates;
}
function getDisableMonthPastWeek(id) {
  const dates = [
    {
      disabled: true,
      message: true,
      updated: false,
      id: id,
      day: 2,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: true,
      message: true,
      updated: false,
      id: id,
      day: 3,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: true,
      message: true,
      updated: false,
      id: id,
      day: 4,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: true,
      message: true,
      updated: false,
      id: id,
      day: 5,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: true,
      message: true,
      updated: false,
      id: id,
      day: 6,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: true,
      message: true,
      updated: false,
      id: id,
      day: 7,
      total: 0,
      count: 0,
      date: null,
    },
    {
      disabled: true,
      message: true,
      updated: false,
      id: id,
      day: 1,
      total: 0,
      count: 0,
      date: null,
    },
  ];
  return dates;
}

const CharterTravelsTable = (props) => {
  let date = new Date();
  let nowMonth = date.getMonth() + 1;
  const {
    t,
    data,
    setCurrentPage,
    globalPageIndex,
    currentMonth,
    currentYear,
    isComplete,
  } = props;
  const [loading, setLoading] = React.useState(true);
  const [localData, setLocalData] = React.useState([]);
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: localData,
      initialState: {
        pageIndex: globalPageIndex,
        sortBy: [
          {
            id: "time",
            desc: false,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    renderCalendar();
  }, [data]);

  useEffect(() => {
    setLocalData([]);
  }, [isComplete]);

  const renderCalendar = () => {
    if (!data.length) {
      setLocalData([]);
      return;
    }
    setLocalData(
      data.map((d) => {
        d.calendar = generateCalendar(
          d.calendar,
          d.id,
          d.expirationDate,
          d.minimumRequestTime
        );
        return d;
      })
    );
    setLoading(false);
  };

  const isValidDifference = (
    currentMonthCharter,
    currentYearCharter,
    minimumRequestTime
  ) => {
    let result = 0;
    let status = true;
    let date = new Date();
    let currentYearToday = date.getFullYear();
    let currentMonthToday = date.getMonth() + 2;
    let currentMonthDay = date.getDate();
    let date1 = moment([currentYearCharter, currentMonthCharter, 1]);
    let date2 = moment([currentYearToday, currentMonthToday, currentMonthDay]);
    result = date1.diff(date2, "days", false);
    if (result <= Number(minimumRequestTime)) {
      status = false;
    }
    return status;
  };

  const generateCalendar = (
    calendar,
    id,
    expirationDate,
    minimumRequestTime
  ) => {
    let calendarGenerated = [];
    let expirationMonth = 0;
    let minimumStatus = false;

    if (minimumRequestTime) {
      minimumStatus = isValidDifference(
        currentMonth,
        currentYear,
        minimumRequestTime
      );
    }
    if (expirationDate) {
      expirationMonth = expirationDate.substring(5, 7);
    }
    if (calendar.length === 0 && currentMonth === nowMonth) {
      calendarGenerated = getDisableMonthPastWeek(id);
    } else {
      if (calendar.length === 0) {
        calendarGenerated = getDisableWeek(id);
      } else {
        calendar.forEach((d) => {
          if (currentMonth === nowMonth) {
            calendarGenerated.push({
              disabled: true,
              message: true,
              updated: false,
              id: id,
              day: d.day,
              total: d.total,
              count: 0,
              date: null,
            });
          } else {
            if (currentMonth > Number(expirationMonth) && expirationDate) {
              calendarGenerated.push({
                disabled: true,
                updated: false,
                id: id,
                day: d.day,
                total: d.total,
                count: 0,
                date: null,
              });
            } else {
              if (minimumRequestTime && minimumStatus) {
                calendarGenerated.push({
                  disabled: true,
                  updated: false,
                  id: id,
                  day: d.day,
                  total: d.total,
                  count: 0,
                  date: new Date(),
                });
              } else {
                calendarGenerated.push({
                  disabled: false,
                  updated: false,
                  id: id,
                  day: d.day,
                  total: d.total,
                  count: 0,
                  date: new Date(),
                });
              }
            }
          }
        });
      }
    }
    return calendarGenerated;
  };

  const getColumnProp = (column, headerData) => {
    return Object.assign({}, column, { title: t(headerData.Header) });
  };

  const printColumnHead = (column) => {
    return <span>{t(`${column.render("Header")}`)}</span>;
  };

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(10),
      border: "1px solid #dadde9",
      whiteSpace: "pre",
    },
  }));

  return (
    <div className="body-box">
      {loading ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ flexGrow: 1 }}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <div>
          <TableContainer>
            <MaUTable {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup, i) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
                    {headerGroup.headers.map((column, j) => (
                      <TableCell
                        {...getColumnProp(
                          column.getHeaderProps(),
                          headerGroup.headers[j]
                        )}
                        key={j}
                        className={j === 0 ? "" : ""}
                      >
                        {printColumnHead(column)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <p>
                          {`${row.original.hour}hs - ${row.original.name}`}{" "}
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <p className="tools">
                                  {row.original.description}
                                </p>
                              </React.Fragment>
                            }
                            placement="top-start"
                          >
                            <InfoIcon
                              style={{
                                width: "0.6em",
                                marginTop: 1,
                                marginBottom: -6,
                                marginLeft: 9,
                              }}
                            ></InfoIcon>
                          </HtmlTooltip>
                        </p>
                      </TableCell>
                      {row.original.calendar.map((d, j) => {
                        return (
                          <TableCell key={j + 1}>
                            {d.message ? (
                              <Tooltip
                                title={t("charter_reserve_msj_blocked_days")}
                                placement="top-start"
                              >
                                <input
                                  type="number"
                                  id="quantity"
                                  name="quantity"
                                  min="0"
                                  value={d.total}
                                  disabled={d.disabled}
                                  style={{
                                    width: 97,
                                    backgroundColor: "white",
                                    textAlign: "center",
                                    color: "black",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <CharterTravelCell d={d} />
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: t("all"), value: data.length },
                    ]}
                    colSpan={12}
                    count={data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={(params) => {
                      return t("pagination")
                        .replace("%%FROM%%", params.from)
                        .replace("%%TO%%", params.to)
                        .replace("%%TOTAL%%", params.count);
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </MaUTable>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

CharterTravelsTable.propTypes = {
  data: PropTypes.array.isRequired,
  t: PropTypes.func,
  globalPageIndex: PropTypes.number,
  setCurrentPage: PropTypes.func,
  currentMonth: PropTypes.number,
  currentYear: PropTypes.number,
  isComplete: PropTypes.bool,
};

export default CharterTravelsTable;
