import React from "react";
import ByDetails from "./reservations/ByDetails";
import ByDesks from "./reservations/ByDesks";

class DesksReservations extends React.Component {
  constructor(props) {
    super(props);
    this.handleList = this.handleList.bind(this);

    this.state = {
      list: "Desks",
    };
  }

  handleList() {
    const { list } = this.state;
    if (list === "Desks") this.setState({ list: "details" });
    else this.setState({ list: "Desks" });
  }

  render() {
    const { list } = this.state;

    if (list === "Desks")
      return <ByDesks handleList={this.handleList}></ByDesks>;
    else return <ByDetails handleList={this.handleList}></ByDetails>;
  }
}

export default DesksReservations;
