import { FormControl } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SPACES_EDIT_CREDIT_USERS } from "../../actions/types";
const CellActions = (props) => {
  const value = props.rowData.original.credit;
  const period = props.rowData.original.period;
  const [travelValue, setTravelValue] = React.useState(value);
  const column = props.data.column;
  let cellContent = props.data.render("Cell");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    setTravelValue(value);
  }, [value]);
  if (typeof column.action === "undefined" || !column.action) {
    return <div style={{ padding: "14px 0px" }}>{cellContent}</div>;
  }
  const onChange = (value) => {
    setTravelValue(value);
    dispatch({
      type: SPACES_EDIT_CREDIT_USERS,
      payload: {
        id: props.data.row.original.id,
        credit: Number(value),
      },
    });
  };

  const getMonthsCredits = () => {
    return [
      {
        value: 1,
        name: t("january"),
      },
      {
        value: 2,
        name: t("february"),
      },
      {
        value: 3,
        name: t("march"),
      },
      {
        value: 4,
        name: t("april"),
      },
      {
        value: 5,
        name: t("may"),
      },
      {
        value: 6,
        name: t("june"),
      },
      {
        value: 7,
        name: t("july"),
      },
      {
        value: 8,
        name: t("august"),
      },
      {
        value: 9,
        name: t("september"),
      },
      {
        value: 10,
        name: t("october"),
      },
      {
        value: 11,
        name: t("november"),
      },
      {
        value: 12,
        name: t("december"),
      },
    ];
  };

  switch (column.action) {
    case "credit": {
      return (
        <FormControl className="box-input">
          <input
            type="number"
            min="0"
            value={travelValue}
            onChange={(e) => onChange(e.target.value)}
            style={{
              textAlign: "center",
            }}
          />
        </FormControl>
      );
    }
    case "balance_credit": {
      return (
        <p style={{ marginTop: 5, marginBottom: 5 }}>
          {value + " " + t("space_balance_balance_name")}
        </p>
      );
    }
    case "balance_credit_date": {
      const currentYear = period.slice(0, 4);
      const monthNumber = Number(period.slice(-2));
      const months = getMonthsCredits();
      const monthFind = months.find((m) => m.value === monthNumber);
      return monthFind.name + " " + currentYear;
    }
  }
};
export default CellActions;
