import React from "react";
// @material-ui/core
import { withStyles, createStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withTranslation } from "react-i18next";
import { DialogActions, DialogTitle, Dialog } from "@material-ui/core";
import Button from "components/common/CustomButtons/Button.js";
import { connect } from "react-redux";
import { onDialogClose, onDialogExited } from "../../../actions/dialog";

const useStyles = createStyles(styles);

class MessageDialog extends React.Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.props.onDialogClose();
  }

  render() {
    const { t, open, onDialogExited, message, className } = this.props;

    return (
      <Dialog
        onClose={this.onClose}
        className={className + " confirmation-dialog"}
        open={open}
        TransitionProps={{ onExited: onDialogExited }}
      >
        <DialogTitle>{message}</DialogTitle>

        <DialogActions className="dialog-buttons">
          <Button round onClick={this.onClose} color="primary">
            {t("accept")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({});

const actionCreators = {
  onDialogClose: onDialogClose,
  onDialogExited: onDialogExited,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withStyles(useStyles)(MessageDialog)));
