export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const LOGOUT = "USERS_LOGOUT";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_FRIENDS = "GET_FRIENDS";
export const GLOBAL_OPTIONS_REQUEST = "GLOBAL_OPTIONS_REQUEST";
export const GLOBAL_OPTIONS_SUCCESS = "GLOBAL_OPTIONS_SUCCESS";
export const GLOBAL_OPTIONS_ERROR = "GLOBAL_OPTIONS_ERROR";
export const HOST_INVITES_REQUEST = "HOST_INVITES_REQUEST";
export const HOST_INVITES_SUCCESS = "HOST_INVITES_SUCCESS";
export const HOST_INVITES_ERROR = "HOST_INVITES_ERROR";
export const AUTHORIZATIONS_REQUEST = "AUTHORIZATIONS_REQUEST";
export const AUTHORIZATIONS_SUCCESS = "AUTHORIZATIONS_SUCCESS";
export const AUTHORIZATIONS_ERROR = "AUTHORIZATIONS_ERROR";
export const DIALOG_OPEN = "DIALOG_OPEN";
export const DIALOG_CLOSE = "DIALOG_CLOSE";
export const DIALOG_EXIT = "DIALOG_EXIT";
export const DIALOG_MODIFIED = "DIALOG_MODIFIED";
export const DIALOG_CLEAR_MODIFIED = "DIALOG_CLEAR_MODIFIED";
export const DIALOG_HIDE_CONFIRMATION = "DIALOG_HIDE_CONFIRMATION";
export const PASS_UPDATING = "PASS_UPDATING";
export const PASS_UPDATED = "PASS_UPDATED";
export const PASS_CREATING = "PASS_CREATING";
export const PASS_CREATED = "PASS_CREATED";
export const PASS_ERROR = "PASS_ERROR";
export const PASS_GETTING = "PASS_GETTING";
export const PASS_ACQUIRED = "PASS_ACQUIRED";
export const PASS_MANAGER_LOAD = "PASS_MANAGER_LOAD";
export const PASS_MANAGER_UPD = "PASS_MANAGER_UPD";
export const PASS_MANAGER_REMOVE = "PASS_MANAGER_REMOVE";
export const DYNAMIC_LINK_CREATING = "DYNAMIC_LINK_CREATING";
export const DYNAMIC_LINK_CREATED = "DYNAMIC_LINK_CREATED";
export const DYNAMIC_LINK_ERROR = "DYNAMIC_LINK_ERROR";
export const DYNAMIC_LINK_CLEAR = "DYNAMIC_LINK_CLEAR";
export const PHONE_VERIFY_REQUEST = "PHONE_VERIFY_REQUEST";
export const PHONE_VERIFY_REQUESTED = "PHONE_VERIFY_REQUESTED";
export const PHONE_VERIFY_ERROR = "PHONE_VERIFY_ERROR";
export const PHONE_VERIFY_SEND = "PHONE_VERIFY_SEND";
export const PHONE_VERIFY_CANCEL = "PHONE_VERIFY_CANCEL";
export const PHONE_VERIFYING = "PHONE_VERIFYING";
export const PHONE_VERIFIED = "PHONE_VERIFIED";
export const PHONE_VERIFIED_ERROR = "PHONE_VERIFIED_ERROR";
export const ESTABLISHMENT_JOINING = "ESTABLISHMENT_JOINING";
export const ESTABLISHMENT_JOINED = "ESTABLISHMENT_JOINED";
export const USER_UPDATING = "USER_UPDATING";
export const USER_UPDATED = "USER_UPDATED";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";
export const USER_WHATSAPP_CODE = "USER_WHATSAPP_CODE";
export const USER_TOKEN_FILE = "USER_TOKEN_FILE";
export const USER_REQUEST_TOKEN_FILE = "USER_REQUEST_TOKEN_FILE";
export const USER_ASSOCIATION = "USER_ASSOCIATION";
export const CLEAR_ASSOCIATION = "CLEAR_ASSOCIATION";
export const USER_CLEAR = "USER_CLEAR";
export const ENABLE_ASSOCIATION = "ENABLE_ASSOCIATION";
export const SET_EXTERNAL_USER = "SET_EXTERNAL_USER";
export const SET_EXTERNAL_USER_FACE = "SET_EXTERNAL_USER_FACE";
export const SET_EXTERNAL_USER_FACE_EMAIL_VALIDATION =
  "SET_EXTERNAL_USER_FACE_EMAIL_VALIDATION";
export const SET_EXTERNAL_USER_TOKEN = "SET_EXTERNAL_USER_TOKEN";
export const CLOSE_LOADING_EXTERNAL_USER = "CLOSE_LOADING_EXTERNAL_USER";
export const SET_CUSTOM_EXTERNAL_USER = "SET_CUSTOM_EXTERNAL_USER";
export const SET_ALLOW_FACEID_EXTERNAL_USER = "SET_ALLOW_FACEID_EXTERNAL_USER";
export const ESTABLISHMENT_SET_PENDING = "ESTABLISHMENT_SET_PENDING";
export const ESTABLISHMENT_CLEAR_PENDING = "ESTABLISHMENT_CLEAR_PENDING";
export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_FINISHED = "NOTIFICATIONS_FINISHED";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
export const COMPANY_STAFF_LOAD = "COMPANY_STAFF_LOAD";
export const COMPANY_STAFF_LOAD_ACTIVE = "COMPANY_STAFF_LOAD_ACTIVE";
export const COMPANY_STAFF_LOAD_REQUEST = "COMPANY_STAFF_LOAD_REQUEST";
export const COMPANY_STAFF_LOAD_UNASSIG = "COMPANY_STAFF_LOAD_UNASSIG";
export const COMPANY_STAFF_RELOAD_ACTIVE = "COMPANY_STAFF_RELOAD_ACTIVE";
export const COMPANY_STAFF_UPD = "COMPANY_STAFF_UPD";
export const COMPANY_STAFF_UPD_ACTIVE = "COMPANY_STAFF_UPD_ACTIVE";
export const COMPANY_STAFF_UPD_REQUEST = "COMPANY_STAFF_UPD_REQUEST";
export const COMPANY_STAFF_UPD_UNASSIG = "COMPANY_STAFF_UPD_UNASSIG";
export const COMPANY_STAFF_REMOVE_ACTIVE = "COMPANY_STAFF_REMOVE_ACTIVE";
export const COMPANY_STAFF_REMOVE_REQUEST = "COMPANY_STAFF_REMOVE_REQUEST";
export const COMPANY_STAFF_REMOVE_UNASSIG = "COMPANY_STAFF_REMOVE_UNASSIG";
export const COMPANY_STAFF_MANAGERS = "COMPANY_STAFF_MANAGERS";
export const COMPANY_STAFF_SAVE_PERSONAL = "COMPANY_STAFF_SAVE_PERSONAL";
export const COMPANY_STAFF_STATUS = "COMPANY_STAFF_STATUS";
export const COMPANY_STAFF_OPEN_PASS_MANAGER =
  "COMPANY_STAFF_OPEN_PASS_MANAGER";
export const COMPANY_STAFF_OPEN_STAFF_MANAGER =
  "COMPANY_STAFF_OPEN_STAFF_MANAGER";
export const AREAS_INITIAL = "AREAS_INITIAL";
export const AREAS_LOAD = "AREAS_LOAD";
export const ROOM_RESERV_INITIAL = "ROOM_RESERV_INITIAL";
export const ROOM_RESERV_LOAD = "ROOM_RESERV_LOAD";
export const ROOM_RESERV_BASEURL = "ROOM_RESERV_BASEURL";
export const ROOM_RESERV_RELOAD = "ROOM_RESERV_RELOAD";
export const ROOM_RESERV_UPD = "ROOM_RESERV_UPD";
export const ROOM_RESERV_REMOVE = "ROOM_RESERV_REMOVE";
export const ROOM_RESERV_GET = "ROOM_RESERV_GET";
export const ROOM_RESERV_NEW = "ROOM_RESERV_NEW";
export const ROOM_RESERV_SETTINGS = "ROOM_RESERV_SETTINGS";
export const ROOM_RESERV_PURCHASE_ORDER = "ROOM_RESERV_PURCHASE_ORDER";
export const ROOM_RESERV_FILES = "ROOM_RESERV_FILES";
export const PARKINGS_LOADING = "PARKINGS_LOADING";
export const PARKINGS_LOADING_END = "PARKINGS_LOADING_END";
export const PARKINGS_FILTERED_LOAD = "PARKINGS_FILTERED_LOAD";
export const PARKINGS_LOAD = "PARKINGS_LOAD";
export const PARKINGS_GROUP_LOAD = "PARKINGS_GROUP_LOAD";
export const PARKINGS_EMPLOYEES_LOAD = "PARKINGS_EMPLOYEES_LOAD";
export const PARKINGS_CHECK_PARKING_GROUP = "PARKINGS_CHECK_PARKING_GROUP";
export const PARKINGS_LOAD_RESERV_BY_PARKING =
  "PARKINGS_LOAD_RESERV_BY_PARKING";
export const PARKINGS_LOAD_RESERV_BY_DETAIL = "PARKINGS_LOAD_RESERV_BY_DETAIL";
export const PARKINGS_LOAD_REPORT_BY_PARKING =
  "PARKINGS_LOAD_REPORT_BY_PARKING";
export const PARKINGS_LOAD_REPORT_BY_USERS = "PARKINGS_LOAD_REPORT_BY_USERS";
export const PARKINGS_CHECK_PARKING_LOT = "PARKINGS_CHECK_PARKING_LOT";
export const PARKINGS_ACTION = "PARKINGS_ACTION";
export const DESKS_LOADING = "DESKS_LOADING";
export const DESKS_LOADING_END = "DESKS_LOADING_END";
export const DESKS_FILTERED_LOAD = "DESKS_FILTERED_LOAD";
export const DESKS_LOAD = "DESKS_LOAD";
export const DESKS_GROUP_LOAD = "DESKS_GROUP_LOAD";
export const DESKS_EMPLOYEES_LOAD = "DESKS_EMPLOYEES_LOAD";
export const DESKS_CHECK_DESKS_GROUP = "DESKS_CHECK_DESKS_GROUP";
export const DESKS_LOAD_RESERV_BY_DESK = "DESKS_LOAD_RESERV_BY_DESK";
export const DESKS_LOAD_RESERV_BY_DETAIL = "DESKS_LOAD_RESERV_BY_DETAIL";
export const DESKS_LOAD_REPORT_BY_DESK = "DESKS_LOAD_REPORT_BY_DESK";
export const DESKS_LOAD_REPORT_BY_USERS = "DESKS_LOAD_REPORT_BY_USERS";
export const DESKS_CHECK_DESKS_LOT = "DESKS_CHECK_DESKS_LOT";
export const DESKS_ACTION = "DESKS_ACTION";
export const DESKS_LAYOUT = "DESKS_LAYOUT";
export const EVENT_INIT = "EVENT_INIT";
export const EVENT_REQUEST = "EVENT_REQUEST";
export const EVENT_CHANGE_FROM = "EVENT_CHANGE_FROM";
export const EVENT_CHANGE_TO = "EVENT_CHANGE_TO";
export const EVENT_CHANGE_FILTER = "EVENT_CHANGE_FILTER";
export const EVENT_CHANGE_FILTER_QUERY = "EVENT_CHANGE_FILTER_QUERY";
export const CHARTERS_AVAILABILITY_REQUEST = "CHARTERS_AVAILABILITY_REQUEST";
export const CHARTERS_AVAILABILITY_REQUEST_ERROR =
  "CHARTERS_AVAILABILITY_REQUEST_ERROR";
export const CHARTERS_RESERVATIONS_REQUEST = "CHARTERS_RESERVATIONS_REQUEST";
export const CHARTERS_RESERVATIONS_REQUEST_ERROR =
  "CHARTERS_RESERVATIONS_REQUEST_ERROR";
export const CHARTER_SELECTED_ADD_NEW = "CHARTER_SELECTED_ADD_NEW";
export const CHARTER_SELECTED_ADD_REMOVE = "CHARTER_SELECTED_ADD_REMOVE";
export const CHARTER_SELECTED_ADD_CLEAR = "CHARTER_SELECTED_ADD_CLEAR";
export const CHARTER_SELECTED_CANCEL_NEW = "CHARTER_SELECTED_CANCEL_NEW";
export const CHARTER_SELECTED_CANCEL_REMOVE = "CHARTER_SELECTED_CANCEL_REMOVE";
export const CHARTER_SELECTED_CANCEL_CLEAR = "CHARTER_SELECTED_CANCEL_CLEAR";
export const CHARTER_SELECTED_DATE = "CHARTER_SELECTED_DATE";
export const CHARTERS_SET_CURRENT_YEAR = "CHARTERS_SET_CURRENT_YEAR";
export const CHARTERS_SET_CURRENT_MONTH = "CHARTERS_SET_CURRENT_MONTH";
export const CHARTERS_SET_CHANGE_MONTH_STATUS =
  "CHARTERS_SET_CHANGE_MONTH_STATUS";
export const CHARTERS_SET_VALID_DATA_STATUS = "CHARTERS_SET_VALID_DATA_STATUS";
export const CHARTERS_SET_CHANGE_MONTH_PREV = "CHARTERS_SET_CHANGE_MONTH_PREV";
export const CHARTERS_SET_CHANGE_MONTH_NEXT = "CHARTERS_SET_CHANGE_MONTH_NEXT";
export const CHARTER_SET_VALID_DATA = "CHARTER_SET_VALID_DATA";
export const CHARTER_UNSELECTED_DATE = "CHARTER_UNSELECTED_DATE";
export const CHARTER_RESET_DATA = "CHARTER_RESET_DATA";
export const CHARTER_SELECTED_PARAMS = "CHARTER_SELECTED_PARAMS";
export const CHARTER_SELECTED_TOKEN = "CHARTER_SELECTED_TOKEN";
export const CHARTER_GET_REQUESTS = "CHARTER_GET_REQUESTS";
export const CHARTER_GET_REQUESTS_ERROR = "CHARTER_GET_REQUESTS_ERROR";
export const CHARTER_ADD_TRAVEL = "CHARTER_ADD_TRAVEL";
export const CHARTER_REMOVE_TRAVEL = "CHARTER_REMOVE_TRAVEL";
export const CHARTER_GET_REQUESTS_LAST_MONTH =
  "CHARTER_GET_REQUESTS_LAST_MONTH";
export const CHARTER_GET_REQUESTS_LAST_MONTH_ERROR =
  "CHARTER_GET_REQUESTS_LAST_MONTH_ERROR";
export const CHARTER_ADD_ALL_TRAVEL = "CHARTER_ADD_ALL_TRAVEL";
export const CHARTER_REMOVE_ALL_TRAVEL = "CHARTER_REMOVE_ALL_TRAVEL";
export const CHARTER_TRAVEL_STATUS = "CHARTER_TRAVEL_STATUS";
export const CHARTER_USER_SELECTED_ADD = "CHARTER_USER_SELECTED_ADD";
export const CHARTER_USER_SELECTED_REMOVE = "CHARTER_USER_SELECTED_REMOVE";
export const CHARTER_USER_ALL_ADD = "CHARTER_USER_ALL_ADD";
export const CHARTER_USER_ALL_REMOVE = "CHARTER_USER_ALL_REMOVE";
export const CHARTER_GET_RESERVATIONS_DETAIL =
  "CHARTER_GET_RESERVATIONS_DETAIL";
export const CHARTER_GET_RESERVATIONS_CHARTER =
  "CHARTER_GET_RESERVATIONS_CHARTER";
export const CHARTER_FILTERED_LOAD = "CHARTER_FILTERED_LOAD";
export const CHARTER_LOADING = "CHARTER_LOADING";
export const CHARTER_LOADING_END = "CHARTER_LOADING_END";
export const CHARTER_RESERVED_ADD = "CHARTER_RESERVED_ADD";
export const CHARTER_RESERVED_DELETE = "CHARTER_RESERVED_DELETE";
export const CHARTERS_SET_INVALID_DATA_STATUS =
  "CHARTERS_SET_INVALID_DATA_STATUS";
export const CHARTER_SELECT_USER = "CHARTER_SELECT_USER";
export const CHARTER_ADD_OLD_SELECTED = "CHARTER_ADD_OLD_SELECTED";
export const CHARTER_GET_REPORTS_CHARTER = "CHARTER_GET_REPORTS_CHARTER";
export const CHARTER_GET_REPORTS_USERS = "CHARTER_GET_REPORTS_USERS";
export const CHARTER_RESERVES_MIXED_STATUS = "CHARTER_RESERVES_MIXED_STATUS";
export const CHARTER_USERS_ALL_SET = "CHARTER_USERS_ALL_SET";
export const CHARTER_USERS_ALL_UNSET = "CHARTER_USERS_ALL_UNSET";
export const CHARTER_UPDATE_DATA = "CHARTER_UPDATE_DATA";
export const CHARTER_COMPANY_NAME = "CHARTER_COMPANY_NAME";
export const CHARTER_RESERVED_ACTIVE_DELETE = "CHARTER_RESERVED_ACTIVE_DELETE";
export const SPACES_GET_RESERVATIONS = "SPACES_GET_RESERVATIONS";
export const SPACES_GET_CREDIT_USERS = "SPACES_GET_CREDIT_USERS";
export const SPACES_GET_CREDIT_USERS_FILTERED =
  "SPACES_GET_CREDIT_USERS_FILTERED";
export const SPACES_EDIT_CREDIT_USERS = "SPACES_EDIT_CREDIT_USERS";
export const SPACES_RESERVATIONS_REQUEST_ERROR =
  "SPACES_RESERVATIONS_REQUEST_ERROR";
export const SPACE_LOADING = "SPACE_LOADING";
export const SPACE_LOADING_END = "SPACE_LOADING_END";
export const SPACE_FILTERED_LOAD = "SPACE_FILTERED_LOAD";
export const SPACE_GET_RESERVATIONS_FILTER = "SPACE_GET_RESERVATIONS_FILTER";
export const SPACES_GET_CREDIT_BALANCE = "SPACES_GET_CREDIT_BALANCE";
export const SPACE_FILTERED_LOAD_CREDIT = "SPACE_FILTERED_LOAD_CREDIT";
export const MENU_MY_QR_CLICKED = "MENU_MY_QR_CLICKED";
export const MENU_MY_GUESTS_CLICKED = "MENU_MY_GUESTS_CLICKED";
export const MENU_NOTIFICATION_ITEM_TOUCHED = "MENU_NOTIFICATION_ITEM_TOUCHED";
export const MENU_UPDATE_FACEPHOTO_TAKE = "MENU_UPDATE_FACEPHOTO_TAKE";
export const MENU_UPDATE_FACEPHOTO_TAKED = "MENU_UPDATE_FACEPHOTO_TAKED";
export const MENU_UPDATE_FACEPHOTO_UPLOAD = "MENU_UPDATE_FACEPHOTO_UPLOAD";
export const HANDLE_MENU_MOBILE = "HANDLE_MENU_MOBILE";
