import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PASS_TYPE_REQUEST } from "../../Constant";
import { PassContext } from "../../context/PassContext";
import {
  findEstablishment,
  getPassUser,
  getPassUsers,
  getUserFullname,
  isPendingUser,
} from "../../utils/Utils";
import ItemLabel from "../common/ItemLabel/ItemLabel";
import Pass from "./Pass";

const PassList = ({ permanent, ocasional, requests }) => {
  const {
    hostInvites,
    authorizations,
    companies,
    countries,
    friends,
  } = useSelector((state) => state.userReducer);
  const { token } = useSelector((state) => state.authReducer);
  const { query } = useContext(PassContext);
  const { t } = useTranslation();
  const [hostInvitesFilter, setHostInvitesFilter] = useState([]);
  const [authorizationFilter, setAuthorizationFilter] = useState([]);

  const isSearching = (pass) => {
    const q = query.toLowerCase();
    if (q === "") return true;
    const establishment = findEstablishment(pass, companies, countries);
    const user = getPassUser(pass, friends, token);
    const users = getPassUsers(pass, friends, token);

    if (
      getUserFullname(user)
        .toLowerCase()
        .includes(q)
    )
      return true;

    if (establishment?.name.toLowerCase().includes(q)) return true;

    for (let i in users) {
      if (users[i] != null) {
        if (!isPendingUser(users[i])) {
          let userFilter = getUserFullname(users[i]).toLowerCase();
          if (userFilter.includes(q)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const validatePass = (pass) => {
    return (
      (permanent && pass.calendar) ||
      (ocasional && !pass.calendar) ||
      (requests && pass.type === PASS_TYPE_REQUEST)
    );
  };

  const getLabel = () => {
    if (query !== "") return t("pass_label_no_results");
    else return t(requests ? "no_row_requests" : "no_row_passes");
  };

  useEffect(() => {
    hostInvites &&
      hostInvites?.length !== 0 &&
      setHostInvitesFilter(
        hostInvites.filter((pass) => validatePass(pass) && isSearching(pass))
      );

    authorizations &&
      authorizations?.length !== 0 &&
      setAuthorizationFilter(
        authorizations.filter((pass) => validatePass(pass) && isSearching(pass))
      );
  }, [hostInvites, authorizations, query]);

  return (
    <>
      {hostInvitesFilter?.length !== 0 || authorizationFilter?.length !== 0 ? (
        <>
          {hostInvitesFilter.map((pass) => (
            <Pass key={pass.id} pass={pass} invite />
          ))}
          {authorizationFilter.map((pass) => (
            <Pass key={pass.id} pass={pass} authorization />
          ))}
        </>
      ) : (
        <ItemLabel label={getLabel()} />
      )}
    </>
  );
};

export default PassList;
