import React from "react";

// core components
import Loader from "utils/Loader";

class Loading extends React.Component {
  render() {
    const { loading } = this.props;
    if (loading)
      return (
        <div className="loading_diag">
          <Loader className="loading " visible={loading} />
        </div>
      );
    else return null;
  }
}

export default Loading;
