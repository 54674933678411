import { Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useStyle } from "./AccessEventStyle";
import EventFilter from "../../components/AccessEvent/AccessEventFilter/AccessEventFilter";
import AccessEventTable from "../../components/AccessEvent/AccessEventTable/AccessEventTable";

const AccessEvent = (props) => {
  const classes = useStyle();
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = props;

  const onChangeTabs = (e, value) => setTabIndex(value);

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tab}
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={onChangeTabs}
      >
        <Tab label={t("access_event_tab")} />
      </Tabs>
      {tabIndex === 0 && (
        <div className={classes.container}>
          <EventFilter />
          <AccessEventTable />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(AccessEvent);
