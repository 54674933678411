import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Button,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import ConfirmationDialog from "components/common/ConfirmationDialog/ConfirmationDialog";
import { TextField, FormControl } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DesksTable from "./DesksTables";
import { columns } from "./columns/desks_groups";
import {
  getDeskGroupsList,
  addDeskGroup,
  filterList,
  editDeskGroup,
  deleteDeskGroup,
  checkDeleteDeskGroup,
} from "../../actions/desk";

class DesksGroups extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.openNewGroup = this.openNewGroup.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendItem = this.sendItem.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.state = {
      open: false,
      dialogText: "",
      groupName: "",
      idGroup: null,
      showConfirm: false,
      searchText: "",
      globalPageIndex: 0,
    };
    this.props.getDeskGroupsList(t);
  }

  setCurrentPage(page) {
    this.setState({
      globalPageIndex: page,
    });
  }

  openNewGroup() {
    this.setState({ open: true, dialogText: "", groupName: "", idGroup: null });
  }

  onClose() {
    this.setState({ open: false, dialogText: "" });
  }

  handleChange(event) {
    this.setState({ groupName: event.target.value });
  }

  handleSearch(event) {
    const text = event.target.value;
    this.setState({ searchText: text, globalPageIndex: 0 });
    this.props.filterList(
      this.props.listAll.filter((v) => {
        return v.name && v.name.search(new RegExp(text, "i")) > -1;
      })
    );
  }

  sendItem() {
    const { t, showMessaje, editDeskGroup, addDeskGroup } = this.props;
    const { idGroup, groupName } = this.state;
    if (typeof groupName === "undefined" || groupName.trim() === "") {
      showMessaje(t("error_add_empty_DeskGroupNames"));
      return;
    }
    if (idGroup) {
      editDeskGroup(t, idGroup, groupName);
    } else {
      addDeskGroup(t, groupName);
    }
    this.setState({ open: false, groupName: "", idGroup: null });
  }

  handleUpdate(data) {
    this.setState({ open: true, groupName: data.name, idGroup: data.id });
  }

  handleCancel(data) {
    this.props.checkDeleteDeskGroup(this.props.t, data.id);
    this.setState({
      showConfirm: true,
      groupName: data.name,
      idGroup: data.id,
    });
  }

  handleConfirmDelete() {
    const { idGroup } = this.state;
    this.props.deleteDeskGroup(this.props.t, idGroup);
    this.setState({ showConfirm: false, groupName: "", idGroup: null });
  }

  closeConfirm() {
    this.setState({ showConfirm: false, groupName: "", idGroup: null });
  }

  render() {
    const { t, list, countDesks, loading } = this.props;
    const {
      open,
      searchText,
      groupName,
      showConfirm,
      idGroup,
      globalPageIndex,
    } = this.state;

    return (
      <div className="body-box">
        <div className="search-box-2">
          <FormControl className="box-input">
            <TextField
              type="text"
              placeholder={t("search")}
              name="search"
              value={searchText}
              onChange={this.handleSearch}
            ></TextField>
          </FormControl>
        </div>
        <DesksTable
          data={list}
          columns={columns}
          handleUpdate={(data) => this.handleUpdate(data)}
          handleCancel={(data) => this.handleCancel(data)}
          t={t}
          globalPageIndex={globalPageIndex}
          setCurrentPage={this.setCurrentPage}
          loading={loading}
        ></DesksTable>
        <Fab color="primary" onClick={this.openNewGroup}>
          <AddIcon />
        </Fab>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          onClose={this.onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.sendItem();
            }}
          >
            <DialogTitle onClose={this.onClose}>
              {idGroup ? t("edit_desk_group") : t("add_desk_group")}
            </DialogTitle>
            <DialogContent className="dialog" dividers>
              <FormControl className="full-input">
                <TextField
                  type="text"
                  label={t("name")}
                  name="newGroup"
                  value={groupName}
                  onChange={this.handleChange}
                  autoFocus
                ></TextField>
              </FormControl>
            </DialogContent>
            <DialogActions className="button-group">
              <Button className="buttom-reservation" onClick={this.onClose}>
                {" "}
                {t("cancel")}
              </Button>
              <Button
                color="primary"
                onClick={this.sendItem}
                className="buttom-reservation"
              >
                {t("accept")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <ConfirmationDialog
          open={showConfirm && countDesks !== false}
          onConfirm={this.handleConfirmDelete}
          onClose={this.closeConfirm}
          title={t("confirm_deskGroup_remove", { countDesks: countDesks })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  list: state.desks.currentList,
  listAll: state.desks.listGroups,
  countDesks: state.desks.countDesks,
  loading: state.desks.loading,
});

const mapActionsToProps = {
  getDeskGroupsList: getDeskGroupsList,
  addDeskGroup: addDeskGroup,
  editDeskGroup: editDeskGroup,
  deleteDeskGroup: deleteDeskGroup,
  filterList: filterList,
  checkDeleteDeskGroup,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(DesksGroups));
