import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class LloadStaffManagers extends React.Component {
  constructor(props) {
    super(props);
    const { current } = this.props;
    this.state = { value: typeof current !== "undefined" ? current : "" };
    this.saveStaffManagers = this.saveStaffManagers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  saveStaffManagers() {
    const { saveStaffManagers } = this.props;
    saveStaffManagers(this.state.value);
  }
  render() {
    const {
      t,
      handleClose,
      openStaffManagers,
      staffManagers,
      currentCompanyManager,
      classe,
    } = this.props;
    return (
      <div>
        <Dialog
          open={openStaffManagers}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {t("select_a_staff_manager1")}
            <br />
            {t("select_a_staff_manager2")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <FormControl className={classe}>
              <Select
                labelname="staffManager-label"
                name="staffManager"
                label={t("user_manager")}
                onChange={this.handleChange}
                fullWidth={true}
                value={this.state.value}
              >
                {staffManagers.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    disabled={data.id === currentCompanyManager}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("cancel")}
            </Button>
            <Button onClick={this.saveStaffManagers} color="primary">
              {t("save")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  openStaffManagers: state.companyStaff.openStaffManagers,
  staffManagers: state.companyStaff.managers,
});

const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation()(LloadStaffManagers));
