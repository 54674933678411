//companyStaff
import PassAppApi from "../services/PassAppApi";
import SnackbarMessage from "../utils/SnackbarMessage";
import {
  COMPANY_STAFF_LOAD,
  COMPANY_STAFF_LOAD_ACTIVE,
  COMPANY_STAFF_LOAD_REQUEST,
  COMPANY_STAFF_LOAD_UNASSIG,
  COMPANY_STAFF_RELOAD_ACTIVE,
  COMPANY_STAFF_UPD_ACTIVE,
  COMPANY_STAFF_UPD_REQUEST,
  COMPANY_STAFF_UPD_UNASSIG,
  COMPANY_STAFF_REMOVE_ACTIVE,
  COMPANY_STAFF_REMOVE_REQUEST,
  COMPANY_STAFF_REMOVE_UNASSIG,
  COMPANY_STAFF_MANAGERS,
  COMPANY_STAFF_STATUS,
  COMPANY_STAFF_OPEN_PASS_MANAGER,
  COMPANY_STAFF_OPEN_STAFF_MANAGER,
} from "./types";

export const getCompanyStaffList = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  PassAppApi.getCompanyStaff(token)
    .then((response) => {
      dispatch({
        type: COMPANY_STAFF_LOAD_ACTIVE,
        payload: { data: response.data },
      });
      dispatch({
        type: COMPANY_STAFF_LOAD,
        payload: { data: response.data },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_CompanyStaff")
      );
    });
};
export const getCompanyStaffRequests = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  PassAppApi.getCompanyStaffRequests(token)
    .then((response) => {
      dispatch({
        type: COMPANY_STAFF_LOAD_REQUEST,
        payload: { data: response.data },
      });
      dispatch({
        type: COMPANY_STAFF_LOAD,
        payload: { data: response.data },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_CompanyStaff")
      );
    });
};
export const getCompanyStaffUnassigned = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;
  PassAppApi.getCompanyStaffUnassigned(token)
    .then((response) => {
      dispatch({
        type: COMPANY_STAFF_LOAD_UNASSIG,
        payload: { data: response.data },
      });
      dispatch({
        type: COMPANY_STAFF_LOAD,
        payload: { data: response.data },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_CompanyStaff")
      );
    });
};
export const filterCompanyStaff = (listItems, search) => {
  return listItems.filter((v) => {
    if (v.name && v.name.search(new RegExp(search, "i")) > -1) return true;
    if (v.dni && v.dni.search(new RegExp(search, "i")) > -1) return true;
    if (v.phone && v.phone.search(new RegExp(search, "i")) > -1) return true;
    if (v.email && v.email.search(new RegExp(search, "i")) > -1) return true;
    if (v.place && v.place.search(new RegExp(search, "i")) > -1) return true;
    return false;
  });
};
export const filterCompanyStaffList = (staffList, param) => (dispatch) => {
  const result = filterCompanyStaff(staffList, param);
  dispatch({
    type: COMPANY_STAFF_LOAD,
    payload: { data: result },
  });
};

export const changeCompanyStaffList = (staffList) => (dispatch) => {
  dispatch({
    type: COMPANY_STAFF_LOAD,
    payload: { data: staffList },
  });
};

const errorCompanyStaffStatus = (err, t) => {
  SnackbarMessage.show("error", t("error") + ": " + err);
};

export const changeCompanyStaffStatus = (t, item, action, type, searchText) => (
  dispatch,
  getState
) => {
  const token = getState().authReducer.token;

  PassAppApi.updateCompanyStaffStatus(token, { id: item.id, action: action })
    .then(() => {
      /* Load the updated Item */
      switch (type) {
        case 0: // getCompanyStaffList
          PassAppApi.getCompanyStaff(token, { id: item.id })
            .then((response) => {
              if (typeof response?.id != "undefined") {
                dispatch({
                  type: COMPANY_STAFF_UPD_ACTIVE,
                  payload: { obj: response, searchText: searchText },
                });
              } else {
                dispatch({
                  type: COMPANY_STAFF_REMOVE_ACTIVE,
                  payload: { id: item.id },
                });
                SnackbarMessage.show("success", t("company_staff_removed"));
              }
            })
            .catch((err) => errorCompanyStaffStatus(err, t));
          break;
        case 1:
          PassAppApi.getCompanyStaffRequests(token, { id: item.id })
            .then((response) => {
              if (typeof response?.id != "undefined") {
                dispatch({
                  type: COMPANY_STAFF_UPD_REQUEST,
                  payload: { obj: response, searchText: searchText },
                });
              } else {
                dispatch({
                  type: COMPANY_STAFF_REMOVE_REQUEST,
                  payload: { id: item.id },
                });

                if (action === "approve") {
                  SnackbarMessage.show("success", t("company_staff_approved"));
                } else {
                  SnackbarMessage.show("success", t("company_staff_rejected"));
                }
              }
            })
            .catch((err) => errorCompanyStaffStatus(err, t));
          break;
        case 2:
          PassAppApi.getCompanyStaffUnassigned(token, { id: item.id })
            .then((response) => {
              if (typeof response?.id != "undefined") {
                dispatch({
                  type: COMPANY_STAFF_UPD_UNASSIG,
                  payload: { obj: response, searchText: searchText },
                });
                SnackbarMessage.show("success", t("company_staff_updated"));
              } else {
                dispatch({
                  type: COMPANY_STAFF_REMOVE_UNASSIG,
                  payload: { id: item.id },
                });
              }
            })
            .catch((err) => {
              const msg = err ? err.toString() : "";
              errorCompanyStaffStatus(msg, t);
            });
          break;
        default:
      }
    })
    .catch((err) => {
      const msg = err ? err.toString() : "";
      errorCompanyStaffStatus(msg, t);
    });
};
export const getCompanyManagers = (t) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  PassAppApi.getCompanyManagers(token)
    .then((response) => {
      dispatch({
        type: COMPANY_STAFF_MANAGERS,
        payload: { data: response },
      });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        err ? err.toString() : t("error_getting_CompanyStaff")
      );
    });
};
export const saveCompanyStaff = (t, obj) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  const param = {
    id: obj.id,
    options: {
      manager: obj.manager,
      passManager: obj.passManager,
      isStaffManager: obj.isStaffManager,
      newStaffManager: obj.newStaffManager,
      isPassManager: obj.isPassManager,
      newPassManager: obj.newPassManager,
      isFacilitiesManager: obj.isFacilitiesManager,
      isParkingManager: obj.isParkingManager,
      isDeskManager: obj.isDeskManager,
      isAdmin: obj.isAdmin,
      areas: obj.areas,
      adminComment: obj.adminComment,
    },
  };
  PassAppApi.saveCompanyStaff(token, param)
    .then(() => {
      PassAppApi.getCompanyStaff(token)
        .then((response) => {
          dispatch({
            type: COMPANY_STAFF_RELOAD_ACTIVE,
            payload: { data: response.data },
          });
          dispatch({
            type: COMPANY_STAFF_STATUS,
            payload: true,
          });
          SnackbarMessage.show("success", t("company_staff_personal_updated"));
        })
        .catch((err) => {
          SnackbarMessage.show(
            "error",
            t("error_saving_CompanyStaff") + ": " + (err ? err.toString() : "")
          );
        });
    })
    .catch((err) => {
      SnackbarMessage.show(
        "error",
        t("error_saving_CompanyStaff") + ": " + (err ? err.toString() : "")
      );
    });
};

export const checkPassManagerEmployees = (t, obj) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  const param = {
    id: obj.id,
  };
  return PassAppApi.checkPassManagerEmployees(token, param).catch((err) => {
    SnackbarMessage.show(
      "error",
      t("error") + ": " + (err ? err.toString() : "")
    );
  });
};

export const checkStaffManagerEmployees = (t, obj) => (dispatch, getState) => {
  const token = getState().authReducer.token;

  const param = {
    id: obj.id,
  };
  return PassAppApi.checkStaffManagerEmployees(token, param).catch((err) => {
    SnackbarMessage.show(
      "error",
      t("error") + ": " + (err ? err.toString() : "")
    );
  });
};

export const openPassManagers = (open) => (dispatch) => {
  dispatch({
    type: COMPANY_STAFF_OPEN_PASS_MANAGER,
    payload: { open },
  });
};

export const openStaffManagers = (open) => (dispatch) => {
  dispatch({
    type: COMPANY_STAFF_OPEN_STAFF_MANAGER,
    payload: { open },
  });
};