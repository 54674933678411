import React from "react";

import {
  TimerOff,
  Check,
  DriveEta,
  SmsOutlined,
  Alarm,
  Clear,
  NotInterested,
} from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";

const CellActions = (prop) => {
  const value = prop.data.value;
  const t = prop.t;
  const data = prop.rowData.original;
  const column = prop.data.column;
  let cellContent = prop.data.render("Cell");
  let { btn_acept, btn_reject, btn_cancel } = "";
  if (typeof column.action === "undefined" || !column.action) {
    let tooltipField = column.id + "Tooltip";
    let style = {};
    if (column.id === "date" || column.id === "hour") {
      style = { whiteSpace: "nowrap" };
    }
    if (typeof data[tooltipField] !== "undefined") {
      return (
        <div style={style} title={data[tooltipField]}>
          {cellContent}
        </div>
      );
    } else {
      return <div style={style}>{cellContent}</div>;
    }
  }

  switch (column.action) {
    case "iconStatus": {
      let iconStatu = <TimerOff />;
      let tooltip = t(value);
      switch (value) {
        case "accepted":
          iconStatu = <Check color="primary" />;
          break;
        case "pending":
          iconStatu = <Alarm />;
          break;
        case "finished":
          iconStatu = <TimerOff />;
          break;
        case "canceled":
          iconStatu = <Clear />;
          break;
        case "rejected":
          iconStatu = <NotInterested />;
          break;
        case "expired":
          tooltip = t("authorization_expired");
          break;
        case "revoked":
          tooltip = t("revoked");
          break;
        case "used":
          tooltip = t("pass_status_used");
          break;
        default:
      }

      cellContent = (
        <Tooltip title={tooltip} aria-label={t("accepted")}>
          {iconStatu}
        </Tooltip>
      );
      break;
    }
    case "toggleParking": {
      if (data.status === "accepted") {
        if (value || value === "true") {
          cellContent = (
            <Button onClick={() => prop.handleToggleParking(data)}>
              <DriveEta color="primary" />
            </Button>
          );
        } else {
          cellContent = (
            <Button onClick={() => prop.handleToggleParking(data)}>
              <DriveEta />
            </Button>
          );
        }
      } else {
        if (value || value === "true") {
          cellContent = (
            <Button disabled>
              <DriveEta color="primary" />
            </Button>
          );
        } else {
          cellContent = (
            <Button disabled>
              <DriveEta />
            </Button>
          );
        }
      }
      break;
    }
    case "actions": {
      if (value) {
        cellContent = (
          <Button
            onClick={() => prop.handleOpenComment(value)}
            title={t("show_comment")}
          >
            <SmsOutlined />
          </Button>
        );
      } else {
        cellContent = null;
      }

      if (data.showAccept)
        btn_acept = (
          <Button
            onClick={() => prop.handleChangeStatus(data, "accept")}
            color="primary"
            title={t("accept")}
          >
            <Check />
          </Button>
        );
      if (data.showReject)
        btn_reject = (
          <Button
            onClick={() => prop.handleChangeStatus(data, "reject")}
            title={t("reject")}
          >
            <Clear />
          </Button>
        );
      if (data.showCancel)
        btn_cancel = (
          <Button
            onClick={() => prop.handleChangeStatus(data, "cancel")}
            title={t("cancel")}
          >
            <Clear />
          </Button>
        );

      break;
    }
    default:
  }

  return (
    <div className="columnsContent">
      {cellContent}
      {btn_acept}
      {btn_reject}
      {btn_cancel}
    </div>
  );
};

export default CellActions;
