import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../actions/user";
import Notification from "../../components/NotificationsApp/Notification";

const NotificationsApp = () => {
  const { notifications } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getNotifications(t));
  }, []);

  return (
    <>
      {notifications?.map((item) => <Notification key={item.id} {...item} />)}
      {notifications?.length === 0 && <Notification noRow />}
    </>
  );
};

export default NotificationsApp;
