import React from "react";
import { IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

const AlertShow = (props) => {
  return (
    <>
      {props.in ? (
        <Alert
          severity={props.type}
          color={props.color ? props.color : props.type}
          className={props.className}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={props.onClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.children}
        </Alert>
      ) : null}
    </>
  );
};

export default AlertShow;
