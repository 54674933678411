import React from "react";
import ByUsers from "./reports/ByUsers";
import ByDesks from "./reports/ByDesks";

class DesksUsageReports extends React.Component {
  constructor(props) {
    super(props);
    this.handleList = this.handleList.bind(this);

    this.state = {
      list: "users",
    };
  }

  handleList() {
    const { list } = this.state;
    if (list === "Desks") this.setState({ list: "users" });
    else this.setState({ list: "Desks" });
  }

  render() {
    const { list } = this.state;

    if (list === "Desks")
      return <ByDesks handleList={this.handleList}></ByDesks>;
    else return <ByUsers handleList={this.handleList}></ByUsers>;
  }
}

export default DesksUsageReports;
