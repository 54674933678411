import { SvgIcon } from "@material-ui/core";
import React from "react";

const QrCodeIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="IconQRCode">
        <g id="Group">
          <path id="Vector" d="M0 32H32V0H0V32ZM8 8H24V24H8V8Z" />
          <path id="Vector_2" d="M0 72H32V40H0V72ZM8 48H24V64H8V48Z" />
          <path id="Vector_3" d="M40 0V32H72V0H40ZM64 24H48V8H64V24Z" />
          <path id="Vector_4" d="M72 64H64V72H72V64Z" />
          <path id="Vector_5" d="M48 40H40V48H48V40Z" />
          <path id="Vector_6" d="M56 48H48V56H56V48Z" />
          <path id="Vector_7" d="M48 56H40V64H48V56Z" />
          <path id="Vector_8" d="M56 64H48V72H56V64Z" />
          <path id="Vector_9" d="M64 56H56V64H64V56Z" />
          <path id="Vector_10" d="M64 40H56V48H64V40Z" />
          <path id="Vector_11" d="M72 48H64V56H72V48Z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default QrCodeIcon;
